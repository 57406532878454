import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "@design-system-etat/dsfr-react";
import "./EcranDesFonctionnalites.css";
import { UtilisateurProfilCategorieEnum } from "../../constantes/UtilisateurProfilCategorieEnum";
import { UtilisateurDroitEnum } from "../../constantes/UtilisateurDroitEnum";
import MenuFiche from "./composants/menu/MenuFiche";
import MenuExport from "./composants/menu/MenuExport";
import MenuDemande from "./composants/menu/MenuDemande";
import MenuRechercheArme from "./composants/menu/MenuRechercheArme";
import MenuAdministration from "./composants/menu/MenuAdministration";
import {
  EcranFonctionnaliteApiService,
  SessionService,
} from "../../infrastructure/InfrastructureFactory";
import { fr } from "../../constantes/ClassesDSFR";
import IndicateurEcranFonctionnalite from "../../entites/indicateurEcranFonctionnalite";
import { useAlerteContext } from "../../composants/RGAContext";
import Alerte, { typeAlerte } from "../../entites/Alerte";

const EcranDesFonctionnalites: React.FC = () => {
  const [profil, setProfil] = useState<UtilisateurProfilCategorieEnum>(
    UtilisateurProfilCategorieEnum.PUBLIC
  );
  const [indicateur, setIndicateur] = useState<IndicateurEcranFonctionnalite>({
    creerDemandeFiche: 0,
    gererCompte: 0,
    traiterDemandesFichesEtReferentiels: 0,
  });
  const { mettreAjourAlerte } =
    useAlerteContext();

  useEffect(() => {
    setProfil(SessionService.getProfilCategorie());
    EcranFonctionnaliteApiService.getIndicateurEcranFonctionnalite()
      .then((indicateur: IndicateurEcranFonctionnalite) => {
        setIndicateur(indicateur);
      })
      .catch((error) => {
        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, '', "Erreur lors de la récupération des indicateurs de la page des fonctionnalités."));
      });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  function titreEcran() {
    return (
      <>
        <h1 style={{ fontWeight: "bold" }} className={fr.mb_1w}>
          Bienvenue sur le Référentiel Général des Armes
        </h1>
        <div className={fr.mb_5w + "fr-display-xl"}>
          Vous trouverez ci-dessous la liste des actions qui vous sont
          proposées.
        </div>
      </>
    );
  }

  function aAuMoinsUnDroitAdmin() {
    return (
      SessionService.utilisateurALeDroit(
        UtilisateurDroitEnum.GERER_APPLICATION
      ) ||
      SessionService.utilisateurALeDroit(UtilisateurDroitEnum.GERER_DROITS) ||
      SessionService.utilisateurALeDroit(
        UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS
      ) ||
      SessionService.utilisateurALeDroit(
        UtilisateurDroitEnum.CONSULTER_TRACES
      ) ||
      SessionService.utilisateurALeDroit(UtilisateurDroitEnum.GERER_COMPTES)
    );
  }

  function afficherTuilleSelonProfil() {
    const menuFicheVisible = SessionService.utilisateurALeDroit(
      UtilisateurDroitEnum.CREER_FICHE
    );
    const menuRechercheVisible = SessionService.utilisateurALeDroit(
      UtilisateurDroitEnum.CONSULTER_FICHES
    );
    const menuDemandeVisible =
      (profil === UtilisateurProfilCategorieEnum.SCAE &&
        SessionService.utilisateurALeDroit(
          UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS
        )) ||
      (profil === UtilisateurProfilCategorieEnum.PRO &&
        (SessionService.utilisateurALeDroit(
          UtilisateurDroitEnum.CREER_DEMANDE_FICHE
        ) ||
          SessionService.utilisateurALeDroit(
            UtilisateurDroitEnum.IMPORTER_DEMANDES_FICHES_MASSE
          )));
    return (
      <>
        {menuRechercheVisible && (
          <Col>
            <MenuRechercheArme />
          </Col>
        )}
        {menuFicheVisible && (
          <Col>
            <MenuFiche />
          </Col>
        )}
        {menuDemandeVisible && (
          <Col>
            <MenuDemande
              nombreDemandeProfilPro={indicateur.creerDemandeFiche}
              demandeEnAttenteDeTraitement={
                indicateur.traiterDemandesFichesEtReferentiels
              }
              profil={profil}
            />
          </Col>
        )}
        <Col>
          <MenuExport />
        </Col>
      </>
    );
  }

  return (
    <Container
      margeExterne={false}
      taille={"lg"}
      className="container-fonctionnalites"
    >
      {titreEcran()}
      <Row goutierre className="cardRow">
        {afficherTuilleSelonProfil()}
      </Row>
      <br />
      {(profil === UtilisateurProfilCategorieEnum.SCAE ||
        aAuMoinsUnDroitAdmin()) && (
        <Row className="cardRow">
          <Col col={12}>
            <MenuAdministration
              nombreDemandeNouveauCompte={indicateur.gererCompte}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default EcranDesFonctionnalites;

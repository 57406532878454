import {Control, Controller, FieldErrors, UseFormRegister} from "react-hook-form";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import {AutoCompleteAvecSaisieRGA, AutoCompleteRGA} from "../../../composants/SelectHorsDsfr/AutoCompleteRGA";
import {ReferentielAPIEnum} from "../../../constantes/ReferentielAPIEnum";
import {Input} from "@design-system-etat/dsfr-react/lib/cjs";
import {InfoBulle} from "../../../composants/InfoBulle";
import CaracteristiqueLectureSeule from "./CaracteristiqueLectureSeule";
import React, {useEffect, useState} from "react";
import {ReferentielLibreInexistant} from "../../../entites/ReferentielLibre";
import Arme from "../../../entites/Arme";
import FormulaireArme from "../../../entites/FormulaireArme";
import {FiltreAPI} from "../../../entites/FiltreAPI";
import {chargerReferentiel} from "../FicheArmeUtils";
import {SelectOption} from "../../../entites/Referentiels/SelectOption";
import {useAlerteContext} from "../../../composants/RGAContext";
import SousTitre from "./SousTitre";
import {Col, Row} from "@design-system-etat/dsfr-react";
import "./Caracteristique.css";

interface CaracteristiqueProps {
    referentielInexistant: ReferentielLibreInexistant[]
    arme: Arme
    control: Control<FormulaireArme>
    errors: FieldErrors<FormulaireArme>
    chargerReferentielDebounce: any
    register: UseFormRegister<FormulaireArme>
    lectureSeul: boolean

    marqueForm: SelectOption[]
    setMarqueForm: (options: (precedent: SelectOption[]) => SelectOption[]) => void
    modeleForm: SelectOption[]
    setModeleForm: (options: (precedent: SelectOption[]) => SelectOption[]) => void
    fabricantForm: SelectOption[]
    setFabricantForm: (options: (precedent: SelectOption[]) => SelectOption[]) => void

}


const Caracteristique: React.FC<CaracteristiqueProps> = (props: CaracteristiqueProps) => {
    const {
        referentielInexistant, arme, control, errors, chargerReferentielDebounce, register, lectureSeul, marqueForm,
        setMarqueForm, modeleForm, setModeleForm, fabricantForm, setFabricantForm
    } = props;
    const {mettreAjourAlerte} = useAlerteContext()

    const [modeFonctionnementForm, setModeFonctionnementForm] = useState<SelectOption[]>([]);
    const [systemeAlimentationForm, setSystemeAlimentationForm] = useState<SelectOption[]>([]);
    const [familleForm, setFamilleForm] = useState<SelectOption[]>([]);
    const [typeArmeForm, setTypeArmeForm] = useState<SelectOption[]>([]);
    const [paysFabricantForm, setPaysFabricantForm] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (!lectureSeul) {
            const filtre: FiltreAPI[] = [{cle: "actif", valeur: "true"}] as FiltreAPI[];
            chargerReferentiel(ReferentielAPIEnum.FAMILLES, filtre, (ref) => setFamilleForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.TYPES_ARMES, filtre, (ref) => setTypeArmeForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.SYSTEMES_ALIMENTATION, filtre, (ref) => setSystemeAlimentationForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.FONCTIONNEMENTS, filtre, (ref) => setModeFonctionnementForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.PAYS_FABRICANT, filtre, (ref) => setPaysFabricantForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
        }
    }, [lectureSeul])// eslint-disable-line react-hooks/exhaustive-deps

    const chargerReferentielInstantane = (referentiel, valeurChamp, setReferentiel, setChargementTermine,pagination?) => { // eslint-disable-line react-hooks/exhaustive-deps
        chargerReferentiel(referentiel, [{cle: "libelle", valeur: valeurChamp}, {cle: "actif", valeur: "true"}] as FiltreAPI[], setReferentiel, (alerteErreur) => mettreAjourAlerte(alerteErreur), setChargementTermine,pagination)
    }
    function afficherCaracteristiqueModifiable() {
        function controlerExistanceReferentielLibre(nomReferentiel: string) {
            return referentielInexistant?.some(referentielACreer => referentielACreer.referentiel === nomReferentiel && referentielACreer.type === "UnknownReferentielException");
        }

        function controlerExistanceReferentielDesactive(nomReferentiel: string) {

            return referentielInexistant?.some(referentielACreer => referentielACreer.referentiel === nomReferentiel && referentielACreer.type === "DisabledReferentielException");
        }

        function calculerInfoBulleReferentielLibre(nomReferentiel: string) {
            if (controlerExistanceReferentielLibre(nomReferentiel)) {
                return "Référence inconnue, elle sera créée lors de la validation de la demande d’ajout au RGA."
            }
            if (controlerExistanceReferentielDesactive(nomReferentiel)) {
                return "Référence inactive, elle sera réactivée lors de la validation de la demande d’ajout au RGA."
            }
        }

        function afficherLigneSousTitre1() {
            return <Row className={"fa-caracteristique-titre fa-caracteristique-l1"} alignementVertical={"middle"}>
                <Col> <SousTitre libelle={"Famille*"}/></Col>
                <Col> <SousTitre libelle={"Type d'arme*"}/></Col>
                <Col> <SousTitre libelle={"Pays fabricant*"}/></Col>
                <Col col={2}> <SousTitre libelle={"Longueur de l'arme*"}/></Col>
            </Row>;
        }

        function afficherLigneSousTitre2() {
            return <Row className={"fa-caracteristique-titre"} alignementVertical={"middle"}>
                {["Marque", "Modele", "Fabricant"].map((referentiel,index) => {

                    let sousTitre = referentiel === "Modele" ? "Modèle*" : referentiel + "*";

                    return <Col col={4} key={index}>
                        {controlerExistanceReferentielLibre(referentiel) || controlerExistanceReferentielDesactive(referentiel) ?
                            <div className={"max-content"}>
                                <InfoBulle texte={calculerInfoBulleReferentielLibre(referentiel)}
                                           position={"top-start"} id={"fa-infobulle-" + referentiel + "-libre"}>
                                    <SousTitre libelle={sousTitre}/>
                                    <span
                                        className={controlerExistanceReferentielLibre(referentiel) ? "fr-icon-information-fill texte-warning " : "fr-icon-information-line  texte-warning"}
                                        aria-hidden="true"/>
                                </InfoBulle>
                            </div> :
                            <SousTitre libelle={sousTitre}/>
                        }
                    </Col>
                })}
            </Row>;
        }

        function afficherLigneSousTitre3() {
            return <Row className={"fa-caracteristique-titre"} alignementVertical={"middle"}>
                <Col col={4}>
                    <SousTitre libelle={"Mode de fonctionnement*"}/>
                </Col>
                <Col col={4}>
                    <SousTitre libelle={"Système d'alimentation*"}/>
                </Col>
                <Col col={2}>
                    <SousTitre libelle={"Capacité chambre*"}/>
                </Col>
                <Col col={2}>
                    <SousTitre libelle={"Capacité hors chambre*"}/>
                </Col>
            </Row>;
        }

        function afficherLigneFormulaire1() {
            return <Row className={"fa-caracteristique-ligne fa-caracteristique-l1"} alignementVertical={"top"}>
                <Col>
                    <Controller
                        name="famille"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteRGA
                                id={"fa-select-famille"}
                                erreur={errors.famille?.message}
                                referentiel={ReferentielAPIEnum.FAMILLES}
                                options={familleForm}
                                setOptions={setFamilleForm}
                                chargerOptions={chargerReferentielDebounce}
                                field={field}
                            />
                        )}
                    />
                </Col>
                <Col>
                    <Controller
                        name="typeArme"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteRGA
                                id={"fa-select-type-arme"}
                                erreur={errors.typeArme?.message}
                                referentiel={ReferentielAPIEnum.TYPES_ARMES}
                                options={typeArmeForm}
                                setOptions={setTypeArmeForm}
                                chargerOptions={chargerReferentielDebounce}
                                field={field}
                            />
                        )}
                    />
                </Col>
                <Col>
                    <Controller
                        name="pays"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteRGA
                                id={"fa-select-pays"}
                                erreur={errors.pays?.message}
                                referentiel={ReferentielAPIEnum.PAYS_FABRICANT}
                                options={paysFabricantForm}
                                setOptions={setPaysFabricantForm}
                                chargerOptions={chargerReferentielDebounce}
                                field={field}/>
                        )}
                    />
                </Col>
                <Col>
                    <Input
                        boutonAction={<div className={"align-self-bas"}>mm</div>}
                        maxLength={4}
                        error={errors.longueur ? {message: errors.longueur.message} : null}
                        {...register("longueur", {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            pattern: {
                                value: /^\d{1,4}$/,
                                message: MessagesErreurEnum.MAUVAIS_FORMAT
                            }
                        })}
                        id={"fa-input-longeur-arme"}
                        name={"longueur"} label={null}/>
                </Col>
            </Row>;
        }

        function afficherLigneFormulaire2() {
            return <Row className={"fa-caracteristique-ligne"} alignementVertical={"top"}>
                <Col col={4}>
                    <Controller
                        name="marque"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteAvecSaisieRGA
                                id={"fa-select-marques"}
                                referentiel={ReferentielAPIEnum.MARQUES}
                                options={marqueForm}
                                setOptions={setMarqueForm}
                                chargerOptions={chargerReferentielDebounce}
                                chargerOptionsInstantane={chargerReferentielInstantane}
                                field={field}
                                erreur={errors.marque?.message}
                            />
                        )}
                    />
                </Col>
                <Col col={4}>
                    <Controller
                        name="modele"
                        control={control}

                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteAvecSaisieRGA
                                id={"fa-select-modele"}
                                erreur={errors.modele?.message}
                                referentiel={ReferentielAPIEnum.MODELES}
                                options={modeleForm}
                                setOptions={setModeleForm}
                                chargerOptions={chargerReferentielDebounce}
                                chargerOptionsInstantane={chargerReferentielInstantane}
                                field={field}/>
                        )}
                    />
                </Col>
                <Col col={4}>
                    <Controller
                        name="fabricant"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteAvecSaisieRGA
                                id={"fa-select-fabricant"}
                                erreur={errors.fabricant?.message}
                                referentiel={ReferentielAPIEnum.FABRICANTS}
                                options={fabricantForm}
                                setOptions={setFabricantForm}
                                chargerOptions={chargerReferentielDebounce}
                                chargerOptionsInstantane={chargerReferentielInstantane}
                                field={field}/>
                        )}
                    />
                </Col>
            </Row>;
        }

        function afficherLigneFormulaire3() {
            return <Row className={"fa-caracteristique-ligne"} alignementVertical={"top"}>
                <Col col={4}>
                    <Controller
                        name="modeFonctionnement"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteRGA
                                id={"fa-select-mode-fonctionnement"}
                                erreur={errors.modeFonctionnement?.message}
                                referentiel={ReferentielAPIEnum.FONCTIONNEMENTS}
                                options={modeFonctionnementForm}
                                setOptions={setModeFonctionnementForm}
                                chargerOptions={chargerReferentielDebounce}
                                field={field}
                            />
                        )}
                    />
                </Col>
                <Col col={4}>
                    <Controller
                        name="modeAlimentation"
                        control={control}
                        rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                        render={({field}) => (
                            <AutoCompleteRGA
                                id={"fa-select-caracteristique"}
                                erreur={errors.modeAlimentation?.message}
                                referentiel={ReferentielAPIEnum.SYSTEMES_ALIMENTATION}
                                options={systemeAlimentationForm}
                                setOptions={setSystemeAlimentationForm}
                                chargerOptions={chargerReferentielDebounce}
                                field={field}
                            />
                        )}
                    />
                </Col>
                <Col col={2}>
                    <Input label={null}
                           {...register("capaciteChambre", {
                               required: {
                                   value: true,
                                   message: MessagesErreurEnum.CHAMP_REQUIS
                               },
                               pattern: {
                                   value: /^\d{1,3}$/,
                                   message: MessagesErreurEnum.MAUVAIS_FORMAT
                               }
                           })}
                           id={"fa-input-capacite-C"}
                           maxLength={3}
                           error={errors.capaciteChambre ? {message: errors.capaciteChambre.message} : null}

                    />
                </Col>
                <Col col={2}>
                    <Input label={null}
                           {...register("capaciteHorsChambre", {
                               required: MessagesErreurEnum.CHAMP_REQUIS,
                               pattern: {
                                   value: /^\d{1,3}$/,
                                   message: MessagesErreurEnum.MAUVAIS_FORMAT
                               }
                           })}
                           id={"fa-input-capacite-HC"}
                           maxLength={3}
                           error={errors.capaciteHorsChambre ? {message: errors.capaciteHorsChambre.message} : null}
                    />
                </Col>
            </Row>;
        }

        return <>
            {afficherLigneSousTitre1()}
            {afficherLigneFormulaire1()}
            {afficherLigneSousTitre2()}
            {afficherLigneFormulaire2()}
            {afficherLigneSousTitre3()}
            {afficherLigneFormulaire3()}


        </>;
    }

    return lectureSeul ? <CaracteristiqueLectureSeule arme={arme}/> : afficherCaracteristiqueModifiable()
}


export default Caracteristique;
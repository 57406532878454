export enum ReferentielAPIEnum{
    SIA= "/sia",
    TYPES_ARMES= "/type_armes",
    MARQUES= "/marques",
    CLASSEMENT_FR= "/classement_frs",
    FAMILLES= "/familles",
    FONCTIONNEMENTS= "/fonctionnements",
    CALIBRES= "/calibres",
    CALIBRES_1= "/calibres?numCanon=1",
    CALIBRES_2= "/calibres?numCanon=2",
    CALIBRES_3= "/calibres?numCanon=3",
    CALIBRES_4= "/calibres?numCanon=4",
    MODES_PERCUSSION= "/percussions",
    TYPES_CANON= "/type_canons",
    SYSTEMES_ALIMENTATION= "/alimentations",
    FABRICANTS= "/fabricants",
    MODELES= "/modeles",
    CLASSEMENT_EU= "/classement_eus",
    PAYS_FABRICANT= "/pays",
}
import { Input, Modale, ModaleContent, ModaleTitre } from "@design-system-etat/dsfr-react/lib/cjs";
import React, {useEffect, useState} from "react";

type ModuleDeConversionProps = {
    visible: boolean,
    closeCallback: () => void
}

const ModuleDeConversion = (props: ModuleDeConversionProps) => {

    const [millimetresValue, setMillimetresValue] = useState<number | null>(null)
    const [poucesValue, setPoucesValue] = useState<number | null>(null)

    const handlePouceChange = (valeur: number) => {
        setPoucesValue(valeur);
        setMillimetresValue(+(valeur * 25.4).toFixed(2))
    };

    const handleMillimetresChange = (valeur: number) => {
        setPoucesValue(+(valeur / 25.4).toFixed(2));
        setMillimetresValue(valeur)
    };

    useEffect(() => {
        setPoucesValue(null);
        setMillimetresValue(null);
    }, [props.visible])

    return (
        <>
            { props.visible &&
                <Modale
                    closeCallback={props.closeCallback}
                    taille="lg"
                    id="conversion"
                >
                    <ModaleTitre idModale="conversion">
                        Module de conversion
                    </ModaleTitre>
                    <ModaleContent>
                        <>
                            <p>Saisir une valeur en pouces ou en millimètres pour effectuer une conversion automatique.</p>
                            <Input
                                label="Longueur en pouces"
                                name="pouces"
                                type="number"
                                id="champPouces"
                                value={poucesValue ? poucesValue : ""}
                                onChange={ (event: React.ChangeEvent<HTMLInputElement> ) => { handlePouceChange(Number(event.target.value)) }
                                }
                            />
                            <Input
                                label="Longueur en millimètres"
                                name="millimetres"
                                type="number"
                                id="champMillimetre"
                                value={millimetresValue ? millimetresValue : ""}
                                onChange={ (event: React.ChangeEvent<HTMLInputElement> ) => { handleMillimetresChange(Number(event.target.value)) }
                                }
                            />
                        </>
                    </ModaleContent>
                </Modale>
            }
        </>
    )
}

export default ModuleDeConversion
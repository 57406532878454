import React, {useEffect, useState} from "react";
import './stepperStyle.css';
import {successFill} from "@design-system-etat/dsfr-react/lib/cjs";
import {EtapesStepperEnum} from "../../constantes/EtapesStepperEnum";
import {EtapeRechSimplExp} from "../../entites/EtapeRechSimpl";
import {useRechercheSimplifieeContext} from "../RechercheSimplifieeContext";

interface StepperProps {
    titreEtapeEnCours: string,
    listeEtapes : EtapeRechSimplExp[]
}

export const listeEtapesAirComprime: EtapeRechSimplExp[] = [
    {nom: EtapesStepperEnum.FAMILLE, ordre: 1},
    {nom: EtapesStepperEnum.MARQUE, ordre: 2},
    {nom: EtapesStepperEnum.MODELE, ordre: 3},
    {nom: EtapesStepperEnum.CALIBRE, ordre: 4}
];

export const listeEtapesArmeFeu1Canon: EtapeRechSimplExp[] = [
    {nom: EtapesStepperEnum.FAMILLE, ordre: 1},
    {nom: EtapesStepperEnum.CANON, ordre: 2},
    {nom: EtapesStepperEnum.CALIBRE, ordre: 3},
    {nom: EtapesStepperEnum.MODE_DE_FONCTIONNEMENT, ordre: 4},
    {nom: EtapesStepperEnum.MARQUE, ordre: 5},
    {nom: EtapesStepperEnum.MODELE, ordre: 6}
];

export const listeEtapesArmeFeuPlusDe1Canon: EtapeRechSimplExp[] = [
    {nom: EtapesStepperEnum.FAMILLE, ordre: 1},
    {nom: EtapesStepperEnum.CANON, ordre: 2},
    {nom: EtapesStepperEnum.CALIBRE, ordre: 3},
    {nom: EtapesStepperEnum.MARQUE, ordre: 4},
    {nom: EtapesStepperEnum.MODELE, ordre: 5}
];

export const Stepper = (props: StepperProps) => {

    const {titreEtapeEnCours, listeEtapes} = props
    const [stepper, setStepper] = useState<JSX.Element[]>();
    const {criteres} = useRechercheSimplifieeContext();

    function genererCercleEtape(ordreEnCours: number, ordreEtape: number) {
        if (!ordreEnCours || ordreEnCours > ordreEtape) {
            return <div className={successFill + " fr-icon--lg"}/>

        } else  {
            return <div className="cercle__stepper"/>
        }
    }

    function genererClasse(etapeEnCours: number, ordreEtape: number){
        if (titreEtapeEnCours === EtapesStepperEnum.RESULTAT || etapeEnCours > ordreEtape) {
            return "etape-fini__stepper"

        } else if (etapeEnCours === ordreEtape) {
            return "etape-en-cours__stepper"

        } else {
            return "etape-a-faire__stepper"
        }
    }

    function calculEtapes() {
        const etapeEnCours = listeEtapes.find(etape => etape.nom === titreEtapeEnCours);
        return listeEtapes.map((etape, index) => {
            const identifiantEtape = "etape_"+ etape.nom;

            return (
                <div id={identifiantEtape} key={identifiantEtape}  className={"etape__stepper " + genererClasse(etapeEnCours?.ordre, etape.ordre)}>
                    <div>
                        {index !== 0 && <div className="trait__stepper"/>}
                        {genererCercleEtape(etapeEnCours?.ordre, etape.ordre)}
                    </div>
                    <div>
                        <div className="titre__stepper">{etape.nom}</div>
                    </div>
                </div>
            );
        });
    }

    useEffect(() => {
        setStepper(calculEtapes())
    }, [criteres, listeEtapes]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {stepper}
        </div>
    )
}



import {Bouton} from "@design-system-etat/dsfr-react";
import {Input, refreshLine, volumeUpFill} from "@design-system-etat/dsfr-react/lib/cjs";
import React, {useEffect} from "react";
import MiCaptcha from "../utils/captcha";
import "./Captcha.css"
import {fr} from "../constantes/ClassesDSFR";

interface CaptchaProps {
    onChange?: (string) => void,
    error?: any,
    register?: any
}

const Captcha : React.FC<CaptchaProps> = ({onChange, error, register}) => {
    useEffect(() => {
        MiCaptcha.load({
            application: "rga",
            // @ts-ignore
            serviceBaseUrl: window.CAPTCHA_ROOT_URL
        });
    }, [])

    return (
        <div className={fr.p_2w + fr.my_4w + "mi-captcha captcha-rga"}>
            <div className="catpcha-rga__partie-haute">
                <img className="mi-captcha-image" alt="captcha" src=""/>
                <div className={fr.ml_2w + "catpcha-rga__partie-haute__boutons"}>
                    <div>
                        <Bouton
                            className="mi-captcha-refresh"
                            icone={refreshLine}
                            label="Mon label"
                        />
                    </div>
                    <div className={fr.pt_1w}>
                        <Bouton
                            className="mi-captcha-audio-play"
                            icone={volumeUpFill}
                            label="Mon label"
                        />
                    </div>
                    <audio className="mi-captcha-audio"/>
                </div>
            </div>
            <br/>
            <Input
                label="Code *"
                name="mi-captcha-code"
                type="text"
                onChange={e => onChange(e.target.value)}
                longueurMax
                error={error}
                {...register}
            />
        </div>
    )
}

export default Captcha;
import Canon from "./Canon";
import {auFormat2Caracteres} from "../utils/DateUtils";


export default interface Arme {
    "@context"?: string,
    "@id"?: string,
    "@type"?: string,
    id: number,
    visible: boolean,
    sia: string,
    typeArme: TypeArme,
    famille: Famille,
    fabricantSaisie: string,
    fabricant: Fabricant,
    pays: Pays,
    marque: Marque,
    marqueSaisie: string,
    modele: Model,
    modeleSaisie: string,
    capaciteChambre: number,
    capaciteHorsChambre: number,
    longueur: number,
    modeFonctionnement: ModeFonctionnement,
    modeAlimentation: ModeAlimentation,
    "energie": any,
    "apparenceArmeGuerre": boolean,
    "prototype": boolean,
    "siren": any,
    "neutralise": boolean,
    classementValideFrance: ClassementValideFrance,
    classementValideEurope: ClassementValideEurope,
    canons: Canon[],
    "designationCommerciales": DesignationCommerciale[],
    "photos": [],//TODO NPO trouver le type
    "dateCreation": Date | null,
    "dateMiseAJour": Date | null
}

export interface ClassementValideEurope {
    "@id"?: string,
    "@type"?: string,
    "idClassementEurope": number,
    "libelle": string,
    "actif": boolean,
    "dateDebut":Date | null,
    "dateFin":Date | null,
    "idGdr": number
}

export interface ClassementValideFrance {
    "@id"?: string,
    "@type"?: string,
    "idClassementFrance": number,
    regime: string
    "libelle": string,
    "actif": boolean,
    "dateDebut": Date | null,
    "dateFin": Date | null,
    "idGdr": number
}

export interface ModeAlimentation {
    "@id"?: string,
    "@type"?: string,
    "idModeAlimentation": number,
    "actif": boolean,
    "libelle": string,
    "idGdr": number
}

export interface ModeFonctionnement {
    "@id"?: string,
    "@type"?: string,
    "idModeFonctionnement": number,
    "actif": boolean,
    "libelle": string,
    "idGdr": number
}

export interface Model {
    "@id"?: string,
    "@type"?: string,
    "idModele": number,
    "actif": boolean,
    "libelle": string,
    "idGdr": number
}

export interface Marque {
    "@id"?: string,
    "@type"?: string,
    "idMarque": number,
    "actif": boolean,
    "libelle": string,
    "sia": string,
    "dateCreation": Date | null,
    "idGdr": number
}

export interface Pays {
    "@id"?: string,
    "@type"?: string,
    "id": number,
    "libelle": string
}

export interface TypeArme {
    "@id"?: string,
    "@type"?: string,
    "idTypeArme": number,
    "actif": boolean,
    "libelle": string,
    "idGdr": number
}

export interface Famille {
    "@id"?: string,
    "@type"?: string,
    "idFamilleArme": number,
    "actif": boolean,
    "libelle": string,
    "idGdr": number
}

export interface Fabricant {
    "@id"?: string,
    "@type"?: string,
    "idFabricant": number,
    "actif": boolean,
    "libelle": string,
    "idGdr": number
}


export interface DesignationCommerciale {
    "@id"?: string
    "@type"?: string
    actif?: boolean
    action: any
    id?: number
    libelle: string
}

export const armeToLigneCsv = (arme: Arme) => {
    return {
        referenceRGA: arme.sia,
        famille: arme.famille?.libelle,
        typeArme: arme.typeArme?.libelle,
        marque: arme.marque?.libelle,
        modele: arme.modele?.libelle,
        fabricant: arme.fabricant?.libelle,
        paysFabricant: arme.pays?.libelle,
        modeFonctionnement: arme.modeFonctionnement?.libelle,
        systemeAlimentation: arme.modeAlimentation?.libelle,
        longueurArme: arme.longueur,
        capaciteHorsChambre: arme.capaciteHorsChambre,
        capaciteChambre: arme.capaciteChambre,
        calibreCanonUn: arme.canons.at(0)?.calibre?.libelle,
        modePercussionCanonUn: arme.canons.at(0)?.modePercussion?.libelle,
        typeCanonUn: arme.canons.at(0)?.typeCanon?.libelle,
        "longueurCanonUn(mm/po)": arme.canons.at(0)?.longueurCanon,
        calibreCanonDeux: arme.canons.at(1)?.calibre?.libelle,
        modePercussionCanonDeux: arme.canons.at(1)?.modePercussion?.libelle,
        typeCanonDeux: arme.canons.at(1)?.typeCanon?.libelle,
        "longueurCanonDeux(mm/po)": arme.canons.at(1)?.longueurCanon,
        calibreCanonTrois: arme.canons.at(2)?.calibre?.libelle,
        modePercussionCanonTrois: arme.canons.at(2)?.modePercussion?.libelle,
        typeCanonTrois: arme.canons.at(2)?.typeCanon?.libelle,
        "longueurCanonTrois(mm/po)": arme.canons.at(2)?.longueurCanon,
        calibreCanonQuatre: arme.canons.at(3)?.calibre?.libelle,
        modePercussionCanonQuatre: arme.canons.at(3)?.modePercussion?.libelle,
        typeCanonQuatre: arme.canons.at(3)?.typeCanon?.libelle,
        "longueurCanonQuatre(mm/po)": arme.canons.at(3)?.longueurCanon,
        armeSemiAutoApparenceArmeAuto: arme.apparenceArmeGuerre ? "Oui" : "Non",
        designation_commerciale: arme.designationCommerciales.map((designation, index) =>
            designation.libelle + (index > 0 ? '[separateur-designation-commerciale]' : '')
        ),
        classementFrancais: arme.classementValideFrance?.libelle,
        classementEuropeen: arme.classementValideEurope?.libelle,
        prototype: arme.prototype ? "Oui" : "Non",
        visible: arme.visible ? "Oui" : "Non",
        dateCreaRGA: arme.dateCreation ? auFormat2Caracteres(new Date(arme.dateCreation).getDate()) + "/" + auFormat2Caracteres(new Date(arme.dateCreation).getMonth() + 1) + "/" + auFormat2Caracteres(new Date(arme.dateCreation).getFullYear()) :"",
        dateMajRGA: arme.dateMiseAJour ? auFormat2Caracteres(new Date(arme.dateMiseAJour).getDate()) + "/" + auFormat2Caracteres(new Date(arme.dateMiseAJour).getMonth() + 1) + "/" + auFormat2Caracteres(new Date(arme.dateMiseAJour).getFullYear()) :"",
    }
}

import {UtilisateurAPIPort} from "../../utils/port/UtilisateurAPIPort";
import API from "./APIConfiguration";
import {UtilisateurLite, Utilisateur} from "../../entites/Utilisateur";
import {PageTableau} from "../../entites/PageTableau";

const recupererUtilisateurs = (
    page: number,
    itemsParPage: number,
    order: { cle: string, valeur: string },
    utilisateur: UtilisateurLite
) => {
    let params = ''
    params += '&page=' + page
    params += '&itemsPerPage=' + itemsParPage
    if (order.cle) {
        params += `&order[${order.cle}]=${order.valeur.toLowerCase()}`
    }
    params += '&ssoId[exists]=0'
    params += '&siret[exists]=0'
    Object.keys(utilisateur).forEach(cle => {
        if (utilisateur[cle]) params += `&${cle}=${utilisateur[cle]}`
    })
    return API.get('utilisateurs?' + params, {headers: {'Accept': 'application/ld+json'}})
        .then(reponse => {
            return new PageTableau<Utilisateur>(
                reponse.data["hydra:member"].map(utilisateur => {
                    return utilisateur as Utilisateur
                }),
                reponse.data["hydra:totalItems"],
                itemsParPage
            );
        })
}

const modifierUtilisateur = (utilisateur: UtilisateurLite, ancienMail: string): Promise<any> => {
    const formData = {
        nom: utilisateur.nom,
        prenom: utilisateur.prenom,
        siren: utilisateur.siren,
        societe: utilisateur.societe,
        mail: utilisateur.mail,
        oldMail: ancienMail
    }

    return API.post('modifier_utilisateur', {formData})
        .then(reponse => {
            return reponse
        })
}

const modifierStatutUtilisateur = (utilisateur: UtilisateurLite, action: string): Promise<any> => {
    const formData = {
        mail: utilisateur.mail,
        etat: utilisateur.etat,
        action: action
    }
    return API.post('modifier_status_utilisateur', {formData})
        .then(reponse => {
            return reponse
        })
}

const verifierMotDePasse = (mail, nouveauMdp1, nouveauMdp2, ancienMdp): Promise<any> => {
    return API.post('check_renouvellement_password', {
        mail: mail,
        newPassword: nouveauMdp1,
        newBisPassword: nouveauMdp2,
        oldPassword: ancienMdp
    })
        .then(reponse => {
            return reponse
        })
}

export const UtilisateurAPIServiceImpl: UtilisateurAPIPort = {
    recupererUtilisateurs,
    modifierUtilisateur,
    modifierStatutUtilisateur,
    verifierMotDePasse
};

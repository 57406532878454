import React from "react";

interface SousTitreProps {
    libelle: string
}

const SousTitre: React.FC<SousTitreProps> = (props: SousTitreProps) => {

    return <span className="texte-desactive-gris">
        {props.libelle}
        </span>;
}
export default SousTitre;
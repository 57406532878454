import {ImageListType} from "react-images-uploading";


export interface PhotoArmeAPIPost {
    position:string;
    photo:File;
}

export interface PhotoArmeAPIGet {
    type:string;
    url:string;
    id:number;
    nom?:string;
}
export interface PhotoArme{
    gauche: ImageListType|null,
    droite: ImageListType|null,
    dessus: ImageListType|null,
    dessous: ImageListType|null,
    gauche_marquage: ImageListType|null,
    droite_marquage: ImageListType|null
}
export const PhotoArmeInitial:PhotoArme={
    dessous: null,
    dessus: null,
    droite: null,
    droite_marquage: null,
    gauche: null,
    gauche_marquage: null

}
import React, {useState} from "react";
import Pictogram from "../../entites/Pictogramme";
import digitalArt from "@gouvfr/dsfr/dist/artwork/pictograms/leisure/digital-art.svg";
import Titre from "../../composants/Titre";
import {arrowRightSLastLine, Container, downloadLine} from "@design-system-etat/dsfr-react/lib/cjs";
import "./EcranImportFichesMasse.css"
import {Bouton, Icone, Row} from "@design-system-etat/dsfr-react";
import {Link, useNavigate} from "react-router-dom";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import Toggle from "@design-system-etat/dsfr-react/lib/cjs/components/interface/Form/Toogle/Toogle";
import {URLPageEnum} from "../../constantes/URLPageEnum";

export default function EcranImportFichesMasse(): JSX.Element {
    const navigate = useNavigate();
    const pictoFichesRga: Pictogram = {
        svg: digitalArt,
        alt: "pictogram fiches RGA"
    };

    const [fichierMasse, setFichierMasse] = useState(null)
    const [modeImport, setModeImport] = useState(true)
    const {mettreAjourAlerte} = useAlerteContext();

    function importFichierMasse(): void {
        RgaAppliAPI.importMasseFiches(fichierMasse)
            .then((res) => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", res))
            })
            .catch((res) => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", res))
            })
    }

    return (<>
        <Container className={"importMasse"}>
            <div className={"zoneTitre"}>
                <img src={pictoFichesRga.svg} alt={pictoFichesRga.alt} id="carte_menu_picto"/>
                <div>
                    <Titre>
                        Création fiches RGA
                    </Titre>
                    <p>
                        {modeImport ? "Importer" : "Modifier"} des armes en masse
                    </p>
                </div>
            </div>

            <div className={"content"}>

                <div className={"divSwitch"}>
                    <p>
                        Import / modification d'armes en masse:
                    </p>
                    <Toggle name={"modeSwitch"}  label={""} onClick={() => setModeImport(!modeImport)}        texteEtatActive={"Modification"}
                    texteEtatDesactive={"Import"}  />
                </div>

                <p>
                    Importez votre fichier {modeImport ? "d'ajout" : "de modification"} d'armes en masse
                </p>
                <div>
                    <input className="fr-upload" type="file" id="file-upload" name="file-upload"
                           onChange={(e) => setFichierMasse(e.target.files[0])}/>
                </div>
                <Bouton label={"Déposer"} niveau={"primaire"} onClick={() => importFichierMasse()}/>
                <p>
                    Vous n’avez pas le modèle de fichier à utiliser ? Téléchargez-le ici
                </p>
                <div>
                    <Link to={modeImport ?
                        "/demandes/RGA-modele_import_armes_SCA_masse.csv"
                        : "/demandes/RGA-modele_modification_armes_SCA_masse.csv"
                    } target="_blank">
                        Télécharger le
                        modèle <Icone icone={downloadLine} taille="sm"/>
                    </Link>
                    <p className={"description"}>CSV - 0,5Ko </p>
                </div>
            </div>

            <Row alignementHorizontal={"right"}>
                <Bouton className={'boutonRetour'} label={"Retour"} iconePosition={"right"}
                        icone={arrowRightSLastLine} niveau={"primaire"}
                        onClick={() => {
                            navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                        }}/>
            </Row>
        </Container>

    </>);
}
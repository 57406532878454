import React, {useEffect, useState} from "react";
import {fr} from "../../../constantes/ClassesDSFR";
import RechSimplCarteReferentiel from "../carte/RechSimplCarteReferentiel";
import RechSimplCarte from "../carte/RechSimplCarte";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";
import {Bouton, searchLine} from "@design-system-etat/dsfr-react";
import {arrowLeftSFirstLine} from "@design-system-etat/dsfr-react/lib/cjs";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {RechSimplTypeArmeEnum} from "../../../constantes/RechSimplOptionsEnum";

interface RechSimplModeleProps {
    onSuivantClick: (modele: string) => any
    onPrecedentClick: () => any
    onAfficherResultatClick: (modele: string) => any
}

const RechSimplModele : React.FC<RechSimplModeleProps> = ({onSuivantClick, onPrecedentClick, onAfficherResultatClick}) => {

    const {resultats, rechercheEnCours} = useRechercheSimplifieeContext();
    const [modeleSelectionne, setModeleSelectionne] = useState<string>(null);
    const [connaisLeModele, setConnaisLeModele] = useState<boolean>(null);

    const {criteres, referentiels} = useRechercheSimplifieeContext();

    useEffect(() => {
        setModeleSelectionne(`${criteres.modele}`)
        if(criteres.modele === "-"){
            setConnaisLeModele(false)
        } else if(criteres.modele === ""){
            setConnaisLeModele(null)
        } else {
            setConnaisLeModele(true)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <div className={"w-100"}>
            <h4 className={"texte-centre"}>Modèle</h4>
            <div className={fr.mt_2w + "d-flex flex-space-around"}>
                <RechSimplCarteReferentiel
                    titre={"Je connais le modèle"}
                    estActif={connaisLeModele === true}
                    options={referentiels?.MODELE}
                    avecTexteSaisie
                    onRadioClick={() => setConnaisLeModele(true)}
                    onReferentielChange={modele => {
                        setModeleSelectionne(modele)
                    }}
                    valeur={modeleSelectionne}
                />
                <RechSimplCarte
                    titre={"Je ne le connais pas"}
                    estActif={connaisLeModele === false}
                    onClick={() => {
                        setModeleSelectionne(null)
                        setConnaisLeModele(false)
                    }}
                />
            </div>
        </div>
        {criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU ?
            <div className={fr.mt_2w + "d-flex flex-space-between"}>
                <Bouton
                    icone={arrowLeftSFirstLine}
                    iconePosition="left"
                    label={"Retour"}
                    niveau="secondaire"
                    onClick={onPrecedentClick}
                />
                <div className={"d-flex flex-space-between"}>
                    <g className={fr.p_1w + "texte-warning"}>{rechercheEnCours ? "Chargement du nombre de résultats ..." : resultats.nombreElementsTotal + " résultats"}</g>
                    <Bouton
                        icone={searchLine}
                        iconePosition="right"
                        label="Consulter les résultats"
                        niveau="secondaire"
                        disabled={rechercheEnCours}
                        onClick={() => onAfficherResultatClick(connaisLeModele ? modeleSelectionne : "")}
                    />
                </div>
            </div>
        :
            <BoutonsEcranRecherche
                onPrecedentClick={onPrecedentClick}
                onAfficherResultatClick={() => onAfficherResultatClick(connaisLeModele ? modeleSelectionne : "")}
                onSuivantClick={() => {
                    onSuivantClick(connaisLeModele ? modeleSelectionne : "")
                }}
                suivantInterdit={!modeleSelectionne && connaisLeModele !== false}
            />
        }
    </>
}

export default RechSimplModele;

import React, {useEffect, useState} from "react";
import RechSimplCarte from "../carte/RechSimplCarte";
import RechSimplCarteCalibre from "../carte/RechSimplCarteCalibre";
import {fr} from "../../../constantes/ClassesDSFR";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";
import {RechSimplTypeArmeEnum} from "../../../constantes/RechSimplOptionsEnum";
import {Bouton, searchLine} from "@design-system-etat/dsfr-react";
import {arrowLeftSFirstLine} from "@design-system-etat/dsfr-react/lib/cjs";
import {auPluriel} from "../../../utils/Utils";

interface RechSimplCalibreProps {
    onSuivantClick: (calibres: {calibre1: string, calibre2: string, calibre3: string, calibre4: string}) => any
    onPrecedentClick: () => any
    onAfficherResultatClick: (calibres: {calibre1: string, calibre2: string, calibre3: string, calibre4: string}) => any
    onCalibreChange: (calibres: {calibre1: string, calibre2: string, calibre3: string, calibre4: string}) => any
}

const RechSimplCalibre : React.FC<RechSimplCalibreProps> = ({onSuivantClick, onPrecedentClick, onAfficherResultatClick, onCalibreChange}) => {

    const {criteres} = useRechercheSimplifieeContext();

    const [calibres, setCalibres] = useState<{calibre1: string, calibre2: string, calibre3: string, calibre4: string}>({calibre1: "", calibre2: "", calibre3: "", calibre4: ""});
    const [connaisLesCalibres, setConnaisLesCalibres] = useState<boolean>(null);
    const [nombreCalibreRenseignes, setNombreCalibresRenseignes] = useState<number>(0);
    const {rechercheEnCours, resultats} = useRechercheSimplifieeContext();

    const calculerNombreCalibresRenseignes = (calibres: {calibre1: string, calibre2: string, calibre3: string, calibre4: string}) : number => {
        return Object.values(calibres).filter(valeur => !!valeur).length
    }

    useEffect(() => {
        setCalibres({...criteres.calibres})
        const libelleCalibres = Object.values({...criteres.calibres})
        if(libelleCalibres.filter(libelle => libelle === "").length === 4){
            setConnaisLesCalibres(null)
        } else if(libelleCalibres.filter(libelle => libelle === "-").length === 4){
            setConnaisLesCalibres(false)
        } else{
            setConnaisLesCalibres(true)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <div className={"w-100 "}>
            <h4 className={"texte-centre"}>{auPluriel("Calibre", criteres.nombreCanons)}</h4>
            {criteres.nombreCanons > 1 && <div className={"texte-centre texte-erreur"}>Renseigner le calibre du canon lisse suivi du calibre du canon rayé le cas échéant</div>}
            <div className={fr.mt_2w + "d-flex flex-space-around"}>
                <RechSimplCarteCalibre
                    calibres={calibres}
                    nombreCanons={criteres.nombreCanons}
                    titre={"Je connais" + auPluriel(" le", criteres.nombreCanons) + auPluriel(" calibre", criteres.nombreCanons)}
                    estActif={connaisLesCalibres === true}
                    onRadioClick={() => setConnaisLesCalibres(true)}
                    onCalibresChange={calibres => {
                        onCalibreChange(calibres)
                        setNombreCalibresRenseignes(calculerNombreCalibresRenseignes(calibres))
                        setCalibres(calibres)
                    }}
                />
                <RechSimplCarte
                    titre={criteres.nombreCanons > 1 ? "Je ne les connais pas":"Je ne le connais pas"}
                    estActif={connaisLesCalibres === false}
                    onClick={() => {
                        onCalibreChange({calibre1: "", calibre2: "", calibre3: "", calibre4: ""})
                        setConnaisLesCalibres(false)
                    }}
                />
            </div>
        </div>
        {criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_AIR_COMPRIME ?
            <div className={fr.mt_2w + "d-flex flex-space-between"}>
                <Bouton
                    icone={arrowLeftSFirstLine}
                    iconePosition="left"
                    label={"Retour"}
                    niveau="secondaire"
                    onClick={onPrecedentClick}
                />
                <div className={"d-flex flex-space-between"}>
                    <g className={fr.p_1w + "texte-warning"}>{rechercheEnCours ? "Chargement du nombre de résultats ..." : resultats.nombreElementsTotal + " résultats"}</g>
                    <Bouton
                        icone={searchLine}
                        iconePosition="right"
                        label="Consulter les résultats"
                        niveau="secondaire"
                        disabled={connaisLesCalibres !== false && nombreCalibreRenseignes !== criteres.nombreCanons}
                        onClick={() => connaisLesCalibres === true ? onAfficherResultatClick(calibres) : onAfficherResultatClick({calibre1: "-", calibre2: "-", calibre3: "-", calibre4: "-"})}
                    />
                </div>
            </div>
            :
            <BoutonsEcranRecherche
                onPrecedentClick={onPrecedentClick}
                onAfficherResultatClick={() => connaisLesCalibres === true ? onAfficherResultatClick(calibres) : onAfficherResultatClick({calibre1: "-", calibre2: "-", calibre3: "-", calibre4: "-"})}
                onSuivantClick={() => connaisLesCalibres === true ? onSuivantClick(calibres) : onSuivantClick({calibre1: "-", calibre2: "-", calibre3: "-", calibre4: "-"})}
                suivantInterdit={connaisLesCalibres !== false && nombreCalibreRenseignes < criteres.nombreCanons}
            />
        }
    </>
}

export default RechSimplCalibre;

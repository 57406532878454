import React from "react";
import BaseMenuCarte from "./BaseMenuCarte";
import gear from "@gouvfr/dsfr/dist/artwork/pictograms/system/system.svg";
import {Bouton, Col} from "@design-system-etat/dsfr-react";
import {SessionService} from "../../../../infrastructure/InfrastructureFactory";
import {UtilisateurDroitEnum} from "../../../../constantes/UtilisateurDroitEnum";
import {
    adminFill,
    bookmarkFill,
    bugFill,
    computerFill,
    home4Fill, Tag,
    userAddFill
} from "@design-system-etat/dsfr-react/lib/cjs";
import {Link} from "react-router-dom";
import {URLPageEnum} from "../../../../constantes/URLPageEnum";
import Pictogram from "../../../../entites/Pictogramme";
import {useAlerteContext} from "../../../../composants/RGAContext";

interface MenuAdministrationProps {
    nombreDemandeNouveauCompte: number
}

const MenuAdministration: React.FC<MenuAdministrationProps> = (props) => {

    const titreAdministration: string = "Administration";
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    const pictoAdministration: Pictogram = {
        svg: gear,
        alt: "pictogram Administration"
    };

    function boutonGestionApplication() {
        return <>
            <Col >
                <Link to={URLPageEnum.ADMINISTRATION_PARAMETRES} onClick={mettreAJourDateDerniereAction}>
                    <Bouton
                        icone={computerFill}
                        iconePosition="left"
                        label="Application"
                        niveau="secondaire"
                        className="fullSizeButton_administration"
                        id="menu-admin-btn-application"
                    />
                </Link>
            </Col>
            <Col >
                <Link to={URLPageEnum.ADMINISTRATION_PAGE_DE_CONNEXION} onClick={mettreAJourDateDerniereAction}>
                    <Bouton
                        icone={home4Fill}
                        iconePosition="left"
                        label="Page de connexion"
                        niveau="secondaire"
                        className="fullSizeButton_administration"
                        id="menu-admin-btn-connexion"
                    />
                </Link>
            </Col>
        </>;
    }

    function boutonGestionDroits() {
        return <Col >
            <Link to={URLPageEnum.ADMINISTRATION_DROITS} onClick={mettreAJourDateDerniereAction}>
                <Bouton
                    icone={adminFill}
                    iconePosition="left"
                    label="Droits"
                    niveau="secondaire"
                    className="fullSizeButton_administration"
                    id="menu-admin-btn-droits"
                />
            </Link>
        </Col>;
    }

    function boutonTraiterDemande() {
        return <Col >
            <Link to={URLPageEnum.ADMINISTRATION_REFERENTIELS + "/familles"} onClick={mettreAJourDateDerniereAction}>
                <Bouton
                    icone={bookmarkFill}
                    iconePosition="left"
                    label="Référentiels"
                    niveau="secondaire"
                    className="fullSizeButton_administration"
                    id="menu-admin-btn-referentiels"

                />
            </Link>
        </Col>;
    }

    function boutonConsultationTrace() {
        return <Col >
            <Link to={URLPageEnum.ECRAN_CONSULTER_LES_TRACES} onClick={mettreAJourDateDerniereAction}>
                <Bouton
                    icone={bugFill}
                    iconePosition="left"
                    label="Accéder à la traçabilité"
                    niveau="secondaire"
                    className="fullSizeButton_administration"
                    id="menu-admin-btn-trace"

                />
            </Link>
        </Col>;
    }

    function boutonGestionComptes() {
        return <Col >
            <Link to={URLPageEnum.ADMINISTRATION_UTILISATEURS} onClick={mettreAJourDateDerniereAction} style={{position: "relative"}} >
                <Bouton
                    icone={userAddFill}
                    iconePosition="left"
                    niveau="secondaire"
                    className="fullSizeButton_administration"
                    id="menu-admin-btn-utilisateur"
                    label={"Gérer comptes utilisateurs"}><Tag label="7" /></Bouton>
                <span className="testTag"><Tag label={props.nombreDemandeNouveauCompte.toString()} taille="sm"/></span>
            </Link>
        </Col>;
    }

    function calculerBouttonVisible() {

        return <div className={"surcharge-href-dsfr-menu-administration"}>
            {SessionService.utilisateurALeDroit(UtilisateurDroitEnum.GERER_APPLICATION) &&
                boutonGestionApplication()
            }
            {SessionService.utilisateurALeDroit(UtilisateurDroitEnum.GERER_DROITS) &&
                boutonGestionDroits()
            }
            {SessionService.utilisateurALeDroit(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS) &&
                boutonTraiterDemande()
            }
            {SessionService.utilisateurALeDroit(UtilisateurDroitEnum.CONSULTER_TRACES) &&
                boutonConsultationTrace()
            }
            {SessionService.utilisateurALeDroit(UtilisateurDroitEnum.GERER_COMPTES) &&
                boutonGestionComptes()
            }
        </div>;
    }


    return <BaseMenuCarte
        id={"menu-administration"}
        titre={titreAdministration}
        titrePicto={pictoAdministration}
        bouton={calculerBouttonVisible()}
        description={""}
    />
}
export default MenuAdministration;

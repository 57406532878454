export class SelectOption {
    value: string|number;
    label: string;
    referentiel: any;

    constructor(value);
    constructor(value, label);
    constructor(value, label, referentiel);
    constructor(...params: any[]) {
        switch (params.length) {
            case 3:
                this.referentiel = params[2];
                this.label = params[1];
                this.value = params[0]
                break;
            case 2:
                this.label = params[1];
                this.value = params[0]
                break;
            default :
                this.value = params[0]
                this.label = params[0];
                break;
        }
    }
}
import TuileArme from "./TuileArme";
import {Col, Row} from "@design-system-etat/dsfr-react";
import {SessionService} from "../../../infrastructure/InfrastructureFactory";
import {UtilisateurProfilCategorieEnum} from "../../../constantes/UtilisateurProfilCategorieEnum";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import {AutoCompleteRGA} from "../../../composants/SelectHorsDsfr/AutoCompleteRGA";
import {ReferentielAPIEnum} from "../../../constantes/ReferentielAPIEnum";
import React, {useEffect, useState} from "react";
import Arme from "../../../entites/Arme";
import FormulaireArme from "../../../entites/FormulaireArme";
import {SelectOption} from "../../../entites/Referentiels/SelectOption";
import {FiltreAPI} from "../../../entites/FiltreAPI";
import {chargerReferentiel} from "../FicheArmeUtils";
import {useAlerteContext} from "../../../composants/RGAContext";

interface ClassificationProps {
    lectureSeul: boolean
    estDemande: boolean
    arme: Arme
    control: Control<FormulaireArme>
    errors: FieldErrors<FormulaireArme>
    chargerReferentielDebounce: any
}

const Classification: React.FC<ClassificationProps> = (props: ClassificationProps) => {
    const {lectureSeul, estDemande, arme, control, errors, chargerReferentielDebounce} = props;
    const {mettreAjourAlerte} = useAlerteContext()
    const [classementFRForm, setClassementFRForm] = useState<SelectOption[]>([]);
    const [classementEUForm, setClassementEUForm] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (!lectureSeul) {
            const filtre: FiltreAPI[] = [{cle: "actif", valeur: "true"}] as FiltreAPI[];
            chargerReferentiel(ReferentielAPIEnum.CLASSEMENT_FR, filtre, (ref) => setClassementFRForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.CLASSEMENT_EU, filtre, (ref) => setClassementEUForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
        }
    }, [lectureSeul])// eslint-disable-line react-hooks/exhaustive-deps

    function determinerAffichageClassification() {
        return lectureSeul || (estDemande && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO);
    }

    function afficherClassementEurope() {
        return <>
            <Row className={"texte-desactive-gris"} alignementHorizontal={"center"}>
                <p> Classement
                    européen{(!lectureSeul && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) && "*"}</p>
            </Row>
            <br/>
            <Row alignementHorizontal={"center"}>
                <Col col={12} className={"texte-centre"}>
                    {determinerAffichageClassification() ?
                        <span id="fa-classement-EU"
                              className={"fr-display--xs"}>  {arme.classementValideEurope?.libelle ?? "-"}</span>
                        :
                        <Controller
                            name="classementValideEurope"
                            control={control}
                            rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    id={"fa-select-classementeu"}
                                    erreur={errors.classementValideEurope?.message}
                                    referentiel={ReferentielAPIEnum.CLASSEMENT_EU}
                                    options={classementEUForm}
                                    setOptions={setClassementEUForm}
                                    chargerOptions={chargerReferentielDebounce}
                                    field={field}
                                />
                            )}
                        />
                    }
                </Col>
            </Row>
        </>;
    }

    function afficherClassementFrance() {
        return <>
            <Row className={"texte-desactive-gris"} alignementHorizontal={"center"}>
                <p>Classement
                    national{(!lectureSeul && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) && "*"}</p>
            </Row>
            <br/>
            <Row alignementHorizontal={"center"}>
                <Col col={12} className={"texte-centre"}>
                    {determinerAffichageClassification() ?
                        <span id="fa-classement-FR"
                              className={"fr-display--xs"}>  {arme.classementValideFrance?.libelle ?? "-"}</span>
                        :
                        <Controller
                            name="classementValideFrance"
                            control={control}
                            rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    id={"fa-select-classementfr"}
                                    erreur={errors.classementValideFrance?.message}
                                    referentiel={ReferentielAPIEnum.CLASSEMENT_FR}
                                    options={classementFRForm}
                                    setOptions={setClassementFRForm}
                                    chargerOptions={chargerReferentielDebounce}
                                    field={field}/>
                            )}
                        />
                    }
                </Col>
            </Row>
        </>;
    }

    return <TuileArme idTuile={"arme_categorie"} titre={"Classification"}>
        <br/>
        {afficherClassementFrance()}
        {!estDemande && <><br/>
            <Row className={"texte-info-bleu text-font-size-10"}>
                <span data-html2canvas-ignore="true" className="fr-icon-info-fill"
                      aria-hidden="true">*Cette valeur est donnée uniquement à titre indicatif</span>
            </Row>
        </>
        }
        <br/>
        {afficherClassementEurope()}
    </TuileArme>;
}
export default Classification;
import React, {useEffect, useState} from "react";
import {fr} from "../../../constantes/ClassesDSFR";
import {Bouton, questionFill} from "@design-system-etat/dsfr-react";
import SelectTypeCanon from "./SelectTypeCanon";
import canon_raye from "../../../resources/recherche-simplifiee/1canon/canon_1_raye.svg";
import canons_2_rayes from "../../../resources/recherche-simplifiee/2canons/canon_2rayesV.svg";
import canons_3_rayes from "../../../resources/recherche-simplifiee/3canons/canon_2rayesH_1rayeB.svg";
import canons_4_rayes from "../../../resources/recherche-simplifiee/4canons/canon_4lisses_carre.svg";
import Modale1Canon from "./Modale1Canon";
import Modale2Canons from "./Modale2Canons";
import Modale3Canons from "./Modale3Canons";
import Modale4Canons from "./Modale4Canons";
import {
    RechSimplTypeCanonOption,
    TYPES_CANON_1,
    TYPES_CANON_2,
    TYPES_CANON_3, TYPES_CANON_4
} from "../../../constantes/RechSimplOptionsEnum";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";
import ModaleLisseRaye from "./ModaleLisseRaye";

interface RechSimplTypeCanonProps {
    onSuivantClick: (typeCanon: RechSimplTypeCanonOption, nombreCanons: number) => any
    onPrecedentClick: () => any
}

const RechSimplTypeCanon : React.FC<RechSimplTypeCanonProps> = ({onSuivantClick, onPrecedentClick}) => {

    const {criteres, referentiels} = useRechercheSimplifieeContext();

    const [typeCanonSelectionnee, setTypeCanonSelectionnee] = useState<RechSimplTypeCanonOption>(criteres.typeCanons);
    const [nombreCanonsSelectionnee, setNombreCanonsSelectionnee] = useState<number>(null);

    const [selectActif, setSelectActif] = useState<number>();

    const [modaleLisseRayeOuvert, setModaleLisseRayeOuvert] = useState<boolean>(false);
    const [modale1CanonOuvert, setModale1CanonOuvert] = useState<boolean>(false);
    const [modale2CanonsOuvert, setModale2CanonsOuvert] = useState<boolean>(false);
    const [modale3CanonsOuvert, setModale3CanonsOuvert] = useState<boolean>(false);
    const [modale4CanonsOuvert, setModale4CanonsOuvert] = useState<boolean>(false);

    const [options1Canon, setOptions1Canon] = useState<RechSimplTypeCanonOption[]>();
    const [options2Canon, setOptions2Canon] = useState<RechSimplTypeCanonOption[]>();
    const [options3Canon, setOptions3Canon] = useState<RechSimplTypeCanonOption[]>();
    const [options4Canon, setOptions4Canon] = useState<RechSimplTypeCanonOption[]>();

    useEffect(() => {
        const nombresCanonsCriteres = Number(`${criteres.nombreCanons}`)
        setTypeCanonSelectionnee(new RechSimplTypeCanonOption(
            criteres.typeCanons.getLibelle(),
            criteres.typeCanons.getCanon1(),
            criteres.typeCanons.getCanon2(),
            criteres.typeCanons.getCanon3(),
            criteres.typeCanons.getCanon4()
        ))
        setNombreCanonsSelectionnee(nombresCanonsCriteres)
        setSelectActif(nombresCanonsCriteres)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(referentiels?.TYPES_CANON_1 && referentiels?.TYPES_CANON_2 && referentiels?.TYPES_CANON_3 && referentiels?.TYPES_CANON_4) {
            const typesCanon1 = referentiels.TYPES_CANON_1.map(type => type.value)
            const typesCanon2 = referentiels.TYPES_CANON_2.map(type => type.value)
            const typesCanon3 = referentiels.TYPES_CANON_3.map(type => type.value)
            const typesCanon4 = referentiels.TYPES_CANON_4.map(type => type.value)

            const options1 = TYPES_CANON_1.filter(type => typesCanon1.includes(type.getCanon1()))
            const options2 = TYPES_CANON_2.filter(type => typesCanon1.includes(type.getCanon1()) && typesCanon2.includes(type.getCanon2()))
            const options3 = TYPES_CANON_3.filter(type => typesCanon1.includes(type.getCanon1()) && typesCanon2.includes(type.getCanon2()) && typesCanon3.includes(type.getCanon3()))
            const options4 = TYPES_CANON_4.filter(type => typesCanon1.includes(type.getCanon1()) && typesCanon2.includes(type.getCanon2()) && typesCanon3.includes(type.getCanon3()) && typesCanon4.includes(type.getCanon4()))

            options1.length > 0 && setOptions1Canon([...options1, RechSimplTypeCanonOption.INCONNU1])
            options2.length > 0 && setOptions2Canon([...options2, RechSimplTypeCanonOption.INCONNU2])
            options3.length > 0 && setOptions3Canon([...options3, RechSimplTypeCanonOption.INCONNU3])
            options4.length > 0 && setOptions4Canon([...options4, RechSimplTypeCanonOption.INCONNU4])
        }
    }, [referentiels])

    return <>
        {modaleLisseRayeOuvert && <ModaleLisseRaye onFermerClick={() => setModaleLisseRayeOuvert(false)}/>}
        {modale1CanonOuvert && <Modale1Canon onFermerClick={() => setModale1CanonOuvert(false)}/>}
        {modale2CanonsOuvert && <Modale2Canons onFermerClick={() => setModale2CanonsOuvert(false)}/>}
        {modale3CanonsOuvert && <Modale3Canons onFermerClick={() => setModale3CanonsOuvert(false)}/>}
        {modale4CanonsOuvert && <Modale4Canons onFermerClick={() => setModale4CanonsOuvert(false)}/>}

        <div className={"w-100"}>
            <h4 className={"texte-centre"}>Combien de canons possède votre arme à feu ?</h4>
            <div className={"d-flex flex-center"}>
                <Bouton
                    label={"Canon lisse ou rayé"}
                    iconePosition="right"
                    className={fr.mb_4w}
                    icone={questionFill}
                    onClick={() => setModaleLisseRayeOuvert(true)}
                />
            </div>
            <div className={"d-flex flex-space-around"}>
                {options1Canon && (<SelectTypeCanon
                    titre={"1 canon"}
                    nombreCanons={1}
                    nombreCanonsSelectionnee={nombreCanonsSelectionnee}
                    options={options1Canon}
                    icone={canon_raye}
                    optionSelectionnee={typeCanonSelectionnee}
                    isActif={selectActif === 1}
                    name={"select-1-canon"}
                    onClick={() => setSelectActif(1)}
                    onOptionClick={option => {setTypeCanonSelectionnee(option); setNombreCanonsSelectionnee(1)}}
                    onExempleClick={() => setModale1CanonOuvert(true)}
                />)}
                {options2Canon && (<SelectTypeCanon
                    titre={"2 canons"}
                    nombreCanons={2}
                    nombreCanonsSelectionnee={nombreCanonsSelectionnee}
                    options={options2Canon}
                    icone={canons_2_rayes}
                    optionSelectionnee={typeCanonSelectionnee}
                    isActif={selectActif === 2}
                    name={"select-2-canon"}
                    onClick={() => setSelectActif(2)}
                    onOptionClick={option => {setTypeCanonSelectionnee(option); setNombreCanonsSelectionnee(2)}}
                    onExempleClick={() => setModale2CanonsOuvert(true)}
                />)}
                {options3Canon && (<SelectTypeCanon
                    titre={"3 canons"}
                    nombreCanons={3}
                    nombreCanonsSelectionnee={nombreCanonsSelectionnee}
                    options={options3Canon}
                    icone={canons_3_rayes}
                    optionSelectionnee={typeCanonSelectionnee}
                    isActif={selectActif === 3}
                    name={"select-3-canon"}
                    onClick={() => setSelectActif(3)}
                    onOptionClick={option => {setTypeCanonSelectionnee(option); setNombreCanonsSelectionnee(3)}}
                    onExempleClick={() => setModale3CanonsOuvert(true)}
                />)}
                {options4Canon && (<SelectTypeCanon
                    titre={"4 canons"}
                    nombreCanons={4}
                    nombreCanonsSelectionnee={nombreCanonsSelectionnee}
                    options={options4Canon}
                    icone={canons_4_rayes}
                    optionSelectionnee={typeCanonSelectionnee}
                    isActif={selectActif === 4}
                    name={"select-4-canon"}
                    onClick={() => setSelectActif(4)}
                    onOptionClick={option => {setTypeCanonSelectionnee(option); setNombreCanonsSelectionnee(4)}}
                    onExempleClick={() => setModale4CanonsOuvert(true)}
                />)}
            </div>
        </div>
        <BoutonsEcranRecherche
            sansResultats
            onPrecedentClick={onPrecedentClick}
            onSuivantClick={() => onSuivantClick(typeCanonSelectionnee, nombreCanonsSelectionnee)}
            suivantInterdit={typeCanonSelectionnee.getLibelle() === ""}
        />
    </>
}

export default RechSimplTypeCanon;

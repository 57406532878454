import React, {useEffect, useRef, useState} from "react";
import {SelectOption} from "../../entites/Referentiels/SelectOption";
import Select, {createFilter} from "react-select";
import {ControllerRenderProps} from "react-hook-form";
import "../../pages/ecran-recherche-avancee/EcranRechercheAvancee.css"
import "./CustomSelectRGA.css"
import CreatableSelect from "react-select/creatable";

interface AutoCompleteRGAProps {
    id?: string;
    referentiel?: string;
    options: SelectOption[];
    sansChargement?: boolean;
    disabled?: boolean;
    chargerOptions?: (referentiel: string, valeurChamp: string, setOptions: any, setChargementTermine: any, pagination?: number) => void;
    setOptions?: (options: (precedent: SelectOption[]) => SelectOption[]) => void;
    field: ControllerRenderProps<any, any>;
    onChange?: (option: SelectOption) => any;
    value?: SelectOption;
    erreur?: string;
    filtreCalibre?: boolean
    chargerOptionsInstantane?:any
}


export const AutoCompleteRGA: React.FC<AutoCompleteRGAProps> = (props) => {

    const {
        id,
        referentiel,
        options,
        chargerOptions,
        sansChargement,
        disabled,
        setOptions,
        field,
        onChange,
        value,
        erreur,
        filtreCalibre
    } = props;
    const ref = useRef(null)
    const [chargementTermine, setChargementTermine] = useState<boolean>(false);


    const filterOptionCalibre = (
        candidate: { label: string; value: string; data: any },
        input: string
    ) => {
        if (input) {
            return candidate.label.replace(/\s/g, '').toUpperCase().trim().includes(input.replace(/\s/g, '').toUpperCase().trim())
        }
        return true;
    };


    return <div onClick={() => {
        ref.current.focus();
    }}>
        <Select
            id={id ?? ""}
            {...field}
            options={options}
            placeholder={"Saisissez dans le champ"}
            unstyled
            isDisabled={disabled}
            classNames={{
                control: () => erreur ? "autocomplete-control autocomplete-erreur" : "autocomplete-control",
                container: () => "autocomplete-container",
                valueContainer: () => "autocomplete-valueContainer",
                placeholder: () => "autocomplete-valueContainer",
                indicatorsContainer: () => "autocomplete-indicatorsContainer",
                menu: () => "autocomplete-menu",
                option: () => "autocomplete-option",
            }}
            loadingMessage={() => 'Recherche en cours ...'}
            noOptionsMessage={() => "Aucune option"}
            value={value ?? field.value}
            onChange={onChange ?? field.onChange}
            onInputChange={valeurChamp => {
                setChargementTermine(true)
                if (referentiel && setOptions && chargerOptions) chargerOptions(referentiel, valeurChamp.trim(), setOptions, setChargementTermine)
            }}
            onMenuOpen={() => {
                if (options.length === 0) {
                    setChargementTermine(true)
                    if (referentiel && setOptions && chargerOptions) chargerOptions(referentiel, "", setOptions, setChargementTermine)
                }
            }}
            isLoading={sansChargement ? false : chargementTermine}
            isClearable
            isSearchable
            ref={ref}
            openMenuOnFocus={true}
            filterOption={filtreCalibre ? filterOptionCalibre : createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: 'any',
                stringify: option => `${option.label} ${option.value}`,
                trim: true,
            })}
        />
        {erreur &&
            <p id={field.name + "-erreur"} className="fr-error-text">
                {erreur}
            </p>
        }
    </div>
}

export const AutoCompleteAvecSaisieRGA: React.FC<AutoCompleteRGAProps> = (props) => {

    const {
        id,
        referentiel,
        options,
        chargerOptions,
        chargerOptionsInstantane,
        sansChargement,
        setOptions,
        field,
        erreur
    } = props;

    const [chargementTermine, setChargementTermine] = useState<boolean>(false);
    const [valeurSaisie, setValeurSaisie] = useState<string>("");
    const [valeur, setValeur] = useState<string>("");
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        if (valeurSaisie) {
            setOptions((precedent: SelectOption[]) => [...precedent, new SelectOption(valeurSaisie)]);
            field.onChange(new SelectOption(valeurSaisie))
        }
    }, [valeurSaisie])// eslint-disable-line react-hooks/exhaustive-deps

    const calculerOptions = () => {
        let optionsAJour = options.slice();
        if (valeurSaisie) {
            optionsAJour.unshift(new SelectOption(valeurSaisie));
        }
        return optionsAJour;
    }

    function ajouterValeur(inputValue: string) {
        setValeurSaisie(inputValue?.toUpperCase());
    }

    useEffect(() => {
        console.log(options)
    }, [options]);

    return <><CreatableSelect
        id={id ?? ""}
        {...field}
        options={calculerOptions()}
        placeholder={"Saisissez dans le champ"}
        unstyled

        classNames={{
            control: () => erreur ? "autocomplete-control autocomplete-erreur" : "autocomplete-control",
            container: () => "autocomplete-container",
            valueContainer: () => "autocomplete-valueContainer",
            placeholder: () => "autocomplete-valueContainer",
            indicatorsContainer: () => "autocomplete-indicatorsContainer",
            menu: () => "autocomplete-menu",
            option: () => "autocomplete-option",
        }}
        loadingMessage={() => 'Recherche en cours ...'}
        noOptionsMessage={() => "Aucune option"}
        value={field.value}
        onChange={(er) => field.onChange(er)}
        onInputChange={valeurChamp => {
            setValeur(valeurChamp)
            setChargementTermine(true)
            chargerOptions(referentiel, valeurChamp, setOptions, setChargementTermine)
        }}
        onMenuOpen={() => {
            if (options.length === 0) {
                setChargementTermine(true)
                chargerOptions(referentiel, "", setOptions, setChargementTermine)
            }
        }}
        onMenuClose={()=>{
            setPage(0)
            setOptions(() => [])
        }}
        onMenuScrollToBottom={() => {

            chargerOptionsInstantane(referentiel, valeur, setOptions, setChargementTermine, page + 1);
            setPage(page + 1)

        }}
        isLoading={sansChargement ? false : chargementTermine}
        isClearable
        isSearchable
        onCreateOption={(inputValue) => ajouterValeur(inputValue)}
        formatCreateLabel={(inputValue) => " Ajouter: " + inputValue}
        createOptionPosition={"first"}
    />
        {erreur &&
            <p id={field.name + "-erreur"} className="fr-error-text">
                {erreur}
            </p>
        }
    </>
}

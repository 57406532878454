import React from "react";
import {Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import "./ModaleTypeCanon.css"
import canon3_2lisse_1raye_1 from "../../../resources/recherche-simplifiee/3canons/canon_2lissesH_1rayeB.svg";
import canon3_2lisse_1raye_2 from "../../../resources/recherche-simplifiee/3canons/canon_2lissesH_1rayeU.svg";
import canon3_2raye_1lisse_1 from "../../../resources/recherche-simplifiee/3canons/canon_1lisse1rayeH_1rayeH.svg";
import canon3_2raye_1lisse_2 from "../../../resources/recherche-simplifiee/3canons/canon_1lisse1rayeH_1rayeB.svg";
import canon3_lisse_1 from "../../../resources/recherche-simplifiee/3canons/canon_2lissesH_1lisseU.svg";
import canon3_lisse_2 from "../../../resources/recherche-simplifiee/3canons/canon_2lissesH_1lisseB.svg";
import canon3_raye_1 from "../../../resources/recherche-simplifiee/3canons/canon_2rayesH_1rayeU.svg";
import canon3_raye_2 from "../../../resources/recherche-simplifiee/3canons/canon_2rayesH_1rayeB.svg";

import ExempleTypeCanonModale from "./ExempleTypeCanonModale";

interface Modale1CanonProps {
    onFermerClick?: () => void
}

const Modale1Canon : React.FC<Modale1CanonProps> = ({onFermerClick}) => {
    return (<Modale id={"modal"}  taille={"lg"} closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>3 canons / exemples</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-around"}>
                <ExempleTypeCanonModale titre={"2 lisses + 1 rayé"} image1={canon3_2lisse_1raye_1} image2={canon3_2lisse_1raye_2}/>
                <ExempleTypeCanonModale titre={"2 rayés + 1 lisse"} image1={canon3_2raye_1lisse_1} image2={canon3_2raye_1lisse_2}/>
            </span>
            <span className={"d-flex flex-space-around"}>
                <ExempleTypeCanonModale titre={"3 lisses"} image1={canon3_lisse_1} image2={canon3_lisse_2}/>
                <ExempleTypeCanonModale titre={"3 rayés"} image1={canon3_raye_1} image2={canon3_raye_2}/>
            </span>
        </ModaleContent>
    </Modale>)
}

export default Modale1Canon;
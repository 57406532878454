import React, {useContext, useEffect, useState} from "react";
import Alerte from "../entites/Alerte";
import {SessionService} from "../infrastructure/InfrastructureFactory";
import {lireParametreTechnique} from "../utils/parametre/ParametreTechniqueService";
import {ParametreTechniqueEnum} from "../constantes/ParametreTechniqueEnum";

type RGAContextType = {
    alerte: Alerte;
    mettreAjourAlerte: (alertes: Alerte) => void;
    fermerAlerte: () => void;
    seDeconnecter: () => void;
    seConnecter: (token: string) => void;
    estUtilisateurConnecte: boolean;
    dateDerniereAction: Date;
    mettreAJourDateDerniereAction: () => void;
    estSso: boolean;
};

export const RGAContext = React.createContext<null | RGAContextType>(null);

export function useAlerteContext() {
    const context = useContext(RGAContext);
    if (context === null) {
        throw new Error(
            "useAlerteContext doit être utilisé dans un AlerteContextProvider"
        );
    }
    return context;
}

export function RGAContextProvider(props: Record<string, any>) {
    const [alerte, setAlerte] = useState<Alerte>(null);
    const [estSso, setSSO] = useState<boolean>(false);
    const [estUtilisateurConnecte, setEstUtilisateurConnecte] = useState<boolean>(false);
    const [dateDerniereAction, setDateDerniereAction] = useState<Date>(new Date());

    useEffect(() => {
        setEstUtilisateurConnecte(SessionService.estConnecter())
        lireParametreTechnique(ParametreTechniqueEnum.SSO_ACTIF).then(ssoActif => {
            ssoActif ? setSSO(true): setSSO(false);
        }).catch(() => setSSO(false))
    }, [])

    function seDeconnecter() {
        setEstUtilisateurConnecte(false)
        SessionService.deconnecterSession();
    }

    function seConnecter(token: string) {
        SessionService.stockerToken(token);
        setDateDerniereAction(new Date());
        setEstUtilisateurConnecte(true);
    }

    function mettreAjourAlerte(nouvellesAlerte: Alerte) {
        setAlerte(nouvellesAlerte);
    }

    function fermerAlerte() {
        setAlerte(null);
    }


    function mettreAJourDateDerniereAction() {
        setDateDerniereAction(new Date());
    }

    return (
        <RGAContext.Provider
            value={{
                alerte,
                mettreAjourAlerte,
                fermerAlerte,
                seConnecter,
                seDeconnecter,
                estUtilisateurConnecte,
                dateDerniereAction,
                mettreAJourDateDerniereAction,
                estSso

            }}
            {...props}
        />
    );
}

import {UtilisateurDroitEnum} from "../../constantes/UtilisateurDroitEnum";
import jwt_decode from "jwt-decode";
import {UtilisateurProfile} from "../../entites/UtilisateurProfile";
import {UtilisateurProfilCategorieEnum} from "../../constantes/UtilisateurProfilCategorieEnum";
import {SessionPort} from "../../utils/port/SessionPort";
import {determineSiDateEstPasse} from "../../utils/DateUtils";
import {UtilisateurToken} from "../../entites/Utilisateur";
import {UtilisateurProfilLibelleEnum} from "../../constantes/UtilisateurProfilLibelleEnum";

const keyToken = 'token'

const getToken = (): string => {
    return localStorage.getItem(keyToken) ?? "";
}

const getNbJoursAvantExpirationMdp = (): number => {
    return jwt_decode<UtilisateurToken>(getToken()).expireDays
}

const isExpireBientot = (): boolean => {
    return jwt_decode<UtilisateurToken>(getToken()).expireSoon
}

const stockerToken = (token: string) => {
    localStorage.setItem(keyToken, token);
}
const deconnecterSession = () => {
    localStorage.clear();
}

const estConnecter = (): boolean => {
    return !!getToken().trim();
}

const estSessionExpirer = (): boolean => {
    // x 1000 pour convertir en timestamp lisible par Date
    const dateExpiration = jwt_decode<UtilisateurToken>(getToken()).exp * 1000;
    return determineSiDateEstPasse(new Date(dateExpiration));
}

const estCompteSSO = (): boolean => {
    const tokenDecoder: UtilisateurToken = jwt_decode<UtilisateurToken>(getToken())
    return !!tokenDecoder.sso && !!tokenDecoder.sso.id;
}

const getUtilisateurDroits = (): UtilisateurDroitEnum[] => {
    if (estConnecter()) {
        const droitToken: UtilisateurDroitEnum[] = jwt_decode<UtilisateurToken>(getToken()).droits

        return Object.keys(droitToken).map(droit => {
            return UtilisateurDroitEnum[droit as keyof typeof UtilisateurDroitEnum]
        })
    }
    return [];

};

const getProfilCategorie = (): UtilisateurProfilCategorieEnum => {
    if (estConnecter()) {
        const categorieToken: UtilisateurProfilCategorieEnum = jwt_decode<UtilisateurToken>(getToken()).profil.categorie

        return UtilisateurProfilCategorieEnum[categorieToken as keyof typeof UtilisateurProfilCategorieEnum]
    }
    return;


};

const lireProfilLibelle = (): UtilisateurProfilLibelleEnum => {
    let libelleProfile;
    if (estConnecter()) {
        libelleProfile = jwt_decode<UtilisateurProfile>(getToken()).profil.libelle
    }
    return libelleProfile;


};

const utilisateurALeDroit = (droit: UtilisateurDroitEnum): boolean => {
    if (estConnecter()) {
        return getUtilisateurDroits().includes(droit);
    }
    return false

};
const utilisateurALesDroits = (droits: UtilisateurDroitEnum[]): boolean => {
    let aDroit = true;
    droits.forEach(droit => {
        aDroit = aDroit && utilisateurALeDroit(droit);
    })
    return aDroit;
}

const getUtilisateurActuel = (): UtilisateurProfile => {
    return jwt_decode<UtilisateurProfile>(getToken())
}

const lireSiren = (): string => {
    return jwt_decode<UtilisateurToken>(getToken()).siren
}


export const SessionServiceImpl: SessionPort = {
    getUtilisateurDroits,
    getProfilCategorie,
    estConnecter,
    stockerToken,
    getToken,
    utilisateurALeDroit,
    utilisateurALesDroits,
    estSessionExpirer,
    deconnecterSession,
    estCompteSSO,
    lireProfilLibelle,
    getUtilisateurActuel,
    isExpireBientot,
    getNbJoursAvantExpirationMdp,
    lireSiren
};

import {DesignationCommercialesCreation} from "../../../entites/ArmeAEnregistrer";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import {Input} from "@design-system-etat/dsfr-react/lib/cjs";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import Chip from "../../../composants/chip/Chip";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {DesignationCommerciale} from "../../../entites/Arme";
import SousTitre from "./SousTitre";
import {useAlerteContext} from "../../../composants/RGAContext";
import removeAccents from 'remove-accents';

interface DesignationCommercialeProps {
    ref: any
    lectureSeul: boolean
    designationCommercialesLectureSeule: DesignationCommerciale[]
    designationCommerciales: DesignationCommercialesCreation[]
    setDesignationCommerciales: (value: (((prevState: DesignationCommercialesCreation[]) => DesignationCommercialesCreation[]) | DesignationCommercialesCreation[])) => void
}

const DesignationCommercialeFicheArme: React.FC<DesignationCommercialeProps> = forwardRef((props: DesignationCommercialeProps, ref: any) => {
    const {
        lectureSeul,
        designationCommerciales,
        setDesignationCommerciales,
        designationCommercialesLectureSeule
    } = props;
    const [saisieDesignation, setSaisieDesignation] = useState<string>();
    const [erreurDesignation, setErreurDesignation] = useState<{}>();
    const {mettreAJourDateDerniereAction} = useAlerteContext()

    const placeholderDesignation = "Ajouter un désignation";
    const designationDejaPresente = "Cette désignation commerciale est déjà présente sur cette arme";

    useImperativeHandle(ref, () => ({
        reinitialiserDesignation() {
            setErreurDesignation(null)
            setSaisieDesignation("")
        }
    }))

    function ajouterDesignation() {
        mettreAJourDateDerniereAction();
        setErreurDesignation(null)
        if (saisieDesignation) {
            if (designationCommerciales.find(designation => designation.libelle === saisieDesignation)) {
                console.error(designationDejaPresente)
                setErreurDesignation({message: designationDejaPresente})
            } else {
                setDesignationCommerciales(prevState => [...prevState, {
                    key: designationCommerciales.length + 1,
                    action: "add",
                    valide: true,
                    libelle: saisieDesignation
                } as DesignationCommercialesCreation])
                setSaisieDesignation("")
            }
        } else {
            console.error(MessagesErreurEnum.CHAMP_REQUIS)
            setErreurDesignation({message: MessagesErreurEnum.CHAMP_REQUIS})
        }
    }

    function supprimerDesignation(libelleASupprimer: string) {
        setDesignationCommerciales(designationCommerciales.filter(designation => designation.libelle !== libelleASupprimer))
    }

    return <>
        <Row>
            <Col col={12}>
                <Row>
                    <SousTitre libelle={"Désignation(s) commerciale(s)"}/>
                </Row>
                <Row>
                    {lectureSeul ?
                        designationCommercialesLectureSeule?.map(designation =>
                            <Chip key={designation.libelle} libelle={designation.libelle} estSupprimable={!lectureSeul}
                                  identifiant={"fa-designation-" + designation.id}
                                  onSuppress={() => supprimerDesignation(designation.libelle)}/>)
                        :
                        <>
                            <Col col={3}>
                                <Input placeholder={placeholderDesignation}
                                       onChange={(event) => {
                                           setSaisieDesignation(removeAccents(event.target.value?.toUpperCase()))
                                       }}
                                       boutonAction={<Bouton icone="fr-icon-add-line" label={placeholderDesignation}
                                                             onClick={() => ajouterDesignation()}/>}
                                       name="inputDesignation"
                                       error={erreurDesignation}
                                       value={saisieDesignation}
                                       label={""}
                                />
                            </Col>

                            {designationCommerciales.map(designation =>
                                <Chip key={designation.libelle} libelle={designation.libelle}
                                      estSupprimable={!lectureSeul}
                                      identifiant={"fa-designation-" + designation.key}
                                      onSuppress={() => supprimerDesignation(designation.libelle)}/>)
                            }
                        </>
                    }
                </Row>
            </Col>
        </Row>
    </>
})

export default DesignationCommercialeFicheArme;
import React from "react";
import "./ModaleTypeCanon.css"

interface ExempleTypeCanonModaleProps {
    titre: string
    image1: string
    image2?: string
    image3?: string
    image4?: string
}

const ExempleTypeCanonModale : React.FC<ExempleTypeCanonModaleProps> = ({titre, image1, image2, image3, image4}) => {
    return (
        <div className={"modale-type-canon-exemple"}>
            <span className={"modale-type-canon-titre d-flex flex-center"}>{titre}</span>
            <div className={"d-flex flex-space-around"}>
                {image1 && (<>
                    <div className={"d-flex flex-center modale-type-canon-image"}>
                        <img
                            alt={titre}
                            src={image1}
                            className={"modale-type-canon-taille-image"}
                        />
                    </div>
                </>)}
                {image2 && (<>
                    <div className={"d-flex flex-center modale-type-canon-image"}>
                        <img
                            alt={titre}
                            src={image2}
                            className={"modale-type-canon-taille-image"}
                        />
                    </div>
                </>)}
                {image3 && (<>
                    <div className={"d-flex flex-center modale-type-canon-image"}>
                        <img
                            alt={titre}
                            src={image3}
                            className={"modale-type-canon-taille-image"}
                        />
                    </div>
                </>)}
                {image4 && (<>
                    <div className={"d-flex flex-center modale-type-canon-image"}>
                        <img
                            alt={titre}
                            src={image4}
                            className={"modale-type-canon-taille-image"}
                        />
                    </div>
                </>)}
            </div>
        </div>
    )
}

export default ExempleTypeCanonModale;
import React from "react";
import './Titre.css';

interface LienExterneRGAProps {
    children?: string
}

const LienExterneRGA = ({children} : LienExterneRGAProps) => {
    return <a className="fr-link" target="_blank" rel="noreferrer" href={children}>{children}</a>
}

export default LienExterneRGA;
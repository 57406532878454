export enum RechSimplTypeArmeEnum {
        ARME_A_FEU = "arme_a_feu",
        ARME_A_AIR_COMPRIME = "arme_a_air_comprime"
}

export enum RechSimplFamilleEnum {
        ARME_DE_POING = "arme_de_poing",
        ARME_EPAULE = "arme_d_epaule"
}

export enum RechSimplModeDeFonctionnementEnum {
        TOUT = "tout",
        A_UN_COUP = "a_un_coup",
        SEMI_AUTOMATIQUE = "semi_automatique",
        A_REPETITION_MANUELLE = "a_repetition_manuelle",
        AUTOMATIQUE = "automatique",
        INCONNU = "-"
}

export enum RechSimplMarqueEnum {
        ARTISAN = "artisan",
        NON_ARTISAN = "non-artisan",
        INCONNU = "-"
}

export class RechSimplTypeCanonOption {
        static readonly LISSE = new RechSimplTypeCanonOption("Lisse", "LISSE", "", "", "");
        static readonly RAYE = new RechSimplTypeCanonOption("Rayé", "RAYE", "", "", "");
        static readonly LISSE2 = new RechSimplTypeCanonOption("2 lisses", "LISSE", "LISSE", "", "");
        static readonly RAYE2 = new RechSimplTypeCanonOption("2 rayés", "RAYE", "RAYE", "", "");
        static readonly LISSE1_RAYE1 = new RechSimplTypeCanonOption("1 lisse + 1 rayé", "LISSE", "RAYE", "", "");
        static readonly LISSE2_RAYE1 = new RechSimplTypeCanonOption("2 lisses + 1 rayé","LISSE", "LISSE", "RAYE", "");
        static readonly LISSE1_RAYE2 = new RechSimplTypeCanonOption("1 lisse + 2 rayés", "LISSE", "RAYE", "RAYE", "");
        static readonly LISSE3 = new RechSimplTypeCanonOption("3 lisses", "LISSE", "LISSE", "LISSE", "");
        static readonly RAYE3 = new RechSimplTypeCanonOption("3 rayés", "RAYE", "RAYE", "RAYE", "");
        static readonly LISSE2_RAYE2 = new RechSimplTypeCanonOption("2 lisses + 2 rayés", "LISSE", "LISSE", "RAYE", "RAYE");
        static readonly LISSE3_RAYE1 = new RechSimplTypeCanonOption("3 lisses + 1 rayé", "LISSE", "LISSE", "LISSE", "RAYE");
        static readonly LISSE1_RAYE3 = new RechSimplTypeCanonOption("1 lisse + 3 rayés", "LISSE", "RAYE", "RAYE", "RAYE");
        static readonly LISSE4 = new RechSimplTypeCanonOption("4 lisses", "LISSE", "LISSE", "LISSE", "LISSE");
        static readonly RAYE4 = new RechSimplTypeCanonOption("4 rayés", "RAYE", "RAYE", "RAYE", "RAYE");
        static readonly INCONNU1 = new RechSimplTypeCanonOption("Je ne sais pas", "-", "", "", "");
        static readonly INCONNU2 = new RechSimplTypeCanonOption("Je ne sais pas", "-", "-", "", "");
        static readonly INCONNU3 = new RechSimplTypeCanonOption("Je ne sais pas", "-", "-", "-", "");
        static readonly INCONNU4 = new RechSimplTypeCanonOption("Je ne sais pas", "-", "-", "-", "-");
        static readonly PAR_DEFAUT = new RechSimplTypeCanonOption("", "", "", "", "");
        constructor(
            private readonly libelle: string,
            private readonly canon1: "LISSE" | "RAYE" | "-" | "",
            private readonly canon2: "LISSE" | "RAYE" | "-" | "",
            private readonly canon3: "LISSE" | "RAYE" | "-" | "",
            private readonly canon4: "LISSE" | "RAYE" | "-" | "",
        ) {}

        getLibelle(){return this.libelle}
        getCanon1(){return this.canon1}
        getCanon2(){return this.canon2}
        getCanon3(){return this.canon3}
        getCanon4(){return this.canon4}
}

export const TYPES_CANON_1 = [
        RechSimplTypeCanonOption.LISSE,
        RechSimplTypeCanonOption.RAYE,
        RechSimplTypeCanonOption.INCONNU1
]

export const TYPES_CANON_2 = [
        RechSimplTypeCanonOption.LISSE2,
        RechSimplTypeCanonOption.RAYE2,
        RechSimplTypeCanonOption.LISSE1_RAYE1,
        RechSimplTypeCanonOption.INCONNU2
]

export const TYPES_CANON_3 = [
        RechSimplTypeCanonOption.LISSE2_RAYE1,
        RechSimplTypeCanonOption.LISSE1_RAYE2,
        RechSimplTypeCanonOption.LISSE3,
        RechSimplTypeCanonOption.RAYE3,
        RechSimplTypeCanonOption.INCONNU3
]

export const TYPES_CANON_4 = [
        RechSimplTypeCanonOption.LISSE2_RAYE2,
        RechSimplTypeCanonOption.LISSE3_RAYE1,
        RechSimplTypeCanonOption.LISSE1_RAYE3,
        RechSimplTypeCanonOption.LISSE4,
        RechSimplTypeCanonOption.RAYE4,
        RechSimplTypeCanonOption.INCONNU4
]

export const MODES_DE_FONCTIONNEMENT = {
        [RechSimplModeDeFonctionnementEnum.TOUT] : ["A 1 COUP", "A REPETITION MANUELLE + A UN COUP", "AUTOMATIQUE TRANSFORMEE A 1 COUP", "A CHARGEMENT PAR LA BOUCHE", "A 1 COUP", "A REPETITION MANUELLE + A UN COUP", "AUTOMATIQUE TRANSFORMEE A 1 COUP", "A CHARGEMENT PAR LA BOUCHE", "SEMI-AUTOMATIQUE", "AUTOMATIQUE TRANSFORMEE SEMI-AUTOMATIQUE", "AUTOMATIQUE"],
        [RechSimplModeDeFonctionnementEnum.A_UN_COUP] : ["A 1 COUP", "A REPETITION MANUELLE + A UN COUP", "AUTOMATIQUE TRANSFORMEE A 1 COUP", "A CHARGEMENT PAR LA BOUCHE"],
        [RechSimplModeDeFonctionnementEnum.A_REPETITION_MANUELLE] : ["A REPETITION MANUELLE", "A REPETITION MANUELLE + A 1 COUP", "AUTOMATIQUE TRANSFORMEE REP. MANUELLE"],
        [RechSimplModeDeFonctionnementEnum.SEMI_AUTOMATIQUE] : ["SEMI-AUTOMATIQUE", "AUTOMATIQUE TRANSFORMEE SEMI-AUTOMATIQUE"],
        [RechSimplModeDeFonctionnementEnum.AUTOMATIQUE]: ["AUTOMATIQUE"]
}
import React, {ReactElement} from "react";

interface TuileArmeProps {
    titre: string
    children: ReactElement | ReactElement[] | string
    bouton?: ReactElement | ReactElement[]
    idTuile: string
}


const TuileArme: React.FC<TuileArmeProps> = (props: TuileArmeProps) => {
    const {titre, children, idTuile, bouton} = props;
    return <div className="fr-tile fr-tile--horizontal align-item-start tuile-arme--alignement" id={idTuile}>
        <div className="fr-tile__body">
            {bouton && <div className={"width-100 texte-droite"}>{bouton} </div>
            }
            <h6>
                <p className={"fr-text--lg texte-titre-gris "}>{titre}</p>
            </h6>
            <div className={"width-100"}>
                {children}
            </div>
        </div>
    </div>
}
export default TuileArme;
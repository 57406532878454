import {Captcha, CaptchaInitial} from "./Captcha";

export interface FormulaireContact {
    nom: string,
    prenom: string,
    email: string,
    tel: string,
    objet: string,
    message: string,
    isAutoDestinataire: boolean,
    captcha: Captcha
}

export const FormulaireContactInitial : FormulaireContact = {
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    objet: "",
    message: "",
    isAutoDestinataire: false,
    captcha: CaptchaInitial
}
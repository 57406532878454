export enum EtapesStepperEnum {
    FAMILLE = "Famille"  ,
    MARQUE = "Marque" ,
    MODELE = "Modèle",
    CALIBRE = "Calibre",
    CANON = "Canon",
    MODE_DE_FONCTIONNEMENT = "Mode de fonctionnement",
    PRECAUTIONS = "Précautions",
    TYPE_ARME = "Type d'arme",
    RESULTAT = "Résultat"
}
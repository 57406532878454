import React from "react";
import Select from "react-select";
import {SelectOption} from "../../entites/Referentiels/SelectOption";

interface SelectMultipleRGAProps {
    options: SelectOption[],
    nom: string,
    onChange,
    valeurParDefaut: SelectOption[]
}

export const SelectMultipleRGA: React.FC<SelectMultipleRGAProps> = (props) => {
    const {options, nom, onChange, valeurParDefaut} = props;

    return <Select
        isClearable={false}
        defaultValue={valeurParDefaut}
        placeholder={<span className={"select-placeholder"}>Filtre</span>}
        isMulti
        name={nom}
        options={options}
        unstyled
        closeMenuOnSelect={false}
        onChange={onChange}
        classNames={{
            control: () => "select-control ",
            container: () => "autocomplete-container ",
            valueContainer: () => "select-valueContainer ",
            placeholder: () => "autocomplete-valueContainer ",
            indicatorsContainer: () => "autocomplete-indicatorsContainer",
            menu: () => "autocomplete-menu",
            option: () => "autocomplete-option",
            multiValue: () => "fr-tag fr-tag--sm select-multiValue ",

        }}
    />

}
export default SelectMultipleRGA;


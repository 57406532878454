import {FiltreAPI} from "../../entites/FiltreAPI";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {ReferentielAPIEnum} from "../../constantes/ReferentielAPIEnum";
import {ReferentielType} from "../../utils/port/RgaAppliAPIPort";
import {SelectOption} from "../../entites/Referentiels/SelectOption";
import {
    ClassementValideEurope,
    ClassementValideFrance,
    Fabricant,
    Famille,
    Marque,
    ModeAlimentation,
    ModeFonctionnement,
    Model,
    Pays,
    TypeArme
} from "../../entites/Arme";
import {Calibre, ModePercussion, TypeCanon} from "../../entites/Canon";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {MessageGenerique} from "../../constantes/MessageGenerique";

export function chargerReferentiel(referentiel, filtre: FiltreAPI[], setReferentiel, mettreAjourAlerte, setChargementTermine?, pagination?:0) {
    const triResultatParLibelle = (ref1, ref2) => ref1.libelle.localeCompare(ref2.libelle);
    const numeroPage = pagination > 0 ? pagination + 1 : 1;
    RgaAppliAPI.recupererReferentielComplet(referentiel, 850, numeroPage, filtre).then(referentiels => {
        if (referentiel !== ReferentielAPIEnum.PAYS_FABRICANT) {
            referentiels.elements.sort(triResultatParLibelle)
        }

        function creerOptions<T>(cle: string) {
            let options = referentiels.elements.map((option: ReferentielType<T>) => new SelectOption(option[cle], option.libelle, option))
            if (pagination >0) {
                setReferentiel((optionsExistantes: ReferentielType<T>[]) => [...optionsExistantes, ...options])
            } else {
                setReferentiel(options)
            }
            if (setChargementTermine) {
                setChargementTermine(false)
            }
        }

        switch (referentiel) {
            case ReferentielAPIEnum.CLASSEMENT_FR:
                creerOptions<ClassementValideFrance>('idClassementFrance');
                break;
            case ReferentielAPIEnum.CLASSEMENT_EU:
                creerOptions<ClassementValideEurope>('idClassementEurope');
                break;
            case ReferentielAPIEnum.FAMILLES:
                creerOptions<Famille>('idFamilleArme');
                break;
            case ReferentielAPIEnum.TYPES_ARMES:
                creerOptions<TypeArme>('idTypeArme');
                break;
            case ReferentielAPIEnum.PAYS_FABRICANT:
                creerOptions<Pays>('id');
                break;
            case ReferentielAPIEnum.MARQUES:
                creerOptions<Marque>('idMarque');
                break;
            case ReferentielAPIEnum.MODELES:
                creerOptions<Model>('idModele');
                break;
            case ReferentielAPIEnum.FABRICANTS:
                creerOptions<Fabricant>('idFabricant');
                break;
            case ReferentielAPIEnum.SYSTEMES_ALIMENTATION:
                creerOptions<ModeAlimentation>('idModeAlimentation');
                break;
            case ReferentielAPIEnum.FONCTIONNEMENTS:
                creerOptions<ModeFonctionnement>('idModeFonctionnement');
                break;
            case ReferentielAPIEnum.MODES_PERCUSSION:
                creerOptions<ModePercussion>('idModePercussion');
                break;
            case ReferentielAPIEnum.TYPES_CANON:
                creerOptions<TypeCanon>('idTypeCanon');
                break;
            case ReferentielAPIEnum.CALIBRES:
                creerOptions<Calibre>('idCalibre');
                break;
            default:
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "Erreur technique", "Le réferentiel chargé est inconnu."))
                setReferentiel([] as SelectOption[])

        }

    }).catch((erreur) => {
        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", MessageGenerique.REQUETE_EN_ERREUR))
    })
}


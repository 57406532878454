import React from "react";
import Titre from "../../composants/Titre";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import Input from "@design-system-etat/dsfr-react/lib/esm/components/interface/Form/Input/Input";
import {Checkbox} from "@design-system-etat/dsfr-react/lib/cjs";
import {sendPlaneFill} from "@design-system-etat/dsfr-react/lib/esm";
import {useForm} from "react-hook-form";
import {FormulaireContact, FormulaireContactInitial} from "../../entites/FormulaireContact";
import {MessagesErreurEnum} from "../../constantes/MessagesErreurEnum";
import Captcha from "../../composants/Captcha";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import MiCaptcha from "../../utils/captcha";
import {FooterServiceApi} from "../../infrastructure/InfrastructureFactory";
import {fr} from "../../constantes/ClassesDSFR";
import {ServiceErrorsEnum} from "../../constantes/ServiceErrorsEnum";

const EcranNousContacter : React.FC = () => {

    const form = useForm<FormulaireContact>({mode: 'onChange', defaultValues: FormulaireContactInitial});

    const alerteMailEnvoye = new Alerte(typeAlerte.REUSSITE, "","Votre mail à été envoyé")
    const alerteCaptchaInvalide = new Alerte(typeAlerte.ERREUR, "","Une erreur est survenue lors de la saisie du captcha.")
    const alerteCaptchaErreur = new Alerte(typeAlerte.ERREUR, "","Une erreur est survenue lors du contrôle du captcha.")

    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();

    const envoyerMessage = () => {
        mettreAJourDateDerniereAction();
        form.setValue("captcha.id", (document.getElementsByName("mi-captcha-id")[0] as HTMLInputElement).value);
        form.setValue("captcha.application", (document.getElementsByName('mi-captcha-application')[0] as HTMLInputElement).value);
        FooterServiceApi.envoyerMessage(form.getValues())
            .then(() => {
                mettreAjourAlerte(alerteMailEnvoye);
            })
            .catch(error => {
                switch(error.response.data.error.type){
                    case ServiceErrorsEnum.CAPTCHA_INVALIDE:
                        mettreAjourAlerte(alerteCaptchaInvalide)
                        break;
                    case ServiceErrorsEnum.CAPTCHA_ERREUR:
                        mettreAjourAlerte(alerteCaptchaErreur)
                }
            })
        form.setValue("captcha.value", "");
        MiCaptcha.refresh();
    }

    return (<section className={fr.my_4w + "fr-container"}>
        <Titre>Nous Contacter</Titre>
        <form onSubmit={form.handleSubmit(envoyerMessage)}>
            <Row goutierre>
                <Col col={6} className={fr.pr_2w}>
                    <Input
                        label={"Nom *"}
                        name="nom"
                        longueurMax
                        error={form.formState.errors.nom}
                        {...form.register('nom', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 50, message: MessagesErreurEnum.LONGUEUR_MAX_50},
                            pattern: {value: /^[A-Za-zÀ-ÿ' -]+$/, message: MessagesErreurEnum.NON_AUTORISE}
                        })}
                    />
                </Col>
                <Col col={6} className={fr.pl_2w}>
                    <Input
                        label={"Prénom *"}
                        name="prenom"
                        longueurMax
                        error={form.formState.errors.prenom}
                        {...form.register('prenom', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 50, message: MessagesErreurEnum.LONGUEUR_MAX_50},
                            pattern: {value: /^[A-Za-zÀ-ÿ' -]+$/, message: MessagesErreurEnum.NON_AUTORISE}
                        })}
                    />
                </Col>
            </Row>
            <Row goutierre>
                <Col col={6} className={fr.pr_2w}>
                    <Input
                        label={"E-mail *"}
                        name="email"
                        longueurMax
                        error={form.formState.errors.email}
                        {...form.register('email',{
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 320, message: MessagesErreurEnum.LONGUEUR_MAX_320},
                            pattern: {value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: MessagesErreurEnum.MAIL}
                        })}
                    />
                </Col>
                <Col col={6} className={fr.pl_2w}>
                    <Input
                        label={"Numéro de téléphone *"}
                        name="telephone"
                        type="number"
                        longueurMax
                        error={form.formState.errors.tel}
                        {...form.register('tel',{
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 15, message: MessagesErreurEnum.LONGUEUR_MAX_15},
                        })}
                    />
                </Col>
            </Row>
            <Row goutierre>
                <Col col={6} className={fr.pr_2w}>
                    <Input
                        label={"Objet *"}
                        name="objet"
                        longueurMax
                        error={form.formState.errors.objet}
                        {...form.register('objet',{
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 255, message: MessagesErreurEnum.LONGUEUR_MAX_255},
                        })}
                    />
                </Col>
            </Row>
            <Row goutierre>
                <Col className={fr.pr_2w}>
                    <Input
                        label={"Message *"}
                        name="message"
                        type="textarea"
                        longueurMax
                        error={form.formState.errors.message}
                        {...form.register('message',{
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 1000, message: MessagesErreurEnum.LONGUEUR_MAX_1000},
                        })}
                    />
                </Col>
            </Row>
            <div className={fr.pt_2w + fr.pr_2w}>
                <Checkbox
                    label="Être destinataire du courriel"
                    name="estDestinataire"
                    {...form.register('isAutoDestinataire')}
                />
            </div>
            <Captcha
                onChange={valeur => form.setValue("captcha", valeur)}
                error={form.formState.errors.captcha}
                register={form.register('captcha.value',{
                    required: MessagesErreurEnum.CHAMP_REQUIS,
                })}
            />
            <Row goutierre className={"texte-centre"}>
                <Col className={fr.pr_2w}>
                    <Bouton
                        type={"submit"}
                        taille="lg"
                        icone={sendPlaneFill}
                        iconePosition="left"
                        label="Envoyer"
                        niveau="primaire"
                    />
                </Col>
            </Row>
        </form>

        <div className={fr.mt_5w}>
            <p>
                Les informations que vous communiquez sur ce formulaire sont nécessaires au SCAE qui est responsable de leur traitement.
            </p>
            <p>
                Le SCAE ne transmet aucune de vos données à des tiers.
            </p>
            <p>
                Ces données sont conservées pendant 1 (un) an et sont ensuite effacées. Vous bénéficiez d’un droit d’accès à vos données, de rectification, à tout moment.
                Pour ce faire, vous pouvez vous adresser au Service central des Armes et Explosifs, Place Beauvau - 75800 PARIS CEDEX 08 ou par mail : {" "}
                <a href={"mailto:webmestre-internet-sca@interieur.gouv.fr"}>webmestre-internet-sca@interieur.gouv.fr</a>, en joignant un titre d’identité afin de pouvoir traiter votre demande. Vous avez la possibilité d’introduire
                une réclamation auprès d’une autorité de contrôle.
            </p>
            <p>
                Mise à jour le 20 février 2023
            </p>
        </div>
    </section>)
}

export default EcranNousContacter;

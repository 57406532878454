import React, {ReactElement} from "react";
import {Container, Input, Select, Option} from "@design-system-etat/dsfr-react/lib/cjs";

import {
    TABLEAU_ORDRE_TRI_ASC,
    TABLEAU_ORDRE_TRI_DESC
} from "@design-system-etat/dsfr-react/lib/esm";
import {
    arrowDownLine,
    arrowUpLine,
    Icone
} from "@design-system-etat/dsfr-react/lib/esm/components/fondamentaux/Icone/index";
import {Col, Row} from "@design-system-etat/dsfr-react";
import {SelectOption} from "../entites/Referentiels/SelectOption";
import SelectMultipleRGA from "./SelectHorsDsfr/SelectMultipleRGA";
import "./TableauHeaderCellFiltres.css";

interface TableauHeaderFiltrableProps {
    onChange?: (nomColonne: string, inputValeur: string | string[]) => void
    idColonne: string,
    ordreTriValeur?: "ASC" | "DESC" | "",
    onClick?: (nomColonne: string, ordreTriValeur: string) => void,
    typeFiltre?: "text" | "date" | "select" | "selectMulti",
    defaultValue?: string,
    defaultValueMulti?: string[],
    selectOptions?: string[],
    className?: string,
    children: string | ReactElement | ReactElement[]
}


export const TableauHeaderCellFiltres: React.FC<TableauHeaderFiltrableProps> = (props) => {
    const {
        onChange,
        idColonne,
        ordreTriValeur,
        onClick,
        typeFiltre,
        selectOptions,
        defaultValue,
        defaultValueMulti,
        className,
        children
    } = props

    function genererSelect() {
        return (
            <Select
                {...{defaultValue: defaultValue}}
                label={""}
                name={idColonne}
                placeholder={"Filtre"}
                onChange={(event) => onChange(idColonne, event.target.value)}
            >
                <>
                    <Option value={""} key={"empty"} children={null}/>
                    {selectOptions.map((option, index) => {
                        //@ts-ignore
                        return <Option value={option} key={index}
                                       selected={option === defaultValue}> {option} </Option>
                    })}
                </>
            </Select>

        )
    }

    function genererMultiSelect() {
        return <SelectMultipleRGA
            valeurParDefaut={defaultValueMulti.map(value => new SelectOption(value))}
            nom={idColonne}
            options={selectOptions.map((option,) => {
                return new SelectOption(option);
            })}
            onChange={(resultat: SelectOption[]) => onChange(idColonne, resultat.map(option => {
                return option.label
            }))}

        />
    }

    function genererInput() {
        return <Input
            onChange={event => onChange(idColonne, event.target.value)}
            placeholder="Filtre"
            {...{defaultValue: defaultValue}}
            label={""}
            name={idColonne}
        />
    }

    function genererDate() {
        return (<Container>
            <Row goutierre>
                <Col col={6} className={"dateFiltre"}>
                    <Input
                        onChange={event => onChange("date[after]", event.target.value)}
                        {...{defaultValue: defaultValue}}
                        type={"date"}
                        label={""} name={"date[after]"}
                    />
                </Col>
                <Col col={6} className={"dateFiltre"}>
                    <Input
                        onChange={event => {
                            let dateValue = event.target.valueAsDate;
                            dateValue.setHours(23, 59, 59, 59)
                            onChange("date[strictly_before]", dateValue.toISOString())
                        }}
                        {...{defaultValue: defaultValue}}
                        type={"date"}
                        label={""} name={"date[strictly_before]"}/>
                </Col>
            </Row>
        </Container>)
    }

    function onClickTri() {
        if (ordreTriValeur === TABLEAU_ORDRE_TRI_ASC) {
            onClick(idColonne, TABLEAU_ORDRE_TRI_DESC)
        } else
            onClick(idColonne, TABLEAU_ORDRE_TRI_ASC)
    }

    let filtre;
    switch (typeFiltre) {
        case "text" :
            filtre = genererInput()
            break
        case "date" :
            filtre = genererDate()
            break
        case "select" :
            filtre = genererSelect()
            break;
        case "selectMulti":
            filtre = genererMultiSelect()
    }


    return (
        <th className={className + " h-100"}>
            <div className={"filtreHeaderTableau"}>
                <div onClick={onClickTri}
                     className={"position-fleche-tri__tableauHeaderFiltrable curseur-fleche-tri__tableauHeaderFiltrable "}
                     role="button" tabIndex={0}
                >
                    {children}
                    {ordreTriValeur === TABLEAU_ORDRE_TRI_ASC ? (<Icone icone={arrowUpLine}/>) : null}
                    {ordreTriValeur === TABLEAU_ORDRE_TRI_DESC ? (<Icone icone={arrowDownLine}/>) : null}
                </div>
                <div style={{verticalAlign: "bottom"}}>
                    {filtre}
                </div>
            </div>
        </th>
    )
}
import {URLPageEnum} from "./URLPageEnum";

export interface RouteFilAriane {
    urlPage: URLPageEnum,
    parent: URLPageEnum,
    nom: string
}

export const routesFilAriane : RouteFilAriane[] = [
    {urlPage: URLPageEnum.ECRAN_FONCTIONNALITE, nom: "Accueil", parent: null},
    {urlPage: URLPageEnum.ADMINISTRATION_DROITS, nom: "Administration des droits", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.ACCESSIBILITE, nom: "Accessibilité", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.CONDITIONS_GENERALES, nom: "Mentions légales", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.ECRAN_CONSULTER_LES_TRACES, nom: "Consulter les traces", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.NOUS_CONTACTER, nom: "Nous contacter", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.RECHERCHE_AVANCEE_ARMES, nom: "Recherche avancée", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.ADMINISTRATION_REFERENTIELS, nom: "Administration des référentiels", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.FICHE_ARME, nom: "Fiche arme", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.RENOUVELER_MOT_DE_PASSE, nom: "Renouveler le mot de passe", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.RECHERCHE_SIMPLIFIEE, nom: "Recherche simplifiée", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.ADMINISTRATION_PARAMETRES, nom: "Administration des paramètres", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.ADMINISTRATION_UTILISATEURS, nom: "Administration des utilisateurs", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.ADMINISTRATION_PAGE_DE_CONNEXION, nom: "Administration de la page de connexion", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.IMPORT_MASSE_FICHES, nom: "Import des armes en masse", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.IMPORT_MASSE_DEMANDE, nom: "Import des demandes en masse", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.DEMANDE_ARME, nom: "Liste des demandes", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.CONSULTER_DEMANDES_ARMES, nom: "Consultation des demandes de création d'armes", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
    {urlPage: URLPageEnum.CONSULATION_COMPTE, nom: "Mes informations", parent: URLPageEnum.ECRAN_FONCTIONNALITE},
]
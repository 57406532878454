import {Captcha, CaptchaInitial} from "./Captcha";

export interface FormulaireCompte {
    nom: string,
    prenom: string,
    mail: string,
    siren: number,
    societe: string,
    captcha: Captcha
}

export const FormulaireCompteInitial : FormulaireCompte = {
    nom: "",
    prenom: "",
    mail: "",
    siren: null,
    societe: "",
    captcha: CaptchaInitial
}
import React from "react";
import {Carte, CarteBody, CarteContent, CarteDescription, CarteTitre} from "@design-system-etat/dsfr-react";
import {CarteHeader, CarteImage} from "@design-system-etat/dsfr-react/lib/cjs";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import warning from "@gouvfr/dsfr/dist/artwork/pictograms/system/warning.svg";
import Pictogram from "../../../entites/Pictogramme";

interface RechSimplPrecautionsProps {
    onSuivantClick: () => any
    onPrecedentClick: () => any
}

const RechSimplPrecautions : React.FC<RechSimplPrecautionsProps> = ({onSuivantClick, onPrecedentClick}) => {


    const pictoPrecautionAvantRecherche: Pictogram = {
        svg: warning,
        alt: "pictogram precaution avant la recherche"
    };

    return <>
        <Carte isHorizontal ratioHorizontal="tier" taille={"md"}>
            <CarteBody>
                <CarteContent>
                    <CarteTitre>
                        Précautions avant la recherche
                    </CarteTitre>
                    <CarteDescription>
                        <p>Une arme doit toujours être considérée comme chargée.</p>
                        <p>Une arme ne doit jamais être dirigée vers quelqu'un.</p>
                        <p>Avant de manipuler une arme, toujours s'assurer qu'elle est mise en sécurité.</p>
                    </CarteDescription>
                </CarteContent>
            </CarteBody>
            <CarteHeader>
                <CarteImage>
                    <img
                        src={pictoPrecautionAvantRecherche.svg}
                        className="fr-responsive-img"
                        alt={pictoPrecautionAvantRecherche.alt}
                    />
                </CarteImage>
            </CarteHeader>
        </Carte>
        <BoutonsEcranRecherche
            sansResultats
            texteBoutonSuivant={"J'ai compris"}
            texteBoutonPrecedent={"Retour"}
            onPrecedentClick={onPrecedentClick}
            onSuivantClick={onSuivantClick}
            suivantInterdit={false}
        />
    </>
}

export default RechSimplPrecautions;
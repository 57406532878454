import React, {useEffect, useState} from 'react';
import {
    Bouton,
    Header,
    HeaderLogo,
    HeaderService,
    HeaderToolsRaccourcis,
    HeaderToolsRaccourcisItem,
    HeaderToolsRecherche,
    Icone,
    Logo,
    Modale,
    ModaleContent,
    userFill
} from "@design-system-etat/dsfr-react";
import "./Header.css";
import logo from './asset/ruler.svg';
import {RgaAppliAPI, SessionService} from "../../infrastructure/InfrastructureFactory";
import {UtilisateurProfilCategorieEnum} from "../../constantes/UtilisateurProfilCategorieEnum";
import ModuleDeConversion from "../../composants/module-de-conversion/ModuleDeConversion";
import {useAlerteContext} from "../../composants/RGAContext";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {FicheArmeActionEnum} from "../../constantes/FicheArmeActionEnum";
import {useNavigate} from "react-router-dom";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {MessageGenerique} from "../../constantes/MessageGenerique";
import {ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import history from "../../utils/navigation/History";
import {accountCircleFill} from "@design-system-etat/dsfr-react/lib/cjs/components/fondamentaux/Icone";
import {logoutBoxRFill} from "@design-system-etat/dsfr-react/lib/esm/components/fondamentaux/Icone";
import Titre from "../../composants/Titre";


const HeaderRga: React.FC = () => {
    const [modaleConversionOuvert, setModaleConversionOuvert] = useState<boolean>(false);
    const [utilisateurCategorieProfile, setUtilisateurCategorieProfile] = useState<UtilisateurProfilCategorieEnum>(null)
    const [utilisateurLibelleProfile, setUtilisateurLibelleProfile] = useState<string>("")
    const [afficherModaleInactivite, setAfficherModaleInactivite] = useState<boolean>(false);
    const [modaleMonCompteOuvert, setModaleMonCompteOuvert] = useState<boolean>(false);
    const [hrefRecherche, setHrefRecherche] = useState<string>("");

    const {
        estUtilisateurConnecte,
        mettreAjourAlerte,
        dateDerniereAction,
        seDeconnecter,
        mettreAJourDateDerniereAction,
        estSso
    } = useAlerteContext();

    const TEMPS_RAFRAICHISSEMENT = 30000; // tous les 30s
    const navigate = useNavigate();
    const profilAfficher = (categorieProfil: UtilisateurProfilCategorieEnum): string => {
        let nomProfileAffiche: string
        switch (categorieProfil) {
            case UtilisateurProfilCategorieEnum.SCAE :
                nomProfileAffiche = " - Espace " + utilisateurLibelleProfile;
                break;

            case UtilisateurProfilCategorieEnum.PRO :
                nomProfileAffiche = " - Espace Professionnel";
                break;

            case UtilisateurProfilCategorieEnum.PUBLIC :
                nomProfileAffiche = " - Espace Détenteur";
                break;

            case UtilisateurProfilCategorieEnum.CONSULTATION :
                nomProfileAffiche = " - Espace " + utilisateurLibelleProfile;
                break;
            default :
                nomProfileAffiche = ""

        }
        return nomProfileAffiche;


    }

    useEffect(() => {
        // @ts-ignore
        const delaiDeconnexionAutomatiqueMinute = window?.DECONNEXION_AUTOMATIQUE_MINUTES ?? 1;

        let interval = null;
        if (!estSso) {
            interval = setInterval(() => {
                let dateExpiration = new Date(dateDerniereAction);
                dateExpiration.setUTCMinutes(delaiDeconnexionAutomatiqueMinute + dateDerniereAction.getUTCMinutes());
                if (dateExpiration < new Date() && estUtilisateurConnecte) {
                    clearInterval(interval);
                    seDeconnecter();
                    setAfficherModaleInactivite(true);
                }
            }, TEMPS_RAFRAICHISSEMENT);
        }
        return () => {
            if (!estSso) {
                clearInterval(interval);
            }
        };
    }, [dateDerniereAction, seDeconnecter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setUtilisateurCategorieProfile(SessionService.getProfilCategorie());
        setUtilisateurLibelleProfile(SessionService.lireProfilLibelle());
        mettreAJourDateDerniereAction();
    }, [estUtilisateurConnecte]); // eslint-disable-line react-hooks/exhaustive-deps

    const deconnecterUtilisateur = async () => {
        setAfficherModaleInactivite(false);
        history.replace("/");
    }

    const verifierNumeroSia = (siaRechercher) => {
        if ((siaRechercher.length === 5 && !new RegExp('[A-Za-z]{2}[0-9]{3}').test(siaRechercher)) || siaRechercher.length > 5) {
            mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, '', 'Veuillez saisir une référence RGA valide (2 lettres suivies de 3 chiffres uniquement)'));
            return false;
        }
        return new RegExp('[A-Za-z]{2}[0-9]{3}').test(siaRechercher) && siaRechercher.length === 5;
    }
    const rechercherArme = (siaRechercher) => {
        if (siaRechercher) {
            verifierNumeroSia(siaRechercher)
            RgaAppliAPI.rechercheArmeParReference(siaRechercher.toUpperCase()).then(() => {
                setHrefRecherche(URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + (siaRechercher.toUpperCase()))
            }).catch(() => mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, '', MessageGenerique.AUCUNE_ARME_TROUVE)))
                .finally(() =>
                    mettreAJourDateDerniereAction())
        }
    }

    const deconnexionManuelle = () => {
        seDeconnecter();
        setModaleMonCompteOuvert(false);
        history.replace("/");
    }


    return (

        <div onKeyUp={(evt) => {
            if (evt.key === "Enter" && hrefRecherche) {
                const ecran= window.open(hrefRecherche)
                if(!ecran || ecran.closed || typeof ecran.closed =="undefined"){
                    mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, '',
                        "Votre navigateur n'autorise l'ouverture d'un nouvel onglet. " +
                        "Autorisez les popups pour ce site ou cliquez sur la loupe."))
                }
            }
        }}>
            <ModuleDeConversion visible={modaleConversionOuvert}
                                closeCallback={() => {
                                    setModaleConversionOuvert(false);
                                    mettreAJourDateDerniereAction();
                                }
                                }/>
            <Header>

                <HeaderLogo>
                    <a href={URLPageEnum.ECRAN_FONCTIONNALITE}
                       title="Accueil - MINISTERE DE L'INTERIEUR ET DES OUTRE-MER"
                    >
                        <Logo>
                            <>
                                MINISTÈRE<br/>
                                DE L'INTÉRIEUR<br/>
                                ET DES OUTRE-MER
                            </>
                        </Logo>
                    </a>
                </HeaderLogo>

                <HeaderService
                    baseline={"Service Central des Armes et des Explosifs" + profilAfficher(utilisateurCategorieProfile)}
                    titre="Référentiel Général des Armes"
                />


                {estUtilisateurConnecte &&

                    <HeaderToolsRecherche
                        onClickRecherche={() => {
                            if (hrefRecherche) {
                                window.open(hrefRecherche)
                            }
                        }}
                        onChange={(evt) => {
                            if (verifierNumeroSia(evt.target.value)) {
                                rechercherArme(evt.target.value);
                            } else {
                                setHrefRecherche("")
                            }
                        }}
                        ref={(ref: HTMLInputElement) => {
                            if (ref) {
                                ref.className = "fr-input " + (hrefRecherche ? "recherche-active" : "")
                                ref.placeholder = "JB166"
                                ref.value = ref.value.toUpperCase()
                                ref.style.textTransform = "uppercase";
                                ref.setAttribute("maxlength", '5');
                            }
                        }} name="recherche-header"/>

                }

                {estUtilisateurConnecte && <HeaderToolsRaccourcis>
                    {utilisateurCategorieProfile === UtilisateurProfilCategorieEnum.PRO && <>
                        <div id="icone-persona-header" className="blue-icone__header">
                            <Icone icone={userFill}/>
                        </div>
                        <HeaderToolsRaccourcisItem id="header__item-moncompte" className="separateur__header" href="#"
                                                   onClick={() => {
                                                       setModaleMonCompteOuvert(true);
                                                       mettreAJourDateDerniereAction();
                                                   }}>
                            Mon compte
                        </HeaderToolsRaccourcisItem>
                    </>
                    }
                    <div>
                        <img className="pointer-conversion__header position-logo-conversion__header" src={logo}
                             alt="icone de règle" onClick={() => {
                            setModaleConversionOuvert(true);
                            mettreAJourDateDerniereAction();
                        }}/>
                    </div>
                    <HeaderToolsRaccourcisItem id="header__item-conversion" onClick={() => {
                        setModaleConversionOuvert(true);
                        mettreAJourDateDerniereAction();
                    }} href="#">
                        Conversion
                    </HeaderToolsRaccourcisItem>
                </HeaderToolsRaccourcis>
                }
            </Header>
            {afficherModaleInactivite && (
                <Modale
                    id="Déconnexion"
                    closeCallback={() => deconnecterUtilisateur()}
                >
                    <ModaleTitre idModale="conversion">
                        Déconnexion
                    </ModaleTitre>
                    <ModaleContent>
                        <p>Vous avez été déconnecté pour inactivité.</p>
                    </ModaleContent>
                </Modale>
            )}
            {modaleMonCompteOuvert && (
                <Modale id={"compte"} closeCallback={() => setModaleMonCompteOuvert(false)}>
                    <ModaleContent>
                        <div className={"modaleCompte"}>
                            <Titre>
                                Mon compte
                            </Titre>
                            <div>
                                <Bouton niveau="primaire" label={"Gérer mes informations"} iconePosition="left"
                                        icone={accountCircleFill} onClick={() => {
                                    setModaleMonCompteOuvert(false)
                                    navigate(URLPageEnum.CONSULATION_COMPTE)
                                }}
                                />

                            </div>
                            <hr style={{marginTop: "24px", border: "3rem"}}/>
                            <div>
                                <Bouton niveau="primaire" label={"Me déconnecter"} iconePosition="left"
                                        id={"mon-compte-deconnecte"}
                                        icone={logoutBoxRFill}
                                        onClick={() => {
                                            deconnexionManuelle();
                                        }}
                                />
                            </div>
                        </div>
                    </ModaleContent>
                </Modale>
            )}

        </div>
    )
}
export default HeaderRga;


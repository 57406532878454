import {UTILISATEUR_STATUTS} from "../constantes/UtilisateurStatusEnum";
import {UtilisateurDroitEnum} from "../constantes/UtilisateurDroitEnum";
import {UtilisateurProfilCategorieEnum} from "../constantes/UtilisateurProfilCategorieEnum";

export interface Utilisateur {
    "@id": string,
    "@type": string,
    "id": number,
    "prenom": string,
    "nom": string,
    "mail": string,
    "profil": ProfilUtilisateur,
    "etat": UTILISATEUR_STATUTS,
    "nbTentativeConnexion": number,
    "dateCreationMdp": Date,
    "dateDerniereConnexion": Date,
    "societe": string,
    "siren": string,
    "siret": string,
    "ssoId": string,
}

export interface UtilisateurLite {
    nom: string,
    prenom: string,
    societe: string,
    siren: string,
    mail: string,
    etat: UTILISATEUR_STATUTS
}

export type UtilisateurToken = {
    roles: string[]
    droits: UtilisateurDroitEnum[]
    profil: {
        libelle: string,
        categorie: UtilisateurProfilCategorieEnum
    }
    sso?: {
        id: string,
        service: string
    }
    nom: string
    prenom: string
    username: string
    mail: string
    exp: number
    expireDays? : number,
    expireSoon? : boolean,
    siren:string
}


interface ProfilUtilisateur {
    "id": number,
    "categorie": string,
    "code": string,
    "libelle": string
}

export const utilisateurToUtilisateurLite = (utilisateur: Utilisateur) : UtilisateurLite => {
    return {
        nom: utilisateur.nom,
        prenom: utilisateur.prenom,
        societe: utilisateur.societe,
        siren: utilisateur.siren,
        mail: utilisateur.mail,
        etat: utilisateur.etat
    }
}
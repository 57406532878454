import React, {useCallback, useEffect, useRef, useState} from "react";
import {Col, Container} from "@design-system-etat/dsfr-react";
import TuileArme from "./composants/TuileArme";
import "./FicheArme.css";
import {SegmentLoader} from "@design-system-etat/dsfr-react/lib/cjs";
import {RgaAppliAPI, SessionService} from "../../infrastructure/InfrastructureFactory";
import {useParams} from "react-router";
import {FicheArmeActionEnum} from "../../constantes/FicheArmeActionEnum";
import {useFieldArray, useForm} from "react-hook-form";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import FormulaireArme, {alimenterFormulaireArme, FormulaireArmeInitial} from "../../entites/FormulaireArme";
import {SelectOption} from "../../entites/Referentiels/SelectOption";
import debounce from "lodash.debounce";
import Arme from "../../entites/Arme";
import {FiltreAPI} from "../../entites/FiltreAPI";
import {ParametresTechniques} from "../../constantes/ParametresTechniques";
import {
    ArmeAEnregistrerImpl,
    CanonCreation,
    DemandeAEnregistrerImpl,
    DesignationCommercialesCreation
} from "../../entites/ArmeAEnregistrer";
import {useLocation, useNavigate} from "react-router-dom";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {ImageType} from "react-images-uploading";
import {PhotoArme, PhotoArmeAPIPost, PhotoArmeInitial} from "../../entites/PhotoArme";
import BandeauInformatif from "./composants/BandeauInformatif";
import {Demande} from "../../entites/Demande";
import {UtilisateurProfilCategorieEnum} from "../../constantes/UtilisateurProfilCategorieEnum";
import {
    construireReferentielLibre,
    ReferentielLibreACreer,
    ReferentielLibreInexistant
} from "../../entites/ReferentielLibre";
import {TypeTraitementSCAEEnum} from "../../constantes/TypeTraitementSCAEEnum";
import BoutonBasDePage from "./composants/BoutonBasDePage";
import {StatutDemandeEnum} from "../../constantes/StatutDemandeEnum";
import Classification from "./composants/Classification";
import {chargerReferentiel} from "./FicheArmeUtils";
import Caracteristique from "./composants/Caracteristique";
import DesignationCommercialeFicheArme from "./composants/DesignationCommercialeFicheArme";
import PrototypeEtAutomatique from "./composants/PrototypeEtAutomatique";
import CanonFicheArme from "./composants/CanonFicheArme";
import PhotosFicheArme from "./composants/PhotosFicheArme";
import EnTeteFicheArme from "./composants/EnTeteFicheArme";
import {UtilisateurDroitEnum} from "../../constantes/UtilisateurDroitEnum";

interface FicheArmeProps {
    afficherDemande?: boolean
}

const FicheArme: React.FC<FicheArmeProps> = (props: FicheArmeProps) => {
    let navigate = useNavigate();
    const location = useLocation();
    const {afficherDemande} = props;
    const [estDemande, setEstDemande] = useState(false);
    const [typeDemande, setTypeDemande] = useState<string>(StatutDemandeEnum.STATUT_BROUILLON);
    const {idFiche, action} = useParams();
    const [arme, setArme] = useState<Arme>({} as Arme);
    const [idDemande, setIdDemande] = useState(null);
    const [lectureSeul, setLectureSeul] = useState(true);
    const [chargementEnCours, setChargementEnCours] = useState(true);
    const [referentielInexistant, setReferentielInexistant] = useState<ReferentielLibreInexistant[]>([]);
    const [remarqueValidationSCAE, setRemarqueValidationSCAE] = useState<string>("");
    const {
        reset, getValues,
        register,
        handleSubmit,
        control, trigger,
        formState: {errors}, setValue,
        clearErrors, watch
    } = useForm<FormulaireArme>({mode: 'onChange', defaultValues: FormulaireArmeInitial});
    const watchModele = watch("modele");
    const watchMarque = watch("marque");
    const watchFabriquant = watch("fabricant");

    const canonsUseFieldArray = useFieldArray({
        control,
        name: "canons"
    });
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext()

    const [marqueForm, setMarqueForm] = useState<SelectOption[]>([]);
    const [modeleForm, setModeleForm] = useState<SelectOption[]>([]);
    const [fabricantForm, setFabricantForm] = useState<SelectOption[]>([]);
    const [designationCommerciales, setDesignationCommerciales] = useState<DesignationCommercialesCreation[]>([]);
    const [estVisible, setEstVisible] = useState<boolean>(true);
    const [photos, setPhotos] = useState<PhotoArme>(PhotoArmeInitial);
    const [idPhotoSupprime, setIdPhotoSupprime] = useState<string[]>([]);
    const [stateDemandeEstPret, setStateDemandeEstPret] = useState<boolean>(false)
    const [createur, setCreateur] = useState<string>("");

    const formRef = useRef();
    let designationCommercialeRef = useRef(null);
    const [formulaireReinitialisation, setFormulaireReinitisalisation] = useState(FormulaireArmeInitial);
    const [photoReinitialisation, setPhotoReinitisalisation] = useState(PhotoArmeInitial);
    const [deisgnationReinitialisation, setDesignationReinitisalisation] = useState([]);


    useEffect(() => {
        setEstDemande(location.state?.afficherDemande || afficherDemande)
        setStateDemandeEstPret(true)
    }, [afficherDemande, location.state?.afficherDemande])

    useEffect(() => {
        if (watchModele && referentielInexistant.some(ref => ref.referentiel === "Modele")
            && referentielInexistant.find(ref => ref.referentiel === "Modele")?.libelle !== watchModele.label) {
            setReferentielInexistant(prevState => prevState.filter(refAFiltre => refAFiltre.referentiel !== "Modele"))
        }
    }, [watchModele, referentielInexistant])

    useEffect(() => {
        if (watchMarque && referentielInexistant.some(ref => ref.referentiel === "Marque")
            && referentielInexistant.find(ref => ref.referentiel === "Marque")?.libelle !== watchMarque.label) {
            setReferentielInexistant(prevState => prevState.filter(refAFiltre => refAFiltre.referentiel !== "Marque"))
        }
    }, [watchMarque, referentielInexistant])

    useEffect(() => {

        if (watchFabriquant && referentielInexistant.some(ref => ref.referentiel === "Fabricant")
            && referentielInexistant.find(ref => ref.referentiel === "Fabricant")?.libelle !== watchFabriquant.label) {
            setReferentielInexistant(prevState => prevState.filter(refAFiltre => refAFiltre.referentiel !== "Fabricant"))
        }
    }, [watchFabriquant, referentielInexistant])

    function rechercherPhotoParReference(idArmeOuDemande: string, recherchePhotoDemande?: boolean) {
        function traiterPhotosExistantes() {
            return photosExitantes => {
                let photoConvertit = {};
                photosExitantes.forEach(photoExitante => {
                    photoConvertit = {
                        ...photoConvertit,
                        [photoExitante.type]: [{
                            id: photoExitante.id,
                            position: photoExitante.type,
                            dataURL: photoExitante.url
                        } as ImageType]
                    }
                })
                setPhotos({...photos, ...photoConvertit});
                setPhotoReinitisalisation({...photoReinitialisation, ...photoConvertit});
            };
        }

        function afficherErreurPhoto() {
            return (erreur) => {
                if(erreur){
                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", erreur))
                }
            };
        }

        if (recherchePhotoDemande) {
            RgaAppliAPI.recupererPhotoDemandeArme(idArmeOuDemande).then(traiterPhotosExistantes()).catch(afficherErreurPhoto())
        } else {
            RgaAppliAPI.recupererPhotoArme(idArmeOuDemande).then(traiterPhotosExistantes()).catch(afficherErreurPhoto())
        }
    }


    function securiserAccesParCategorieProfil() {
        if (estDemande || afficherDemande || location.state?.afficherDemande) {
            if (SessionService.getProfilCategorie() !== UtilisateurProfilCategorieEnum.PRO) {
                if (action === FicheArmeActionEnum.CREER) {
                    mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Vous n'avez pas les droits pour créer une fiche arme."))
                    navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                }
            }
        } else {
            if (!SessionService.utilisateurALeDroit(UtilisateurDroitEnum.CREER_FICHE) && (action === FicheArmeActionEnum.MODIFIER || action === FicheArmeActionEnum.CREER)) {
                mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Vous n'avez pas les droits de créer ou modifier une fiche arme."))
                navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
            }
            if (!SessionService.utilisateurALeDroit(UtilisateurDroitEnum.CONSULTER_FICHES) && (action === FicheArmeActionEnum.CONSULTER)) {
                mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Vous n'avez pas les droits pour consulter une fiche arme."))
                navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
            }
        }
    }

    function securiserAccesDemandeParCategorieProfileEtStatutDemande(statut: string) {


        function determinerSiDroitAccesParStatut(statutsAutorise: StatutDemandeEnum[]) {
            return statutsAutorise.some(statutAutorise => statutAutorise === statut);
        }


        const droitConsultationSCAE = action === FicheArmeActionEnum.CONSULTER && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE
            && determinerSiDroitAccesParStatut([StatutDemandeEnum.STATUT_DEPOSE, StatutDemandeEnum.STATUT_REFUSE, StatutDemandeEnum.STATUT_DEPOSE, StatutDemandeEnum.STATUT_INCOMPLETE, StatutDemandeEnum.STATUT_VALIDE]);
        const droitConsultationPRO = action === FicheArmeActionEnum.CONSULTER && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO &&
            determinerSiDroitAccesParStatut([StatutDemandeEnum.STATUT_BROUILLON, StatutDemandeEnum.STATUT_DEPOSE, StatutDemandeEnum.STATUT_REFUSE, StatutDemandeEnum.STATUT_DEMANDE_CLASSEMENT, StatutDemandeEnum.STATUT_INCOMPLETE, StatutDemandeEnum.STATUT_VALIDE]);
        const droitModificationSCAE = action === FicheArmeActionEnum.MODIFIER && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE
            && determinerSiDroitAccesParStatut([StatutDemandeEnum.STATUT_DEPOSE, StatutDemandeEnum.STATUT_INCOMPLETE, StatutDemandeEnum.STATUT_DEMANDE_CLASSEMENT]);
        const droitModificationPRO = action === FicheArmeActionEnum.MODIFIER && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO &&
            determinerSiDroitAccesParStatut([StatutDemandeEnum.STATUT_BROUILLON, StatutDemandeEnum.STATUT_INCOMPLETE]);


        if (!(droitConsultationSCAE || droitConsultationPRO || droitModificationPRO || droitModificationSCAE)) {
            const messageAlerte: string = "Vous n'avez pas accès à cette page."
            mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", messageAlerte))
            navigate(URLPageEnum.CONSULTER_DEMANDES_ARMES)
        }


    }

    useEffect(() => {
        setChargementEnCours(true)
        if (!stateDemandeEstPret) {
            return
        }
        securiserAccesParCategorieProfil();


        function initialiserCreation() {
            setLectureSeul(false);
            if (location.state?.idFicheADupliquer) {
                setPhotos(PhotoArmeInitial)
                if (estDemande) {
                    RgaAppliAPI.recupererDemandeArme(location.state.idFicheADupliquer).then((demande: Demande) => {
                        setCreateur(demande.statut === StatutDemandeEnum.STATUT_VALIDE ? "" : demande.createur.societe)
                        preRemplirFormulaire(demande.ficheArme)
                        setTypeDemande("")
                        setIdDemande(null)
                    }).catch(message => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", message)))
                } else {
                    rechercherArmeParReference(location.state.idFicheADupliquer, preRemplirFormulaire);
                }
                setArme({} as Arme)
            }
        }

        function initialiserModification() {
            setLectureSeul(false);
            if (estDemande) {
                RgaAppliAPI.recupererDemandeArme(idFiche).then((demande: Demande) => {
                    setCreateur(demande.statut === StatutDemandeEnum.STATUT_VALIDE ? "" : demande.createur.societe)
                    securiserAccesDemandeParCategorieProfileEtStatutDemande(demande.statut)
                    preRemplirFormulaire(demande.ficheArme)
                    setArme(demande.ficheArme)
                    setTypeDemande(demande.statut)
                    setIdDemande(demande.id)
                    rechercherPhotoParReference(demande.id, true);
                    //Vérification des refentiels libres dans le cas de la validation SCAE
                    if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) {
                        RgaAppliAPI.verifierReferentielLibre(construireReferentielLibre(demande.ficheArme))
                            .then(referentielLibreInexistant => {
                                setReferentielInexistant(referentielLibreInexistant)

                                function verifierPresenceReferentielLibre(nomReferentiel: string) {
                                    return referentielLibreInexistant.some(referentielLibre => referentielLibre.referentiel === nomReferentiel)
                                        && referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === nomReferentiel).type === "UnknownReferentielException";
                                }

                                if (verifierPresenceReferentielLibre("Marque")) {
                                    setMarqueForm((precedent: SelectOption[]) => [new SelectOption(referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === "Marque").libelle), ...precedent])
                                    setValue("marque", new SelectOption(referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === "Marque").libelle))
                                }
                                if (verifierPresenceReferentielLibre("Fabricant")) {
                                    setFabricantForm((precedent: SelectOption[]) => [new SelectOption(referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === "Fabricant").libelle), ...precedent])
                                    setValue("fabricant", new SelectOption(referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === "Fabricant").libelle))
                                }
                                if (verifierPresenceReferentielLibre("Modele")) {
                                    setModeleForm((precedent: SelectOption[]) => [new SelectOption(referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === "Modele").libelle), ...precedent])
                                    setValue("modele", new SelectOption(referentielLibreInexistant.find(referentielLibre => referentielLibre.referentiel === "Modele").libelle))
                                }
                            })
                            .catch(raison => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison)))
                    }
                }).catch(message => {
                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", message));
                    navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                })
            } else {
                rechercherArmeParReference(idFiche, (armeAChercher: Arme) => {
                    setEstVisible(armeAChercher.visible)
                    preRemplirFormulaire(armeAChercher)
                    setArme(armeAChercher)
                });
                rechercherPhotoParReference(idFiche);
            }
        }

        function initialiserConsultation() {
            if (estDemande) {
                RgaAppliAPI.recupererDemandeArme(idFiche).then((demande: Demande) => {
                    setCreateur(demande.statut === StatutDemandeEnum.STATUT_VALIDE ? "" : demande.createur.societe)
                    securiserAccesDemandeParCategorieProfileEtStatutDemande(demande.statut)
                    setArme(demande.ficheArme)
                    setTypeDemande(demande.statut)
                    setIdDemande(demande.id)
                    rechercherPhotoParReference(demande.id, true);
                    preRemplirFormulaire(demande.ficheArme)
                }).catch(message => {
                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", message));
                    navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                })
            } else {
                rechercherArmeParReference(idFiche, (armeAChercher: Arme) => setArme(armeAChercher));
                rechercherPhotoParReference(idFiche);
            }
            setLectureSeul(true);
        }

        switch (action) {
            case FicheArmeActionEnum.CREER:
                initialiserCreation();
                break;
            case FicheArmeActionEnum.MODIFIER:
                initialiserModification();
                break;
            case FicheArmeActionEnum.CONSULTER:
                initialiserConsultation();
                break;
            default:
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Action inconnue demandée sur la fiche."))
                navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
        }
        setChargementEnCours(false)
    }, [action, idFiche, stateDemandeEstPret]); // eslint-disable-line react-hooks/exhaustive-deps

    const preRemplirFormulaire = (armeRecu: Arme) => {
        let formulairePreRempli: FormulaireArme = alimenterFormulaireArme(armeRecu);
        if (action === FicheArmeActionEnum.CREER) {
            formulairePreRempli.sia = null;
            formulairePreRempli.id = null;
            formulairePreRempli.dateCreation = null;
            formulairePreRempli.dateMiseAJour = null;
            formulairePreRempli.photos = [];
        }
        const designationExistantes = armeRecu.designationCommerciales.map(designationExistante => {
            return {...designationExistante} as DesignationCommercialesCreation
        })
        setDesignationCommerciales(designationExistantes)
        setDesignationReinitisalisation(designationExistantes)
        setFormulaireReinitisalisation(formulairePreRempli)
        reset(formulairePreRempli);
    }

    function rechercherArmeParReference(siaArme: string, affecterResultat) {
        RgaAppliAPI.rechercheArmeParReference(siaArme).then(armeRecu => {
            affecterResultat(armeRecu)
        }).catch(erreur => {
            console.error(erreur);
            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "L'arme recherchée n'a pas été trouvée."))
            navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
        })

    }


    const chargerReferentielDebounce = useCallback(debounce((referentiel, valeurChamp, setReferentiel, setChargementTermine,pagination?) => { // eslint-disable-line react-hooks/exhaustive-deps
        chargerReferentiel(referentiel, [{cle: "libelle", valeur: valeurChamp}, {cle: "actif", valeur: "true"}] as FiltreAPI[], setReferentiel, (alerteErreur) => mettreAjourAlerte(alerteErreur), setChargementTermine,pagination)
    }, ParametresTechniques.DUREE_DEBOUNCE), []);

    function soumettreFormulaireArme(typeDemandeEnregistrement?: any) {
        mettreAJourDateDerniereAction();

        const valeurFormulaire = getValues();
        if (valeurFormulaire.canons.length === 0) {
            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "L'arme doit posséder au moins un canon."))
            return;
        }

        let armeASoumettre = new ArmeAEnregistrerImpl(
            valeurFormulaire.apparenceArmeGuerre,
            valeurFormulaire.canons.map(canon => {
                    return {
                        "numCanon": canon.numCanon,
                        "typeCanon": estDemande ? (canon.typeCanon ? "/type_canons/" + canon.typeCanon.referentiel['idTypeCanon'] : null) : canon.typeCanon.referentiel["@id"], //TODO NPO trouver pourquoi en demande on n'a pas le @id a renvoyer dans les referentiels
                        "modePercussion": estDemande ? (canon.modePercussion ? "/percussions/" + canon.modePercussion.referentiel['idModePercussion'] : null) : canon.modePercussion.referentiel["@id"],
                        "longueurCanon": +canon.longueurCanon,
                        "calibre": estDemande ? (canon.calibre ? "calibres/" + canon.calibre.referentiel['idCalibre'] : null) : canon.calibre.referentiel["@id"],
                        "idGdr": 0
                    } as CanonCreation
                }
            ),
            +valeurFormulaire.capaciteChambre,
            +valeurFormulaire.capaciteHorsChambre,
            SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO ? "" : valeurFormulaire.classementValideEurope.referentiel["@id"],
            SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO ? "" : valeurFormulaire.classementValideFrance.referentiel["@id"],
            designationCommerciales,
            valeurFormulaire.energie,
            valeurFormulaire?.fabricant?.label,
            estDemande ? (valeurFormulaire.famille ? "/familles/" + valeurFormulaire.famille.referentiel["idFamilleArme"] : null) : valeurFormulaire.famille.referentiel["@id"],
            +valeurFormulaire.longueur,
            valeurFormulaire?.marque?.label,
            estDemande ? (valeurFormulaire.modeAlimentation ? "/alimentations/" + valeurFormulaire.modeAlimentation.referentiel["idModeAlimentation"] : null) : valeurFormulaire.modeAlimentation.referentiel["@id"],
            estDemande ? (valeurFormulaire.modeFonctionnement ? "/fonctionnements/" + valeurFormulaire.modeFonctionnement.referentiel["idModeFonctionnement"] : null) : valeurFormulaire.modeFonctionnement.referentiel["@id"],
            valeurFormulaire?.modele?.label,
            valeurFormulaire.neutralise,
            estDemande ? (valeurFormulaire.pays ? "/pays/" + valeurFormulaire.pays.referentiel["id"] : null) : valeurFormulaire.pays.referentiel["@id"],
            valeurFormulaire.prototype,
            SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO ? SessionService.getUtilisateurActuel().siren : valeurFormulaire.siren,
            estDemande ? (valeurFormulaire.typeArme ? "/type_armes/" + valeurFormulaire.typeArme.referentiel["idTypeArme"] : null) : valeurFormulaire.typeArme.referentiel["@id"],
            estVisible,
            estDemande ? (valeurFormulaire.fabricant && valeurFormulaire?.fabricant.referentiel ? ("/fabricants/" + valeurFormulaire.fabricant.referentiel["idFabricant"]) : null) : valeurFormulaire.fabricant?.referentiel?.["@id"],
            estDemande ? (valeurFormulaire.marque && valeurFormulaire?.marque.referentiel ? ("/marques/" + valeurFormulaire.marque.referentiel["idMarque"]) : null) : valeurFormulaire.marque?.referentiel?.["@id"] ?? null,
            estDemande ? (valeurFormulaire.modele && valeurFormulaire?.modele.referentiel ? ("/modeles/" + valeurFormulaire.modele.referentiel["idModele"]) : null) : valeurFormulaire.modele?.referentiel?.["@id"] ?? null
        );

        function sauvegardeReussi(siaArmeEnregistrer: string) {
            setPhotos(PhotoArmeInitial)
            const messageSucces = "La fiche d’arme " + siaArmeEnregistrer + " a bien été modifiée dans le Référentiel Général des Armes.";
            mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", messageSucces));
            navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
        }

        function sauvegardeReussiDemande(identifiantDemande: string) {
            setPhotos(PhotoArmeInitial)
            mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "Votre demande d’ajout de fiche d’arme a bien été enregistrée sous le numéro " + identifiantDemande + "."));
            navigate((typeDemandeEnregistrement === StatutDemandeEnum.STATUT_BROUILLON) ? URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + identifiantDemande : URLPageEnum.CONSULTER_DEMANDES_ARMES)
        }

        function soumettrePhoto(identifiantArmeOuDemande: string, redirection) {
            let compteurEnregistrementPhoto = Object.keys(photos).length;
            Object.keys(photos).forEach(position => {
                compteurEnregistrementPhoto--;
                //on enregistre que les nouvelles photos => pas d'id
                if (photos[position] && photos[position].length > 0 && !photos[position][0].id) {
                    let dataPhoto = {
                        position: position,
                        photo: photos[position][0].file
                    } as PhotoArmeAPIPost;
                    const alerteErreur = new Alerte(typeAlerte.ERREUR, "", "Erreur lors de la sauvegarde des photographies.");

                    if (estDemande) {
                        RgaAppliAPI.ajouterPhotoDemandeArme(identifiantArmeOuDemande, dataPhoto).then(() => {
                            if (compteurEnregistrementPhoto === 0) {
                                setPhotos(PhotoArmeInitial)
                                redirection()
                            }
                        }).catch(
                            () => mettreAjourAlerte(alerteErreur)
                        )
                    } else {
                        RgaAppliAPI.ajouterPhotoArme(identifiantArmeOuDemande, dataPhoto).then(() => {
                            if (compteurEnregistrementPhoto === 0) {
                                sauvegardeReussi(identifiantArmeOuDemande);
                            }
                        }).catch(
                            () => mettreAjourAlerte(alerteErreur)
                        )
                    }
                } else {
                    if (compteurEnregistrementPhoto === 0) {
                        if (estDemande) {
                            setPhotos(PhotoArmeInitial)
                            mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "Votre demande d’ajout de fiche d’arme a bien été enregistrée sous le numéro " + identifiantArmeOuDemande + "."));
                            navigate((typeDemandeEnregistrement === StatutDemandeEnum.STATUT_BROUILLON) ? URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + identifiantArmeOuDemande : URLPageEnum.CONSULTER_DEMANDES_ARMES)
                        } else {
                            sauvegardeReussi(identifiantArmeOuDemande);
                        }
                    }
                }
            })
        }

        function calculerNouveauReferentielLibre(): ReferentielLibreACreer[] {
            let nouveauReferentiel = [];

            function calculerTypeNouveauReferentiel(libelle: string) {
                return referentielInexistant.some(referentiel => referentiel.libelle === libelle)
                    ? referentielInexistant.find(referentiel => referentiel.libelle === libelle).type : "referentielException";
            }

            if (!armeASoumettre.marque) {
                nouveauReferentiel.push({
                    referentiel: 'marques',
                    type: calculerTypeNouveauReferentiel(armeASoumettre.marqueSaisie),
                    valeur: armeASoumettre.marqueSaisie
                })
            }
            if (!armeASoumettre.fabricant) {
                nouveauReferentiel.push({
                    referentiel: 'fabricants',
                    type: calculerTypeNouveauReferentiel(armeASoumettre.fabricantSaisie),
                    valeur: armeASoumettre.fabricantSaisie
                })
            }
            if (!armeASoumettre.modele) {
                nouveauReferentiel.push({
                    referentiel: 'modeles',
                    type: calculerTypeNouveauReferentiel(armeASoumettre.modeleSaisie),
                    valeur: armeASoumettre.modeleSaisie
                })
            }

            return nouveauReferentiel
        }

        function valideChampSoumissionSansFormulaire(): boolean {
            let estValide: boolean = true;
            Object.keys(armeASoumettre).forEach(champ => {
                if (["typeArme", "pays", "modeAlimentation", "modeFonctionnement", "famille", "capaciteChambre", "capaciteHorsChambre"].includes(champ)) {
                    if (armeASoumettre[champ] === null) {
                        estValide = false
                    }
                }
                if (champ === "canons") {
                    armeASoumettre.canons.forEach(canon => {
                        if (canon.calibre === null || canon.typeCanon === null || canon.modePercussion === null) {
                            estValide = false
                        }
                    })
                }

            })
            if ((armeASoumettre.modele === null && armeASoumettre.modeleSaisie === null)
                || (armeASoumettre.marqueSaisie === null && armeASoumettre.marque)
                || (armeASoumettre.fabricantSaisie === null && armeASoumettre.fabricant)) {
                estValide = false;
            }

            return estValide
        }

        function soumettreDemande() {
            //Le PRO ne peut pas modifier les classements
            if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO) {
                delete armeASoumettre["classementValideEurope"]
                delete armeASoumettre["classementValideFrance"]
            }

            function calculerStatut(statutBouton: string) {
                return (statutBouton === StatutDemandeEnum.STATUT_DEPOSE && armeASoumettre.prototype) ? StatutDemandeEnum.STATUT_DEMANDE_CLASSEMENT : statutBouton;
            }

            if(arme.id){
                armeASoumettre.id =arme.id;
            }

            let demandeASoumettre = new DemandeAEnregistrerImpl(
                armeASoumettre,
                true, //FIXME NPO reliquat nécessaire ?
                calculerStatut(typeDemandeEnregistrement ?? typeDemande)
            );
            switch (action) {
                case FicheArmeActionEnum.MODIFIER:
                    RgaAppliAPI.modifierDemandeArme(idDemande, demandeASoumettre).then(idDemandeModifier => {
                            if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) {
                                RgaAppliAPI.traiterDemande(idDemande, TypeTraitementSCAEEnum.VALIDATION, remarqueValidationSCAE, calculerNouveauReferentielLibre()).then((sia) => {
                                    mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "La fiche d’arme a bien été ajoutée au RGA, sous le numéro RGA " + sia))
                                    navigate(URLPageEnum.CONSULTER_DEMANDES_ARMES);
                                }).catch((raison) => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison)))
                            }
                            if (idPhotoSupprime) {
                                RgaAppliAPI.supprimerPhotoDemandeArme(idPhotoSupprime).then(() => {
                                    soumettrePhoto(idDemandeModifier, () => {
                                        sauvegardeReussiDemande(idDemandeModifier)
                                    });
                                }).catch(() => {
                                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Erreur lors du traitement des photographies."))
                                })
                            } else {
                                soumettrePhoto(idDemandeModifier, () => {
                                    sauvegardeReussiDemande(idDemandeModifier)
                                });
                            }
                            setTypeDemande(demandeASoumettre.statut)
                        }
                    ).catch((raison) => {
                            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison));
                        }
                    )
                    break;
                case FicheArmeActionEnum.CREER:
                    RgaAppliAPI.creerDemandeArme(demandeASoumettre).then(idDemandeCreer => {
                            setTypeDemande(demandeASoumettre.statut)
                            soumettrePhoto(idDemandeCreer, () => {
                                sauvegardeReussiDemande(idDemandeCreer)
                            });
                        }
                    ).catch((raison) => {
                            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison));
                        }
                    )
                    break;
                case FicheArmeActionEnum.CONSULTER:
                    if (valideChampSoumissionSansFormulaire() || SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) {
                        RgaAppliAPI.modifierDemandeArme(idDemande, demandeASoumettre).then(idDemandeModifier => {
                            mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "Votre demande d’ajout de fiche d’arme a bien été enregistrée sous le numéro " + idDemandeModifier + "."));
                            navigate(URLPageEnum.CONSULTER_DEMANDES_ARMES)
                            setTypeDemande(demandeASoumettre.statut)
                        }).catch((raison) => {
                                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison));
                            }
                        )
                    } else {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Cette action est impossible car certains champs obligatoires ne sont pas remplis."));
                        navigate(URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.MODIFIER + "/" + idDemande);
                    }
                    break;
            }
        }

        function soumettreArme() {
            switch (action) {
                case FicheArmeActionEnum.MODIFIER:
                    armeASoumettre.dateCreation = arme.dateCreation;
                    armeASoumettre.dateMiseAJour = new Date(Date.now());
                    RgaAppliAPI.modifierArme(arme.id, armeASoumettre).then(armeAjour => {
                        if (idPhotoSupprime) {
                            RgaAppliAPI.supprimerPhotoArme(idPhotoSupprime, arme.sia).then(() => {
                                soumettrePhoto(armeAjour.sia, () => sauvegardeReussi(armeAjour.sia));
                            }).catch(() => {
                                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Erreur lors du traitement des photographies."))
                            })
                        } else {
                            soumettrePhoto(armeAjour.sia, () => sauvegardeReussi(armeAjour.sia));
                        }
                    }).catch((erreur) => {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", erreur))
                    })
                    break;
                case FicheArmeActionEnum.CREER:
                    RgaAppliAPI.creerArme(armeASoumettre).then(nouvelleArme => {
                        soumettrePhoto(nouvelleArme.sia, () => sauvegardeReussi(nouvelleArme.sia));
                    }).catch((raison) => {
                            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison));
                        }
                    )
                    break;
                default:
                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Action de soumission inconnue"))
            }
        }

        if (estDemande) {
            soumettreDemande();
        } else {
            soumettreArme();
        }
        return Promise.resolve();
    }


    const reinitialiserFormulaire = () => {
        reset(formulaireReinitialisation);
        setPhotos(photoReinitialisation);
        setDesignationCommerciales(deisgnationReinitialisation);
        designationCommercialeRef.current.reinitialiserDesignation();

    }

    function afficherLoader() {
        return <div id="fa-segment-loader">
            <SegmentLoader isLoading>
                <div className={"loader-page-plein"}/>
            </SegmentLoader>
        </div>;
    }


    const checkKeyDown = (e) => {
        if (e.key === "Enter") e.preventDefault();
    };


    return <>
        {chargementEnCours ?
            afficherLoader() : <>
                <form onSubmit={handleSubmit(soumettreFormulaireArme)} ref={formRef}>
                    <EnTeteFicheArme lectureSeul={lectureSeul} estDemande={estDemande} arme={arme}
                                     idFiche={idFiche} estVisible={estVisible} setEstVisible={setEstVisible}
                                     typeDemande={typeDemande}/>
                    <div id={"fiche-pdf"} className={"fa-fiche-arme"} onKeyDown={(e) => checkKeyDown(e)}>
                        <div>
                            <Container className={"fa-surcharge-container-DSFR"}>
                                <BandeauInformatif arme={arme} idDemande={idDemande} createur={createur}/>
                                <br/>
                                <div
                                    className="fr-grid-row fr-grid-row--gutters fr-grid-row--left ">
                                    <Col col={3}>
                                        <Classification lectureSeul={lectureSeul} estDemande={estDemande} arme={arme}
                                                        errors={errors} control={control}
                                                        chargerReferentielDebounce={chargerReferentielDebounce}/>
                                    </Col>
                                    <Col col={9}>
                                        <TuileArme idTuile={"arme_caracteristique"} titre={"Caractéristiques"}>
                                            <Caracteristique referentielInexistant={referentielInexistant} arme={arme}
                                                             control={control} errors={errors}
                                                             chargerReferentielDebounce={chargerReferentielDebounce}
                                                             register={register} lectureSeul={lectureSeul}
                                                             fabricantForm={fabricantForm}
                                                             setFabricantForm={setFabricantForm}
                                                             marqueForm={marqueForm} modeleForm={modeleForm}
                                                             setMarqueForm={setMarqueForm} setModeleForm={setModeleForm}
                                            />
                                            <br/>
                                            <DesignationCommercialeFicheArme lectureSeul={lectureSeul}
                                                                             designationCommercialesLectureSeule={arme.designationCommerciales}
                                                                             designationCommerciales={designationCommerciales}
                                                                             setDesignationCommerciales={setDesignationCommerciales}
                                                                             ref={designationCommercialeRef}/>

                                            <br/>
                                            <PrototypeEtAutomatique prototype={arme.prototype}
                                                                    apparenceArmeGuerre={arme.apparenceArmeGuerre}
                                                                    lectureSeul={lectureSeul} register={register}/>
                                        </TuileArme>
                                    </Col>
                                </div>
                            </Container>
                        </div>
                        <div className="html2pdf__page-break"/>
                        <br/>
                        <div>
                            <CanonFicheArme lectureSeul={lectureSeul} action={action} canons={arme.canons}
                                            control={control} chargerReferentielDebounce={chargerReferentielDebounce}
                                            canonsUseFieldArray={canonsUseFieldArray}
                                            errors={errors} clearErrors={clearErrors} getValues={getValues}/>
                            <PhotosFicheArme lectureSeul={lectureSeul} setIdPhotoSupprime={setIdPhotoSupprime}
                                             photos={photos} setPhotos={setPhotos}/>
                        </div>
                    </div>
                    <br/>
                    <BoutonBasDePage lectureSeul={lectureSeul} chargementEnCours={chargementEnCours}
                                     estDemande={estDemande} reinitialiserFormulaire={() => reinitialiserFormulaire()}
                                     idDemande={idDemande}
                                     siaArme={arme?.sia} actionFiche={action} verifierFormulaire={trigger}
                                     setRemarqueValidationSCAE={setRemarqueValidationSCAE}
                                     typeDemande={typeDemande} setEstDemande={setEstDemande}
                                     soumettreFormulaireArme={soumettreFormulaireArme}
                    />
                </form>
            </>
        }
    </>
}
export default FicheArme;
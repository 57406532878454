import {fr} from "../../constantes/ClassesDSFR";
import {Bouton, searchLine} from "@design-system-etat/dsfr-react";
import {arrowLeftSFirstLine, arrowRightSLastLine} from "@design-system-etat/dsfr-react/lib/cjs";
import React from "react";
import {useRechercheSimplifieeContext} from "../../composants/RechercheSimplifieeContext";
import {auPluriel} from "../../utils/Utils";

interface BoutonsEcranRechercheProps {
    suivantInterdit: boolean
    onPrecedentClick: () => any
    onSuivantClick: () => any
    sansResultats?: boolean
    onAfficherResultatClick?: () => any
    texteBoutonSuivant?: string
    texteBoutonPrecedent?: string

}

const BoutonsEcranRecherche: React.FC<BoutonsEcranRechercheProps> = ({
                                                                         onSuivantClick,
                                                                         onPrecedentClick,
                                                                         onAfficherResultatClick,
                                                                         suivantInterdit,
                                                                         sansResultats,
                                                                         texteBoutonSuivant,
                                                                         texteBoutonPrecedent
                                                                     }) => {

    const {rechercheEnCours, resultats} = useRechercheSimplifieeContext();

    return <div className={fr.mt_2w + "d-flex flex-space-between"}>
        <Bouton
            icone={arrowLeftSFirstLine}
            iconePosition="left"
            label={texteBoutonPrecedent ?? "Retour"}
            niveau="secondaire"
            onClick={onPrecedentClick}
        />
        {!sansResultats && (
            <div className={"d-flex flex-space-between"}>
                <span className={fr.p_1w + "texte-warning"}>{rechercheEnCours ? "Chargement du nombre de résultats ..." : resultats.nombreElementsTotal + auPluriel(" résultat", resultats.nombreElementsTotal)}</span>
                <Bouton
                    icone={searchLine}
                    iconePosition="right"
                    disabled={rechercheEnCours}
                    label="Consulter les résultats"
                    niveau="secondaire"
                    onClick={onAfficherResultatClick}
                />
            </div>
        )}
        <Bouton
            icone={arrowRightSLastLine}
            iconePosition="right"
            label={texteBoutonSuivant ?? "Suivant"}
            niveau="primaire"
            disabled={suivantInterdit}
            onClick={onSuivantClick}
        />
    </div>
}

export default BoutonsEcranRecherche;
import React, {useEffect, useRef} from "react";
import {useAlerteContext} from "./RGAContext";
import AlerteRGATemporise from "./AlerteRGA";

export const AlertesRGA: React.FC = () => {

    const {alerte, fermerAlerte} = useAlerteContext();
    const timeoutId = useRef<NodeJS.Timeout>();

    const clear = () => {
        if (timeoutId.current) clearTimeout(timeoutId.current);
    }

    useEffect(() => {
        clear();
        // @ts-ignore
        timeoutId.current = setTimeout(fermerAlerte, 5000, alerte);
        return () => clear();
    }, [alerte]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        alerte &&
        <AlerteRGATemporise type={alerte.type} titre={alerte.titre} description={alerte.message}/>
    );
}
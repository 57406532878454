import React, {FC, ChangeEvent} from 'react';

import dark from "@gouvfr/dsfr/dist/artwork/dark.svg";
import light from "@gouvfr/dsfr/dist/artwork/light.svg";
import system from "@gouvfr/dsfr/dist/artwork/system.svg";
import useLocalStorage from "@design-system-etat/dsfr-react/lib/esm/components/interface/Footer/Hooks/useLocalStorage";
import {BoutonRadio, Fieldset, ModaleParametreAffichageTypes} from "@design-system-etat/dsfr-react/lib/cjs";
import {Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react";

const ModaleParametreAffichage: FC<ModaleParametreAffichageTypes> = (
    {
        closeCallback,
        changerThemeCallback,
    }
) => {
    const [scheme, setScheme] = useLocalStorage('scheme', 'light');
    const onChangeTheme = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setScheme(event.target.value)
        changerThemeCallback(event.target.value)
    }

    const themes = [
        {
            nom:  'light',
            label: 'Thème clair',
            texteAide: '',
            image: light
        },
        {
            nom: 'dark',
            label: 'Thème sombre',
            texteAide: '',
            image: dark
        },
        {
            nom: 'system',
            label: 'Système',
            texteAide: 'Utilise les paramètres système.',
            image: system
        }
    ]

    return (
        <Modale id="parametre-affichage-footer" closeCallback={closeCallback}>
            <ModaleTitre idModale="parametre-affichage-footer">Paramètres d&apos;affichage</ModaleTitre>
            <ModaleContent>
                <Fieldset id="parametre-affichage-fieldset" legende="Choisissez un thème pour personnaliser l'apparence du site.">
                    {themes.map((theme)=> (
                            <BoutonRadio
                                key={theme.nom}
                                value={theme.nom}
                                checked={scheme === theme.nom}
                                id={`fr-radios-theme-${theme.nom}`}
                                name="fr-radios-theme"
                                label={theme.label}
                                texteAide={theme.texteAide}
                                image={<img src={theme.image} alt={theme.nom}/>}
                                onChange= {onChangeTheme}
                            />
                        )
                    )}
                </Fieldset>
            </ModaleContent>
        </Modale>
    );
}

export default ModaleParametreAffichage;

import {Bouton, GroupeBouton, Input, Modale, ModaleContent, ModaleFooter} from "@design-system-etat/dsfr-react";
import Titre from "../../../composants/Titre";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import React, {BaseSyntheticEvent} from "react";
import {UseFormReturn} from "react-hook-form";
import {TexteModaleParametres} from "../../../constantes/TexteModaleParametres";
import {Loader} from "@design-system-etat/dsfr-react/lib/cjs";

export default function ModaleParametres(props: {
    form: UseFormReturn,
    onClose: () => void,
    pattern: string;
    idTechnique : string,
    maxLength: number;
    isLoading : boolean
    onSubmit: (form : BaseSyntheticEvent) => void;
}) {

    const {form, onClose, pattern,idTechnique, maxLength, isLoading, onSubmit} = props

    return <div className={"modaleParametres"}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Modale id={"modale"} closeCallback={onClose}>
                <ModaleContent>
                    {isLoading ? <Loader/> : (<div className={"modaleContent"}>
                        <Titre>Modifier</Titre>
                        <p className={"paragrapheHaut"}>
                            {TexteModaleParametres[idTechnique]?.description}
                        </p>
                        <Input
                            label={TexteModaleParametres[idTechnique]?.label}
                            name="valeur"
                            error={form.formState.errors.valeur}
                            longueurMax
                            {...form.register("valeur", {
                                required: MessagesErreurEnum.CHAMP_REQUIS,
                                maxLength: {value: maxLength, message: MessagesErreurEnum.LONGUEUR_MAX},
                                pattern: {
                                    value: new RegExp(pattern),
                                    message: MessagesErreurEnum.MAUVAIS_FORMAT
                                }
                            })}
                        />
                    </div>)}
                </ModaleContent>

                <ModaleFooter>
                    <GroupeBouton
                        alignementHorizontal="right"
                        isHorizontal
                        reverse
                    >
                        <Bouton
                            label="Valider"
                            niveau="primaire"
                            id={"valider"}
                            type={"submit"}
                            disabled={!!form.formState.errors.valeur}
                        />
                        <Bouton label="Annuler" onClick={onClose}/>
                    </GroupeBouton>
                </ModaleFooter>

            </Modale>
        </form>
    </div>;
}
import React, {FC} from "react";
import {Input, Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import {useForm} from "react-hook-form";
import {Bouton} from "@design-system-etat/dsfr-react";
import {fr} from "../../../../constantes/ClassesDSFR";
import {MessagesErreurEnum} from "../../../../constantes/MessagesErreurEnum";
import Captcha from "../../../../composants/Captcha";
import MiCaptcha from "../../../../utils/captcha";
import {FormulaireReinitMdp, FormulaireReinitMdpInitial} from "../../../../entites/FormulaireMdpReinit";
import {useAlerteContext} from "../../../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../../../entites/Alerte";
import {RgaAppliAPIImpl} from "../../../../infrastructure/api/RgaAppliAPI";
import {ServiceErrorsEnum} from "../../../../constantes/ServiceErrorsEnum";

interface ModalMdpReinitProps {
    onAnnulerClick: () => void,
    onFermerClick: () => void,
    onValiderClick: () => void
}

export const ModalMdpReinit: FC<ModalMdpReinitProps> = ({onAnnulerClick, onFermerClick, onValiderClick}) => {

    const form = useForm<FormulaireReinitMdp>({mode: 'onChange', defaultValues: FormulaireReinitMdpInitial});

    const reinitMdpOk = new Alerte(typeAlerte.REUSSITE, "","Un nouveau mot de passe provisoire a été généré. Vous allez le recevoir par mail à l’adresse indiquée. Ce mot de passe sera à changer à votre première connexion.")
    const alerteCaptchaInvalide = "Une erreur est survenue lors de la saisie du captcha."
    const alerteCaptchaErreur = "Une erreur est survenue lors du contrôle du captcha."
    const alerteCompteBloque = "Votre compte est bloqué, veuillez contacter un administrateur du site via le lien 'Nous contacter'."
    const alerteComptePasValide = "Votre compte n'a pas encore été validé. Vous ne pouvez pas réinitialiser votre mot de passe."
    const alerteCompteInexistant = "Le mail renseigné ne correspond à aucun compte. Si vous possédez un compte, veuillez vérifier les informations saisies. Si ce n’est pas le cas, veuillez créer un compte."

    const {mettreAjourAlerte} = useAlerteContext();

    const genererMessageErrreur = (erreur: string) => {
        switch(erreur){
            case ServiceErrorsEnum.CAPTCHA_INVALIDE:
                return alerteCaptchaInvalide
            case ServiceErrorsEnum.CAPTCHA_ERREUR:
                return alerteCaptchaErreur
            case ServiceErrorsEnum.COMPTE_BLOQUE:
                return alerteCompteBloque
            case ServiceErrorsEnum.UTILISATEUR_NON_TROUVE:
                return alerteCompteInexistant
            case ServiceErrorsEnum.NOUVEAU_COMPTE:
                return alerteComptePasValide
        }
    }
    const formHandler = () => {
        form.setValue("captcha.id", (document.getElementsByName("mi-captcha-id")[0] as HTMLInputElement).value);
        form.setValue("captcha.application", (document.getElementsByName('mi-captcha-application')[0] as HTMLInputElement).value);
        RgaAppliAPIImpl.reinitialiserMotDePasse(form.getValues())
            .then(() => {
                mettreAjourAlerte(reinitMdpOk);
                onValiderClick()
            })
            .catch(error => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", genererMessageErrreur(error.response.data.error.type)))
            })
        form.setValue("captcha.value", "");
        MiCaptcha.refresh();
    }

    return <>
        <Modale
            closeCallback={onFermerClick}
            id="modifier-utilisateur"
        >
            <ModaleTitre idModale="modifier-utilisateur">
                Mot de passe oublié
            </ModaleTitre>
            <ModaleContent>
                <p>
                    Afin de recevoir un nouveau mot de passe par mail, veuillez renseigner le mail associé à votre compte.
                </p>
                <form onSubmit={form.handleSubmit(formHandler)}>
                    <Input
                        label={"Mail"}
                        name="mail"
                        error={form.formState.errors.email}
                        {...form.register('email', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            pattern: {value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: MessagesErreurEnum.MAIL},
                            maxLength: {value: 320, message: MessagesErreurEnum.LONGUEUR_MAX_320}
                        })}
                    />
                    <Captcha
                        onChange={valeur => form.setValue("captcha", valeur)}
                        error={form.formState.errors.captcha}
                        register={form.register('captcha.value',{
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                        })}
                    />
                    <div className={"d-flex flex-space-between"}>
                        <Bouton
                            label={"Annuler"}
                            className={fr.mr_2w}
                            niveau="secondaire"
                            onClick={onAnnulerClick}
                        />
                        <Bouton
                            label={"Valider"}
                            niveau="primaire"
                            type="submit"
                        />
                    </div>

                </form>
            </ModaleContent>

        </Modale>
    </>

}

import React from "react";
import {Carte, CarteBody, CarteContent, CarteFooter, CarteTitre, Col, Row} from "@design-system-etat/dsfr-react";

import './BaseMenuCard.css'
import Pictogram from "../../../../entites/Pictogramme";


interface BaseMenuCarteProps {
    titre: string
    titrePicto: Pictogram
    description: any
    bouton: JSX.Element
    id: string
}


const BaseMenuCarte = (props: BaseMenuCarteProps) => {

    const {titre, titrePicto, description, bouton, id} = props;

    return (
        <span id={id}>
            <Carte noEnlargeLink={true}>
                <CarteBody>
                    <CarteContent>
                        <CarteTitre>
                            <Row alignementVertical="middle" alignementHorizontal="left">
                                <img src={titrePicto.svg} alt={titrePicto.alt} id="carte_menu_picto"/>
                                <span id="carte_menu_titre">{titre}</span>
                            </Row>
                        </CarteTitre>
                      <div className="fr-card__desc">
                            <Row goutierre>
                                <Col col={12}>
                                    <span id="carte_menu_description">{description}</span>
                                </Col>
                            </Row>
                      </div>
                    </CarteContent>
                    <CarteFooter>
                        {bouton}
                    </CarteFooter>
                </CarteBody>
            </Carte>
        </span>
    );
};


export default BaseMenuCarte;

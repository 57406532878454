import API from "../api/APIConfiguration";
import {ExtensionsEnum} from "../../constantes/ExtensionsEnum";
import dateformat from "dateformat";
import {ExportPort} from "../../utils/port/ExportPort";
import {ExportApiType} from "../../constantes/ExportApiConstantes";
import Arme, { armeToLigneCsv } from "../../entites/Arme";
import { genererDateActuellePourCsv } from "../../utils/DateUtils";
import {DemandeArme, demandeToLigneCsv} from "../../entites/DemandeArme";
import {SessionService} from "../InfrastructureFactory";

const handleExporter = (exportObject : ExportApiType) : Promise<number> => {
    return API.get(exportObject.url)
        .then((response) => {
            let csvData = new Blob(["\ufeff", response.data]);
            let timeStamp = dateformat(new Date(), "yyyymmddHHMMss");
            let filename = exportObject.baseName.concat(timeStamp).concat(ExtensionsEnum.EXTENTIONCSV);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = URL.createObjectURL(csvData);
            a.download = filename;
            a.click();
            a.remove();
            return (response.status)

        }).catch((error) => {
            return(error.response.status)
        });
}

export const ExportServiceImpl: ExportPort = {
    handleExporter
}

export const exportRechercheArmeToCsv = (armes: Arme[]) => {
    if (!armes || !armes.length) {
        return;
    }
    let blob = new Blob(["\ufeff",
    convertToCSV(armes.map(arme => {
        return armeToLigneCsv(arme)
    }))
        .replaceAll('[separateur-designation-commerciale]', ',')
    ], {type: 'text/csv;charset=utf-8'})

    const a = document.createElement('a')
    a.download = "export_armes" + genererDateActuellePourCsv() + ".csv"
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const exportDemandeArmeToCsv = (demandes: DemandeArme[]) => {
    if (!demandes || !demandes.length) {
        return;
    }
    let blob = new Blob(["\ufeff",
        convertToCSV(demandes.map(demande => {
            return demandeToLigneCsv(demande,SessionService.getProfilCategorie())
        }))
            .replaceAll('[separateur-designation-commerciale]', ',')
    ], {type: 'text/csv;charset=utf-8'})

    const a = document.createElement('a')
    a.download = "export_demandes" + genererDateActuellePourCsv() + ".csv"
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

const convertToCSV = (arr) => {
  const array = [Object.keys(arr[0])].concat(arr)
  return array.map(it => {
    return toCsvLine(Object.values(it))
  }).join('\n')
}

export const toCsvLine = (data: Array<any>): string => {
    return data.reduce(( generatedString: string, currentValue: any) => {
        if(!currentValue && currentValue !== 0) {
            return  generatedString + ';'
        }
        if(typeof currentValue === "string" && currentValue.search(';') !== -1) {
            return generatedString.concat('"', currentValue.toString(), '"', ';' )
        }
        return generatedString + currentValue.toString() + ';'
    }, '')
}


import React, {useEffect, useState} from "react";
import EcranResultatRecherche from "./resultats/EcranResultatRecherche";
import RechSimplModele from "./modele/RechSimplTypeModele";
import RechSimplMarque from "./marque/RechSimplMarque";
import RechSimplModeDeFonctionnement from "./mode-de-fonctionnement/RechSimplModeDeFonctionnement";
import RechSimplCalibre from "./calibre/RechSimplTypeCalibre";
import RechSimplTypeCanon from "./type-canon/RechSimplTypeCanon";
import RechSimplFamille from "./famille/RechSimplTypeFamille";
import RechSimplTypeArme from "./type-arme/RechSimplTypeArme";
import {fr} from "../../constantes/ClassesDSFR";
import {ReferentielAPIService} from "../../infrastructure/InfrastructureFactory";
import {
    RechSimplFamilleEnum,
    RechSimplTypeArmeEnum,
    RechSimplTypeCanonOption
} from "../../constantes/RechSimplOptionsEnum";
import {
    CriteresRechercheArme,
    CriteresRechercheSimplifiee,
    CriteresRechercheSimplifieeInitial,
    criteresSimplifieesToCriteresArmes
} from "../../entites/CriteresRechercheArme";
import search from "@gouvfr/dsfr/dist/artwork/pictograms/digital/search.svg";
import {arrowGoBackLine, Bouton} from "@design-system-etat/dsfr-react";
import {useNavigate} from "react-router-dom";
import {useRechercheSimplifieeContext} from "../../composants/RechercheSimplifieeContext";
import {EtapesStepperEnum} from "../../constantes/EtapesStepperEnum";
import {EtapeRechSimplExp} from "../../entites/EtapeRechSimpl";
import RechSimplPrecautions from "./precautions/RechSimplPrecautions";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {
    listeEtapesAirComprime,
    listeEtapesArmeFeu1Canon,
    listeEtapesArmeFeuPlusDe1Canon,
    Stepper
} from "../../composants/stepper/Stepper";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {SelectOption} from "../../entites/Referentiels/SelectOption";
import {useAlerteContext} from "../../composants/RGAContext";
import Pictogram from "../../entites/Pictogramme";

export const etapesArmeFeu1Canon: EtapeRechSimplExp[] = [
    {nom: EtapesStepperEnum.PRECAUTIONS, ordre: 1},
    {nom: EtapesStepperEnum.TYPE_ARME, ordre: 2},
    {nom: EtapesStepperEnum.FAMILLE, ordre: 3},
    {nom: EtapesStepperEnum.CANON, ordre: 4},
    {nom: EtapesStepperEnum.CALIBRE, ordre: 5},
    {nom: EtapesStepperEnum.MODE_DE_FONCTIONNEMENT, ordre: 6},
    {nom: EtapesStepperEnum.MARQUE, ordre: 7},
    {nom: EtapesStepperEnum.MODELE, ordre: 8},
    {nom: EtapesStepperEnum.RESULTAT, ordre: 9}
];

export const etapesArmeFeuPlusDe1Canon: EtapeRechSimplExp[] = [
    {nom: EtapesStepperEnum.PRECAUTIONS, ordre: 1},
    {nom: EtapesStepperEnum.TYPE_ARME, ordre: 2},
    {nom: EtapesStepperEnum.FAMILLE, ordre: 3},
    {nom: EtapesStepperEnum.CANON, ordre: 4},
    {nom: EtapesStepperEnum.CALIBRE, ordre: 5},
    {nom: EtapesStepperEnum.MARQUE, ordre: 6},
    {nom: EtapesStepperEnum.MODELE, ordre: 7},
    {nom: EtapesStepperEnum.RESULTAT, ordre: 8}
];

export const etapesAirComprime: EtapeRechSimplExp[] = [
    {nom: EtapesStepperEnum.PRECAUTIONS, ordre: 1},
    {nom: EtapesStepperEnum.TYPE_ARME, ordre: 2},
    {nom: EtapesStepperEnum.FAMILLE, ordre: 3},
    {nom: EtapesStepperEnum.MARQUE, ordre: 4},
    {nom: EtapesStepperEnum.MODELE, ordre: 5},
    {nom: EtapesStepperEnum.CALIBRE, ordre: 6},
    {nom: EtapesStepperEnum.RESULTAT, ordre: 7}
];

const EcranRechercheSimplifiee : React.FC = () => {

    const [accepteLesPrecautions, setAccepteLesPrecautions] = useState<boolean>(false);
    const [ecranEtapeActuelle, setEcranEtapeActuelle] = useState<JSX.Element>();
    const [veutVoirLesResultats, setVeutVoirLesResultats] = useState<boolean>(false);
    const [etapeActuelle, setEtapeActuelle] = useState<EtapeRechSimplExp>({nom: EtapesStepperEnum.PRECAUTIONS, ordre: 1});
    const [listeEtapesStepper, setListeEtapesStepper] = useState<EtapeRechSimplExp[]>();
    const {
        mettreAJourRechercheEnCours,
        mettreAJourResultats,
        mettreAJourCriteres,
        criteres,
        mettreAJourReferentiels
    } = useRechercheSimplifieeContext();
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();
    const navigate = useNavigate();

    const pictoRechercheArme: Pictogram = {
        svg: search,
        alt: "pictogram recherche"
    };

    function handleAfficherResultats() {
        mettreAJourDateDerniereAction();
        setVeutVoirLesResultats(true);
    }

    const rechercher = (criteres: CriteresRechercheSimplifiee, pageDemande: number, elementsParPage: number,tri?) => {
        mettreAJourDateDerniereAction();
        mettreAJourRechercheEnCours(true)
        const criteresArme : CriteresRechercheArme = criteresSimplifieesToCriteresArmes(criteres)
        ReferentielAPIService.rechercherArmes(criteresArme, pageDemande, elementsParPage, true,tri)
            .then(pageTableau => {
                mettreAJourResultats(pageTableau)
                mettreAJourRechercheEnCours(false)
            })
            .catch(() => {mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "Résultats", "Une erreur est survenue lors des résultats"))})

        ReferentielAPIService.recupererReferentielsFiltres(criteresArme,true)
            .then(referentiels => {
                let nouveauxReferentiels = {...referentiels}
                Object.keys(referentiels).forEach(cle => nouveauxReferentiels[cle] = referentiels[cle].map(referentiel => new SelectOption(referentiel)))
                // @ts-ignore
                mettreAJourReferentiels(nouveauxReferentiels)
            })
            .catch(() => {mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "Referentiels", "Une erreur est survenue lors de la récupération des référentiels"))})
    }

    const recupererListeEtapesStepper = () : EtapeRechSimplExp[] => {
        const estArmeAFeu = criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU
        if(estArmeAFeu){
            if(criteres.nombreCanons === 1){
                return listeEtapesArmeFeu1Canon
            } else {
                return listeEtapesArmeFeuPlusDe1Canon
            }
        } else {
            return listeEtapesAirComprime
        }
    }

    const recupererListeEtapes = () : EtapeRechSimplExp[] => {
        const estArmeAFeu = criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU
        if(estArmeAFeu){
            if(criteres.nombreCanons === 1){
                return etapesArmeFeu1Canon
            } else {
                return etapesArmeFeuPlusDe1Canon
            }
        } else {
            return etapesAirComprime
        }
    }

    const genererEtape = (nomEtape: EtapesStepperEnum, pourEtapeSuivante: boolean) => {
        const listeEtapes = recupererListeEtapes()
        const etapeActuelle = listeEtapes.find(etape => etape.nom === nomEtape)
        return listeEtapes.find(etape => etape.ordre === (pourEtapeSuivante ? etapeActuelle.ordre + 1 : etapeActuelle.ordre - 1))
    }

    useEffect(() => {
        mettreAJourDateDerniereAction();
        setListeEtapesStepper(recupererListeEtapesStepper())
    }, [criteres]) // eslint-disable-line react-hooks/exhaustive-deps

    const recupererEtape = (nomEtape: EtapesStepperEnum) => {
        setListeEtapesStepper(recupererListeEtapesStepper())
        let nouveauxCriteres : CriteresRechercheSimplifiee
        switch (nomEtape) {
            case EtapesStepperEnum.PRECAUTIONS :
                return <RechSimplPrecautions
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                    }
                    }
                    onSuivantClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.PRECAUTIONS, true));
                        setAccepteLesPrecautions(true);
                    }}
                />
            case EtapesStepperEnum.TYPE_ARME :
                nouveauxCriteres = {...criteres, typeArme: null}
                return <RechSimplTypeArme
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.TYPE_ARME, false));
                        mettreAJourCriteres(nouveauxCriteres)
                        setAccepteLesPrecautions(false);
                    }}
                    onSuivantClick={typeArme => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.TYPE_ARME, true));
                        mettreAJourCriteres({...criteres, typeArme: typeArme});
                    }}
                />
            case EtapesStepperEnum.FAMILLE :
                nouveauxCriteres = {...criteres, famille: null}
                return <RechSimplFamille
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.FAMILLE, false));
                        mettreAJourCriteres(nouveauxCriteres)
                    }}
                    onSuivantClick={famille => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.FAMILLE, true));
                        mettreAJourCriteres({...criteres, famille: famille})
                    }}
                />
            case EtapesStepperEnum.CANON :
                nouveauxCriteres = {...criteres, typeCanons: RechSimplTypeCanonOption.PAR_DEFAUT, nombreCanons: 0}
                rechercher(nouveauxCriteres, 1, 25);
                return <RechSimplTypeCanon
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.CANON, false));
                        mettreAJourCriteres(nouveauxCriteres)
                    }}
                    onSuivantClick={(typeCanon, nombreCanons) => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.CANON, true));
                        mettreAJourCriteres({
                            ...criteres,
                            typeCanons: typeCanon,
                            nombreCanons: nombreCanons,
                        })
                    }}
                />
            case EtapesStepperEnum.CALIBRE :
                nouveauxCriteres = {...criteres, calibres: {calibre1: "", calibre2: "", calibre3: "", calibre4: ""}}
                rechercher(nouveauxCriteres, 1, 25);
                return <RechSimplCalibre
                    onCalibreChange={calibres => rechercher({...criteres, calibres}, 1, 25)}
                    onAfficherResultatClick={calibres => {
                        mettreAJourDateDerniereAction();
                        if(criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_AIR_COMPRIME){
                            setEtapeActuelle(genererEtape(EtapesStepperEnum.CALIBRE, true))
                            mettreAJourCriteres({...criteres, calibres: calibres})
                        } else {
                            mettreAJourCriteres(nouveauxCriteres)
                            handleAfficherResultats();
                        }
                    }}
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        mettreAJourCriteres({...criteres, calibres: {calibre1: "", calibre2: "", calibre3: "", calibre4: ""}, modeDeFonctionnement: null})
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.CALIBRE, false))
                    }}
                    onSuivantClick={calibres => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.CALIBRE, true));
                        mettreAJourCriteres({...criteres, calibres: calibres})
                    }}
                />
            case EtapesStepperEnum.MODE_DE_FONCTIONNEMENT :
                nouveauxCriteres = {...criteres, modeDeFonctionnement: null}
                rechercher(nouveauxCriteres, 1, 25);
                return <RechSimplModeDeFonctionnement
                    onAfficherResultatClick={() => handleAfficherResultats()}
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.MODE_DE_FONCTIONNEMENT, false));
                        mettreAJourCriteres(nouveauxCriteres)
                    }}
                    onSuivantClick={modeDeFonctionnement => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.MODE_DE_FONCTIONNEMENT, true));
                        mettreAJourCriteres({...criteres, modeDeFonctionnement: modeDeFonctionnement})
                    }}
                />
            case EtapesStepperEnum.MARQUE :
                nouveauxCriteres = {...criteres, marque: "", estMarqueArtisante: false}
                rechercher(nouveauxCriteres, 1, 25);
                return <RechSimplMarque
                    onAfficherResultatClick={() => handleAfficherResultats()}
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.MARQUE, false));
                        mettreAJourCriteres(nouveauxCriteres)
                    }}
                    onSuivantClick={(marque, estMarqueArtisante) => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.MARQUE, true));
                        mettreAJourCriteres({...criteres, marque: marque, estMarqueArtisante: estMarqueArtisante})
                    }}
                />
            case EtapesStepperEnum.MODELE :
                nouveauxCriteres = {...criteres, modele: ""}
                rechercher(nouveauxCriteres, 1, 25);
                return <RechSimplModele
                    onAfficherResultatClick={modele => {
                        if(criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU){
                            setEtapeActuelle(genererEtape(EtapesStepperEnum.MODELE, true))
                            mettreAJourCriteres({...criteres, modele: modele})
                        } else {
                            handleAfficherResultats();
                        }
                    }}
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.MODELE, false));
                        mettreAJourCriteres(nouveauxCriteres)
                    }}
                    onSuivantClick={modele => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.MODELE, true));
                        if(criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU){
                            mettreAJourCriteres({...criteres, modele: modele})
                        } else {
                            mettreAJourCriteres({...criteres, modele: modele, nombreCanons: 1})
                        }
                    }}
                />
            case EtapesStepperEnum.RESULTAT :
                rechercher(criteres, 1, 25);
                return <EcranResultatRecherche
                    onChangePage={(pageDemande, elementsParPage,tri) => rechercher(criteres, pageDemande, elementsParPage,tri)}
                    onPrecedentClick={() => {
                        mettreAJourDateDerniereAction();
                        setEtapeActuelle(genererEtape(EtapesStepperEnum.RESULTAT, false));
                    }}
                />
        }
    }

    const afficherEtape = (afficherLesResultats: boolean) => {
        if(afficherLesResultats){
            setEcranEtapeActuelle(<EcranResultatRecherche
                onChangePage={(pageDemande, elementsParPage,tri) => rechercher(criteres, pageDemande, elementsParPage,tri)}
                onPrecedentClick={() => {setEcranEtapeActuelle(recupererEtape(etapeActuelle.nom)); setVeutVoirLesResultats(false)}} // relance le useEffect
            />)
        }
        else setEcranEtapeActuelle(recupererEtape(etapeActuelle.nom))
    }

    useEffect(() => {
        afficherEtape(veutVoirLesResultats);
    }, [etapeActuelle, accepteLesPrecautions, veutVoirLesResultats]) // eslint-disable-line react-hooks/exhaustive-deps

    return <section className={fr.my_6w + "fr-container"}>
        <div className={"d-flex flex-space-between"}>
            <div className={ fr.mb_2w + "d-flex"}>
                <div>
                    <img src={pictoRechercheArme.svg} alt={pictoRechercheArme.alt}/>
                </div>
                <div>
                    <div style={{marginTop: "1rem"}}></div>
                    <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>
                        Rechercher une arme
                        {criteres.typeArme ? criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU ? " à feu" : " à air comprimé" : ""}
                        {criteres.famille ? criteres.famille === RechSimplFamilleEnum.ARME_DE_POING ? " de poing" : " d'épaule" : ""}
                    </span>
                    <br/>
                    <span style={{fontWeight: "bold", fontSize: "1rem"}}>Recherche simplifiée</span>
                </div>
            </div>
            {accepteLesPrecautions && criteres.typeArme && (<div className={fr.pt_2w}>
                <Bouton
                    icone={arrowGoBackLine}
                    label="Réinitialiser"
                    iconePosition="left"
                    onClick={() => {
                        setVeutVoirLesResultats(false)
                        mettreAJourCriteres(CriteresRechercheSimplifieeInitial)
                        setEtapeActuelle({nom: EtapesStepperEnum.TYPE_ARME, ordre: 2})
                        setEcranEtapeActuelle(recupererEtape(EtapesStepperEnum.TYPE_ARME))
                    }}
                />
            </div>
            )}
        </div>
        <div>
            {etapeActuelle?.ordre > 2 ?
                <div className={"d-flex flex-space-between"}>
                    <Stepper
                        titreEtapeEnCours={etapeActuelle.nom}
                        listeEtapes={listeEtapesStepper}
                    />
                    <div className={"w-100"}>{ecranEtapeActuelle}</div>
                </div>
                :
                <div className={"w-100"}>{ecranEtapeActuelle}</div>
            }
        </div>
    </section>
}

export default EcranRechercheSimplifiee;

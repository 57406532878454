import {ReferentielAPIEnum} from "./ReferentielAPIEnum";

const editMessage: string = "Veuillez renseigner le nouveau libellé de l’élément de référentiel."

const ReferentielsModaleConstantes = {
        FAMILLES: {
            titre: {
                ajouter: "Veuillez renseigner une nouvelle famille",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        MARQUES: {
            titre: {
                ajouter: "Veuillez renseigner une nouvelle marque",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur",
                },
                {
                    nom: "artisan",
                    type: "toggle",
                    description: "Artisan",
                    option: ["Non", "Oui"]
                }
            ] as ReferentielModaleChamp[],
        } as ReferentielModaleType,

        MODELES: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau modèle",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        CALIBRES: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau calibre",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                },
                {
                    nom: "synonyme",
                    type: "text",
                    description: "Saisir le synonyme"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        FABRICANTS: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau fabricant",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        TYPES_ARMES: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau type d'armes",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        MODES_FONCTIONNEMENT: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau mode de fonctionnement",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        SYSTEMES_ALIMENTATIONS: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau système d'alimentation",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        TYPES_CANON: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau type de canon",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        MODE_PERCUSSION: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau mode de percussion",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,
        CLASSEMENT_EUROPEEN: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau classement Européen",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        CLASSEMENT_FRANCAIS: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau classement Français",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,

        PAYS_FABRICANT: {
            titre: {
                ajouter: "Veuillez renseigner un nouveau pays fabricant",
                editer: editMessage
            },
            champs: [
                {
                    nom: "libelle",
                    type: "text",
                    description: "Saisir la valeur"
                }
            ] as ReferentielModaleChamp[]
        } as ReferentielModaleType,
    };

interface ReferentielConstanteInterface{[cle : string] : ReferentielConstanteType}

export const ReferentielsConstantes: ReferentielConstanteInterface = {
    FAMILLES: {
        id: "familles",
        nom: "Familles",
        modale: ReferentielsModaleConstantes.FAMILLES,
        idName : "idFamilleArme",
        dbName : ReferentielAPIEnum.FAMILLES

    },
    MARQUES: {
        id: "marques",
        nom: "Marques",
        modale: ReferentielsModaleConstantes.MARQUES,
        idName : "idMarque",
        dbName : ReferentielAPIEnum.MARQUES
    },
    MODELES: {
        id: "modeles",
        nom: "Modèles",
        modale: ReferentielsModaleConstantes.MODELES,
        idName : "idModele",
        dbName : ReferentielAPIEnum.MODELES
    },
    CALIBRES: {
        id: "calibres",
        nom: "Calibres",
        modale: ReferentielsModaleConstantes.CALIBRES,
        idName: "idCalibre",
        dbName: ReferentielAPIEnum.CALIBRES
    },
    FABRICANTS: {
        id: "fabricants",
        nom: "Fabricants",
        modale: ReferentielsModaleConstantes.FABRICANTS,
        idName : "idFabricant",
        dbName : ReferentielAPIEnum.FABRICANTS
    },
    TYPES_ARMES: {
        id: "types-armes",
        nom: "Types d'armes",
        dbName: ReferentielAPIEnum.TYPES_ARMES,
        modale: ReferentielsModaleConstantes.TYPES_ARMES,
        idName : "idTypeArme"
    },
    MODES_FONCTIONNEMENT: {
        id: "modes-fonctionnement",
        nom: "Modes de fonctionnement",
        dbName: ReferentielAPIEnum.FONCTIONNEMENTS,
        modale: ReferentielsModaleConstantes.MODES_FONCTIONNEMENT,
        idName : "idModeFonctionnement"
    },
    SYSTEMES_ALIMENTATIONS: {
        id: "systemes-alimentation",
        nom: "Systèmes d'alimentation",
        dbName: ReferentielAPIEnum.SYSTEMES_ALIMENTATION,
        modale: ReferentielsModaleConstantes.SYSTEMES_ALIMENTATIONS,
        idName : "idModeAlimentation"
    },
    TYPES_CANON: {
        id: "types-canon",
        nom: "Types de canon",
        dbName: ReferentielAPIEnum.TYPES_CANON,
        modale: ReferentielsModaleConstantes.TYPES_CANON,
        idName : "idTypeCanon"
    },
    MODE_PERCUSSION: {
        id: "mode-percussion",
        nom: "Mode de percussion",
        dbName: ReferentielAPIEnum.MODES_PERCUSSION,
        modale: ReferentielsModaleConstantes.MODE_PERCUSSION,
        idName : "idModePercussion"
    },
    CLASSEMENT_EUROPEEN: {
        id: "classement-europeens",
        nom: "Classement Européens",
        dbName: ReferentielAPIEnum.CLASSEMENT_EU,
        modale: ReferentielsModaleConstantes.CLASSEMENT_EUROPEEN,
        idName :"idClassementEurope"
    },

    CLASSEMENT_FRANCAIS: {
        id: "classement-nationaux",
        nom: "Classement Nationaux",
        dbName: ReferentielAPIEnum.CLASSEMENT_FR,
        modale: ReferentielsModaleConstantes.CLASSEMENT_FRANCAIS,
        idName : "idClassementFrance"
    },

    PAYS_FABRICANT: {
        id: "pays-fabricant",
        nom: "Pays Fabricant",
        dbName: ReferentielAPIEnum.PAYS_FABRICANT,
        modale: ReferentielsModaleConstantes.PAYS_FABRICANT,
        idName :"id"

    },

    RAHC: {
        id: "rahc",
        nom: "RAHC",
        modale: false
    }
}

export type ReferentielConstanteType = {
    id: string,
    nom: string,
    dbName?: ReferentielAPIEnum,
    idName?: string,
    modale: ReferentielModaleType | false
}

type ReferentielModaleType = {
    titre: {
        ajouter: string,
        editer: string
    },
    champs: ReferentielModaleChamp[]
}

type ReferentielModaleChamp = {
    nom: string,
    type: "text" | "toggle",
    description: string,
    option?: string[]
}

import React, {useEffect, useState} from "react";
import {
    Footer,
    FooterCopyright,
    FooterLiens,
    FooterLiensItem,
    FooterLogo
} from "@design-system-etat/dsfr-react/lib/esm/components/interface/Footer";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import "./FooterRGA.css";
import {useAlerteContext} from "../../composants/RGAContext";
import {Col, Row} from "@design-system-etat/dsfr-react";
import useLocalStorage from "@design-system-etat/dsfr-react/lib/esm/components/interface/Footer/Hooks/useLocalStorage";
import useSystemThemeDetector
    from "@design-system-etat/dsfr-react/lib/esm/components/interface/Footer/Hooks/useSystemThemeDetector";
import ModaleParametreAffichageRGA from "./ModaleParametreAffichageRGA";

const FooterRGA: React.FC = () => {
    const [modaleThemeOuverte, setModaleThemeOuverte] = useState(false);
    const [scheme] = useLocalStorage('scheme', 'light');
    const [theme, setTheme] = useState(scheme);
    const isDarkSystemTheme = useSystemThemeDetector();

    useEffect(() => {
        let themeActuel = theme;
        if (theme === 'system' && isDarkSystemTheme) {
            themeActuel = 'dark'
        }
        if (theme === 'system' && !isDarkSystemTheme) {
            themeActuel = 'light'
        }

        document.getElementsByTagName("html")[0].setAttribute("data-fr-theme", themeActuel)
    }, [theme, isDarkSystemTheme]);

    const {mettreAJourDateDerniereAction} = useAlerteContext();

    function FooterLiensThemeRGA() {
        return <li className="fr-footer__bottom-item">
            <button
                type="button"
                className="fr-footer__bottom-link fr-fi-theme-fill fr-link--icon-left"
                aria-controls="fr-theme-modal"
                data-fr-opened="false"
                onClick={() => setModaleThemeOuverte(true)}
            >
                Paramètres d&apos;affichage
            </button>
            {modaleThemeOuverte && (
                <ModaleParametreAffichageRGA
                    closeCallback={() => setModaleThemeOuverte(false)}
                    changerThemeCallback={(theme) => setTheme(theme)}
                />
            )}
        </li>;
    }

    return <>
        <Footer>
            <FooterLogo>
                <a href="src/pages/footer/FooterRGA" title="Accueil - RGA" id="logo-footer">
                    <p className="fr-logo" title="ministère de l'intérieur et des outre-mer">
                        Ministère
                        <br/>de l'Intérieur
                        <br/>et des outre-mer
                    </p>
                </a>
            </FooterLogo>
            <FooterLiens activerParametrageTheme={false}>
                <FooterLiensItem id="nous-contacter" href={URLPageEnum.NOUS_CONTACTER}
                                 onClick={mettreAJourDateDerniereAction}>
                    Nous contacter
                </FooterLiensItem>
                <FooterLiensItem id="tutoriel-utilisation" target="_blank" onClick={mettreAJourDateDerniereAction}
                                 href="https://www.interieur.gouv.fr/Le-ministere/Secretariat-general/Service-central-des-armes/Professionnels-des-armes-a-feu/Referentiel-General-des-Armes">
                    Tutoriel d'utilisation
                </FooterLiensItem>
                <FooterLiensItem id="plan-du-site" href="#">
                    Plan du site
                </FooterLiensItem>
                <FooterLiensItem id="accessibilite" href={URLPageEnum.ACCESSIBILITE}
                                 onClick={mettreAJourDateDerniereAction}>
                    Accessibilité
                </FooterLiensItem>
                <FooterLiensItem id="mentions-legales" href={URLPageEnum.CONDITIONS_GENERALES}
                                 onClick={mettreAJourDateDerniereAction}>
                    Mentions légales
                </FooterLiensItem>
                <FooterLiensItem id="donnees-personnelles" href="#">
                    Données personnelles
                </FooterLiensItem>
                <FooterLiensItem id="gestion-cookies" href="#">
                    Gestion des cookies
                </FooterLiensItem>
                {FooterLiensThemeRGA()}

            </FooterLiens>
            <FooterCopyright>
                <Row className="blockFullSize">
                    <Col>
                        Sauf mention contraire, tous les textes de ce site sont sous{' '}
                        <a
                            href="https://github.com/etalab/licence-ouverte/blob/master/LO.md"
                            rel="noreferrer"
                            target="_blank"
                            onClick={mettreAJourDateDerniereAction}
                        >
                            licence etalab-2.0
                        </a>
                    </Col>
                    <Col className={"numero_version"}>v{
                         // @ts-ignore
                         window.VERSION
                    }</Col>
                </Row>
            </FooterCopyright>
        </Footer>
    </>
}

export default FooterRGA;

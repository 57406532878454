import React, {useEffect} from "react";
import {BoutonRadio, Checkbox} from "@design-system-etat/dsfr-react/lib/cjs";
import "../EcranRechercheSimplifiee.css"
import {fr} from "../../../constantes/ClassesDSFR";
import {Controller, useForm} from "react-hook-form";
import {AutoCompleteRGA} from "../../../composants/SelectHorsDsfr/AutoCompleteRGA";
import {SelectOption} from "../../../entites/Referentiels/SelectOption";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";

interface RechSimplCarteCalibreProps {
    titre: string;
    estActif?: boolean;
    onRadioClick: () => any;
    calibres: { calibre1: string, calibre2: string, calibre3: string, calibre4: string };
    nombreCanons: number;
    onCalibresChange: ({calibre1, calibre2, calibre3, calibre4}) => void;
}

const RechSimplCarteCalibre: React.FC<RechSimplCarteCalibreProps> = ({
                                                                         titre,
                                                                         estActif,
                                                                         onRadioClick,
                                                                         calibres,
                                                                         nombreCanons,
                                                                         onCalibresChange
                                                                     }) => {

    const {referentiels, rechercheEnCours} = useRechercheSimplifieeContext();

    const form = useForm<{ calibre1: string, calibre2: string, calibre3: string, calibre4: string }>({
        mode: 'onChange',
        defaultValues: calibres
    });

    useEffect(() => {
        form.reset(calibres)
        onCalibresChange(calibres)
    }, [calibres]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (estActif) form.reset()
    }, [estActif]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={"rech-simpl-carte-calibre"}>
        <div className={"rech-simpl-carte__presentation " + (estActif ? "rech-simpl-carte__presentation--active" : "")}>
            <div className={"d-flex flex-center rech-simpl-carte__radio"}>
                <BoutonRadio
                    label={titre}
                    name={"radio"}
                    value={titre}
                    checked={estActif}
                    onClick={onRadioClick}
                />
            </div>
            {estActif && (<>
                <p className={fr.ml_2w}>Je saisis dans les champs ci-dessous :</p>
                <div className={fr.px_2w}>
                    <label>Calibre 1</label>
                    <Controller
                        name="calibre1"
                        control={form.control}
                        render={({field}) => (
                            <AutoCompleteRGA
                                onChange={(option: SelectOption) => {
                                    field.onChange(option?.value ? option.value : "")
                                    onCalibresChange(form.getValues())
                                    // @ts-ignore
                                    document.getElementsByName("checkbox-calibre1")[0]!.checked = false
                                }}
                                disabled={rechercheEnCours}
                                sansChargement
                                value={form.getValues('calibre1') ? new SelectOption(form.getValues("calibre1")) : null}
                                field={field}
                                options={referentiels?.CALIBRE1}
                                filtreCalibre={true}
                            />
                        )}
                    />
                </div>
                <div className={fr.p_2w}>
                    <Checkbox
                        label="Je ne le connais pas"
                        name="checkbox-calibre1"
                        checked={form.getValues('calibre1') === '-'}
                        value="1"
                        onClick={e => {
                            if (e.target.checked) form.setValue("calibre1", "-")
                            else form.setValue("calibre1", "")
                            onCalibresChange(form.getValues())
                        }}
                    />
                </div>
                {nombreCanons > 1 && (<>
                    <div className={fr.px_2w}>
                        <label>Calibre 2</label>
                        <Controller
                            name="calibre2"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? option.value : "")
                                        onCalibresChange(form.getValues())
                                        // @ts-ignore
                                        document.getElementsByName("checkbox-calibre2")[0]!.checked = false
                                    }}
                                    disabled={rechercheEnCours}
                                    sansChargement
                                    value={form.getValues('calibre2') ? new SelectOption(form.getValues("calibre2")) : null}
                                    field={field}
                                    options={referentiels?.CALIBRE2}
                                    filtreCalibre={true}
                                />
                            )}
                        />
                    </div>
                    <div className={fr.p_2w}>
                        <Checkbox
                            label="Je ne le connais pas"
                            name="checkbox-calibre2"
                            value="2"
                            checked={form.getValues('calibre2') === '-'}
                            onClick={e => {
                                if (e.target.checked) form.setValue("calibre2", "-")
                                else form.setValue("calibre2", "")
                                onCalibresChange(form.getValues())
                            }}

                        />
                    </div>
                </>)}
                {nombreCanons > 2 && (<>
                    <div className={fr.px_2w}>
                        <label>Calibre 3</label>
                        <Controller
                            name="calibre3"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? option.value : "")
                                        onCalibresChange(form.getValues())
                                        // @ts-ignore
                                        document.getElementsByName("checkbox-calibre3")[0]!.checked = false
                                    }}
                                    disabled={rechercheEnCours}
                                    sansChargement
                                    value={form.getValues('calibre3') ? new SelectOption(form.getValues("calibre3")) : null}
                                    field={field}
                                    options={referentiels?.CALIBRE3}
                                    filtreCalibre={true}
                                />
                            )}
                        />
                    </div>
                    <div className={fr.p_2w}>
                        <Checkbox
                            label="Je ne le connais pas"
                            name="checkbox-calibre3"
                            value="3"
                            checked={form.getValues('calibre3') === '-'}
                            onClick={e => {
                                if (e.target.checked) form.setValue("calibre3", "-")
                                else form.setValue("calibre3", "")
                                onCalibresChange(form.getValues())
                            }}

                        />
                    </div>
                </>)}
                {nombreCanons > 3 && (<>
                    <div className={fr.px_2w}>
                        <label>Calibre 4</label>
                        <Controller
                            name="calibre4"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? option.value : "")
                                        onCalibresChange(form.getValues())
                                        // @ts-ignore
                                        document.getElementsByName("checkbox-calibre4")[0]!.checked = false
                                    }}
                                    disabled={rechercheEnCours}
                                    sansChargement
                                    value={form.getValues('calibre4') ? new SelectOption(form.getValues("calibre4")) : null}
                                    field={field}
                                    options={referentiels?.CALIBRE4}
                                    filtreCalibre={true}
                                />
                            )}
                        />
                    </div>
                    <div className={fr.p_2w}>
                        <Checkbox
                            label="Je ne le connais pas"
                            name="checkbox-calibre4"
                            value="4"
                            checked={form.getValues('calibre4') === '-'}
                            onClick={e => {
                                if (e.target.checked) form.setValue("calibre4", "-")
                                else form.setValue("calibre4", "")
                                onCalibresChange(form.getValues())
                            }}
                        />
                    </div>
                </>)}
            </>)}
        </div>
    </div>
}

export default RechSimplCarteCalibre;

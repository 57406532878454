import {Col, Container, Row} from "@design-system-etat/dsfr-react";
import {StatutDemandeEnum} from "../../../constantes/StatutDemandeEnum";
import {ChipBouton} from "../../../composants/chip/Chip";
import {URLPageEnum} from "../../../constantes/URLPageEnum";
import {FicheArmeActionEnum} from "../../../constantes/FicheArmeActionEnum";
import {SessionService} from "../../../infrastructure/InfrastructureFactory";
import {UtilisateurProfilCategorieEnum} from "../../../constantes/UtilisateurProfilCategorieEnum";
import React from "react";
import {fr} from "../../../constantes/ClassesDSFR";
import {useAlerteContext} from "../../../composants/RGAContext";
import Arme from "../../../entites/Arme";
import {useNavigate} from "react-router-dom";
import {UtilisateurDroitEnum} from "../../../constantes/UtilisateurDroitEnum";

interface EnTeteFicheArmeProps {
    lectureSeul: boolean
    estDemande: boolean
    setEstVisible
    estVisible: boolean
    idFiche: string
    arme: Arme
    typeDemande: string
}

const EnTeteFicheArme: React.FC<EnTeteFicheArmeProps> = (props: EnTeteFicheArmeProps) => {
    const {lectureSeul, estDemande, setEstVisible, estVisible, arme, idFiche, typeDemande} = props;
    let navigate = useNavigate();
    const {mettreAJourDateDerniereAction} = useAlerteContext()

    function calculerVisibilite() {
        if (lectureSeul) {
            return arme?.visible
        }
        return estVisible;
    }

    function titreEcran() {
        return <b>
            <h1 className={fr.mb_1w}> {estDemande ? "Demande de création RGA" : "Fiche RGA"}</h1>
        </b>;
    }


    function afficherBoutonDupliquer() {
        const dupliquerDemandeAfficher = (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO
            && ([StatutDemandeEnum.STATUT_BROUILLON, StatutDemandeEnum.STATUT_INCOMPLETE, StatutDemandeEnum.STATUT_DEPOSE,StatutDemandeEnum.STATUT_DEMANDE_CLASSEMENT].some(statut => statut === typeDemande)));
        const modifierDemandeAfficher = SessionService.utilisateurALeDroit(UtilisateurDroitEnum.CREER_FICHE) || SessionService.utilisateurALeDroit(UtilisateurDroitEnum.CREER_DEMANDE_FICHE)

        return estDemande ?
            <>
                {dupliquerDemandeAfficher &&
                    <ChipBouton
                        libelle={<>
                            <span>Dupliquer</span>
                            <span className="fr-icon-file-pdf-fill" aria-hidden="true"></span>
                        </>}
                        identifiant={"fa-bouton-dupliquer"} onClick={() => {
                        mettreAJourDateDerniereAction();
                        navigate(URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.CREER, {
                            state: {
                                idFicheADupliquer: idFiche,
                                afficherDemande: estDemande
                            }
                        })
                    }}/>
                }
            </>
            : <>
                {modifierDemandeAfficher && <ChipBouton
                    libelle={<>
                        <span>Dupliquer</span>
                        <span className="fr-icon-file-pdf-fill" aria-hidden="true"></span>
                    </>}
                    identifiant={"fa-bouton-dupliquer"} onClick={() => {
                    mettreAJourDateDerniereAction();
                    navigate((SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO ?
                            URLPageEnum.DEMANDE_ARME :
                            URLPageEnum.FICHE_ARME)
                        + "/" + FicheArmeActionEnum.CREER,
                        {
                            state: {
                                idFicheADupliquer: idFiche,
                                afficherDemande: estDemande,
                                afficherDemandePagePrecedente: estDemande
                            }
                        })
                }}/>}
            </>;
    }

    function afficherBoutonModifier() {
        const modifierDemandeAfficher = [StatutDemandeEnum.STATUT_BROUILLON, StatutDemandeEnum.STATUT_INCOMPLETE].some(statut => statut === typeDemande)
        const modifierArmeAfficher = SessionService.utilisateurALeDroit(UtilisateurDroitEnum.CREER_FICHE)

        return estDemande ?
            <>
                {modifierDemandeAfficher &&
                    <ChipBouton
                        libelle={<><span>Modifier</span>
                            <span className="fr-icon-pencil-fill" aria-hidden="true"></span>
                        </>}
                        identifiant={"fa-bouton-modifier"} onClick={() => {
                        mettreAJourDateDerniereAction();
                        navigate(URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.MODIFIER + "/" + idFiche, {state: {afficherDemande: estDemande}})
                    }}/>
                }
            </> :
            <>
                {modifierArmeAfficher &&
                    <ChipBouton
                        libelle={<><span>Modifier</span>
                            <span className="fr-icon-pencil-fill" aria-hidden="true"></span>
                        </>}
                        identifiant={"fa-bouton-modifier"} onClick={() => {
                        mettreAJourDateDerniereAction();
                        navigate(URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.MODIFIER + "/" + idFiche, {state: {afficherDemande: estDemande}})
                    }}/>
                }
            </>;
    }

    function afficherToggleVisibilite() {
        return <>
            {calculerVisibilite() ? <div className={"fiche-arme--visible"}>Visible </div> :
                <div className={"fiche-arme--non-visible"}>Non visible </div>}
            <div className={"fr-toggle"}>
                <input
                    // @ts-ignore
                    name={"visible"}
                    type="checkbox"
                    className="fr-toggle__input"
                    aria-describedby={"tooglevisibilite-hint-text"}
                    id={"tooglevisibilite"}
                    disabled={lectureSeul}
                    defaultChecked={calculerVisibilite()}
                    onClick={() => {
                        mettreAJourDateDerniereAction();
                        setEstVisible(!estVisible)
                    }}
                />
                <label
                    className="fr-toggle__label"
                    htmlFor={"tooglevisibilite"}
                >
                    {""}
                </label>
            </div>
        </>;
    }

    return <div className={"fa-fiche-arme"}>
        <Container className={"fa-surcharge-container-DSFR"}>
            <Row alignementVertical={"middle"}>
                <Col> {titreEcran()}</Col>
                <Col className={"texte-droite"}>
                    <Row alignementHorizontal={"right"} alignementVertical={"middle"}>
                        {!estDemande && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE && afficherToggleVisibilite()}
                        {lectureSeul && <>
                            {afficherBoutonModifier()}
                            {afficherBoutonDupliquer()}
                        </>
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>

}

export default EnTeteFicheArme;

export const auPluriel = (mot: string, quantite: number) : string => {
    return quantite > 1 ? mot + "s" : mot;
}

export const downloadImage = (url, imageNom) => {
    let a = document.createElement("a");
    // @ts-ignore
    a.href = window.BACKEND_URL + url;
    a.download = imageNom;
    a.click();
    a.remove();
}
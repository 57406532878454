import React from "react";
import {Bouton, Icone} from "@design-system-etat/dsfr-react";
import {arrowDownSLine, BoutonRadio} from "@design-system-etat/dsfr-react/lib/cjs";
import "./SelectTypeCanon.css"
import {questionFill} from "@design-system-etat/dsfr-react/lib/esm";
import {RechSimplTypeCanonOption} from "../../../constantes/RechSimplOptionsEnum";

interface SelectTypeCanonProps {
    titre: string,
    icone: string,
    name: string,
    options: RechSimplTypeCanonOption[],
    optionSelectionnee?: RechSimplTypeCanonOption;
    isActif?: boolean,
    onClick: () => any,
    onOptionClick: (option: RechSimplTypeCanonOption) => any,
    onExempleClick?: () => any
    nombreCanons: number
    nombreCanonsSelectionnee: number
}

const SelectTypeCanon : React.FC<SelectTypeCanonProps> = ({titre, icone, nombreCanons, nombreCanonsSelectionnee, name, options, isActif, onClick, optionSelectionnee, onExempleClick, onOptionClick}) => {

    const hauteurOptionRem = 1.9;
    const hauteurExempleRem = 2.5;

    return <div>
        <div className={"select-type-canon d-flex flex-space-between " + (isActif ? "select-type-canon--actif" : "")} onClick={onClick}>
            <div className={"d-flex flex-space-around"}>
                <div className={"select-type-canon__option--element-gauche"}>
                    <img
                        alt="1 canon"
                        className={"select-type-canon__option--image"}
                        src={icone}
                    />
                </div>
                <div className={"select-type-canon__option--nom"}>{titre}</div>
            </div>
            <div className={"select-type-canon__dropdown-icone"}>
                <Icone icone={arrowDownSLine}/>
            </div>
        </div>
        {isActif && (
            <div className={"select-type-canon__options"} style={{height: options?.length * hauteurOptionRem + hauteurExempleRem + "rem"}}>
                {options.map(option => { return (
                    <div className={"select-type-canon__option--element-gauche"} key={option.getLibelle()}>
                        <BoutonRadio
                            checked={option.getLibelle() === optionSelectionnee?.getLibelle() && nombreCanonsSelectionnee === nombreCanons}
                            label={option.getLibelle()}
                            name={name}
                            value={option.getLibelle()}
                            onClick={() => onOptionClick(option)}
                        />
                    </div>
                )})}
                <div className={"d-flex flex-center"}>
                    <Bouton
                        icone={questionFill}
                        iconePosition="right"
                        label="Exemples"
                        onClick={onExempleClick}
                    />
                </div>
            </div>
        )}
    </div>
}

export default SelectTypeCanon;

import React, {FC, useCallback, useEffect, useState} from "react";
import {addLine, Bouton, Container} from "@design-system-etat/dsfr-react";
import {fr} from "../../constantes/ClassesDSFR";
import Titre from "../../composants/Titre";
import {
    Modale,
    ModaleContent,
    ModaleTitre,
    Tableau,
    TableauBody,
    TableauCell,
    TableauHeader,
    TableauRow
} from "@design-system-etat/dsfr-react/lib/cjs";
import {TableauHeaderCellFiltres} from "../../composants/TableauHeaderCellFiltres";
import {ParametresTechniques} from "../../constantes/ParametresTechniques";
import debounce from "lodash.debounce";
import "../administration-referentiels/AdministrationDesReferentiels.css"
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {
    DemandeArme,
    demandeArmeColonneOrdre,
    DemandeArmeFiltre,
    demandeArmeToDemandeArmeLite
} from "../../entites/DemandeArme";
import {ArmeServiceAPI, SessionService} from "../../infrastructure/InfrastructureFactory";
import {UtilisateurDroitEnum} from "../../constantes/UtilisateurDroitEnum";
import PaginationRGA from "../../composants/PaginationRGA";
import {
    DEMANDES_STATUTS,
    DEMANDES_STATUTS_LIBELLES_CREER_ARME,
    DEMANDES_STATUTS_LIBELLES_TRAITER_ARME
} from "../../constantes/DemandeStatutEnum";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {FicheArmeActionEnum} from "../../constantes/FicheArmeActionEnum";
import {
    closeCircleFill,
    downloadFill,
    Icone
} from "@design-system-etat/dsfr-react/lib/esm/components/fondamentaux/Icone/index";
import {exportDemandeArmeToCsv} from "../../infrastructure/arme/ExportService";
import ModaleRecapDemandeArme from "./ModaleRecapDemandeArme";
import {auFormat2Caracteres} from "../../utils/DateUtils";
import {UtilisateurProfilCategorieEnum} from "../../constantes/UtilisateurProfilCategorieEnum";
import {StatutDemandeEnum} from "../../constantes/StatutDemandeEnum";
import "./AdministrationDesArmes.css"
import {useNavigate} from "react-router-dom";

export const AdministrationDesArmes: FC = () => {

    const [nombrePages, setNombrePages] = useState<number>(1);
    const [pageCourante, setPageCourante] = useState<number>(1);
    const [elementsParPage, setElementsParPage] = useState<number>(25);
    const [demandes, setDemandes] = useState<DemandeArme[]>([]);
    const [tri, setTri] = useState<{
        cle: string,
        valeur: "ASC" | "DESC" | ""
    }>({cle: "dateDepot", valeur: "DESC"});
    const [filtres, setFiltres] = useState<DemandeArmeFiltre>(undefined);
    const [droitsUtilisateur, setDroitsUtilisateur] = useState<UtilisateurDroitEnum[]>([]);
    const [statusOptions, setStatusOptions] = useState<any>([]);
    const [aDesDemandes, setADesDemandes] = useState<boolean>(true);
    const [modaleArmeOuvert, setModaleArmeOuvert] = useState<boolean>(false);
    const [modaleSuppressionOuvert, setModaleSuppressionOuvert] = useState<boolean>(false);
    const [demandeModale, setDemandeModale] = useState<DemandeArme>(null);
    const {mettreAjourAlerte} = useAlerteContext();
    const navigate = useNavigate();

    function updateTri(cle: string, valeur: "ASC" | "DESC" | "") {
        setTri({cle: cle, valeur: valeur});
    }

    function updateFiltre(cle: string, valeur: string | string[]) {
        const nouveauxFiltres = {...filtres, [cle]: valeur};
        setFiltres(nouveauxFiltres);
        sessionStorage.setItem("filtresAdministrationDesArmes", JSON.stringify(nouveauxFiltres));
    }

    const recupererDemandes = (useCallback(debounce((filtresRecu, triRecu, pageDemandee: number, elementsParPageRecu: number) => { // eslint-disable-line react-hooks/exhaustive-deps
        debounceRecupererDemandes(filtresRecu, triRecu, pageDemandee, elementsParPageRecu);
    }, ParametresTechniques.DUREE_DEBOUNCE), []))

    function debounceRecupererDemandes(pageCourante, elementsParPage, tri, filtres) {
        ArmeServiceAPI.recupererDemandeArmes(pageCourante, elementsParPage, tri, filtres)
            .then(resultat => {
                setDemandes(resultat.elements)
                setNombrePages(resultat.nombrePages)
            })
    }

    function genererTitre() {
        if (droitsUtilisateur.includes(UtilisateurDroitEnum.CREER_DEMANDE_FICHE) && !droitsUtilisateur.includes(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS)) {
            return "Mes demandes";
        } else if (droitsUtilisateur.includes(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS) && !droitsUtilisateur.includes(UtilisateurDroitEnum.CREER_DEMANDE_FICHE)) {
            return "Demandes à traiter";
        } else {
            return "Demandes";
        }
    }

    const visiterArme = (idDemande: number, statutDemande: string, idArme: string) => {

        if (StatutDemandeEnum.STATUT_VALIDE === statutDemande) {
            window.open(URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + idArme, '_blank')
        } else {
        const droitSCAEConsultation = SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE && [StatutDemandeEnum.STATUT_VALIDE, StatutDemandeEnum.STATUT_REFUSE, StatutDemandeEnum.STATUT_INCOMPLETE].some(statutConsultation => statutConsultation === statutDemande);
        const droitPROConsultation = SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO && [StatutDemandeEnum.STATUT_BROUILLON, StatutDemandeEnum.STATUT_VALIDE, StatutDemandeEnum.STATUT_REFUSE, StatutDemandeEnum.STATUT_INCOMPLETE, StatutDemandeEnum.STATUT_DEPOSE, StatutDemandeEnum.STATUT_DEMANDE_CLASSEMENT].some(statutConsultation => statutConsultation === statutDemande);
            if (droitPROConsultation || droitSCAEConsultation) {
                navigate(URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + idDemande)
            } else {
                navigate(URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.MODIFIER + "/" + idDemande)
            }
        }
    }

    const supprimer = (demande: DemandeArme) => {
        ArmeServiceAPI.supprimerDemandeArme(demande.id)
            .then(resultat => {
                if (resultat === 200) mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "La demande " + demande.id + " a bien été supprimée."))
            })
            .catch(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Une erreur est survenue lors de la suppression de la demande " + demande.id))
            })
    }

    function genererCellules(demande: DemandeArme) {
        let cellules: JSX.Element[] = []
        const demandeLite = demandeArmeToDemandeArmeLite(demande)

        function genererTexte(cle: string) {
            if (cle === "dateDepot" || cle === "dateCreation" || cle === "dateMaj" || cle === "dateValidation" || cle === "dateSuppression") {
                const date = new Date(demandeLite[cle])
                return (demandeLite[cle] === null) ? "" : auFormat2Caracteres(date.getDate()) + "/" + auFormat2Caracteres(date.getMonth() + 1) + "/" + auFormat2Caracteres(date.getFullYear())
            }
            if (cle === "statut") {
                if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO) {
                    if (DEMANDES_STATUTS_LIBELLES_CREER_ARME[demandeLite[cle]]) {
                        return DEMANDES_STATUTS_LIBELLES_CREER_ARME[demandeLite[cle]]
                    } else {
                        return ""
                    }
                } else if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) {
                    if (DEMANDES_STATUTS_LIBELLES_TRAITER_ARME[demandeLite[cle]]) {
                        return DEMANDES_STATUTS_LIBELLES_TRAITER_ARME[demandeLite[cle]]
                    } else {
                        return ""
                    }
                } else {
                    return DEMANDES_STATUTS_LIBELLES_TRAITER_ARME.depose_validation
                }
            } else {
                return demandeLite[cle]
            }
        }

        const conditionAffichageCellule = (cle: string) => {
            return (cle !== "dateCreation" && cle !== "dateMaj" && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE)
                || (cle !== "demandeur" && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO);
        }

        Object.keys(demandeLite).sort((a, b) => demandeArmeColonneOrdre[a] - demandeArmeColonneOrdre[b]).forEach((cle, index) => {
            if (
                conditionAffichageCellule(cle)
            ) {
                cellules.push(<TableauCell>
                    <div style={{overflowWrap: "break-word"}}
                         id={demandeLite.id.toString() + '_' + index}
                         onClick={() => visiterArme(demande.id, demande.statut, demande.ficheArme.sia)}>
                        {genererTexte(cle)}
                    </div>
                </TableauCell>)
            }
        })

        cellules.push(<td>
            <div className={"texte-centre"} style={{zIndex: "1000"}}>
                <Bouton
                    className={"bouton-afficher-informations"}
                    icone={addLine}
                    label="afficher-informations"
                    id={"bouton-afficher-informations-" + demande.id}
                    onClick={() => {
                        setModaleArmeOuvert(true)
                        setDemandeModale(demande)
                    }}
                />
            </div>
        </td>)
        const suppressionPossible = demande.statut !== DEMANDES_STATUTS.VALIDE
        cellules.push(<TableauCell>
            <div className={suppressionPossible ? "admin-arme__bouton-supprimer" : "texte-desactive-gris"}
                 onClick={() => {
                     if (suppressionPossible) {
                         setDemandeModale(demande)
                         setModaleSuppressionOuvert(true)
                     }
                 }}>
                <Icone icone={closeCircleFill}/>
            </div>

        </TableauCell>)
        return cellules
    }

    useEffect(() => {
        recupererDemandes(pageCourante, elementsParPage, tri, filtres)
    }, [tri, filtres]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const filtresRecuperes = JSON.parse(sessionStorage.getItem("filtresAdministrationDesArmes")) as DemandeArmeFiltre
        const droitsUtilisateurRecupere = SessionService.getUtilisateurDroits()
        setDroitsUtilisateur(droitsUtilisateurRecupere)
        if (droitsUtilisateurRecupere.includes(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS)) {
            setStatusOptions(Object.values(DEMANDES_STATUTS_LIBELLES_TRAITER_ARME))
        } else {
            setStatusOptions(Object.values(DEMANDES_STATUTS_LIBELLES_CREER_ARME))
        }
        if(filtresRecuperes?.statut && filtresRecuperes.statut.length > 0){
            setFiltres({...filtresRecuperes})
        } else {
            setFiltres({...filtresRecuperes, statut: SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE ? [DEMANDES_STATUTS_LIBELLES_TRAITER_ARME.depose_validation] : []})
        }
        if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE) {
        } else {
        }
        ArmeServiceAPI.recupererDemandeArmes(pageCourante, elementsParPage, tri, {
            id: null,
            statut: [],
            dateCreation: null,
            dateMaj: null,
            dateDepot: null,
            marque: "",
            modele: "",
            calibre: "",
            demandeur: ""
        })
            .then(resultat => {
                if (resultat.elements.length === 0) {
                    setADesDemandes(false)
                }
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <Container className={fr.my_4w + fr.mx_4w} margeExterne={false}>
        {modaleArmeOuvert && droitsUtilisateur.includes(UtilisateurDroitEnum.CONSULTER_FICHES) && (
            <span className={"modal-recap-arme-surcharge-DSFR"}>
            <ModaleRecapDemandeArme
                demande={demandeModale}
                onFermerClick={() => setModaleArmeOuvert(false)}
                onAccederFicheClick={() =>
                    visiterArme(demandeModale.id, demandeModale.statut, demandeModale.ficheArme.sia)
                }
            /> </span>)}
        {modaleSuppressionOuvert && (
            <Modale id={"modal"} taille={"lg"} closeCallback={() => setModaleSuppressionOuvert(false)}>
                <ModaleTitre idModale="modal">
                    <span className={"d-flex flex-center"}>Supprimer une demande</span>
                </ModaleTitre>
                <ModaleContent>
            <span className={"d-flex flex-center"}>
                <p>Êtes-vous sûr de vouloir supprimer cette demande ?</p>
            </span>
                    <span className={"d-flex flex-end"}>
                <Bouton
                    onClick={() => {
                        supprimer(demandeModale)
                        setModaleSuppressionOuvert(false)
                        recupererDemandes(pageCourante, elementsParPage, tri, filtres)
                    }}
                    className={"fa-bouton-suppression"}
                    niveau="primaire"
                    icone="fr-icon-arrow-right-s-last-line"
                    iconePosition="right"
                    label={"Supprimer"}
                    id={"fa-supprimer"}
                />
            </span>
                </ModaleContent>
            </Modale>)}
        <Titre>{genererTitre()}</Titre>
        <div>
            {aDesDemandes ?
                <>
                    {filtres && (<Tableau caption={""} avecBordure tailleFixe couleur={""}>
                            <TableauHeader>
                                <TableauRow className={"tr-1px"}>
                                    <TableauHeaderCellFiltres
                                        typeFiltre="text"
                                        ordreTriValeur={tri.cle === "id" && tri.valeur}
                                        onChange={updateFiltre}
                                        defaultValue={filtres!.id ? filtres!.id + "" : ""}
                                        onClick={updateTri}
                                        idColonne={"id"}
                                        key={"numero_demande"}
                                    >
                                        N° Demande
                                    </TableauHeaderCellFiltres>
                                    <TableauHeaderCellFiltres
                                        typeFiltre="selectMulti"
                                        ordreTriValeur={tri.cle === "statut" && tri.valeur}
                                        onChange={updateFiltre}
                                        defaultValueMulti={filtres.statut}
                                        onClick={updateTri}
                                        selectOptions={statusOptions}
                                        idColonne={"statut"}
                                        key={"statut"}

                                    >
                                        Statut
                                    </TableauHeaderCellFiltres>
                                    {droitsUtilisateur.includes(UtilisateurDroitEnum.CREER_DEMANDE_FICHE) && <>
                                        <TableauHeaderCellFiltres
                                            typeFiltre="text"
                                            ordreTriValeur={tri.cle === "dateCreation" && tri.valeur}
                                            onChange={updateFiltre}
                                            defaultValue={filtres!.dateCreation ? filtres!.dateCreation + "" : ""}
                                            onClick={updateTri}
                                            idColonne={"dateCreation"}
                                            key={"dateCreation"}
                                        >
                                            Date de création
                                        </TableauHeaderCellFiltres>
                                        <TableauHeaderCellFiltres
                                            typeFiltre="text"
                                            ordreTriValeur={tri.cle === "dateMaj" && tri.valeur}
                                            onChange={updateFiltre}
                                            defaultValue={filtres!.dateMaj ? filtres!.dateMaj + "" : ""}
                                            onClick={updateTri}
                                            idColonne={"dateMaj"}
                                            key={"dateMaj"}
                                        >
                                            Dernière modification
                                        </TableauHeaderCellFiltres>
                                    </>}
                                    <TableauHeaderCellFiltres
                                        typeFiltre="date"
                                        ordreTriValeur={tri.cle === "dateDepot" && tri.valeur}
                                        onChange={(cle, valeur) => updateFiltre("dateDepot" + (cle.includes("after") ? "[after]" : "[strictly_before]"), valeur)}
                                        onClick={(cle, valeur) => {
                                            // @ts-ignore
                                            updateTri("dateDepot", valeur)
                                        }}
                                        idColonne={"dateDepot"}
                                        key={"dateDepot"}
                                        className={"caseLongue"}
                                    >
                                        Date de soumission
                                    </TableauHeaderCellFiltres>
                                    {droitsUtilisateur.includes(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS) &&
                                        <TableauHeaderCellFiltres
                                            typeFiltre="text"
                                            ordreTriValeur={tri.cle === "demandeur" && tri.valeur}
                                            onChange={updateFiltre}
                                            defaultValue={filtres!.demandeur ? filtres!.demandeur + "" : ""}
                                            onClick={updateTri}
                                            idColonne={"demandeur"}
                                            key={"demandeur"}
                                        >
                                            Demandeur
                                        </TableauHeaderCellFiltres>
                                    }
                                    <TableauHeaderCellFiltres
                                        typeFiltre="text"
                                        ordreTriValeur={tri.cle === "marque" && tri.valeur}
                                        onChange={updateFiltre}
                                        defaultValue={filtres!.marque ? filtres!.marque + "" : ""}
                                        onClick={updateTri}
                                        idColonne={"marque"}
                                        key={"marque"}
                                    >
                                        Marque
                                    </TableauHeaderCellFiltres>
                                    <TableauHeaderCellFiltres
                                        typeFiltre="text"
                                        ordreTriValeur={tri.cle === "modele" && tri.valeur}
                                        onChange={updateFiltre}
                                        defaultValue={filtres!.modele ? filtres!.modele + "" : ""}
                                        onClick={updateTri}
                                        idColonne={"modele"}
                                        key={"modele"}
                                    >
                                        Modèle
                                    </TableauHeaderCellFiltres>
                                    <TableauHeaderCellFiltres
                                        typeFiltre="text"
                                        ordreTriValeur={tri.cle === "calibre" && tri.valeur}
                                        onChange={updateFiltre}
                                        defaultValue={filtres!.calibre ? filtres!.calibre + "" : ""}
                                        onClick={updateTri}
                                        idColonne={"calibre"}
                                        key={"calibre"}
                                    >
                                        Calibre
                                    </TableauHeaderCellFiltres>
                                    <th className={"infosHeader"}>
                                        Afficher plus d'informations
                                    </th>
                                    <th className={"actionHeader"}>
                                        Action
                                    </th>
                                </TableauRow>
                            </TableauHeader>
                            <TableauBody>
                                {demandes.map(ligne => {
                                    return <TableauRow className={"filtreRow"} key={ligne.id}>
                                        {genererCellules(ligne)}
                                    </TableauRow>
                                })}
                            </TableauBody>
                        </Tableau>
                    )}
                    <PaginationRGA
                        pageCourante={pageCourante}
                        totalPages={nombrePages}
                        elementsParPageParDefaut={elementsParPage}
                        optionElementsParPage={[25, 50, 100]}
                        changePage={pageRecupere => {
                            setPageCourante(pageRecupere)
                            recupererDemandes(pageRecupere, elementsParPage, tri, filtres)
                        }}
                        onElementsParPageChange={elementsParPageRecu => {
                            setPageCourante(1)
                            setElementsParPage(elementsParPageRecu)
                            recupererDemandes(1, elementsParPageRecu, tri, filtres)
                        }}
                    />
                    <div>
                        <div className={"d-flex flex-end"}>
                            <Bouton
                                icone={downloadFill}
                                iconePosition={"right"}
                                label={"Export CSV"}
                                className={fr.mt_2w}
                                niveau={"primaire"}
                                onClick={() => exportDemandeArmeToCsv(demandes)}
                            />
                        </div>
                    </div>
                </>
                :
                <p>Vous n’avez aucune demande d’ajout de fiche d’arme en cours.</p>
            }
        </div>
    </Container>

}

import React from "react";
import Titre from "../../composants/Titre";
import {fr} from "../../constantes/ClassesDSFR";
import LienExterneRGA from "../../composants/LienExterneRGA";

const EcranAccessibilite: React.FC = () => {

    function etatDeConformite() {
        return <>
            <h4 className={"uppercase"}>état de conformité</h4>
            <p>
                SIA Livre de police, <LienExterneRGA>https://sia.registres.interieur.gouv.fr</LienExterneRGA> est en
                conformité partielle avec le référentiel général d’amélioration de l’accessibilité (RGAA),
                version 4.0 en raison des non-conformités et des dérogations énumérées ci-dessous.
            </p>
        </>
    }

    function resultatDesTests() {
        return <>
            <h4 className={"uppercase"}>résultats des tests</h4>
            <div>
                <p>L’audit de conformité par le service central des armes et explosifs révèle que :</p>
                <ul>
                    <li><p>93,40 % des critères du RGAA version 4.0 sont respectés.</p></li>
                </ul>
            </div>
        </>
    }

    function contenusNonAccessibles() {
        return <>
            <h4 className={"uppercase"}>contenus non accessibles non conformité</h4>
            <div>
                <ul>
                    <li><p>Les nouveaux logos de présentation ne disposent pas d’une alternative textuelle. La version 2021
                        respectera les critères du RGAA sur les alternatives textes pour les documents multimédias.</p>
                    </li>
                    <li><p>Les tableaux de données complexes ne contiennent pas un résumé pertinent. La version 2021
                        respectera les critères RGAA sur les tableaux.</p>
                    </li>
                    <li><p>Les infobulles ne sont pas affichables via le clavier.</p></li>
                </ul>
            </div>
        </>
    }

    function derogations() {
        return <>
            <h4 className={"uppercase"}>Dérogations pour charge disproportionnée</h4>
            <p>Le tableau de gestion des logos ne dispose pas de description pour les retours Json. Il est cependant
                ppossible d’en réaliser un export au format csv.</p>
        </>
    }

    function contenusNonSoumis() {
        return <>
            <h4 className={"uppercase"}>Contenus non soumis à l’obligation d’accessibilité</h4>
            <div>
                <ul>
                    <li><p>La base photographique du RGA.</p></li>
                </ul>
            </div>
        </>
    }

    function etablissement() {
        return <>
            <h4 className={"uppercase"}>Établissement de cette déclaration d’accessibilité</h4>
            <p>Cette déclaration a été établie le 01 octobre 2020.</p>
        </>
    }

    function technologiesUtilisees() {
        return <>
            <h4 className={"uppercase"}>
                Technologies utilisées pour la réalisation de <br/>
                <a className="lien-titre-accesibilite" target="_blank" rel="noreferrer"
                   href="https://sia.registres.interieur.gouv.fr">https://sia.registres.interieur.gouv.fr</a>
            </h4>
            <div>
                <ul>
                    <li>HTML 5</li>
                    <p>
                        Déclaration d’accessibilité RGAA.<br/>
                        Système d’information sur les armes (octobre 2020)
                    </p>
                    <li>CSS</li>
                </ul>
            </div>
        </>
    }

    function environnementDeTest() {
        return <>
            <h4 className={"uppercase"}>Environnement de test</h4>
            <div>
                <ul>
                    <li><p>Firefox 60.9esr (64 bits).</p></li>
                </ul>
            </div>
        </>
    }

    function outils() {
        return <>
            <h4 className={"uppercase"}>Outils pour évaluer l’accessibilité</h4>
            <div>
                <ul>
                    <li><p>WCAG Color contrast ; NVDA 2020.2</p></li>
                </ul>
            </div>
        </>
    }

    function pagesDuSite() {
        return <>
            <h4 className={"uppercase"}>Pages du site ayant fait l’objet de la vérification de conformité</h4>
            <div>
                <ul>
                    <li><p>PAGE 1 : <LienExterneRGA>https://sia.registres.interieur.gouv.fr</LienExterneRGA></p></li>
                    <li><p>PAGE 2 : <LienExterneRGA>https://sca.rga.interieur.gouv.fr</LienExterneRGA></p></li>
                </ul>
            </div>
        </>
    }

    function retourInformation() {
        return <>
            <h4 className={"uppercase"}>Retour information et contact</h4>
            <div>
                <p>
                    Si vous n’arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter le responsable
                    du
                    SIA pour être orienté vers une alternative accessible ou
                    obtenir le contenu sous une autre forme.
                </p>
                <ul>
                    <li>
                        <p>
                            Envoyer un message via le bouton “nous contacter” sur la page à l’adresse :
                            <LienExterneRGA>https://sia.registres.interieur.gouv.fr</LienExterneRGA>
                        </p>
                    </li>
                </ul>
            </div>
        </>
    }

    function voiesDeRecours() {
        return <>
            <h4 className={"uppercase"}>Voies de recours</h4>
            <div>
                <p>
                    Cette procédure esr à utliser dans le cas suivant;<br/>
                    Vous avez signalé au responsable du site internet un défaut d’accessibilité qui vous empêche
                    d’accéder à un contenu ou à un des services du portail
                    et vous n’avez pas obtenu de réponse satisfaisante.
                </p>
                <ul>
                    <li>
                        <p>Écrire un message au Défenseur des droits
                            : <LienExterneRGA>https://formulaire.defenseurdesdroits.fr</LienExterneRGA>
                        </p>
                    </li>
                    <li>
                        <p>Contacter le délégué du Défenseur des droits dans votre région
                            : <LienExterneRGA>https://www.defenseurdesdroits.fr/saisir/delegues</LienExterneRGA></p>
                    </li>
                    <li>
                        <p>Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre)<br/>
                            <span className={fr.pl_2w}>Défenseur des droits</span><br/>
                            <span className={fr.pl_2w}>Libre réponse 71120 75342 Paris CEDEX 07</span>
                        </p>
                    </li>
                </ul>
            </div>
        </>
    }

    return (<section className={fr.my_4w + "fr-container"}>

        <Titre souligne>Déclaration d'accessibilité</Titre>
        <p>
            Le Service central des armes et explosifs s’engage à rendre son système d’information internet, conformément
            à l’article 47 de la loi n°2005-102 du 11 février 2005.
            Cette déclaration d’accessibilité s’applique
            à <LienExterneRGA>https://sia.registres.interieur.gouv.fr</LienExterneRGA>
        </p>

        {etatDeConformite()}
        {resultatDesTests()}
        {contenusNonAccessibles()}
        {derogations()}
        {contenusNonSoumis()}
        {etablissement()}
        {technologiesUtilisees()}
        {environnementDeTest()}
        {outils()}
        {pagesDuSite()}
        {retourInformation()}
        {voiesDeRecours()}
    </section>);
}

export default EcranAccessibilite;
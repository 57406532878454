import React from "react";
import "./Chip.css";

interface ChipProps {
    estSupprimable: boolean,
    libelle: string,
    identifiant: any,
    onSuppress?: Function,
    onClick?: Function,
}

interface ChipBoutonProps {
    libelle: string|any
    identifiant: any,
    onClick: Function,
}

const Chip: React.FC<ChipProps> = (props) => {
    return <div className={"chip"} id={props.identifiant}>
        <span className={props.estSupprimable ? "fr-icon-close-circle-fill fr-icon--sm chip--icone" : ""}
              onClick={() => props.onSuppress(props)} aria-hidden="true"/>
        <span className={"chip--libelle"} >   {props.libelle}</span>
    </div>
}
export const ChipBouton: React.FC<ChipBoutonProps> = (props) => {
    const {libelle,onClick}=props;
    return <div data-html2canvas-ignore="true" className={"chip-bouton"} onClick={ ()=>onClick()}>
        <span>  {libelle}</span>
    </div>
}
export default Chip;
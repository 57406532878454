import React from "react";
import './Titre.css';

interface TitreProps {
    className?: string,
    souligne?: boolean,
    children?: any
}

const Titre = ({className, souligne, children} : TitreProps) => {
    return <h2 className={"titre " + (souligne ? "titre-souligne " : "") + (className ?? "")}>{children}</h2>
}

export default Titre;
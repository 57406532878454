export interface Captcha {
    id: string,
    application: string,
    value: string
}

export const CaptchaInitial : Captcha = {
    id: "",
    application: "",
    value: ""
}
import React from "react";
import BaseMenuCarte from "./BaseMenuCarte";
import search from "@gouvfr/dsfr/dist/artwork/pictograms/digital/search.svg";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import {Link} from "react-router-dom";
import {URLPageEnum} from "../../../../constantes/URLPageEnum";
import Pictogram from "../../../../entites/Pictogramme";
import {useAlerteContext} from "../../../../composants/RGAContext";

const MenuRechercheArme: React.FC = () => {
    const titreRechercheArme: string = "Rechercher une arme";
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    const pictoRechercheArme: Pictogram = {
        svg: search,
        alt: "pictogram recherche"
    };
    const descriptionRechercheArme =
        <span>
            Le mode de recherche avancée est destiné aux professionnels.
            <br/>
            Le mode de recherche simplifiée est destiné aux particuliers.
        </span>;

    function boutonRechercheAvance() {
        return <Row goutierre alignementHorizontal="left">
            <Col col={12}>
                <Link to={URLPageEnum.RECHERCHE_AVANCEE_ARMES} onClick={mettreAJourDateDerniereAction}>
                    <Bouton
                        icone="fr-icon-star-fill"
                        iconePosition="left"
                        label="Recherche avancée"
                        niveau="primaire"
                        className="fullSizeButton"
                        id="menu-arme-recherche-avance"
                    />
                </Link>
            </Col>
        </Row>;
    }

    function boutonRechercheSimplifie() {
        return <Row goutierre alignementHorizontal="left">
            <Col col={12}>
                <Link to={URLPageEnum.RECHERCHE_SIMPLIFIEE} onClick={mettreAJourDateDerniereAction}>
                    <Bouton
                        icone="fr-icon-questionnaire-fill"
                        iconePosition="left"
                        label="Recherche simplifiée"
                        niveau="primaire"
                        className="fullSizeButton"
                        id="menu-arme-recherche-simplifie"

                    />
                </Link>
            </Col>
        </Row>;
    }

    function calculerBouttonVisible() {
        return <>
            {boutonRechercheAvance()}
            {boutonRechercheSimplifie()}
        </>
    }

    return <BaseMenuCarte
        id={"menu-recherche-arme"}
        titre={titreRechercheArme}
        titrePicto={pictoRechercheArme}
        bouton={calculerBouttonVisible()}
        description={descriptionRechercheArme}
    />

}
export default MenuRechercheArme;
import React from "react";
import BaseMenuCarte from "./BaseMenuCarte";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import {ExportService, SessionService} from "../../../../infrastructure/InfrastructureFactory";
import book from "@gouvfr/dsfr/dist/artwork/pictograms/leisure/book.svg";
import {UtilisateurDroitEnum} from "../../../../constantes/UtilisateurDroitEnum";

import Pictogram from "../../../../entites/Pictogramme";
import {ExportApiConstantes, ExportApiType} from "../../../../constantes/ExportApiConstantes";
import {useAlerteContext} from "../../../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../../../entites/Alerte";
import {MessageGenerique} from "../../../../constantes/MessageGenerique";

const MenuExport: React.FC = () => {
    const titreExportRga: string = "Export RGA";

    const pictoExportRga: Pictogram = {
        svg: book,
        alt: "pictogram export"
    };
    const descriptionExport = "Vous avez la possibilité de télécharger les catalogues sous forme de tableur au format .csv.";
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();

    function exportRGA(exportObject: ExportApiType) {
        mettreAJourDateDerniereAction();
        let alerte
        ExportService.handleExporter(exportObject)
            .then((response) => {
                switch (response) {
                    case 200 :
                        alerte = new Alerte(typeAlerte.REUSSITE, "", exportObject.successMessage)
                        break;
                    case 502 :
                        alerte = new Alerte(typeAlerte.ERREUR, "", MessageGenerique.REQUETE_EN_ERREUR)
                        break;
                    default:
                        alerte = new Alerte(typeAlerte.ERREUR, "", exportObject.errorMessage)
                }
                mettreAjourAlerte(alerte)
            });
    }

    function calculerBoutonVisible() {
        function boutonCatalogue() {
            return <Bouton
                id="bouton-export-catalogue"
                icone="fr-icon-file-download-fill"
                iconePosition="left"
                label="Catalogue"
                niveau="primaire"
                className="fullSizeButton"
                onClick={() => exportRGA(ExportApiConstantes.CATALOGUE)}
            />;
        }

        function boutonBase() {
            return <Bouton
                id="bouton-export-base"
                icone="fr-icon-file-download-fill"
                iconePosition="left"
                label="Base"
                niveau="primaire"
                className="fullSizeButton"
                onClick={() => exportRGA(ExportApiConstantes.BASE)}
            />;
        }

        function boutonMarque() {
            return <Bouton
                id="bouton-export-marque"
                icone="fr-icon-file-download-fill"
                iconePosition="left"
                label="Marque"
                niveau="primaire"
                className="fullSizeButton"
                onClick={() => exportRGA(ExportApiConstantes.MARQUE)}
            />
        }

        function boutonCalibre() {
            return <Bouton
                id="bouton-export-calibre"
                icone="fr-icon-file-download-fill"
                iconePosition="left"
                label="Calibre"
                niveau="primaire"
                className="fullSizeButton"
                onClick={() => exportRGA(ExportApiConstantes.CALIBRE)}
            />;
        }

        function boutonRHAC() {
            return <Bouton
                id="bouton-export-RHAC"
                icone="fr-icon-file-download-fill"
                iconePosition="left"
                label="RAHC"
                niveau="primaire"
                className="fullSizeButton"
                onClick={() => exportRGA(ExportApiConstantes.RAHC)}
            />
                ;
        }

        return <>
            <Row goutierre alignementHorizontal="left" className={"exportRGASection"}>

                <Col>
                    {boutonCatalogue()}
                </Col>
                <Col>
                    {boutonRHAC()}
                </Col>

            </Row>
            <Row goutierre alignementHorizontal="left" className={"exportRGASection"}>
                <Col>
                    {boutonMarque()}
                </Col>
                <Col>
                    {boutonCalibre()}
                </Col>
            </Row>
            {SessionService.utilisateurALeDroit(UtilisateurDroitEnum.VISIBILITE_FICHES) &&
                <Row goutierre alignementHorizontal="center">
                    <Col col={6}>
                        {boutonBase()}
                    </Col>
                </Row>
            }
        </>;
    }


    return <BaseMenuCarte
        id={"menu-export"}
        titre={titreExportRga}
        titrePicto={pictoExportRga}
        bouton={calculerBoutonVisible()}
        description={descriptionExport}
    />

}
export default MenuExport;
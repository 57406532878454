import ReactImageUploading, {ImageListType, ImageType} from "react-images-uploading";
import imageVide from "../../../resources/image-vide.svg";
import {Bouton, Col, Container, Row} from "@design-system-etat/dsfr-react";
import SousTitre from "./SousTitre";
import React, {useEffect, useState} from "react";
import {Modale, ModaleContent} from "@design-system-etat/dsfr-react/lib/cjs";
import {PhotoArme} from "../../../entites/PhotoArme";
import {useAlerteContext} from "../../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../../entites/Alerte";

interface PhotosFicheArmeProps {
    lectureSeul: boolean
    setIdPhotoSupprime: (value: (((prevState: string[]) => string[]) | string[])) => void
    photos: PhotoArme
    setPhotos: any
}
interface Photo64Props {
    url: string
    position: string
    onclick: any
}


const Photo64 :React.FC<Photo64Props>=(props)=>{
    const{onclick,position,url}=props;
    const [photo64,setPhoto64]= useState<string>();
    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
    useEffect(() => {
        if(url){
            toDataURL(url,function (data64){setPhoto64(data64)})
        }
    }, [url]);


    return <img src={photo64}
                className={"fr-responsive-img"}
                alt={"Photo " + position}
                onClick={onclick}/>
}

const PhotosFicheArme: React.FC<PhotosFicheArmeProps> = (props: PhotosFicheArmeProps) => {
    const {mettreAjourAlerte} = useAlerteContext()
    const {lectureSeul, setIdPhotoSupprime, photos, setPhotos} = props;
    const positionImage = ["gauche", "droite", "dessus", "dessous", "gauche_marquage", "droite_marquage"]
    const [contenuModalePhoto, setContenuModalePhoto] = useState<string>(null);
    const {mettreAJourDateDerniereAction} = useAlerteContext()

    function modalePhoto() {
        return <div onClick={() => {
            mettreAJourDateDerniereAction();
            setContenuModalePhoto(null)
        }}>
            <Modale taille={"lg"} id={"fa-modale-annulation"}>
                <ModaleContent>
                    <img src={contenuModalePhoto} className={"fr-responsive-img"}
                         alt={"Aperçu"}
                    />
                </ModaleContent>

            </Modale></div>;
    }


    function afficherImage(position) {

        const onChange = (
            imageList: ImageListType,
        ) => {
            setPhotos({...photos, [position]: imageList});
        };

        function afficherImageLectureSeul() {



            if (photos[position] && photos[position].length > 0) {

return <Photo64 url={photos[position][0].dataURL} position={position} onclick={() => {
    mettreAJourDateDerniereAction();
    setContenuModalePhoto((photos[position][0].dataURL ?? imageVide))
}} />
                // return <img src={photo64}
                //             className={"fr-responsive-img"}
                //             alt={"Photo " + position}
                //             onClick={() => {
                //                 mettreAJourDateDerniereAction();
                //                 setContenuModalePhoto((photos[position][0].dataURL ?? imageVide))
                //             }}/>;
            } else {
                return <img src={imageVide}
                            className={"fr-responsive-img"}
                            alt={"Photo " + position}/>
            }
        }

        const retirerPhoto = (image: ImageType) => {
            if (image.id) {
                setIdPhotoSupprime(prevState => [...prevState, image.id])
            }
        }


        function calculerLibelle() {
            const positionTronquer = position.split("_")[0];
            return positionTronquer.charAt(0).toUpperCase() + positionTronquer.slice(1);
        }


        function calculerURLImage(image: ImageType) {
            // @ts-ignore
            return (image.id ? window.PREFIX_URL_IMAGE : "") + image.dataURL;

        }

        return <>
            {lectureSeul ?
                afficherImageLectureSeul()
                :

                <ReactImageUploading
                    value={photos[position]}
                    onChange={onChange}
                    acceptType={['jpeg', 'jpg', 'png']}
                    maxFileSize={4194304}
                    onError={(erreur) => {
                        if (erreur.acceptType) {
                            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "L’image ne respecte pas le format attendu. Seuls les formats PNG et JPEG sont acceptés."))
                        }
                        if (erreur.maxFileSize) {
                            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "La taille de l’image dépasse la taille maximale attendue (4Mo)."))
                        }
                    }}
                >
                    {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          isDragging,
                          dragProps
                      }) => (<div>

                        {imageList.length > 0 ?
                            imageList.map((image, index) => (
                                <div key={index} className={"width-100"}>
                                    {/*@ts-ignore*/}
                                    <img src={calculerURLImage(image)}
                                         alt={"Photo " + position}
                                         className={"fr-responsive-img fa-photo-arme"}
                                         onClick={() => {
                                             mettreAJourDateDerniereAction();
                                             setContenuModalePhoto(calculerURLImage(image))
                                         }}/>
                                    <br/>
                                    <Bouton type={"button"} label={"Supprimer"}
                                            icone={"fr-icon-close-circle-fill"}
                                            niveau={"tertiaire-sans-bordure"}
                                            iconePosition={"left"}
                                            onClick={() => {
                                                mettreAJourDateDerniereAction();
                                                onImageRemoveAll();
                                                retirerPhoto(image);
                                            }} className={"width-100"}
                                    />

                                </div>))
                            :
                            <div  {...dragProps}
                                  className={isDragging ? "fr-responsive-img fa-photo-arme fa-photo-arme-dragging" : "fr-responsive-img fa-photo-arme"}
                                  onClick={onImageUpload}>

                                <img src={imageVide} alt={"Photo " + position}
                                     className={"fr-responsive-img"}/>
                                <Bouton type={"button"} label={"Ajouter"}
                                        icone={"fr-icon-add-line"}
                                        niveau={"tertiaire-sans-bordure"}
                                        iconePosition={"left"} taille="sm"
                                        className={"fa-photo-arme--bouton"}
                                />
                            </div>

                        }
                    </div>)}
                </ReactImageUploading>
            }

            {position && <Row alignementHorizontal={"center"}>
                <Col col={12}>
                    <p className={"texte-centre texte-italique"}>{calculerLibelle()} </p>
                </Col>
            </Row>
            }
        </>
    }

    return <>
        <Container className={"fa-surcharge-container-DSFR"}>
            <Row alignementHorizontal={"left"}>
                <div className="fiche-arme--sous-titre">
                    Photographies
                </div>
            </Row>
            <Row alignementHorizontal={"center"}>
                <Col col={12}>
                    <div className={"fa-photo-groupe"}>
                        <div className={"fa-photo-groupe-colonne texte-gauche"}>
                            <SousTitre libelle={"Profils"}/>
                        </div>
                        <div
                            className={"fa-photo-groupe-colonne texte-gauche"}>
                            <SousTitre libelle={"Gros plans des marquages"}/>
                        </div>
                        {
                            positionImage.map((position) => <div key={position} className={"fa-photo-groupe-colonne"}>
                                    {afficherImage(position)}
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </Container>
        {contenuModalePhoto && modalePhoto()}
    </>;
}

export default PhotosFicheArme;
import {Col, Row} from "@design-system-etat/dsfr-react";
import {ChipBouton} from "../../../composants/chip/Chip";
import Alerte, {typeAlerte} from "../../../entites/Alerte";
import {DateUtils} from "../../../utils/DateUtils";
import React from "react";
import Arme from "../../../entites/Arme";
import {useAlerteContext} from "../../../composants/RGAContext";

interface BandeauInformatifProps {
    arme?: Arme,
    createur?: string,
    idDemande?:string
}

const BandeauInformatif: React.FC<BandeauInformatifProps> = (props) => {
    const {arme, createur, idDemande} = props;
    const {mettreAjourAlerte} = useAlerteContext()


    return idDemande ?
        <Row className={"ligne-bleu"} alignementVertical={"middle"}>
            <Col className={"vertical-align-middle"}>
                <p className={"fiche-arme--titre"}> {"Demande n° : "} <b>{idDemande}</b></p>
                {createur && <p className={" fr-ml-6w fiche-arme--titre"}> {"Demandeur : "} <b>{createur}</b></p>}
            </Col>
        </Row>
        :
        <Row className={"ligne-bleu"} alignementVertical={"middle"}>
        <Col col={6} className={"vertical-align-middle"}>
            {arme?.sia &&
                <>
                    <h4 className={"fiche-arme--titre"}> {"Référence : " + arme.sia}</h4>
                    <ChipBouton libelle={"Copier Réf. RGA"}
                                identifiant={"fa-copier-ref"}
                                onClick={() => navigator.clipboard.writeText(arme.sia)
                                    .then(() => mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "Référence RGA copié dans le presse papier")))
                                }/>
                </>
            }
        </Col>
        <Col col={6} className={"texte-droite"}>
            <Row goutierre alignementHorizontal={"right"}>
                <div className={"white-space-desactive"}>
                    {arme?.dateCreation && "Date d'ajout au RGA : " + DateUtils.convertirDateEnString(arme.dateCreation)}
                    {"   "}
                    <br/>
                    {arme?.dateMiseAJour && "Modifiée le : " + DateUtils.convertirDateEnString(arme.dateMiseAJour)}
                </div>
            </Row>
        </Col>
    </Row>;
}
export default BandeauInformatif;
export enum typeAlerte {
    ERREUR = 'error',
    REUSSITE = 'success',
    ATTENTION = 'warning',
    INFORMATION = 'info'
}

export default class Alerte {
    type: typeAlerte;
    titre: string;
    message: string;

    constructor(type: typeAlerte, titre: string, message: string) {
        this.type = type;
        this.titre = titre;
        this.message = message;
    }
}
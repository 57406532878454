import React, {FC} from "react";
import {UtilisateurLite} from "../../../entites/Utilisateur";
import {Input, Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import {useForm} from "react-hook-form";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import {Bouton} from "@design-system-etat/dsfr-react";
import {fr} from "../../../constantes/ClassesDSFR";

interface ModaleModifierUtilisateurProps {
    utilisateur: UtilisateurLite,
    onValiderClick: (utilisateur: UtilisateurLite) => void,
    onAnnulerClick: () => void,
    onFermerClick: () => void
}

export const ModaleModifierUtilisateur: FC<ModaleModifierUtilisateurProps> = ({utilisateur, onValiderClick, onAnnulerClick, onFermerClick}) => {

    const form = useForm<UtilisateurLite>({mode: 'onChange', defaultValues: utilisateur});

    return <>
        <Modale
            closeCallback={onFermerClick}
            id="modifier-utilisateur"
        >
            <ModaleTitre idModale="modifier-utilisateur">
                Gestion d'un utilisateur
            </ModaleTitre>
            <ModaleContent>
                <form onSubmit={form.handleSubmit(onValiderClick)}>
                    <Input
                        label={"Nom"}
                        name="nom"
                        error={form.formState.errors.nom}
                        {...form.register('nom', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 50, message: MessagesErreurEnum.LONGUEUR_MAX_50},
                            pattern: {value: /^[A-Za-zÀ-ÿ' -]+$/, message: MessagesErreurEnum.NON_AUTORISE}
                        })}
                    />
                    <Input
                        label={"Prénom"}
                        name="prenom"
                        error={form.formState.errors.prenom}
                        {...form.register('prenom', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 50, message: MessagesErreurEnum.LONGUEUR_MAX_50},
                            pattern: {value: /^[A-Za-zÀ-ÿ' -]+$/, message: MessagesErreurEnum.NON_AUTORISE}
                        })}
                    />
                    <Input
                        label={"Société"}
                        name="societe"
                        error={form.formState.errors.societe}
                        {...form.register('societe', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 200, message: MessagesErreurEnum.LONGUEUR_MAX_200},
                        })}
                    />
                    <Input
                        label={"Siren"}
                        name="siren"
                        type={"number"}
                        error={form.formState.errors.siren}
                        {...form.register('siren', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 9, message: MessagesErreurEnum.NEUF_CHIFFRES},
                            minLength: {value: 9, message: MessagesErreurEnum.NEUF_CHIFFRES}
                        })}
                    />
                    <Input
                        label={"Mail"}
                        name="mail"
                        error={form.formState.errors.mail}
                        {...form.register('mail', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            pattern: {value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: MessagesErreurEnum.MAIL}
                        })}
                    />
                    <div className={"d-flex flex-end"}>
                        <Bouton
                            label={"Annuler"}
                            className={fr.mr_2w}
                            niveau="secondaire"
                            onClick={onAnnulerClick}
                        />
                        <Bouton
                            label={"Valider"}
                            niveau="primaire"
                            onClick={() => {}}
                            type="submit"
                        />
                    </div>

                </form>
            </ModaleContent>

        </Modale>
    </>

}


import React from "react";
import BaseMenuCarte from "./BaseMenuCarte";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import {Link} from "react-router-dom";
import {URLPageEnum} from "../../../../constantes/URLPageEnum";
import contact from "@gouvfr/dsfr/dist/artwork/pictograms/document/contract.svg";
import {UtilisateurProfilCategorieEnum} from "../../../../constantes/UtilisateurProfilCategorieEnum";
import {settings5Fill, Tag, upload2Fill} from "@design-system-etat/dsfr-react/lib/cjs";
import Pictogram from "../../../../entites/Pictogramme";
import {useAlerteContext} from "../../../../composants/RGAContext";
import {FicheArmeActionEnum} from "../../../../constantes/FicheArmeActionEnum";


interface MenuDemandeProp {
    profil: UtilisateurProfilCategorieEnum
    demandeEnAttenteDeTraitement: number
    nombreDemandeProfilPro:number
}


const MenuDemande: React.FC<MenuDemandeProp> = (props) => {

    const {profil} = props;
    const {mettreAJourDateDerniereAction} = useAlerteContext();

    const pictoDemandes: Pictogram = {
        svg: contact,
        alt: "pictogram export"
    };
    const descriptionRechercheArme = "Vous avez la possibilité de traiter les demandes de création et importer des demandes en masse.";


    function boutonTraiterDemande() {
        return <Row goutierre alignementHorizontal="left">
            <Col className="testTagDemandeContainer">
                <Link to={URLPageEnum.CONSULTER_DEMANDES_ARMES} onClick={mettreAJourDateDerniereAction} >
                    <Bouton
                        icone="fr-icon-questionnaire-fill"
                        iconePosition="left"
                        label="Traiter les demandes"
                        niveau="primaire"
                        className="fullSizeButton"
                        id="bouton-traiter-demandes"
                    />
                </Link>
                <span className="testTagDemande"><Tag label={props.demandeEnAttenteDeTraitement.toString()} taille="sm"/></span>
            </Col>
        </Row>;
    }

    function boutonProfessionel() {
        return <>
            <Row goutierre alignementHorizontal="left">
                <Col col={4}>
                    <Link to={URLPageEnum.DEMANDE_ARME + "/" + FicheArmeActionEnum.CREER} onClick={mettreAJourDateDerniereAction}>
                        <Bouton
                            id="bouton-creation-demande"
                            icone="fr-icon-file-download-fill"
                            iconePosition="left"
                            label="Créer"
                            niveau="primaire"
                            className="fullSizeButton"/>
                    </Link>
                </Col>
                <Col col={8} className="testTagDemandeContainer">
                    <Link to={URLPageEnum.CONSULTER_DEMANDES_ARMES} onClick={mettreAJourDateDerniereAction}>
                        <Bouton
                            id="bouton-mes-demandes"
                            icone={settings5Fill}
                            iconePosition="left"
                            label="Mes demandes"
                            niveau="primaire"
                            className="fullSizeButton"/>
                    </Link>
                    <span className="testTagDemande"><Tag label={props.nombreDemandeProfilPro.toString()} taille="sm"/></span>
                </Col>
            </Row>
            <Row goutierre alignementHorizontal="left">
                <Col col={12}>
                    <Link to={URLPageEnum.IMPORT_MASSE_DEMANDE} onClick={mettreAJourDateDerniereAction}>
                        <Bouton
                            id="bouton-demande-masse"
                            icone={upload2Fill}
                            iconePosition="left"
                            label="Importer en masse"
                            niveau="primaire"
                            className="fullSizeButton"/>
                    </Link>
                </Col>
            </Row>
        </>
    }

    function calculerBouttonVisible() {
        return (profil === UtilisateurProfilCategorieEnum.SCAE) ? boutonTraiterDemande() : boutonProfessionel();
    }

    function calculerTitre() {
        const titreDemandesPro: string = "Mes demandes";
        const titreDemandesScae: string = "Les demandes";
        return profil === UtilisateurProfilCategorieEnum.SCAE ? titreDemandesScae : titreDemandesPro
    }

    return <BaseMenuCarte
        id={"menu-demande"}
        titre={calculerTitre()}
        titrePicto={pictoDemandes}
        bouton={calculerBouttonVisible()}
        description={descriptionRechercheArme}
    />

}


export default MenuDemande;

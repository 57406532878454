export class PageTableau<T> {
    elements: T[];
    nombreElementsTotal: number;
    nombrePages: number;

    constructor(elements, nombreElementsTotal, nombreElementsDemandes){
        this.elements = elements;
        this.nombreElementsTotal = nombreElementsTotal;
        const nombrePages = Math.floor(nombreElementsTotal / nombreElementsDemandes);
        const resteSurNombrePages = !!(nombreElementsTotal % nombreElementsDemandes);
        this.nombrePages = resteSurNombrePages ? nombrePages + 1 : nombrePages ;
    }
}
export function convertirStringEnDate(date: string): Date {
    return new Date(Date.parse(date));
}

export function auFormat2Caracteres(valeur: number){
    if(valeur < 10){
        return '0' + valeur
    } else {
        return valeur
    }
}

export function genererDateActuellePourCsv() {
    const dateActuelle = new Date()
    const annee = String(dateActuelle.getFullYear())
    const mois = auFormat2Caracteres(dateActuelle.getMonth() + 1)
    const jour = auFormat2Caracteres(dateActuelle.getDate())
    const heure = auFormat2Caracteres(dateActuelle.getHours())
    const minute = auFormat2Caracteres(dateActuelle.getMinutes())
    const seconde = auFormat2Caracteres(dateActuelle.getSeconds())
    return annee + mois + jour + heure + minute + seconde
}

export function determineSiDateEstPasse(dateReference: Date): boolean {
    return dateReference < new Date(Date.now());
}

function convertirDateEnString(date: Date) {
    return new Date(date).toLocaleDateString('fr-FR')
}

export const DateUtils = {
    convertirDateEnString
}

import axios from "axios";
import {RgaAppliAPI, SessionService} from "../InfrastructureFactory";
import history from "../../utils/navigation/History"
import {ConnexionAPIEnum} from "../../constantes/ConnexionAPIEnum";


const API = axios.create({baseURL:window.BACKEND_URL});

API.interceptors.request.use(async function (config) {

    if (SessionService.estConnecter()) {
        if (SessionService.estSessionExpirer() && SessionService.estCompteSSO() && !config.url.includes(ConnexionAPIEnum.LOGIN_SSO)) {
            await RgaAppliAPI.connexionSSO().then(token => {
                SessionService.stockerToken(token);
                config.headers.authorization = "Bearer " + token;
                return config;
            })
        }
        config.headers.authorization = "Bearer " + SessionService.getToken();
    }

    return config;


}, function (error) {
    return Promise.reject(error);
});

API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response?.data?.code === 401) {
        console.warn("Vous êtes déconnecté, redirection vers l'écran de connexion.")
        history.replace("/");
        // TODO : a chaque appel api, gérer le promise reject
    }
    return Promise.reject(error);


});

export default API;
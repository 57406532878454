import {UtilisateurDroitEnum} from "../../constantes/UtilisateurDroitEnum";
import {SessionService} from "../../infrastructure/InfrastructureFactory";
import {Navigate, Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";

interface RouteProtegeProps {
    droitsRequis?: UtilisateurDroitEnum[],
    children?: any
}

export default function RouteProtege({droitsRequis, children}: RouteProtegeProps) {
    const {mettreAjourAlerte} = useAlerteContext();
    const [page, setPage] = useState(null);

    useEffect(() => {
        if (SessionService.estConnecter()) {
            if (droitsRequis) {
                const droitsUtilisateur = SessionService.getUtilisateurDroits();
                if (droitsUtilisateur && droitsUtilisateur.length > 0) {
                    if (droitsRequis.every((droit) => droitsUtilisateur.includes(droit))) {
                        setPage(children ? children : <Outlet/>)
                    } else {
                        console.error("Droits insuffisants.")
                        mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Droits insuffisants."))
                        setPage(<Navigate to="/" replace/>)
                    }
                } else {
                    console.error("L'utilisateur n'a aucun droit.")
                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Vous n'avez pas les droits nécessaires pour accéder à cette application."))
                    setPage(<Navigate to="/" replace/>)
                }
            } else {
                setPage(children ? children : <Outlet/>)
            }
        } else {
            console.warn("L'utilisateur n'est pas connecté.")
            mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "Vous êtes déconnecté.", "Redirection vers l'écran de connexion."))
            setPage(<Navigate to="/" replace/>)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>{page}</>
}

export enum UtilisateurProfilLibelleEnum {
    MOA = "SCAE MOA",
    CHEF_EXPERTISE = "SCAE CHEF EXPERTISE",
    AGENT = 'SCAE AGENT',
    SSI = 'SCAE SSI',
    ROLE_PROFESSIONNEL = 'PROFESSIONNEL',
    DGDDI = 'DGDDI',
    GN = 'GN',
    PN = 'PN',
    PREF ='PREF',
    DETENTEUR = "detenteur"
}

import React, {useCallback, useEffect, useState} from "react";
import {Tableau, TableauBody, TableauCell, TableauHeader, TableauRow} from "@design-system-etat/dsfr-react/lib/cjs";
import {TableauHeaderCellFiltres} from "../../composants/TableauHeaderCellFiltres";
import './EcranConsulterLesTraces.css'
import Titre from "../../composants/Titre";
import PaginationRGA from "../../composants/PaginationRGA";
import {FiltreAPI} from "../../entites/FiltreAPI";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import debounce from "lodash.debounce";
import {Bouton, Col, Container, Row, TABLEAU_ORDRE_TRI_DESC} from "@design-system-etat/dsfr-react";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {MessageGenerique} from "../../constantes/MessageGenerique";
import dateFormat from "dateformat";
import {Trace} from "../../entites/Trace";
import {UtilisateurProfilLibelleEnum} from "../../constantes/UtilisateurProfilLibelleEnum";
import {ParametresTechniques} from "../../constantes/ParametresTechniques";

const actionSelectOptions = [
    "Activation de l'utilisateur",
    "Ajout de la demande",
    "Authentification SSO réussie",
    "Authentification standard réussie",
    "Blocage de l'utilisateur",
    "Connexion à un compte bloqué",
    "Création d’un référentiel",
    "Création de compte",
    "Création de l’arme",
    "Déblocage de l'utilisateur",
    "Déconnexion réussie",
    "Demande d'informations complémentaires",
    "Enregistrement de la demande",
    "Import de demandes",
    "Modification d’un référentiel",
    "Modification de l’arme",
    "Modification de l’utilisateur",
    "Modification des droits utilisateur",
    "Modification du mot de passe",
    "Refus de la demande",
    "Renouvellement automatique du mot de passe",
    "Renouvellement du mot de passe",
    "Soumission de la demande",
    "Suppression de la demande",
    "Suppression logique de la demande",
    "Tentative de connexion avec un mauvais mot de passe",
    "Tentative de connexion avec un mauvais mot de passe sur un compte",
    "Tentative de connexion sur un compte inexistant"
]


const EcranConsulterLesTraces: React.FC = () => {
    const [traces, setTraces] = useState<Trace[]>([])
    const [filtres, setFiltres] = useState({})
    const [tri, setTri] = useState({})
    const [nombrePages, setNombrePages] = useState<number>(1);
    const [pageCourante, setPageCourante] = useState<number>(1);
    const [elementsParPage, setElementsParPage] = useState<number>(25)
    const [isLoading, setIsLoading] = useState(true)
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();

    useEffect(() => {
        updateTri('date', TABLEAU_ORDRE_TRI_DESC)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPageCourante(1)
        debounceRecupererTraces(filtres, tri, 1, elementsParPage)
    }, [filtres, tri])// eslint-disable-line react-hooks/exhaustive-deps

    function formaterFiltres(filtres: object, tri: object): FiltreAPI[] {
        let filtresEnvoyes = [] as FiltreAPI[]

        const mergedObjects = {...filtres, ...tri}
        Object.keys(mergedObjects).forEach((key) => {
                if (mergedObjects[key]) {
                    filtresEnvoyes.push(
                        {cle: key, valeur: mergedObjects[key]} as FiltreAPI
                    )
                }
            }
        )
        return filtresEnvoyes
    }

    function recupererTraces(filtresRecu, triRecu, pageDemandee: number, elementsParPageRecu: number) {
        setIsLoading(true)
        const filtresFormattees = formaterFiltres(filtresRecu, triRecu)
        RgaAppliAPI.recupererTraces(elementsParPageRecu, pageDemandee, filtresFormattees)
            .then((pageTableau) => {
                setTraces(pageTableau.elements)
                setPageCourante(pageDemandee)
                setNombrePages(pageTableau.nombrePages)
                setIsLoading(false)
            })
            .catch(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "Erreur", MessageGenerique.REQUETE_EN_ERREUR))
            })
    }

    const debounceRecupererTraces = (useCallback(debounce((filtresRecu, triRecu, pageDemandee: number, elementsParPageRecu: number) => { // eslint-disable-line react-hooks/exhaustive-deps
        recupererTraces(filtresRecu, triRecu, pageDemandee, elementsParPageRecu);
    }, ParametresTechniques.DUREE_DEBOUNCE), []))

    function updateFiltre(cle: string, valeur: string) {
        mettreAJourDateDerniereAction();
        setFiltres({...filtres, [cle]: valeur})
    }

    function updateTri(cle: string, valeur: string) {
        mettreAJourDateDerniereAction();
        setTri({["order[" + cle + "]"]: valeur})
    }

    function exporterTraces(filtresRecu, triRecu) {
        mettreAJourDateDerniereAction();
        const filtresFormattees = formaterFiltres(filtresRecu, triRecu)
        RgaAppliAPI.exporterTraces(filtresFormattees)
            .then((message) => {
                if (message)
                    mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "Attention", message));
            })
            .catch((message) => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", message))
            })
    }

    return (
        <div className={"tableauTraces"}>
            <Titre>Consulter la traçabilité</Titre>
            <Tableau avecBordure caption={""}>
                <TableauHeader>
                    <TableauRow>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            onChange={updateFiltre}
                            idColonne={"utilisateur.nom"}
                            ordreTriValeur={tri['order[utilisateur.nom]']}
                            onClick={updateTri}
                            key={"nom"}

                        >
                            Nom
                        </TableauHeaderCellFiltres>

                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            onChange={updateFiltre}
                            idColonne={"utilisateur.prenom"}
                            ordreTriValeur={tri['order[utilisateur.prenom]']} onClick={updateTri}
                            key={"prenom"}
                        >
                            Prénom
                        </TableauHeaderCellFiltres>

                        <TableauHeaderCellFiltres
                            typeFiltre="select"
                            onChange={updateFiltre}
                            idColonne={"utilisateur.profil.libelle"}
                            ordreTriValeur={tri['order[utilisateur.profil.libelle]']}
                            selectOptions={Object.values(UtilisateurProfilLibelleEnum)}
                            onClick={updateTri}
                            key={"profil"}
                        >
                            Profil
                        </TableauHeaderCellFiltres>

                        <TableauHeaderCellFiltres
                            typeFiltre="select"
                            onChange={updateFiltre}
                            idColonne={"message"}
                            ordreTriValeur={tri['order[message]']}
                            selectOptions={actionSelectOptions}
                            onClick={updateTri}
                            key={"action"}
                        >
                            Action
                        </TableauHeaderCellFiltres>

                        <TableauHeaderCellFiltres
                            onChange={updateFiltre}
                            idColonne={"date"}
                            ordreTriValeur={tri['order[date]']}
                            typeFiltre="date"
                            onClick={updateTri}
                            key={"date"}
                            className={"longueurTableau"}
                        >
                            Date action
                        </TableauHeaderCellFiltres>

                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            onChange={updateFiltre}
                            idColonne={"cibleTable"}
                            ordreTriValeur={tri['order[cibleTable]']}
                            onClick={updateTri}
                            key={"cibleTable"}
                        >
                            Table impactée
                        </TableauHeaderCellFiltres>

                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            onChange={updateFiltre}
                            idColonne={"cibleId"}
                            ordreTriValeur={tri['order[cibleId]']}
                            onClick={updateTri}
                            key={"cibleId"}
                        >
                            Identifiant cible
                        </TableauHeaderCellFiltres>
                    </TableauRow>
                </TableauHeader>



                <TableauBody>
                    {isLoading ? <tr key={"loading"}>
                        <td colSpan={7} className={"loadingTd"}>
                            <div className={"loader-container"}>
                                <div className={"lds-dual-ring"}></div>
                            </div>
                        </td>
                    </tr> : (traces.map((trace, index) => {
                        return (
                            <TableauRow className={"tableauTracesRow"} key={index}>
                                <TableauCell key={"nom" + trace.cibleId}>
                                    {trace.utilisateur?.nom}
                                </TableauCell>
                                <TableauCell key={"prenom" + trace.cibleId}>
                                    {trace.utilisateur?.prenom}
                                </TableauCell>
                                <TableauCell key={"profil" + trace.cibleId}>
                                    {trace.utilisateur?.profil?.libelle}
                                </TableauCell>
                                <TableauCell key={"action" + trace.cibleId}>
                                    {trace.message}
                                </TableauCell>
                                <TableauCell key={"date" + trace.cibleId}>
                                    <>{dateFormat(trace.date, "dd/mm/yyyy HH:MM:ss")}</>
                                </TableauCell>
                                <TableauCell key={"cibleTable" + trace.cibleId}>
                                    {trace.cibleTable}
                                </TableauCell>
                                <TableauCell key={"cibleId" + trace.cibleId}>
                                    {trace.cibleId}
                                </TableauCell>
                            </TableauRow>
                        )
                    }))}
                </TableauBody>
            </Tableau>

            <Container margeExterne={false}>
                <Row>
                    <Col col={10}>
                        <PaginationRGA
                            pageCourante={pageCourante}
                            totalPages={nombrePages}
                            changePage={pageDemandee => recupererTraces(filtres, tri, pageDemandee, elementsParPage)}
                            onElementsParPageChange={elementsParPageRecu => {
                                setElementsParPage(elementsParPageRecu)
                                setPageCourante(1)
                                recupererTraces(filtres, tri, 1, elementsParPageRecu)
                            }}
                            elementsParPageParDefaut={elementsParPage}
                            optionElementsParPage={[10, 25, 50, 100]}/>
                    </Col>
                    <Col col={2}>
                        <div className={"boutonExporter"}>
                            <Bouton label={"Exporter"} niveau="primaire" onClick={() => exporterTraces(filtres, tri)}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EcranConsulterLesTraces;
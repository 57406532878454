import React from "react";
import {Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import "./ModaleTypeCanon.css"
import canon2_lisse_1 from "../../../resources/recherche-simplifiee/2canons/canon_2lissesH.svg";
import canon2_lisse_2 from "../../../resources/recherche-simplifiee/2canons/canon_2lissesV.svg";
import canon2_rayes_1 from "../../../resources/recherche-simplifiee/2canons/canon_2rayesV.svg";
import canon2_rayes_2 from "../../../resources/recherche-simplifiee/2canons/canons_2rayesH.svg";
import canon2_lisse_raye_1 from "../../../resources/recherche-simplifiee/2canons/canon_1lisse_1rayeB.svg";
import canon2_lisse_raye_2 from "../../../resources/recherche-simplifiee/2canons/canon_1lisse_1rayeD.svg";
import canon2_lisse_raye_3 from "../../../resources/recherche-simplifiee/2canons/canon_1lisseB_1raye.svg";
import canon2_lisse_raye_4 from "../../../resources/recherche-simplifiee/2canons/canon_1lisse_1rayeH.svg";
import ExempleTypeCanonModale from "./ExempleTypeCanonModale";

interface Modale1CanonProps {
    onFermerClick?: () => void
}

const Modale1Canon : React.FC<Modale1CanonProps> = ({onFermerClick}) => {
    return (<Modale id={"modal"}  taille={"lg"} closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>2 canons / exemples</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-around"}>
                <ExempleTypeCanonModale titre={"2 lisses"} image1={canon2_lisse_1} image2={canon2_lisse_2}/>
                <ExempleTypeCanonModale titre={"2 rayés"} image1={canon2_rayes_1} image2={canon2_rayes_2}/>
            </span>
            <span className={"d-flex flex-center"}>
                <ExempleTypeCanonModale titre={"1 lisse + 1 rayé"} image1={canon2_lisse_raye_1} image2={canon2_lisse_raye_2} image3={canon2_lisse_raye_3} image4={canon2_lisse_raye_4}/>
            </span>
        </ModaleContent>
    </Modale>)
}

export default Modale1Canon;
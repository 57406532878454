import React, {useContext, useState} from "react";
import {PageTableau} from "../entites/PageTableau";
import Arme from "../entites/Arme";
import {CriteresRechercheSimplifiee, CriteresRechercheSimplifieeInitial} from "../entites/CriteresRechercheArme";
import {ReferentielsOptions} from "../entites/Referentiels/Referentiels";

type RechercheSimplifieeContextType = {
    criteres: CriteresRechercheSimplifiee;
    mettreAJourCriteres: (criteres: CriteresRechercheSimplifiee) => void;
    referentiels: ReferentielsOptions;
    mettreAJourReferentiels: (referentiels: ReferentielsOptions) => void;
    rechercheEnCours: boolean;
    mettreAJourRechercheEnCours: (rechercheEnCours: boolean) => void;
    resultats: PageTableau<Arme>
    mettreAJourResultats: (resultats: PageTableau<Arme>) => void
};

export const RechercheSimplifieeContext = React.createContext<null | RechercheSimplifieeContextType>(null);

export function useRechercheSimplifieeContext() {
    const context = useContext(RechercheSimplifieeContext);
    if (context === null) {
        throw new Error(
            "useRechercheSimplifieeContext doit être utilisé dans un RechercheSimplifieeContextProvider"
        );
    }
    return context;
}

export function RechercheSimplifieeContextProvider(props: Record<string, any>) {

    const [criteres, setCriteres] = useState<CriteresRechercheSimplifiee>(CriteresRechercheSimplifieeInitial)
    const [referentiels, setReferentiels] = useState<ReferentielsOptions>();
    const [rechercheEnCours, setRechercheEnCours] = useState<boolean>(false);
    const [resultats, setResultats] = useState<PageTableau<Arme>>({elements: [], nombreElementsTotal: 0, nombrePages: 0});

    function mettreAJourCriteres(criteres: CriteresRechercheSimplifiee) {
        setCriteres(criteres);
    }

    function mettreAJourReferentiels(referentiels: ReferentielsOptions) {
        setReferentiels(referentiels);
    }

    function mettreAJourRechercheEnCours(rechercheEnCours: boolean) {
        setRechercheEnCours(rechercheEnCours);
    }

    function mettreAJourResultats(resultats: PageTableau<Arme>) {
        setResultats(resultats);
    }

    return (
        <RechercheSimplifieeContext.Provider
            value={{
                criteres,
                mettreAJourCriteres,
                referentiels,
                mettreAJourReferentiels,
                rechercheEnCours,
                mettreAJourRechercheEnCours,
                resultats,
                mettreAJourResultats
            }}
            {...props}
        />
    );
}

import React from "react";
import Arme from "../../../entites/Arme";
import {Col, Row} from "@design-system-etat/dsfr-react";

interface CaracteristiqueLectureSeuleProps {
    arme: Arme
}

const CaracteristiqueLectureSeule: React.FC<CaracteristiqueLectureSeuleProps> = (props) => {
    const {arme} = props;

    function afficherSousTitre(sousTitre: string) {
        return <span className="texte-desactive-gris">
            {sousTitre}
        </span>;
    }

    return <>
            <Row >
                <Col col={4}>
                    {afficherSousTitre("Famille")}
                </Col>
                <Col col={4}>
                    {afficherSousTitre("Type d'arme")}
                </Col>
                <Col col={4}>
                    {afficherSousTitre("Pays fabricant")}
                </Col>
            </Row>
            <Row >
                <Col col={4}>
                    <p id="fa-caracteristique-famille">
                        {arme?.famille?.libelle}
                    </p>
                </Col>
                <Col col={4}>
                    <p id="fa-caracteristique-type">
                        {arme?.typeArme?.libelle}
                    </p>
                </Col>
                <Col col={4}>
                    <p id="fa-caracteristique-pays">
                        {arme?.pays?.libelle}
                    </p>
                </Col>
            </Row>
            <br/>
        <Row>
                <Col col={4}>
                    {afficherSousTitre("Marque")}
                </Col>
                <Col col={4}>
                    {afficherSousTitre("Modèle")}
                </Col>
                <Col col={4}>
                    {afficherSousTitre("Fabricant")}
                </Col>
            </Row>
        <Row>
                <Col col={4}>
                    <p id="fa-caracteristique-marque">
                        {arme?.marque?.libelle ?? arme.marqueSaisie}
                    </p>
                </Col>
                <Col col={4}>
                    <p id="fa-caracteristique-modele">
                        {arme?.modele?.libelle ?? arme.modeleSaisie}
                    </p>
                </Col>
                <Col col={4}>
                    <p id="fa-caracteristique-fabricant">
                        {arme?.fabricant?.libelle ?? arme.fabricantSaisie}
                    </p>
                </Col>
            </Row>
            <br/>
        <Row alignementVertical={"bottom"}>
                <Col col={4}>
                    {afficherSousTitre("Mode de fonctionnement")}
                </Col>
                <Col col={4}>
                    {afficherSousTitre("Système d'alimentation")}
                </Col>
                <Col col={2}>
                    {afficherSousTitre("Capacité")}
                </Col>
                <Col col={2} >
                    <div className={"fa-caracteristique-longueur__padding-pdf"}>
                                            {afficherSousTitre("Longueur de l'arme")}
                    </div>
                </Col>
            </Row>
        <Row>
                <Col col={4}>
                    <p id="fa-caracteristique-mode">
                        {arme?.modeFonctionnement?.libelle}
                    </p>
                </Col>
                <Col col={4}>
                    <p id="fa-caracteristique-alimentation">
                        {arme?.modeAlimentation?.libelle}
                    </p>
                </Col>
                <Col col={2}>
                    <p id="fa-caracteristique-capacité">
                        {(arme?.capaciteHorsChambre + arme?.capaciteChambre) + " = " + arme?.capaciteHorsChambre + " + " + arme?.capaciteChambre}
                    </p>
                </Col>
                <Col col={2}>
                    <p id="fa-caracteristique-longueur " className={"fa-caracteristique-longueur__padding-pdf"}>
                        {arme?.longueur + " mm"}
                    </p>
                </Col>
            </Row>
    </>;
}

export default CaracteristiqueLectureSeule;
export enum MessageGenerique {
    MISE_A_JOUR_VALIDE = "Mise à jour effectuée avec succès",
    REQUETE_EN_ERREUR = "La requête n'a pu aboutir, veuillez contacter un administrateur du site via le lien « Nous contacter ».",
    ARME_DEJA_EXISTANTE = "L’arme que vous souhaitez ajouter existe déjà dans le Référentiel Général des Armes. Vous ne pouvez pas l’ajouter à nouveau.",
    CONNEXION_INFRUCTUEUSE = "Suite à plusieurs tentatives de connexions infructueuses, un nouveau mot de passe temporaire vous a été envoyé par mail",
    COMPTE_BLOQUE="Votre compte est bloqué, veuillez contacter un administrateur du site via le lien « Nous contacter ».",
    IDENTIFIANT_INCORRECT="Identifiants incorrects, veuillez essayer de vous reconnecter en vérifiant vos identifiants.",
    AUCUNE_ARME_TROUVE="Aucune arme ne correspond à cette référence RGA",
    ANCIEN_MDP_INCORRECT="Ancien mot de passe incorrect.",
    MDPS_IDENTIQUES = "Le nouveau mot de passe doit être différent de l’ancien.",
    MDPS_PAS_IDENTIQUES = "Erreur lors de la double saisie du nouveau mot de passe.",
    MDP_PAS_CORRECT = "Le mot de passe doit contenir au moins 10 caractères avec à minima une majuscule, une minuscule, un chiffre et un caractère spécial.",
    NOUVEAU_COMPTE = "Votre compte n'a pas encore été validé. Vous ne pouvez pas renouveler votre mot de passe.",
    UTILISATEUR_NON_TROUVE = "Le mail renseigné ne correspond à aucun compte. Si vous possédez un compte, veuillez vérifier les informations saisies. Si ce n’est pas le cas, veuillez créer un compte."
}
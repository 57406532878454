import {ReferentielsConstantes} from "../../../constantes/ReferentielsConstantes";
import {Bouton} from "@design-system-etat/dsfr-react";
import {Link} from "react-router-dom";
import {URLPageEnum} from "../../../constantes/URLPageEnum";
import React from "react";
import {useParams} from "react-router";
import PropTypes from "prop-types";

ListeDesReferentiels.propTypes = {
    onClick: PropTypes.func,
}

export default function ListeDesReferentiels(props): JSX.Element {


    const params = useParams()
    const referentiel = params.referentiel ?? ""
    const button = <Bouton niveau="primaire"
                           id = "bouton-modale"
                           icone="fr-icon-add-line"
                           className={"iconPlus"}
                           taille={"sm"}
                           onClick={() => {
                               props.onClick(true, false)
                           }}
                           label={""}
                            />


    return (
        <>
            <div className={"leftBar"}>
                <div className={"cell"}>
                    <p style={{fontWeight: "bold"}}>Liste des référentiels</p>
                </div>

                {Object.values(ReferentielsConstantes).map((element) => {
                    const active = referentiel.localeCompare(element.id, "fr", {sensitivity: 'accent'}) === 0
                    const cell =
                        <div className={active ? "active cell" : "cell"} key={element.nom}>
                            <p>{element.nom}</p>
                            {active && element.modale && button}
                        </div>

                    if (!active)
                        return <Link to={URLPageEnum.ADMINISTRATION_REFERENTIELS + "/" + element.id} key={element.nom}>{cell}</Link>
                    else
                        return cell
                })}

            </div>
        </>
    )
}
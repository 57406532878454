import React from "react";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {App} from "./App";
import {createRoot} from "react-dom/client";
import RGARouter from "./utils/navigation/RGARouter";
import rgaHistory from "./utils/navigation/History"
import {RGAContextProvider} from "./composants/RGAContext";
import ScrollTopAuto from "./utils/navigation/ScrollTopAuto";

const root = createRoot(document.getElementById("root")!);

root.render(
    <RGARouter history={rgaHistory}>
        <RGAContextProvider>
            <ScrollTopAuto/>
            <App/>
        </RGAContextProvider>
    </RGARouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

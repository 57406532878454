import React from "react";
import TuileArme from "./TuileArme";
import {Row} from "@design-system-etat/dsfr-react";
import Canon from "../../../entites/Canon";

interface TuileCanonProps {
    canon: Canon
}

const TuileCanon: React.FC<TuileCanonProps> = (props: TuileCanonProps) => {
    const {canon} = props;

    return <TuileArme idTuile={"tuile-canon-" + canon.numCanon} titre={"CANON " + canon.numCanon}>
        <Row>
            <p className="texte-desactive-gris">
                Calibre
            </p>
        </Row>
        <Row className={"canon--ligne-valeur"}>
            <p id="canon-calibre" className={"texte-active-gris"}>
                {canon.calibre?.libelle}
            </p>
        </Row>
        <Row>
            <p className="texte-desactive-gris">
                Mode de percussion
            </p>
        </Row>
        <Row className={"canon--ligne-valeur"}>
            <p id="canon-percussion">
                {canon.modePercussion?.libelle}
            </p>
        </Row>
        <Row>
            <p className="texte-desactive-gris">
                Type
            </p>
        </Row>
        <Row className={"canon--ligne-valeur"}>
            <p id="canon-type">
                {canon.typeCanon?.libelle}
            </p>
        </Row>
        <Row>
            <p className="texte-desactive-gris">
                Longueur du canon
            </p>
        </Row>
        <Row className={"canon--ligne-valeur"}>
            <p id={"canon-longueur"}>
                {canon.longueurCanon + " mm"}
            </p>
        </Row>
    </TuileArme>
}
export default TuileCanon;
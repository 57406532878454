import Arme, {DesignationCommerciale} from "./Arme";
import {SelectOption} from "./Referentiels/SelectOption";


export default interface FormulaireArme {

    "@context"?: string,
    "@id"?: string,
    "@type"?: string,
    id: number,
    visible: boolean,
    sia: string,
    typeArme: SelectOption,
    famille: SelectOption,
    fabricantSaisie: string,
    fabricant: SelectOption,
    pays: SelectOption,
    marque: SelectOption,
    marqueSaisie: string,
    modele: SelectOption,
    modeleSaisie: string,
    capaciteChambre: number,
    capaciteHorsChambre: number,
    longueur: number,
    modeFonctionnement: SelectOption,
    modeAlimentation: SelectOption,
    "energie": any,
    "apparenceArmeGuerre": boolean,
    "prototype": boolean,
    "siren": any,
    "neutralise": boolean,
    classementValideFrance: SelectOption,
    classementValideEurope: SelectOption,
    canons: FormulaireCanon[],
    "designationCommerciales": DesignationCommerciale[],
    "photos": [],//TODO NPO trouver le type
    "dateCreation": Date | null,
    "dateMiseAJour": Date | null
}

export interface FormulaireCanon {
    "numCanon": number,
    "typeCanon": SelectOption,
    "modePercussion": SelectOption,
    "longueurCanon": number,
    "calibre": SelectOption,
    "idGdr": number
}

export const FormulaireArmeInitial: FormulaireArme = {
    id: null,
    visible: true,
    sia: "",
    typeArme: null,
    famille: null,
    fabricantSaisie: null,
    fabricant: null,
    pays: null,
    marque: null,
    marqueSaisie: null,
    modele: null,
    modeleSaisie: null,
    capaciteChambre: null,
    capaciteHorsChambre: null,
    longueur: null,
    modeFonctionnement: null,
    modeAlimentation: null,
    "energie": null,
    "apparenceArmeGuerre": false,
    "prototype": false,
    "siren": null,
    "neutralise": false,
    classementValideFrance: null,
    classementValideEurope: null,
    canons: [{
        numCanon: 1,
        typeCanon: null,
        longueurCanon: 0,
        "idGdr": null,
        modePercussion: null,
        calibre: null,
    } as FormulaireCanon],
    "designationCommerciales": [],
    "photos": [],//TODO NPO trouver le type
    "dateCreation": null,
    "dateMiseAJour": null
}

export function alimenterFormulaireArme(arme: Arme): FormulaireArme {

    let canonsFormulaire = arme.canons ? arme.canons.map(canon => {
        return {
            numCanon: canon.numCanon,
            typeCanon: canon.typeCanon ? new SelectOption(canon.typeCanon.idTypeCanon, canon.typeCanon.libelle, canon.typeCanon) : null,
            longueurCanon: canon.longueurCanon,
            idGdr: null,
            modePercussion: canon.modePercussion ? new SelectOption(canon.modePercussion.idModePercussion, canon.modePercussion.libelle, canon.modePercussion) : null,
            calibre: canon.calibre ? new SelectOption(canon.calibre.idCalibre, canon.calibre.libelle, canon.calibre) : null,
        } as FormulaireCanon
    }) : [] as FormulaireCanon[]

    return {
        id: arme.id,
        visible: arme.visible,
        sia: arme.sia,
        typeArme: arme.typeArme ? new SelectOption(arme.typeArme.idTypeArme, arme.typeArme.libelle, arme.typeArme) : null,
        famille: arme.famille ? new SelectOption(arme.famille.idFamilleArme, arme.famille.libelle, arme.famille) : null,
        fabricantSaisie: arme.fabricantSaisie,
        fabricant: arme.fabricant ? new SelectOption(arme.fabricant.idFabricant, arme.fabricant.libelle, arme.fabricant) : arme.fabricantSaisie ? new SelectOption(arme.fabricantSaisie) : null,
        pays: arme.pays?new SelectOption(arme.pays.id, arme.pays.libelle, arme.pays):null,
        marque: arme.marque ? new SelectOption(arme.marque.idMarque, arme.marque.libelle, arme.marque) : arme.marqueSaisie ? new SelectOption(arme.marqueSaisie) : null,
        marqueSaisie: arme.marqueSaisie,
        modele: arme.modele ? new SelectOption(arme.modele.idModele, arme.modele.libelle, arme.modele) : arme.modeleSaisie ? new SelectOption(arme.modeleSaisie) : null,
        modeleSaisie: arme.modeleSaisie,
        capaciteChambre: arme.capaciteChambre,
        capaciteHorsChambre: arme.capaciteHorsChambre,
        longueur: arme.longueur,
        modeFonctionnement: arme.modeFonctionnement ? new SelectOption(arme.modeFonctionnement.idModeFonctionnement, arme.modeFonctionnement.libelle, arme.modeFonctionnement) : null,
        modeAlimentation: arme.modeAlimentation ? new SelectOption(arme.modeAlimentation.idModeAlimentation, arme.modeAlimentation.libelle, arme.modeAlimentation) : null,
        energie: arme.energie,
        apparenceArmeGuerre: arme.apparenceArmeGuerre,
        prototype: arme.prototype,
        siren: arme.siren,
        neutralise: arme.neutralise,
        classementValideFrance: arme.classementValideFrance ? new SelectOption(arme.classementValideFrance?.idClassementFrance, arme.classementValideFrance?.libelle, arme.classementValideFrance) : null,
        classementValideEurope: arme.classementValideEurope ? new SelectOption(arme.classementValideEurope?.idClassementEurope, arme.classementValideEurope?.libelle, arme.classementValideEurope) : null,
        canons: canonsFormulaire,
        designationCommerciales: arme.designationCommerciales,
        photos: [],
        dateCreation: null,
        dateMiseAJour: null
    } as FormulaireArme


}
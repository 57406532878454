import React from "react";
import {Col, Row} from "@design-system-etat/dsfr-react";
import {UseFormRegister} from "react-hook-form";
import FormulaireArme from "../../../entites/FormulaireArme";

interface PrototypeEtAutomatiqueProps {
    lectureSeul: boolean
    register: UseFormRegister<FormulaireArme>
    prototype: boolean
    apparenceArmeGuerre: boolean
}

const PrototypeEtAutomatique: React.FC<PrototypeEtAutomatiqueProps> = (props: PrototypeEtAutomatiqueProps) => {
    const {lectureSeul, register, prototype, apparenceArmeGuerre} = props;

    function afficherChampArmeAutomatique() {

        return <>
            {lectureSeul ?
                <input disabled={lectureSeul} name="apparenceArmeGuerre" id="apparenceArmeGuerre" type="checkbox"
                       className={"fa-checkbox"}
                       checked={apparenceArmeGuerre}/> :
                <input disabled={lectureSeul} name="apparenceArmeGuerre" id="apparenceArmeGuerre" type="checkbox"
                       className={"fa-checkbox"}
                       {...register("apparenceArmeGuerre")} />}
            <span
                className="texte-desactive-gris"> Arme
                    semi-automatique ayant l'apparence d'une
                    arme
                    automatique</span>
        </>
    }

    function afficherChampPrototype() {

        return <>
            {lectureSeul ? <input disabled={lectureSeul} name="prototype" id="prototype" type="checkbox"
                                  className={"fa-checkbox"}
                                  checked={prototype}/> :
                <input disabled={lectureSeul} name="prototype" id="prototype" type="checkbox"
                       className={"fa-checkbox"}
                       {...register("prototype")} />}
            <span
                className="texte-desactive-gris"> Prototype / échantillon*</span>
        </>

    }

    return <Row
        className={lectureSeul ? "fiche-arme--surcharge-curseur-checkbox-disabled" : ""} alignementVertical={"middle"}>
        <Col col={4} className="fr-fieldset__element">
            {afficherChampPrototype()}
        </Col>
        <Col col={8} className="fr-fieldset__element">
            {afficherChampArmeAutomatique()}
        </Col>
    </Row>

}

export default PrototypeEtAutomatique;
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {ParametreTechnique} from "../../entites/ParametreTechnique";


export function lireParametreTechnique(cle: string) {
    return RgaAppliAPI.recupererParametreTechnique().then((parametres: ParametreTechnique[]) => {
        let valeurParametre: string  ="";
        const parametreCorrespondant = parametres.find(parametre => parametre.idTechnique === cle);
        if (parametreCorrespondant && parametreCorrespondant.valeur) {
            valeurParametre = parametreCorrespondant.valeur;
        } else {
            console.log("Le parametre technique " + cle + " n'existe pas.");
        }
        return valeurParametre;
    })
}

import React, {useCallback, useEffect, useState} from "react"
import {Col, Container, Row} from "@design-system-etat/dsfr-react";
import "./AdministrationDesReferentiels.css";
import Titre from "../../composants/Titre";
import ListeDesReferentiels from "./composants/ListeDesReferentiels";
import TableauReferentiel from "./composants/TableauReferentiel";
import {ReferentielAPIService, RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import debounce from "lodash.debounce";
import ModaleReferentiel from "./composants/ModaleReferentiel";
import {FiltreAPI} from "../../entites/FiltreAPI";
import {useParams} from "react-router";
import {ReferentielConstanteType, ReferentielsConstantes} from "../../constantes/ReferentielsConstantes";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {useAlerteContext} from "../../composants/RGAContext";
import PaginationRGA from "../../composants/PaginationRGA";
import RAHCReferentiel from "./composants/RAHCReferentiel";
import {ParametreTechniqueEnum} from "../../constantes/ParametreTechniqueEnum";

const AdministrationDesReferentiels: React.FC = () => {

    const params = useParams()
    const referentiel: ReferentielConstanteType = Object.values(ReferentielsConstantes).find(item => item.id === params?.referentiel)
    const [contenuTableau, setContenuTableau] = useState([])
    const [isLoading, setIsLoading] = useState({content: false, modale: false})
    const [contenuModale, setContenuModale] = useState({
        isOpen: false,
        modeEdition: false,
        valeurInitiale: {},
        titre: ""
    })
    const [filtres, setFiltres] = useState<FiltreAPI[]>([])
    const [nombrePages, setNombrePages] = useState<number>(1);
    const [pageCourante, setPageCourante] = useState<number>(1);
    const [elementsParPage, setElementsParPage] = useState<number>(25);
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();

    useEffect(() => {
        setPageCourante(1)
    },[referentiel])

    useEffect(() => {
        if(referentiel !== ReferentielsConstantes['RAHC'])
            debounceRecupererContenuTableau(referentiel, filtres, pageCourante, elementsParPage)
    }, [filtres, referentiel])// eslint-disable-line react-hooks/exhaustive-deps

    function recupererContenuTableau(refRecu, filtresRecu: FiltreAPI[], pageDemande: number, elementsParPageRecu : number, avecChargement = true) {
        mettreAJourDateDerniereAction();
        if (avecChargement){
            setIsLoading({...isLoading, content: true})
            setContenuTableau([])
        }
        RgaAppliAPI.recupererReferentielComplet(refRecu.dbName, elementsParPageRecu, pageDemande, filtresRecu)
            .then((pageTableau) => {
                setContenuTableau(pageTableau.elements)
                setPageCourante(pageDemande)
                setNombrePages(pageTableau.nombrePages)
                if(avecChargement){
                    setIsLoading({modale: false, content: false})
                }
            })
    }

    const debounceRecupererContenuTableau = (useCallback(debounce((refRecu, filtresRecu: FiltreAPI[], pageDemande : number, elementsParPageRecu : number) => { // eslint-disable-line react-hooks/exhaustive-deps
        recupererContenuTableau(refRecu, filtresRecu, pageDemande, elementsParPageRecu);
        }, ParametreTechniqueEnum.DUREE_DEBOUNCE), []))

    function remplirModale(isOpen?: boolean, modeEdition?: boolean, modaleContenu?) : void {
        mettreAJourDateDerniereAction();
        const titre = modeEdition ? referentiel.modale['titre']['editer'] : referentiel.modale['titre']["editer"]
        setContenuModale({
            ...contenuModale,
            isOpen: isOpen,
            modeEdition: modeEdition,
            valeurInitiale: modaleContenu,
            titre: titre
        })
    }

    function ouvrirModale(isOpen: boolean) : void {
        mettreAJourDateDerniereAction();
        setContenuModale({...contenuModale, isOpen: isOpen})
    }

    function updateFiltre(filtres: object): void {
        let filtresEnvoyes = [] as FiltreAPI[]
        Object.keys(filtres).forEach((key) => {
                if (filtres[key]) {
                    filtresEnvoyes.push(
                        {cle: key, valeur: filtres[key]} as FiltreAPI
                    )
                }
            }
        )
        setFiltres(filtresEnvoyes)
    }

    function submit(data, modeRequete : "AJOUTER" | "EDITER" | "ACTIVER_STATUS" | "ACTIVER_ARTISAN"): void {
        mettreAJourDateDerniereAction();
        setIsLoading({...isLoading, modale: true})
        Object.keys(data).forEach((champ) => {
            if (typeof data[champ] == "string") {
                let temp = data[champ]
                data = {...data, [champ]: temp.replace(/\s{2,}/g, ' ').trim().toUpperCase()}
            }
        })

        if (modeRequete === "AJOUTER") {
            ReferentielAPIService.addRefElement(referentiel.dbName, data)
                .then(res => {
                    mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", res))
                    ouvrirModale(false)
                })
                .catch(res => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", res.response)))
        }
        else {
            ReferentielAPIService.updateRefElement(referentiel.dbName, data[referentiel.idName], data, modeRequete, contenuModale?.valeurInitiale?.['libelle'] )
                .then(res => {
                    mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", res))
                    ouvrirModale(false)
                })
                .catch(res => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", res)))
        }
        recupererContenuTableau(referentiel, filtres, pageCourante, elementsParPage, (modeRequete === "AJOUTER" || modeRequete === "EDITER"))
    }

    function envoyerRAHC(file:File) {

        mettreAJourDateDerniereAction();
        ReferentielAPIService.importRAHC(file)
            .then((response) => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", response))
            })
            .catch((res) => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, '', res))
            })
    }

    return (
        <>
            <Container className={"mainContainerReferentiel"}>
                <Titre>
                    {
                        referentiel === ReferentielsConstantes.RAHC ?
                        "RAHC (Référentiel des armes historiques et de collection)" :
                        "Référentiels des "+ referentiel.nom
                    }
                </Titre>
                <Row>
                    <Col col={3}>
                        <ListeDesReferentiels onClick={remplirModale}/>
                    </Col>
                    <Col col={9} className={"coteDroit"}>
                        {referentiel === ReferentielsConstantes.RAHC ? <RAHCReferentiel onSubmit={envoyerRAHC}/>
                            :
                            <>

                                <ModaleReferentiel
                                    isOpen={contenuModale.isOpen}
                                    modeEdition={contenuModale.modeEdition}
                                    valeurInitiale={contenuModale.valeurInitiale}
                                    titre={contenuModale.titre}
                                    onClose={() => ouvrirModale(false)}
                                    onClick={submit}
                                    isLoading={isLoading.modale}
                                />


                                <TableauReferentiel
                                    contenu={contenuTableau}
                                    isLoading={isLoading.content}
                                    updateFiltre={updateFiltre}
                                    onClick={remplirModale}
                                    onToggleChange={submit}
                                />

                                <div className={"paginationReferentiel"}>

                                    <PaginationRGA
                                        pageCourante={pageCourante}
                                        totalPages={nombrePages}
                                        changePage={pageDemande => {
                                            recupererContenuTableau(referentiel, filtres, pageDemande, elementsParPage)
                                        }}
                                        elementsParPageParDefaut={elementsParPage}
                                        onElementsParPageChange={elementsParPageRecu => {
                                            setElementsParPage(elementsParPageRecu)
                                            setPageCourante(1)
                                            recupererContenuTableau(referentiel, filtres, 1, elementsParPageRecu)
                                        }}
                                        optionElementsParPage={[10, 25, 50, 100]}
                                    />

                                </div>
                            </>
                        }

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdministrationDesReferentiels
export interface ArmeAEnregistrer {
    "typeArme": string,
    "famille": string,
    "fabricantSaisie": string,
    "marqueSaisie": string,
    "modeleSaisie": string,
    "capaciteChambre": number,
    "capaciteHorsChambre": number,
    "longueur": number,
    "modeFonctionnement": string,
    "modeAlimentation": string,
    "energie": any, //FIXME champ obsolete necessaire pour l'api
    "apparenceArmeGuerre": boolean,
    "neutralise": boolean,
    "prototype": boolean,
    "visible": boolean,
    "classementValideFrance": string,
    "classementValideEurope": string,
    "canons": CanonCreation[],
    "designationCommerciales": DesignationCommercialesCreation[],
    "siren": any, //FIXME champ obsolete necessaire pour l'api
    "pays": string
    "fabricant": string,
    "marque": string,
    "modele": string,
    dateCreation?: Date,
    dateMiseAJour?: Date
}

export interface DemandeAEnregistrer {
    ficheArme: ArmeAEnregistrer;
    statut: string,
    modification: boolean

}


export interface DesignationCommercialesCreation {
    "key": number,
    "libelle": string,
    "valide": boolean,
    "action": string
    "@id"?: string
    "@type"?: string
    actif?: boolean
    id?: number
}

export interface CanonCreation {
    "numCanon": number,
    "typeCanon": string,
    "modePercussion": string,
    "longueurCanon": number,
    "calibre": string,
    "idGdr": number
}

export class ArmeAEnregistrerImpl implements ArmeAEnregistrer {
    id?:number;
    apparenceArmeGuerre: boolean;
    canons: CanonCreation[];
    capaciteChambre: number;
    capaciteHorsChambre: number;
    classementValideEurope: string;
    classementValideFrance: string;
    designationCommerciales: DesignationCommercialesCreation[];
    energie: any;
    fabricantSaisie: string;
    famille: string;
    longueur: number;
    marqueSaisie: string;
    modeAlimentation: string;
    modeFonctionnement: string;
    modeleSaisie: string;
    neutralise: boolean;
    pays: string;
    prototype: boolean;
    siren: any;
    typeArme: string;
    visible: boolean;
    fabricant: string;
    marque: string;
    modele: string;
    dateCreation?: Date;
    dateMiseAJour?: Date;

    constructor(apparenceArmeGuerre: boolean, canons: CanonCreation[], capaciteChambre: number, capaciteHorsChambre: number,
                classementValideEurope: string, classementValideFrance: string,
                designationCommerciales: DesignationCommercialesCreation[], energie: any, fabricantSaisie: string,
                famille: string, longueur: number, marqueSaisie: string, modeAlimentation: string, modeFonctionnement: string,
                modeleSaisie: string, neutralise: boolean, pays: string, prototype: boolean, siren: any, typeArme: string,
                visible: boolean, fabricant: string, marque: string, modele: string) {
        this.apparenceArmeGuerre = apparenceArmeGuerre;
        this.canons = canons;
        this.capaciteChambre = capaciteChambre;
        this.capaciteHorsChambre = capaciteHorsChambre;
        this.classementValideEurope = classementValideEurope;
        this.classementValideFrance = classementValideFrance;
        this.designationCommerciales = designationCommerciales;
        this.energie = energie;
        this.fabricantSaisie = fabricantSaisie;
        this.famille = famille;
        this.longueur = longueur;
        this.marqueSaisie = marqueSaisie;
        this.modeAlimentation = modeAlimentation;
        this.modeFonctionnement = modeFonctionnement;
        this.modeleSaisie = modeleSaisie;
        this.neutralise = neutralise;
        this.pays = pays;
        this.prototype = prototype;
        this.siren = siren;
        this.typeArme = typeArme;
        this.visible = visible;
        this.fabricant = fabricant;
        this.marque = marque;
        this.modele = modele;
    }
}

export class DemandeAEnregistrerImpl implements DemandeAEnregistrer {
    ficheArme: ArmeAEnregistrer;
    modification: boolean;
    statut: string;


    constructor(ficheArme: ArmeAEnregistrer, modification: boolean, statut: string) {
        this.ficheArme = ficheArme;
        this.modification = modification;
        this.statut = statut;
    }
}


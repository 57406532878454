import React from "react";
import {Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import "./ModaleTypeCanon.css"
import canon_lisse from "../../../resources/recherche-simplifiee/1canon/canon_1_lisse.svg";
import canon_raye from "../../../resources/recherche-simplifiee/1canon/canon_1_raye.svg";
import ExempleTypeCanonModale from "./ExempleTypeCanonModale";

interface Modale1CanonProps {
    onFermerClick?: () => void
}

const Modale1Canon : React.FC<Modale1CanonProps> = ({onFermerClick}) => {
    return (<Modale id={"modal"}  taille={"lg"} closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>1 canon / exemples</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-around"}>
                <ExempleTypeCanonModale titre={"1 lisse"} image1={canon_lisse}/>
                <ExempleTypeCanonModale titre={"1 rayé"} image1={canon_raye}/>
            </span>
        </ModaleContent>
    </Modale>)
}

export default Modale1Canon;
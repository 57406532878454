import React, {FC, useCallback, useEffect, useState} from "react";
import {Bouton, Container} from "@design-system-etat/dsfr-react";
import {fr} from "../../constantes/ClassesDSFR";
import Titre from "../../composants/Titre";
import {
    Tableau,
    TableauBody,
    TableauCell,
    TableauHeader,
    TableauHeaderCell,
    TableauRow
} from "@design-system-etat/dsfr-react/lib/cjs";
import {TableauHeaderCellFiltres} from "../../composants/TableauHeaderCellFiltres";
import {UtilisateurAPIServiceImpl} from "../../infrastructure/api/UtilisateurAPIService";
import {UtilisateurLite, utilisateurToUtilisateurLite} from "../../entites/Utilisateur";
import PaginationRGA from "../../composants/PaginationRGA";
import "./EcranAdministrationUtilisateurs.css"
import {ParametresTechniques} from "../../constantes/ParametresTechniques";
import debounce from "lodash.debounce";
import "../administration-referentiels/AdministrationDesReferentiels.css"
import {ModaleModifierUtilisateur} from "./ModaleModifierUtilisateur/ModaleModifierUtilisateur";
import {UTILISATEUR_STATUTS} from "../../constantes/UtilisateurStatusEnum";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {genererDateActuellePourCsv} from "../../utils/DateUtils";

export const EcranAdministrationUtilisateurs: FC = () => {

    const [nombrePages, setNombrePages] = useState<number>(1);
    const [pageCourante, setPageCourante] = useState<number>(1);
    const [elementsParPage, setElementsParPage] = useState<number>(25);
    const [lignesUtilisateur, setLignesUtilisateur] = useState<UtilisateurLite[]>([]);
    const [tri, setTri] = useState<{ cle: string, valeur: "ASC" | "DESC" | "" }>({cle: "", valeur: ""});
    const [filtres, setFiltres] = useState<UtilisateurLite>({
        nom: "",
        prenom: "",
        societe: "",
        siren: "",
        mail: "",
        etat: UTILISATEUR_STATUTS.NOUVEAU
    });
    const [utilisateurEnModification, setUtilisateurEnModification] = useState<UtilisateurLite>(null);

    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();

    const alerteUtilisateurModifie = new Alerte(typeAlerte.REUSSITE, "", 'Ce compte a bien été mis à jour.');
    const alerteMailLie = new Alerte(typeAlerte.ERREUR, "", "'Ce mail est déjà lié à un autre compte utilisateur.'");
    const alerteMailPasEnvoye = new Alerte(typeAlerte.ERREUR, "", "'Le mail contenant le nouveau mot de passe n'a pas pu être envoyé',");

    function updateTri(cle: string, valeur: "ASC" | "DESC" | "") {
        mettreAJourDateDerniereAction();
        setTri({cle: cle, valeur: valeur});
    }

    function updateFiltre(cle: string, valeur: string) {
        mettreAJourDateDerniereAction();
        const nouveauxFiltres = {...filtres}
        nouveauxFiltres[cle] = valeur
        setFiltres(nouveauxFiltres);
    }

    const convertToCSV = (arr) => {
        const array = [["Nom", "Prénom", "Société", "Mail", "SIREN", "Statut"]].concat(arr)
        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }

    const modifierStatutUtilisateur = (utilisateur: UtilisateurLite, etat: UTILISATEUR_STATUTS, action: string) => {
        mettreAJourDateDerniereAction();
        const nouvelUtilisateur = {...utilisateur}
        nouvelUtilisateur.etat = etat
        UtilisateurAPIServiceImpl.modifierStatutUtilisateur(nouvelUtilisateur, action)
            .then(reponse => {
                setUtilisateurEnModification(null)
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", reponse?.data?.message))
                recupererUtilisateurs(pageCourante, elementsParPage, tri, filtres)
            })
            .catch(error => {
                switch (error?.response?.data?.error?.type) {
                    case 'ExistingUser':
                        mettreAjourAlerte(alerteMailLie)
                        break;
                    case 'SendMailError':
                        mettreAjourAlerte(alerteMailPasEnvoye)
                        break;
                }
            })
    }

    const modifierUtilisateur = (utilisateur: UtilisateurLite) => {
        mettreAJourDateDerniereAction();
        UtilisateurAPIServiceImpl.modifierUtilisateur(utilisateur, utilisateurEnModification.mail)
            .then(() => {
                setUtilisateurEnModification(null)
                mettreAjourAlerte(alerteUtilisateurModifie)
                recupererUtilisateurs(pageCourante, elementsParPage, tri, filtres)
            })
            .catch(error => {
                switch (error?.response?.data?.error?.type) {
                    case 'ExistingUser':
                        mettreAjourAlerte(alerteMailLie)
                        break;
                    case 'SendMailError':
                        mettreAjourAlerte(alerteMailPasEnvoye)
                        break;
                }
            })
    }

    const recupererUtilisateurs = (useCallback(debounce((pageDemandee: number, elementsParPageRecu: number, triRecu, filtresRecu) => { // eslint-disable-line react-hooks/exhaustive-deps
        debounceRecupererUtilisateurs(pageDemandee, elementsParPageRecu, triRecu, filtresRecu);
    }, ParametresTechniques.DUREE_DEBOUNCE), []))

    function debounceRecupererUtilisateurs(pageCourante, elementsParPage, tri, filtres) {
        mettreAJourDateDerniereAction();
        UtilisateurAPIServiceImpl.recupererUtilisateurs(pageCourante, elementsParPage, tri, filtres)
            .then(resultat => {
                const lignes: UtilisateurLite[] = (resultat.elements && resultat.elements.length > 0) ? resultat.elements.map(utilisateur => {
                    return utilisateurToUtilisateurLite(utilisateur)
                }) : []
                setNombrePages(resultat.nombrePages)
                setLignesUtilisateur(lignes)
            })
    }

    function exporter(utilisateurs: UtilisateurLite[]) {
        mettreAJourDateDerniereAction();
        const BOM = "\uFEFF";
        const contenu = BOM + convertToCSV(utilisateurs)
        const blob = new Blob([contenu.replaceAll(',', ';')], {type: 'text/csv;charset=utf-8'})
        const a = document.createElement('a')
        a.download = "export_utilisateurs" + genererDateActuellePourCsv() + ".csv"
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    function genererBoutonAction(texte: string, onClick: () => void) {
        return <button
            className={"fr-btn fr-btn--sm administration-utilisateurs__boutons-action"}
            title={texte}
            onClick={onClick}
        >
            <div className={"administration-utilisateurs__bouton-action"}>
                {texte}
            </div>
        </button>
    }

    const mapperEtat = (etat: UTILISATEUR_STATUTS) => {
        switch (etat) {
            case UTILISATEUR_STATUTS.NOUVEAU :
                return "Nouveau"
            case UTILISATEUR_STATUTS.DELETED :
                return "Supprimé"
            case UTILISATEUR_STATUTS.BLOCKED :
                return "Bloqué"
            case UTILISATEUR_STATUTS.ACTIF :
                return "Actif"
            case UTILISATEUR_STATUTS.ENREGISTRE :
                return "Enregistré"
        }
    }

    function genererCellules(utilisateur: UtilisateurLite) {
        let cellules: JSX.Element[]

        function genererTexte(cle: string) {
            if (cle === "etat") {
                return mapperEtat(utilisateur[cle])
            } else {
                return utilisateur[cle]
            }
        }

        cellules = Object.keys(utilisateur).map((cle, index) => {
            return <TableauCell key={index}>
                <div style={{overflowWrap: "break-word"}}>
                    {genererTexte(cle)}
                </div>
            </TableauCell>
        })
        cellules.push(<TableauCell key={"btn-0"}>
            {genererBoutonAction("Modifier", () => setUtilisateurEnModification(utilisateur))}
            {utilisateur.etat === UTILISATEUR_STATUTS.NOUVEAU &&
                genererBoutonAction("Activer", () => modifierStatutUtilisateur(utilisateur, UTILISATEUR_STATUTS.ENREGISTRE, 'activer'))
            }
            {[UTILISATEUR_STATUTS.NOUVEAU, UTILISATEUR_STATUTS.ENREGISTRE, UTILISATEUR_STATUTS.ACTIF].includes(utilisateur.etat) &&
                genererBoutonAction("Bloquer", () => modifierStatutUtilisateur(utilisateur, UTILISATEUR_STATUTS.BLOCKED, 'bloquer'))
            }
            {utilisateur.etat === UTILISATEUR_STATUTS.BLOCKED &&
                genererBoutonAction("Débloquer", () => modifierStatutUtilisateur(utilisateur, UTILISATEUR_STATUTS.ENREGISTRE, 'debloquer'))
            }
            {utilisateur.etat === UTILISATEUR_STATUTS.ENREGISTRE &&
                genererBoutonAction("Renvoyer mot de passe", () => modifierStatutUtilisateur(utilisateur, UTILISATEUR_STATUTS.ENREGISTRE, 'activer'))
            }
        </TableauCell>)
        return cellules
    }

    useEffect(() => {
        recupererUtilisateurs(pageCourante, elementsParPage, tri, filtres)
    }, [tri, filtres, pageCourante, elementsParPage]) // eslint-disable-line react-hooks/exhaustive-deps


    return <Container className={fr.my_4w}>
        <Titre>Gestion des utilisateurs</Titre>
        {utilisateurEnModification && (
            <ModaleModifierUtilisateur
                utilisateur={utilisateurEnModification}
                onValiderClick={utilisateur => modifierUtilisateur(utilisateur)}
                onAnnulerClick={() => setUtilisateurEnModification(null)}
                onFermerClick={() => setUtilisateurEnModification(null)}
            />
        )}
        <div>
            <Tableau caption={""} avecBordure tailleFixe>
                <TableauHeader>
                    <TableauRow>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            ordreTriValeur={tri.cle === "nom" && tri.valeur}
                            onChange={updateFiltre}
                            onClick={updateTri}
                            idColonne={"nom"}
                            key={"nom"}
                        >
                            Nom
                        </TableauHeaderCellFiltres>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            ordreTriValeur={tri.cle === "prenom" && tri.valeur}
                            onChange={updateFiltre}
                            onClick={updateTri}
                            idColonne={"prenom"}
                            key={"prenom"}
                        >
                            Prénom
                        </TableauHeaderCellFiltres>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            ordreTriValeur={tri.cle === "societe" && tri.valeur}
                            onChange={updateFiltre}
                            onClick={updateTri}
                            idColonne={"societe"}
                            key={"societe"}
                        >
                            Société
                        </TableauHeaderCellFiltres>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            ordreTriValeur={tri.cle === "siren" && tri.valeur}
                            onChange={updateFiltre}
                            onClick={updateTri}
                            idColonne={"siren"}
                            key={"siren"}
                        >
                            Siren
                        </TableauHeaderCellFiltres>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            ordreTriValeur={tri.cle === "mail" && tri.valeur}
                            onChange={updateFiltre}
                            onClick={updateTri}
                            idColonne={"mail"}
                            key={"mail"}
                        >
                            Mail
                        </TableauHeaderCellFiltres>
                        <TableauHeaderCellFiltres
                            typeFiltre="text"
                            ordreTriValeur={tri.cle === "etat" && tri.valeur}
                            onChange={updateFiltre}
                            onClick={updateTri}
                            defaultValue={filtres.etat}
                            idColonne={"etat"}
                            key={"etat"}
                        >
                            Statut
                        </TableauHeaderCellFiltres>
                        <TableauHeaderCell>
                            Action
                        </TableauHeaderCell>
                    </TableauRow>
                </TableauHeader>
                <TableauBody>
                    {lignesUtilisateur.map((ligne, index) => {
                        return <TableauRow className={"filtreRow"} key={index}>
                            {genererCellules(ligne)}
                        </TableauRow>
                    })}
                </TableauBody>
            </Tableau>
            {lignesUtilisateur && lignesUtilisateur.length > 0 && <PaginationRGA
                pageCourante={pageCourante}
                totalPages={nombrePages}
                elementsParPageParDefaut={elementsParPage}
                optionElementsParPage={[25, 50, 100]}
                changePage={pageRecupere => {
                    setPageCourante(pageRecupere)
                }}
                onElementsParPageChange={elementsParPageRecu => {
                    setPageCourante(1)
                    setElementsParPage(elementsParPageRecu)
                }}
            />
            }
        </div>
        <div>
            <div className={"d-flex flex-end"}>
                <Bouton
                    label={"Exporter"}
                    className={fr.mt_2w}
                    niveau={"primaire"}
                    onClick={() => exporter(lignesUtilisateur)}
                />
            </div>
        </div>
    </Container>

}


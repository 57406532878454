import React from "react";
import {
    Tableau,
    TableauBody,
    TableauCell,
    TableauHeader,
    TableauHeaderCell,
    TableauRow
} from "@design-system-etat/dsfr-react/lib/cjs";
import {addLine, Bouton} from "@design-system-etat/dsfr-react";
import {auPluriel} from "../../../utils/Utils";
import {URLPageEnum} from "../../../constantes/URLPageEnum";
import {FicheArmeActionEnum} from "../../../constantes/FicheArmeActionEnum";
import Arme from "../../../entites/Arme";
import "./EcranResultatRecherche.css"
import {useAlerteContext} from "../../../composants/RGAContext";
import {PageTableau} from "../../../entites/PageTableau";

interface TableauResultatRechercheProps {
    handleExport: () => any,
    resultats: PageTableau<Arme>,
    onAfficherInformation: (arme: Arme) => any,
    onTri: (tri: string) => any,
    tri: {},
}

const TableauResultatRecherche: React.FC<TableauResultatRechercheProps> = ({resultats, onTri, tri, onAfficherInformation}) => {

    const {mettreAJourDateDerniereAction} = useAlerteContext();

    const visiterArme = (sia: string) => {
        mettreAJourDateDerniereAction();
        window.open(URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + sia, "_blank")
    }

    return <>
        <div className={"d-flex flex-space-between"}>
            <div className={"texte-centre"}>
                <h4 className={"texte-centre"}>Cliquez sur la référence RGA pour consulter la fiche</h4>
                <div className={"texte-gauche"}>{resultats.nombreElementsTotal + auPluriel(" résultat", resultats.nombreElementsTotal)}</div>
                <Tableau caption="">
                    <TableauHeader>
                        <TableauRow>
                            <TableauHeaderCell scope={"col"} onClick={() => onTri("sia")}
                                               ordreTri={tri["sia"]}>Ref. RGA</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"} onClick={() => onTri("classementValideFrance.libelle")}
                                               ordreTri={tri["classementValideFrance.libelle"]}>Class. Nat.</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"} onClick={() => onTri("type")}
                                               ordreTri={tri["type"]}>Type</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"} onClick={() => onTri("marque")}
                                               ordreTri={tri["marque"]}>Marque</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"} onClick={() => onTri("modele")}
                                               ordreTri={tri["modele"]}>Modèle</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"}>Calibre 1</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"}>Longueur et type canon 1</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"}>Calibre 2</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"}>Longueur et type canon 2</TableauHeaderCell>
                            <TableauHeaderCell scope={"col"}><div>Capacité</div></TableauHeaderCell>
                            <TableauHeaderCell><p
                                className={"texte-bleu texte-centre"}>Plus d'informations</p>
                            </TableauHeaderCell>
                        </TableauRow>
                    </TableauHeader>
                    <TableauBody>
                        {resultats.elements.map((arme, index) => {
                            const canon1 = arme.canons.find(canon => canon.numCanon === 1);
                            const canon2 = arme.canons.find(canon => canon.numCanon === 2);
                            return <>
                                <TableauRow onClick={() => visiterArme(arme.sia)} key={index}>
                                    <TableauCell>
                                        <div>{arme.sia}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{arme.classementValideFrance.libelle}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{arme.typeArme.libelle}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{arme.marque.libelle}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{arme.modele.libelle}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{canon1?.calibre?.libelle ?? "Aucun"}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{(canon1?.typeCanon?.libelle && canon1?.longueurCanon) ? canon1?.longueurCanon + "mm " + canon1?.typeCanon?.libelle : "Aucun"}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{canon2?.calibre?.libelle ?? "Aucun"}</div>
                                    </TableauCell>
                                    <TableauCell>
                                        <div>{(canon2?.typeCanon?.libelle && canon2?.longueurCanon) ? canon2?.longueurCanon + "mm " + canon2?.typeCanon?.libelle : "Aucun"}</div>
                                    </TableauCell>
                                    <TableauCell><div className={"texte-centre"}>{(+arme.capaciteHorsChambre + arme.capaciteChambre) + " = " + arme.capaciteHorsChambre + ' + ' + arme.capaciteChambre}</div></TableauCell>
                                    <TableauCell>
                                        <div className={"texte-centre"}>
                                            <Bouton
                                                icone={addLine}
                                                label="afficher-informations"
                                                onClick={(e) => {e.stopPropagation();onAfficherInformation(arme)}}
                                            />
                                        </div>
                                    </TableauCell>
                                </TableauRow>
                            </>
                        })}
                    </TableauBody>
                </Tableau>

            </div>
        </div>
    </>
}

export default TableauResultatRecherche;

import React from "react";
import {Option, Pagination, Select} from "@design-system-etat/dsfr-react";
import {fr} from "../constantes/ClassesDSFR";

interface CaptchaProps {
    pageCourante: number,
    totalPages: number,
    changePage: (numeroPage: number) => void,
    onElementsParPageChange: (nombreElementsParPage: number) => void,
    elementsParPageParDefaut: number
    optionElementsParPage: number[]

}

const PaginationRGA : React.FC<CaptchaProps> = ({pageCourante, totalPages, changePage, onElementsParPageChange, elementsParPageParDefaut,optionElementsParPage}) => {

    return (<div className={"d-flex flex-space-between"}>
        <div className={fr.pt_3w}>
            <Pagination
                pageCourante={pageCourante}
                totalPages={totalPages}
                changePage={changePage}
            />
        </div>
        <div>
            <Select
                label="Nombre de lignes"
                name="elements-par-page"
                defaultValue={elementsParPageParDefaut}
                onChange={e => {
                    onElementsParPageChange(Number(e.target.value))
                }}
            >
                {optionElementsParPage.map(option => {
                    return <Option value={option.toString()} key={option}>
                        {option.toString()}
                    </Option>
                })}
            </Select>
        </div>
    </div>)
}

export default PaginationRGA;
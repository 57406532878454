import RgaAppliAPIPort, {ReferentielType} from "../../utils/port/RgaAppliAPIPort";
import API from "./APIConfiguration";
import {ParametreTechnique} from "../../entites/ParametreTechnique";
import Arme from "../../entites/Arme";
import {ReferentielAPIEnum} from "../../constantes/ReferentielAPIEnum";
import {FiltreAPI} from "../../entites/FiltreAPI";
import {ArmeAEnregistrer, DemandeAEnregistrer} from "../../entites/ArmeAEnregistrer";
import {PageTableau} from "../../entites/PageTableau";
import {PhotoArmeAPIGet, PhotoArmeAPIPost} from "../../entites/PhotoArme";
import {AdministrationDroits} from "../../entites/Profil";
import {DroitAModifier} from "../../entites/DroitAModifier";
import {ExtensionsEnum} from "../../constantes/ExtensionsEnum";
import dateFormat from "dateformat";
import {Trace} from "../../entites/Trace";
import {lireParametreTechnique} from "../../utils/parametre/ParametreTechniqueService";
import {ParametreTechniqueEnum} from "../../constantes/ParametreTechniqueEnum";
import {MessageGenerique} from "../../constantes/MessageGenerique";
import {FormulaireCompte} from "../../entites/FormulaireCompte";
import {FormulaireReinitMdp} from "../../entites/FormulaireMdpReinit";
import Parametre from "../../entites/Parametre";
import {ConnexionAPIEnum} from "../../constantes/ConnexionAPIEnum";
import {ConfigurationPageDeConnexion} from "../../entites/ConfigurationPageDeConnexion";
import {Demande} from "../../entites/Demande";
import {TypeTraitementSCAEEnum} from "../../constantes/TypeTraitementSCAEEnum";
import {ReferentielLibre, ReferentielLibreACreer, ReferentielLibreInexistant} from "../../entites/ReferentielLibre";


const recupererParametreTechnique = (): Promise<ParametreTechnique[] | []> => {
    return API.get("/parametre_rga_public").then(response => {
        return JSON.parse(response.data.data) as ParametreTechnique[];
    });
};

const connexionSSO = (): Promise<string> => {
    return recupererTokenConnexion(ConnexionAPIEnum.LOGIN_SSO)
}

const connexionLivreDePolice = (): Promise<string> => {
    return recupererTokenConnexion(ConnexionAPIEnum.LOGIN_LIVRE_DE_POLICE)
}

const connexionDetenteur = (): Promise<string> => {
    return recupererTokenConnexion(ConnexionAPIEnum.LOGIN_DETENTEUR)
}

const recupererTokenConnexion = (uri: string): Promise<string> => {
    return API.get(uri).then(response => {
        return response.data?.token
    })
}

const recupererReferentielComplet = <T extends ReferentielAPIEnum>(type: T, itemParPage: number | string, numeroPage: string | number, filtres?: FiltreAPI[]): Promise<PageTableau<ReferentielType<T>>> => {
    const item: string = "?itemsPerPage=" + itemParPage;
    const page: string = "&page=" + numeroPage;

    let url: string = type;
    url += item;
    url += page;

    if (filtres) {
        filtres.forEach(filtre => {
            url = url + "&" + filtre.cle + "=" + encodeURIComponent(filtre.valeur) + '&sort[libelle]=asc'
        })
    }
    return API.get(url, {headers: {'Accept': 'application/ld+json'}}
    ).then(reponse => {
        return new PageTableau<ReferentielType<T>>(
            reponse.data["hydra:member"] as ReferentielType<T>[],
            reponse.data["hydra:totalItems"],
            itemParPage
        );
    }).catch(() => Promise.reject())
}
const droitParProfil = (): Promise<AdministrationDroits> => {
    return API.get("/init_droit").then(response => {
        return {matrice: response.data.matrice, profils: response.data.profil} as AdministrationDroits;
    })
}
const enregistrerDroit = (nouveauxDroits: DroitAModifier): Promise<AdministrationDroits> => {
    return API.post("/update_droit", {data: nouveauxDroits}).then(
        response => {
            return response.status === 200 ? {
                matrice: response.data.matrice,
                profils: response.data.profil
            } as AdministrationDroits : null;
        }
    )
}
const rechercheArmeParReference = (reference: string): Promise<Arme> => {
    return API.get("/armes/" + reference).then(response => {
        return response.data as Arme;
    }).catch(erreur => {
        const raison = erreur.response.status === 500 ? "L'arme n'a pas été trouvé ou n'existe pas." : "Erreur lors de la recherche de l'arme.";
        return Promise.reject(raison)
    })
}

const creerArme = (armeACreer: ArmeAEnregistrer): Promise<Arme> => {
    return API.post("/armes", armeACreer).then(reponse => reponse.data).catch(erreur => {

            let messageErreur = MessageGenerique.REQUETE_EN_ERREUR;
            if (erreur.response.status === 409) {
                messageErreur = erreur.response.data.error.message
            }
            return Promise.reject(messageErreur);
        }
    )
}

const ajouterPhotoArme = (sia: string, photo: PhotoArmeAPIPost): Promise<boolean> => {

    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    const data = new FormData();
    data.append('file', photo.photo);
    data.append('type', photo.position);
    return API.post("/armes/" + sia + "/photos", data, config).then(() => true).catch(
        () => Promise.reject()
    )
}


const supprimerPhotoArme = async (idPhotos: string[], sia: string): Promise<boolean> => {

    //L'API ne gére qu'une photo à la fois
    if (idPhotos.length > 0) {
        let nombrePhoto = idPhotos.length;
        let erreur = "";
        for (const idPhoto of idPhotos) {
            await API.delete("/armes/" + sia + "/photos/" + idPhoto)
                // eslint-disable-next-line
                .then(() => nombrePhoto--)
                // eslint-disable-next-line
                .catch((reponse) => {
                        erreur += reponse.data ?? ''
                    }
                )
        }
        if (nombrePhoto !== 0) {
            return Promise.reject(erreur)
        }
        return Promise.resolve(true);
    }
    return Promise.resolve(true);
}

const recupererPhotoArme = (sia: string): Promise<PhotoArmeAPIGet[]> => {
    return API.get("/armes/" + sia + "/photos").then(reponse =>
        reponse.data.result.picture as PhotoArmeAPIGet[]
    ).catch(
        (erreur) => {
            if (erreur.response.status === 404) {
                return Promise.reject("Demande non trouvée.")
            }
            return Promise.reject(erreur.response.message);
        }
    )
}
const recupererTraces = (itemParPage: number | string, numeroPage: string | number, filtres?: FiltreAPI[]): Promise<PageTableau<Trace>> => {
    const item: string = "?itemsPerPage=" + itemParPage;
    const page: string = "&page=" + numeroPage;

    let url: string = "/traces";
    url += item;
    url += page;

    if (filtres) {
        filtres.forEach(filtre => {
            url = url + "&" + filtre.cle + "=" + filtre.valeur
        })
    }
    return API.get(url, {headers: {'Accept': 'application/ld+json'}}
    ).then(reponse => {
        return new PageTableau<Trace>(
            reponse.data["hydra:member"] as Trace[],
            reponse.data["hydra:totalItems"],
            itemParPage
        );
    })
}

function exporterTraces(filtres?: FiltreAPI[]): Promise<string> {

    const avertissementLimite = "Le nombre de lignes à exporter dépasse la limite autorisée. Uniquement les @@@ premières ont été exportées.";
    let url: string = "/export_suivi_trace?";
    if (filtres) {
        filtres.forEach(filtre => {
            url = url + "&" + filtre.cle + "=" + filtre.valeur
        })
    }
    return API.get(url)
        .then((response) => {
            const csvData = new Blob(["\ufeff", response.data]);
            const timeStamp = dateFormat(new Date(), "ddmmyyyy-HHMMss");
            const filename = "export_traces_" + timeStamp + ExtensionsEnum.EXTENTIONCSV

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = URL.createObjectURL(csvData);
            a.download = filename;
            a.click();
            a.remove();
            if (response.status === 299) {
                return Promise.resolve(
                    lireParametreTechnique(ParametreTechniqueEnum.NB_MAX_TRACES_EXPORTEES)
                        .then((valeur) => {
                            return avertissementLimite.replace("@@@", valeur)
                        })
                        .catch(() => avertissementLimite)
                )
            }
        }).catch(() => {
            return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR)
        });
}

const recupererHealthCheck = (): Promise<any> => {
    return API.get('/health-check').then(response => {
        const healthCheck = response.data.healthcheck
        return {...healthCheck, rga_sca_intranet: healthCheck.rga_sca_internet}
    });
}

const connexionLoginMdp = (login: string, password: string): Promise<string> => {
    return API.post("/login", {login, password}).then(response => {
        return response.data?.token as string;
    }).catch(erreur => {
        let typeErreur = erreur?.response?.data?.error?.type ?? "";
        let raison = {doitChangerMotDePasse: false, message: "Erreur de connexion", erreurIdentification: false}
        switch (typeErreur) {
            case "RegisterUserException":
                raison.message = erreur.response.data.error.message
                raison.doitChangerMotDePasse = true;
                break;
            case "CredentialsExpiredException":
                raison.message = erreur.response.data.error.message;
                raison.doitChangerMotDePasse = true;
                break;
            case"ResetAccount":
            case"PasswordResetException":
                raison.message = MessageGenerique.CONNEXION_INFRUCTUEUSE;
                break;
            case"LockedException":
                raison.message = MessageGenerique.COMPTE_BLOQUE;
                break;
            case"BadCredentialsException":
            case"UsernameNotFoundException":
                raison.message = MessageGenerique.IDENTIFIANT_INCORRECT;
                raison.erreurIdentification = true;
                break;
        }
        return Promise.reject(raison)
    });
}

const creationCompte = (compte: FormulaireCompte): Promise<any> => {
    const formData = {
        nom: compte.nom,
        prenom: compte.prenom,
        mail: compte.mail,
        siren: compte.siren,
        societe: compte.societe,
    }

    return API.post("/creation_compte", {formData: formData, captcha: compte.captcha}).then(response => {
        return response;
    });
}

const reinitialiserMotDePasse = (form: FormulaireReinitMdp): Promise<any> => {
    return API.post(`/mot_de_passe_oublie`, form);
}

const recupererParametres = (): Promise<Parametre[]> => {
    return API.get("/parametre_rgas?pagination=false").then(reponse =>
        reponse.data as Parametre[]
    ).catch(
        (erreur) => {
            return Promise.reject(erreur.message);
        }
    )
}

const enregistrerParametre = (id: number, valeur: string): Promise<any> => {
    return API.put("/parametre_rgas/" + id, {id: id, valeur: valeur})
}

const modifierArme = (idArme: number, arme: ArmeAEnregistrer): Promise<Arme> => {
    return API.put("/fiche_armes/" + idArme, arme).then(reponse => reponse.data).catch(() => Promise.reject(MessageGenerique.REQUETE_EN_ERREUR));
}

const creerDemandeArme = (demandeACreer: DemandeAEnregistrer): Promise<string> => {
    return API.post("/demande_fiche_armes", demandeACreer).then(reponse => reponse.data.id).catch((erreur) => {
            console.error(erreur)
            if (erreur.response.status === 409) {
                return Promise.reject(erreur.response.data.error.message);
            }
            return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR);
        }
    )
}

const recupererDemandeArme = (idDemande: string): Promise<Demande> => {
    return API.get("/demande_fiche_armes/" + idDemande).then(reponse => {
        return reponse.data as Demande
    }).catch((erreur) => {
        if (erreur.response.status === 404) {
            return Promise.reject("Demande non trouvée.")
        }
        if (erreur.response.status === 403 && erreur.response.data.error.type === "accessDeniedHttp") {
            return Promise.reject("Vous n'avez pas accès à cette page.")
        }
        return Promise.reject(erreur.response.message ?? "Vous n'avez pas accès à cette page.");
    })
}

const ajouterPhotoDemandeArme = (idDemande: string, photo: PhotoArmeAPIPost): Promise<boolean> => {

    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    const data = new FormData();
    data.append('file', photo.photo);
    data.append('type', photo.position);
    return API.post("/demande_fiche_armes/" + idDemande + "/photos", data, config).then(() => true).catch(
        () => Promise.reject()
    )
}

const recupererPhotoDemandeArme = (idDemande: string): Promise<PhotoArmeAPIGet[]> => {
    return API.get("/demande_fiche_armes/" + idDemande + "/photos").then(reponse => {
            return reponse.data as PhotoArmeAPIGet[]
        }
    ).catch(
        (erreur) => {
            if (erreur.response.status === 404) {
                return Promise.reject("Demande non trouvée.")
            }
            return Promise.reject(erreur.response.message);
        }
    )
}
const modifierDemandeArme = (idDemande: string, demandeASoumettre: DemandeAEnregistrer): Promise<string> => {
    return API.put("/demande_fiche_armes/" + idDemande, demandeASoumettre).then(reponse => reponse.data.id)
        .catch((erreur) => {
            if (erreur.response.status === 409) {
                return Promise.reject(
                    erreur.response.data.error.message
                )
            } else {
                return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR)
            }
        })
};

const supprimerPhotoDemandeArme = async (idPhotoSupprime: string[]): Promise<boolean> => {
    //L'API ne gére qu'une photo à la fois
    if (idPhotoSupprime.length > 0) {
        let nombrePhoto = idPhotoSupprime.length;
        let erreur = "";
        for (const idPhoto of idPhotoSupprime) {
            await API.delete("/photos/" + idPhoto)
                // eslint-disable-next-line
                .then(() => nombrePhoto--)
                // eslint-disable-next-line
                .catch((reason) => erreur += reason)
        }
        if (nombrePhoto !== 0) {
            return Promise.reject(erreur)
        }
        return Promise.resolve(true);
    }
    return Promise.resolve(true);
}
const supprimerDemande = (idDemande: string): Promise<boolean> => {
    return API.delete("/demande_fiche_armes/" + idDemande).then(() => true).catch(() => Promise.reject())
}


const traiterDemande = (idDemande: string, typeTraitementSCAE: TypeTraitementSCAEEnum, contenuMail: string, referentielLibre?: ReferentielLibreACreer[]): Promise<string> => {
    let donnees;
    if (TypeTraitementSCAEEnum.VALIDATION === typeTraitementSCAE) {
        donnees = {remarque: contenuMail, referentielLibre};
    } else {
        donnees = {message: contenuMail};
    }
    let url = "/demande_fiche_armes/" + idDemande + "/statut/" + typeTraitementSCAE;
    return API.put(url, donnees).then(reponse =>
        reponse.data?.result?.sia
    ).catch(() => Promise.reject(MessageGenerique.REQUETE_EN_ERREUR))
}

const verifierReferentielLibre = (referentielLibre: ReferentielLibre): Promise<ReferentielLibreInexistant[]> => {
    return API.post("/demande_fiche_armes/referentiel", referentielLibre).then(reponse => {
        return reponse.data.result.statuts as ReferentielLibreInexistant[];
    }).catch(() => Promise.reject("Vérification des référentiels libres en erreur. " + MessageGenerique.REQUETE_EN_ERREUR))
}

const importMasseDemandesFiches = (fichier: File): Promise<string> => {
    let formData = new FormData();
    formData.append("file", fichier);
    return API.post('/import_masse', formData)
        .then(
            () => {
                return Promise.resolve('Votre fichier ' + fichier.name + ' a bien été déposé. Vous serez informé par courriel lorsque celui-ci aura été traité')
            }
        ).catch((error) => {
            if (error.response.status === 400) {
                return Promise.reject(error.response.data.error.message ?? MessageGenerique.REQUETE_EN_ERREUR)
            } else {
                return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR)
            }
        })
}

const importMasseFiches = (fichier: File): Promise<string> => {
    let formData = new FormData();
    formData.append("file", fichier);
    return API.post('/import_masse_arme', formData)
        .then(
            () => {
                return Promise.resolve('Votre fichier ' + fichier.name + ' a bien été déposé.')
            }
        ).catch((error) => {
            if (error.response.status === 400) {
                return Promise.reject(error.response.data.error.message ?? MessageGenerique.REQUETE_EN_ERREUR)
            } else {
                return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR)
            }
        })
}

const recupererConfigurationPageDeConnexion = (): Promise<ConfigurationPageDeConnexion[]> => {
    return API.get("/media_informations").then(reponse =>
        reponse.data as ConfigurationPageDeConnexion[]
    ).catch(
        (erreur) => {
            return Promise.reject(erreur.message);
        }
    )
}

const enregistrerConfigurationPageDeConnexion = (configurations: ConfigurationPageDeConnexion[], photo: File[]) => {
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    let requests: Promise<boolean>[] = []
    configurations.forEach((configuration, index) => {
        const data = new FormData();
        if (photo[index]) {
            data.append('file', photo[index]);
            data.append('photo', photo[index].name);
        }
        const configurationKeys = Object.keys(configuration);
        configurationKeys.forEach((key) => {
            if (key !== 'id' && key !== 'url') {
                data.append(key, configuration[key]);
            }
        })
        requests.push(API.post("/media_information/" + configuration.id, data, config))
    })

    return Promise.all(requests).then(() => true).catch(
        () => {
            Promise.reject()
        }
    )
}

const getFileFromUrl = (url: string, nomPhoto: string): Promise<any> => {
    return API.get(url).then((response) => {
        const data = response.data;
        const type = url.split('.')[1];
        return new File([data], nomPhoto, {
            type: data.type || "image/" + type,
        });
    });

}

export const RgaAppliAPIImpl: RgaAppliAPIPort =
    {
        recupererParametreTechnique,
        recupererTraces,
        connexionSSO,
        connexionLivreDePolice,
        connexionDetenteur,
        rechercheArmeParReference,
        recupererReferentielComplet,
        creerArme,
        enregistrerDroit,
        ajouterPhotoArme,
        supprimerPhotoArme,
        droitParProfil,
        recupererPhotoArme,
        connexionLoginMdp,
        exporterTraces,
        creationCompte,
        reinitialiserMotDePasse,
        recupererParametres,
        enregistrerParametre,
        recupererHealthCheck,
        modifierArme,
        creerDemandeArme,
        recupererDemandeArme,
        ajouterPhotoDemandeArme,
        recupererPhotoDemandeArme,
        modifierDemandeArme,
        supprimerPhotoDemandeArme,
        supprimerDemande,
        traiterDemande,
        verifierReferentielLibre,
        importMasseDemandesFiches,
        recupererConfigurationPageDeConnexion,
        enregistrerConfigurationPageDeConnexion,
        getFileFromUrl,
        importMasseFiches
    }

import React from "react";
import BaseMenuCarte from "./BaseMenuCarte";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import {Link} from "react-router-dom";
import {URLPageEnum} from "../../../../constantes/URLPageEnum";
import {editFill, upload2Fill} from "@design-system-etat/dsfr-react/lib/cjs";

import digitalArt from "@gouvfr/dsfr/dist/artwork/pictograms/leisure/digital-art.svg";
import Pictogram from "../../../../entites/Pictogramme";
import {FicheArmeActionEnum} from "../../../../constantes/FicheArmeActionEnum";
import {useAlerteContext} from "../../../../composants/RGAContext";


const MenuFiche: React.FC = () => {
    const titreFichesRga: string = "Création fiches RGA";
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    const pictoFichesRga: Pictogram = {
        svg: digitalArt,
        alt: "pictogram fiches RGA"
    };
    const descriptionFiche = "Vous avez la possibilité de créer, modifier, supprimer et importer des fiches RGA.";


    function calculerBouttons() {
        function bouttonCreer() {
            return <Link to={URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.CREER} onClick={mettreAJourDateDerniereAction}>
                <Bouton
                    id="bouton_creation_fiche"
                    icone={editFill}
                    iconePosition="left"
                    label="Créer une fiche RGA"
                    niveau="primaire"
                    className="fullSizeButton"
                />
            </Link>;
        }

        function bouttonEnMasse() {
            return <Link to={URLPageEnum.IMPORT_MASSE_FICHES} onClick={mettreAJourDateDerniereAction}>
                <Bouton
                    id="bouton_modification_fiche"

                    icone={upload2Fill}
                    iconePosition="left"
                    label="Importer / Modifier en masse"
                    niveau="primaire"
                    className="fullSizeButton"
                />
            </Link>;
        }


        return <>
                <Row  goutierre>
                    <Col>
                        {bouttonCreer()}
                    </Col>
                </Row>
                <Row goutierre>
                    <Col>
                        {bouttonEnMasse()}
                    </Col>
                </Row>
        </>;
    }


    return <BaseMenuCarte
        id={"menu-fiche"}
        titre={titreFichesRga}
        titrePicto={pictoFichesRga}
        bouton={calculerBouttons()}
        description={descriptionFiche}
    />

}
export default MenuFiche;
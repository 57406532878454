import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import {addLine, Bouton} from "@design-system-etat/dsfr-react";
import {Controller, useForm} from "react-hook-form";
import {ReferentielAPIService} from "../../infrastructure/InfrastructureFactory";
import {SelectOption} from "../../entites/Referentiels/SelectOption";
import {subtractLine} from "@design-system-etat/dsfr-react/lib/esm";
import debounce from "lodash.debounce";
import {AutoCompleteRGA} from "../../composants/SelectHorsDsfr/AutoCompleteRGA";
import {ReferentielAPIEnum} from "../../constantes/ReferentielAPIEnum";
import {CriteresRechercheAvancee, CriteresRechercheAvanceeInitial} from "../../entites/CriteresRechercheArme";
import {ParametresTechniques} from "../../constantes/ParametresTechniques";
import {ReferentielsOptions} from "../../entites/Referentiels/Referentiels";
import {useAlerteContext} from "../../composants/RGAContext";

interface FiltrageArmeAvanceeProps {
    rechercher: (criteres: CriteresRechercheAvancee) => void,
    referentiels: ReferentielsOptions,

}

export interface FiltrageArmeAvanceeRef {
    reset(): void;
}

export const FiltrageArmeAvancee = React.forwardRef<FiltrageArmeAvanceeRef, FiltrageArmeAvanceeProps>(({rechercher, referentiels}: FiltrageArmeAvanceeProps, ref) => {

    const [marques, setMarques] = useState<SelectOption[]>([]);
    const [modeles, setModeles] = useState<SelectOption[]>([]);
    const [calibres1, setCalibres1] = useState<SelectOption[]>([]);
    const [calibres2, setCalibres2] = useState<SelectOption[]>([]);
    const [calibres3, setCalibres3] = useState<SelectOption[]>([]);
    const [calibres4, setCalibres4] = useState<SelectOption[]>([]);
    const [typesCanon, setTypesCanon] = useState<SelectOption[]>([]);
    const [fabricants, setFabricants] = useState<SelectOption[]>([]);
    const [modeDeFonctionnements, setModeDeFonctionnements] = useState<SelectOption[]>([]);
    const [modeAlimentations, setModeAlimentations] = useState<SelectOption[]>([]);
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    const [calibre4Affiche, setCalibre4Affiche] = useState<boolean>(false);

    const form = useForm<CriteresRechercheAvancee>({mode: 'onChange', defaultValues: CriteresRechercheAvanceeInitial});

    const chargerReferentielDebounce = useCallback(debounce((referentiel, valeurChamp, setReferentiel, setChargementTermine) => { // eslint-disable-line react-hooks/exhaustive-deps
        mettreAJourDateDerniereAction();
        ReferentielAPIService.recupererReferentielLite(referentiel, valeurChamp).then(referentiels => {
            setReferentiel(referentiels.map(referentiel => new SelectOption(referentiel.libelle)));
            setChargementTermine(false)
        }).catch(()=>{console.error("Erreur de récupération du référentiel: "+referentiel)})
    // @ts-ignore
    }, ParametresTechniques.DUREE_DEBOUNCE), []);

    const afficherCalibre4 = (doitAfficher) => {
        setCalibre4Affiche(doitAfficher)
        if(!doitAfficher){
            form.setValue("canons4.calibre", "")
            form.setValue("canons4.typeCanon", "")
        }
    }

    const reset = () => {
        mettreAJourDateDerniereAction();
        form.reset()
    }

    useImperativeHandle(ref, () => ({reset}));


    useEffect(() => {
        ReferentielAPIService.recupererReferentielLite(ReferentielAPIEnum.TYPES_CANON, "").then(referentiels => {
            setTypesCanon(referentiels.map(referentiel => new SelectOption(referentiel.libelle)));
        }).catch(()=>{console.error("Erreur de récupération du référentiel.")})
    }, [])

    function marque() {
        return <div className={"recherche-avancee__titre fr-mx-2w fr-my-1w"}>
            <label className={"texte-centre"}>Marque</label>
            <Controller
                name="marque"
                control={form.control}
                defaultValue={form.getValues("marque")}
                render={({field}) => (
                    <AutoCompleteRGA
                        onChange={(option: SelectOption) => {
                            field.onChange(option?.value ? String(option.value) : "")
                            rechercher(form.getValues())
                        }}
                        value={form.getValues('marque') ? new SelectOption(form.getValues("marque")) : null}
                        field={field}
                        referentiel={ReferentielAPIEnum.MARQUES}
                        options={referentiels?.MARQUE ?? marques}
                        setOptions={setMarques}
                        chargerOptions={chargerReferentielDebounce}
                    />
                )}
            />
        </div>;
    }

    function modele() {
        return <div className={"recherche-avancee__titre fr-mx-2w fr-my-1w"}>
            <label className={"texte-centre"}>Modèle</label>
            <Controller
                name="modele"
                control={form.control}
                render={({field}) => (
                    <AutoCompleteRGA
                        onChange={(option: SelectOption) => {
                            field.onChange(option?.value ? String(option.value) : "")
                            rechercher(form.getValues())
                        }}
                        value={form.getValues('modele') ? new SelectOption(form.getValues("modele")) : null}
                        field={field}
                        referentiel={ReferentielAPIEnum.MODELES}
                        options={referentiels?.MODELE ?? modeles}
                        setOptions={setModeles}
                        chargerOptions={chargerReferentielDebounce}
                    />
                )}
            />
        </div>;
    }

    function fabricant() {
        return <div className={"recherche-avancee__titre fr-mx-2w fr-my-1w"}>
            <label className={"texte-centre"}>Fabricant</label>
            <Controller
                name="fabricant"
                control={form.control}
                render={({field}) => (
                    <AutoCompleteRGA
                        onChange={(option: SelectOption) => {
                            field.onChange(option?.value ? String(option.value) : "")
                            rechercher(form.getValues())
                        }}
                        value={form.getValues('fabricant') ? new SelectOption(form.getValues("fabricant")) : null}
                        field={field}
                        referentiel={ReferentielAPIEnum.FABRICANTS}
                        options={referentiels?.FABRICANT ?? fabricants}
                        setOptions={setFabricants}
                        chargerOptions={chargerReferentielDebounce}
                    />
                )}
            />
        </div>;
    }

    function canon1() {
        return <>
            <div className="recherche-avancee__titre">
                <label>Calibre canon 1</label>
                <Controller
                    name="canons1.calibre"
                    control={form.control}
                    render={({field}) => (
                        <AutoCompleteRGA
                            onChange={(option: SelectOption) => {
                                field.onChange(option?.value ? String(option.value) : "")
                                rechercher(form.getValues())
                            }}
                            value={form.getValues('canons1.calibre') ? new SelectOption(form.getValues("canons1.calibre")) : null}
                            field={field}
                            referentiel={ReferentielAPIEnum.CALIBRES_1}
                            options={referentiels?.CALIBRE1 ?? calibres1}
                            setOptions={setCalibres1}
                            chargerOptions={chargerReferentielDebounce}
                            filtreCalibre={true}
                        />
                    )}
                />
            </div>
            <div className={"recherche-avancee__titre"}>
                <label>Type de canon 1</label>
                <Controller
                    name="canons1.typeCanon"
                    control={form.control}
                    render={({field}) => (
                        <Controller
                            name="canons1.typeCanon"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? String(option.value) : "")
                                        rechercher(form.getValues())
                                    }}
                                    value={form.getValues('canons1.typeCanon') ? new SelectOption(form.getValues("canons1.typeCanon")) : null}
                                    field={field}
                                    referentiel={ReferentielAPIEnum.TYPES_CANON}
                                    options={referentiels?.TYPES_CANON_1 ?? typesCanon}
                                    setOptions={setTypesCanon}
                                    sansChargement
                                    chargerOptions={() => {
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>;
    }

    function canon2() {
        return <>
            <div className="recherche-avancee__titre fr-mt-2w">
                <label>Calibre canon 2</label>
                <Controller
                    name="canons2.calibre"
                    control={form.control}
                    render={({field}) => (
                        <AutoCompleteRGA
                            onChange={(option: SelectOption) => {
                                field.onChange(option?.value ? String(option.value) : "")
                                rechercher(form.getValues())
                            }}
                            value={form.getValues('canons2.calibre') ? new SelectOption(form.getValues("canons2.calibre")) : null}
                            field={field}
                            referentiel={ReferentielAPIEnum.CALIBRES_2}
                            options={referentiels?.CALIBRE2 ?? calibres2}
                            setOptions={setCalibres2}
                            chargerOptions={chargerReferentielDebounce}
                            filtreCalibre={true}
                        />
                    )}
                />
            </div>
            <div className={"recherche-avancee__titre"}>
                <label>Type de canon 2</label>
                <Controller
                    name="canons2.typeCanon"
                    control={form.control}
                    render={({field}) => (
                        <Controller
                            name="canons2.typeCanon"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? String(option.value) : "")
                                        rechercher(form.getValues())
                                    }}
                                    value={form.getValues('canons2.typeCanon') ? new SelectOption(form.getValues("canons2.typeCanon")) : null}
                                    field={field}
                                    referentiel={ReferentielAPIEnum.TYPES_CANON}
                                    options={referentiels?.TYPES_CANON_2 ?? typesCanon}
                                    setOptions={setTypesCanon}
                                    sansChargement
                                    chargerOptions={(referentiel, valeurChamp, setOptions, setChargementTermine) => setChargementTermine(true)}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>;
    }

    function canon3() {
        return <>
            <div className={"recherche-avancee__titre fr-mt-2w"}>
                <label>Calibre canon 3</label>
                <Controller
                    name="canons3.calibre"
                    control={form.control}
                    render={({field}) => (
                        <AutoCompleteRGA
                            onChange={(option: SelectOption) => {
                                field.onChange(option?.value ? String(option.value) : "")
                                rechercher(form.getValues())
                            }}
                            value={form.getValues('canons3.calibre') ? new SelectOption(form.getValues("canons3.calibre")) : null}
                            field={field}
                            referentiel={ReferentielAPIEnum.CALIBRES_3}
                            options={referentiels?.CALIBRE3 ?? calibres3}
                            setOptions={setCalibres3}
                            chargerOptions={chargerReferentielDebounce}
                            filtreCalibre={true}
                        />
                    )}
                />
            </div>
            <div className={"recherche-avancee__titre"}>
                <label>Type de canon 3</label>
                <Controller
                    name="canons3.typeCanon"
                    control={form.control}
                    render={({field}) => (
                        <Controller
                            name="canons3.typeCanon"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? String(option.value) : "")
                                        rechercher(form.getValues())
                                    }}
                                    value={form.getValues('canons3.typeCanon') ? new SelectOption(form.getValues("canons3.typeCanon")) : null}
                                    field={field}
                                    referentiel={ReferentielAPIEnum.TYPES_CANON}
                                    options={referentiels?.TYPES_CANON_3 ?? typesCanon}
                                    setOptions={setTypesCanon}
                                    sansChargement
                                    chargerOptions={(referentiel, valeurChamp, setOptions, setChargementTermine) => setChargementTermine(true)}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>;
    }

    function canon4() {
        return <>
            <div className={"recherche-avancee__titre fr-mt-2w"}>
                <label>Calibre canon 4</label>
                <Controller
                    name="canons4.calibre"
                    control={form.control}
                    render={({field}) => (
                        <AutoCompleteRGA
                            onChange={(option: SelectOption) => {
                                field.onChange(option?.value ? String(option.value) : "")
                                rechercher(form.getValues())
                            }}
                            value={form.getValues('canons4.calibre') ? new SelectOption(form.getValues("canons4.calibre")) : null}
                            field={field}
                            referentiel={ReferentielAPIEnum.CALIBRES_4}
                            options={referentiels?.CALIBRE4 ?? calibres4}
                            setOptions={setCalibres4}
                            chargerOptions={chargerReferentielDebounce}
                            filtreCalibre={true}
                        />
                    )}
                />
            </div>
            <div className={"recherche-avancee__titre"}>
                <label>Type de canon 4</label>
                <Controller
                    name="canons4.typeCanon"
                    control={form.control}
                    render={({field}) => (
                        <Controller
                            name="canons4.typeCanon"
                            control={form.control}
                            render={({field}) => (
                                <AutoCompleteRGA
                                    onChange={(option: SelectOption) => {
                                        field.onChange(option?.value ? String(option.value) : "")
                                        rechercher(form.getValues())
                                    }}
                                    value={form.getValues('canons4.typeCanon') ? new SelectOption(form.getValues("canons4.typeCanon")) : null}
                                    field={field}
                                    referentiel={ReferentielAPIEnum.TYPES_CANON}
                                    options={referentiels?.TYPES_CANON_4 ?? typesCanon}
                                    setOptions={setTypesCanon}
                                    sansChargement
                                    chargerOptions={(referentiel, valeurChamp, setOptions, setChargementTermine) => setChargementTermine(true)}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>;
    }

    function boutonAjoutCanon4() {
        return <div className={"d-flex fr-mr-2w fr-mt-2w"} style={{justifyContent: "flex-end"}}>
            <div className={"fr-m-1w"}
                 style={{color: "var(--text-title-blue-france)"}}>{calibre4Affiche ? "Supprimer" : "Ajouter"} Canon
                4
            </div>
            <Bouton
                icone={calibre4Affiche ? subtractLine : addLine}
                label="ajouter-canon"
                className={"recherche-avancee__ajouter-canon"}
                onClick={() => afficherCalibre4(!calibre4Affiche)}
            />
        </div>;
    }

    function separateur() {
        return <div className={"fr-mt-2w"} style={{borderBottom: "0.2rem solid var(--text-title-blue-france)"}}/>;
    }

    function modeFonctionnement() {
        return <div className={"texte-centre fr-my-1w recherche-avancee__titre"}>
            <label>Mode de fonctionnement</label>
            <Controller
                name="modeFonctionnement"
                control={form.control}
                render={({field}) => (
                    <AutoCompleteRGA
                        onChange={(option: SelectOption) => {
                            field.onChange(option?.value ? String(option.value) : "")
                            rechercher(form.getValues())
                        }}
                        value={form.getValues('modeFonctionnement') ? new SelectOption(form.getValues("modeFonctionnement")) : null}
                        field={field}
                        referentiel={ReferentielAPIEnum.FONCTIONNEMENTS}
                        options={referentiels?.MODE_FONCTIONNEMENT ?? modeDeFonctionnements}
                        setOptions={setModeDeFonctionnements}
                        chargerOptions={chargerReferentielDebounce}
                    />
                )}
            />
        </div>;
    }

    function systemeAlimentation() {
        return <div className={"texte-centre fr-my-1w recherche-avancee__titre"}>
            <label>Système d'alimentation</label>
            <Controller
                name="modeAlimentation"
                control={form.control}
                render={({field}) => (
                    <AutoCompleteRGA
                        onChange={(option: SelectOption) => {
                            field.onChange(option?.value ? String(option.value) : "")
                            rechercher(form.getValues())
                        }}
                        value={form.getValues('modeAlimentation') ? new SelectOption(form.getValues("modeAlimentation")) : null}
                        field={field}
                        referentiel={ReferentielAPIEnum.SYSTEMES_ALIMENTATION}
                        options={referentiels?.SYSTEME_ALIMENTATION ?? modeAlimentations}
                        setOptions={setModeAlimentations}
                        chargerOptions={chargerReferentielDebounce}
                    />
                )}
            />
        </div>;
    }

    return <form>
        <div style={{
            backgroundColor: "var(--text-title-blue-france)",
            borderRadius: "8px 8px 0 0",
            fontWeight: "bold",
            color: "white",
            padding: "0.35rem 0 0.25rem 0.8rem"
        }}>Filtrer par
        </div>
        <div style={{border: "0.2rem solid var(--text-title-blue-france)"}} className={"fr-pb-3w"}>
            {marque()}
            {modele()}
            {fabricant()}
        </div>
        <div style={{
            backgroundColor: "var(--text-title-blue-france)",
            color: "white",
            padding: "0.35rem 0 0.25rem 0.8rem"
        }}>
            Renseignez les calibres des canons lisses suivis des canons rayés, le cas échant.
        </div>
        <div style={{border: "0.2rem solid var(--text-title-blue-france)"}} className={"fr-pb-2w fr-pt-1w"}>
            <div className={"fr-mx-2w fr-my-1w"}>
                {canon1()}
                {canon2()}
                {canon3()}
                {calibre4Affiche && canon4()}
                {boutonAjoutCanon4()}
                {separateur()}
                {modeFonctionnement()}
                {systemeAlimentation()}
            </div>
        </div>
    </form>
})
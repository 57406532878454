import React, {useEffect, useState} from "react";
import Titre from "../../composants/Titre";
import {fr} from "../../constantes/ClassesDSFR";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import {Input} from "@design-system-etat/dsfr-react/lib/cjs";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {ConfigurationPageDeConnexion} from "../../entites/ConfigurationPageDeConnexion";
import {downloadImage} from "../../utils/Utils";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {MessageGenerique} from "../../constantes/MessageGenerique";
import {useAlerteContext} from "../../composants/RGAContext";

const AdministrationPageDeConnexion: React.FC = () => {

    const {mettreAjourAlerte} = useAlerteContext()
    const [configurationsPageDeConnexion, setConfigurationsPageDeConnexion] = useState([] as ConfigurationPageDeConnexion[])
    const [fileMedia, setFileMedia] = useState(Array<File>)

    useEffect(() => {
        recupererConfigurationPageDeConnexion()
    }, [])

    const recupererConfigurationPageDeConnexion = () => {
        RgaAppliAPI.recupererConfigurationPageDeConnexion()
            .then((reponse) => {
                setConfigurationsPageDeConnexion(reponse)
            })
    }

    const update = (field: string, item: number, value: string) => {
        // trick pour forcer le rerender du component après modif.
        let newConfigurationsPageDeConnexion = [...configurationsPageDeConnexion]
        newConfigurationsPageDeConnexion[item] = {
            ...newConfigurationsPageDeConnexion[item],
            [field]: value
        }
        setConfigurationsPageDeConnexion(newConfigurationsPageDeConnexion)
    }

    const handleInputFileChange = (e, mediaNumber) => {
        if (e.target.files[0].size > 1000000) {
            mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "La taille de l’image dépasse la taille maximale attendue (1Mo)."));
            e.target.value = null
        } else {
            if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg") {
                updatePhoto(e.target.files[0], mediaNumber)
            } else {
                e.target.value = null
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Format de fichier invalide, choisissez un fichier au format jpeg ou png"));
            }
        }
    }

    const updatePhoto = (file, mediaNumber) => {
        let newFileMedia = fileMedia
        newFileMedia[mediaNumber] = file
        setFileMedia(newFileMedia)
    }

    const enregistreConfigurationPageDeConnexion = () => {
        if (configurationsPageDeConnexion && !configurationsPageDeConnexion[0].id) {
            configurationsPageDeConnexion[0].id = 1
        }
        if (configurationsPageDeConnexion[1] && !configurationsPageDeConnexion[1].id) {
            configurationsPageDeConnexion[1].id = 2
        }
        RgaAppliAPI.enregistrerConfigurationPageDeConnexion(configurationsPageDeConnexion, fileMedia).then(() => {
            mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", MessageGenerique.MISE_A_JOUR_VALIDE));
        }).catch(
            () => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Erreur lors de la sauvegarde de la configuration de la page de connexion."))
        )
    }

    return (<section className={fr.my_6w + "fr-container"}>
        <Titre souligne>Administrer la page de connexion</Titre>
        <Row>
            <Col className={"mainContainerParametres " + fr.m_1w}>
                <Titre className={'centerText'}>Media 1</Titre>
                <Row alignementHorizontal={"center"} alignementVertical={"middle"} className={fr.mb_4w}>
                    <Col>
                        <label htmlFor="file-upload">Importez l'image</label>
                        <input className="fr-upload" type="file" id="file-upload" name="file-upload"
                               accept={"image/png, image/jpg, image/jpeg"}
                               onChange={e => handleInputFileChange(e, 0)}/></Col>
                    {configurationsPageDeConnexion[0] && configurationsPageDeConnexion[0].url &&
                        <Col xl={3}><Bouton label={"Télécharger"} niveau="tertiaire-sans-bordure" onClick={() => {
                            if (configurationsPageDeConnexion[0]) {
                                downloadImage(configurationsPageDeConnexion[0].url, configurationsPageDeConnexion[0].nom)
                            }
                        }}></Bouton></Col>}
                </Row>
                <Input label={"Titre"} type={"text"} name={"titre_1"}
                       value={configurationsPageDeConnexion[0] ? configurationsPageDeConnexion[0].titre : ""}
                       onChange={(e) => {
                           update('titre', 0, e.target.value)
                       }}></Input>
                <Input label={"Texte d'accompagnement"} type={"textarea"} name={"description_1"}
                       value={configurationsPageDeConnexion[0] ? configurationsPageDeConnexion[0].description : ""}
                       onChange={(e) => {
                           update('description', 0, e.target.value)
                       }}></Input>
            </Col>
            <Col className={"mainContainerParametres " + fr.m_1w}>
                <Titre className={'centerText'}>Media 2</Titre>
                <Row alignementHorizontal={"center"} alignementVertical={"middle"} className={fr.mb_4w}>
                    <Col>
                        <label htmlFor="file-upload">Importez l'image</label>
                        <input className="fr-upload" type="file" id="file-upload" name="file-upload"
                               accept={"image/png, image/jpg, image/jpeg"}
                               onChange={e => handleInputFileChange(e, 1)}/></Col>
                    {configurationsPageDeConnexion[1] && configurationsPageDeConnexion[1].url &&
                        <Col xl={3}><Bouton label={"Télécharger"} niveau="tertiaire-sans-bordure" onClick={() => {
                            if (configurationsPageDeConnexion[1]) {
                                downloadImage(configurationsPageDeConnexion[1].url, configurationsPageDeConnexion[1].nom)
                            }
                        }}></Bouton></Col>}
                </Row>
                <Input label={"Titre"} type={"text"} name={"titre_2"}
                       value={configurationsPageDeConnexion[1] ? configurationsPageDeConnexion[1].titre : ""}
                       onChange={(e) => {
                           update('titre', 1, e.target.value)
                       }}></Input>
                <Input label={"Texte d'accompagnement"} type={"textarea"} name={"description_2"}
                       value={configurationsPageDeConnexion[1] ? configurationsPageDeConnexion[1].description : ""}
                       onChange={(e) => {
                           update('description', 1, e.target.value)
                       }}></Input>
            </Col>
        </Row>
        <Row alignementHorizontal={"right"} className={fr.mt_2w}>
            <Bouton label={"Annuler"} niveau={"secondaire"}
                    onClick={recupererConfigurationPageDeConnexion}
                    className={fr.mr_2w}></Bouton>
            <Bouton label={"Enregister"} niveau={"primaire"}
                    onClick={enregistreConfigurationPageDeConnexion}>
            </Bouton>
        </Row>
    </section>);
}

export default AdministrationPageDeConnexion;

import Titre from "../../composants/Titre";
import "./EcranMonCompte.css"
import {Bouton, Col, Container, Input, Row} from "@design-system-etat/dsfr-react";
import React, {useEffect} from "react";
import {RgaAppliAPI, SessionService} from "../../infrastructure/InfrastructureFactory";
import {InputPassword} from "@design-system-etat/dsfr-react/lib/cjs";
import {useForm} from "react-hook-form";
import {UtilisateurAPIServiceImpl} from "../../infrastructure/api/UtilisateurAPIService";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {MessagesErreurEnum} from "../../constantes/MessagesErreurEnum";
import FormulaireRenouvellementMdp from "../../entites/FormulaireRenouvellementMdp";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {useAlerteContext} from "../../composants/RGAContext";
import {ServiceErrorsEnum} from "../../constantes/ServiceErrorsEnum";
import {useNavigate} from "react-router-dom";

export default function EcranMonCompte(): JSX.Element {

    const utilisateur = SessionService.getUtilisateurActuel()
    const form = useForm<FormulaireRenouvellementMdp>({mode: 'onChange'});
    const {mettreAjourAlerte, seConnecter} = useAlerteContext();
    const navigate = useNavigate();

    useEffect(()=> {
      form.setValue("mail",utilisateur.mail)
        // eslint-disable-next-line
    }, [])


    const modifierMdp = () => {
        UtilisateurAPIServiceImpl.verifierMotDePasse(form.getValues("mail"), form.getValues("nouveauMdp1"), form.getValues("nouveauMdp2"), form.getValues("ancienMdp"))
            .then(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", 'Mot de passe modifié.'))
                RgaAppliAPI.connexionLoginMdp(form.getValues("mail"), form.getValues("nouveauMdp1"))
                    .then(token => {
                        seConnecter(token);
                        navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                    })
                    .catch(() => {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Une erreur innatendue est survenue"))
                    })
            })
            .catch(reponse => {
                const typeErreur = reponse.response.data.error.type
                genererErreursChamp(typeErreur)
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", genererMessage(typeErreur)))
            })
    }


    const genererMessage = (erreur: ServiceErrorsEnum): string => {
        switch (erreur) {
            case ServiceErrorsEnum.MAUVAIS_ANCIEN_MDP :
                return 'Ancien mot de passe incorrect.'
            case ServiceErrorsEnum.MDP_IDENTIQUE :
                return 'Le nouveau mot de passe doit être différent de l’ancien.'
            case ServiceErrorsEnum.MDP_PAS_IDENTIQUE :
                return 'Erreur lors de la double saisie du nouveau mot de passe.'
            case ServiceErrorsEnum.NOUVEAU_MDP_INVALIDE :
                return 'Le mot de passe doit contenir au moins 10 caractères avec à minima une majuscule, une minuscule, un chiffre et un caractère spécial.'
            case ServiceErrorsEnum.COMPTE_BLOQUE :
                return "Votre compte est bloqué, veuillez contacter un administrateur du site via le lien 'Nous contacter'."
            case ServiceErrorsEnum.NOUVEAU_COMPTE :
                return 'Votre compte n\'a pas encore été validé. Vous ne pouvez pas renouveler votre mot de passe.'
            case ServiceErrorsEnum.UTILISATEUR_NON_TROUVE :
                return 'Le mail renseigné ne correspond à aucun compte. Si vous possédez un compte, veuillez vérifier les informations saisies. Si ce n’est pas le cas, veuillez créer un compte.'
        }
    }


    const genererErreursChamp = (erreur: ServiceErrorsEnum) => {
        switch (erreur) {
            case ServiceErrorsEnum.MAUVAIS_ANCIEN_MDP :
                form.reset({mail: form.getValues("mail"), ancienMdp: "", nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('ancienMdp', {type: "custom", message: 'Ancien mot de passe incorrect.'})
                break;
            case ServiceErrorsEnum.MDP_IDENTIQUE :
                form.reset({
                    mail: form.getValues("mail"),
                    ancienMdp: form.getValues("ancienMdp"),
                    nouveauMdp1: "",
                    nouveauMdp2: ""
                })
                form.setError('nouveauMdp1', {
                    type: "custom",
                    message: 'Le nouveau mot de passe doit être différent de l’ancien.'
                })
                break;
            case ServiceErrorsEnum.MDP_PAS_IDENTIQUE :
                form.reset({
                    mail: form.getValues("mail"),
                    ancienMdp: form.getValues("ancienMdp"),
                    nouveauMdp1: "",
                    nouveauMdp2: ""
                })
                form.setError('nouveauMdp1', {
                    type: "custom",
                    message: 'Erreur lors de la double saisie du nouveau mot de passe.'
                })
                form.setError('nouveauMdp2', {
                    type: "custom",
                    message: 'Erreur lors de la double saisie du nouveau mot de passe.'
                })
                break;
            case ServiceErrorsEnum.NOUVEAU_MDP_INVALIDE :
                form.reset({
                    mail: form.getValues("mail"),
                    ancienMdp: form.getValues("ancienMdp"),
                    nouveauMdp1: "",
                    nouveauMdp2: ""
                })
                form.setError('nouveauMdp1', {
                    type: "custom",
                    message: 'Le nouveau mot de passe ne respecte pas les conditions.'
                })
                break;
            case ServiceErrorsEnum.UTILISATEUR_NON_TROUVE :
                form.reset({mail: "", ancienMdp: "", nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('mail', {type: "custom", message: 'Le mail renseigné ne correspond à aucun compte.'})
        }
    }

    return (
        <div className={"monCompte"}>
            <Container>
                <Titre souligne>
                    Mes informations
                </Titre>

                <Row goutierre className={"infoMonCompte"}>
                    <Col col={6}>
                        <Input name={"Nom"} label={"Nom"} disabled defaultValue={utilisateur.nom}/>
                    </Col>
                    <Col col={6}>
                        <Input name={"Prénom"} label={"Prénom"} disabled defaultValue={utilisateur.prenom}/>
                    </Col>
                    <Col col={6}>
                        <Input name={"Société"} label={"Société"} disabled defaultValue={utilisateur.societe}/>
                    </Col>
                    <Col col={6}>
                        <Input name={"Siren"} label={"Siren"} disabled defaultValue={utilisateur.siren}/>
                    </Col>
                    <Col col={6}>
                        <Input name={"E-mail"} label={"E-mail"} disabled defaultValue={utilisateur.mail}/>
                    </Col>
                    <Col col={6}>
                        <div className="fr-input-group fr-input-group--warning">
                            <label
                                className="fr-label"
                                htmlFor="test">
                                <span className="fr-label-texte">Mot de passe</span>
                            </label>
                            <input
                                id="password"
                                name="password"
                                disabled
                                type="password"
                                className="fr-input"
                                value="xxxxxx"
                            />
                            <p id="test-desc" className="motDePasseNote">Votre mot de passe arrive à expiration
                                dans {utilisateur.expireDays} jour(s)</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <form onSubmit={form.handleSubmit(modifierMdp)}>
                <Container className={"bloc-bleu"}>
                    <Titre souligne>Changer le mot de passe</Titre>

                    <Row goutierre>
                        <Col col={4} className={"details"}>
                            <div><p>
                                Le mot de passe doit contenir
                            </p>

                                <ul>
                                    <li>au moins 1 majuscule</li>
                                    <li>au moins 1 minuscule</li>
                                    <li>au moins 1 chiffre</li>
                                    <li>au moins 1 caractère spécial</li>
                                    <li>10 caractères min et 20 caractères max.</li>
                                </ul>
                            </div>
                        </Col>

                        <Col col={4} className={"inputsMDP"}>


                            <InputPassword
                                maskFonction={texte => texte}
                                name={"ancienMdp"}
                                label={"Ancien mot de passe (ou mot de passe temporaire)"}
                                error={form.formState.errors.ancienMdp}
                                {...form.register('ancienMdp', {
                                    required: MessagesErreurEnum.CHAMP_REQUIS,
                                    maxLength: {value: 20, message: MessagesErreurEnum.LONGUEUR_MAX_20}
                                })}


                            />

                            <InputPassword
                                maskFonction={texte => texte}
                                name={"nouveauMdp"}
                                label={"Nouveau mot de passe"}
                                error={form.formState.errors.nouveauMdp1}
                                {...form.register('nouveauMdp1', {
                                    required: MessagesErreurEnum.CHAMP_REQUIS,
                                    maxLength: {value: 20, message: MessagesErreurEnum.LONGUEUR_MAX_20}
                                })}


                            />

                            <InputPassword
                                maskFonction={texte => texte}
                                name={"confirmeMdp"}
                                label={"Confirmer le nouveau mot de passe"}
                                error={form.formState.errors.nouveauMdp2}
                                {...form.register('nouveauMdp2', {
                                    required: MessagesErreurEnum.CHAMP_REQUIS,
                                    maxLength: {value: 20, message: MessagesErreurEnum.LONGUEUR_MAX_20}
                                })}

                            />


                        </Col>
                        <Col col={4} className={"boutons"}>

                            <Bouton taille="lg" label={"Annuler"} niveau={"secondaire"}
                                    onClick={() => {
                                        form.setValue("nouveauMdp1", "");
                                        form.setValue("nouveauMdp2", "");
                                        form.setValue("ancienMdp", "")
                                    }}/>
                            <Bouton taille="lg" label={"Enregistrer"} niveau={"primaire"} type={"submit"}/>

                        </Col>
                    </Row>

                </Container>
            </form>
        </div>
    )
};
import React from "react";
import {Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import "./ModaleTypeCanon.css"
import lisse_raye from "../../../resources/recherche-simplifiee/lisse_raye.svg";

interface ModaleLisseRayeProps {
    onFermerClick?: () => void
}

const ModaleLisseRaye : React.FC<ModaleLisseRayeProps> = ({onFermerClick}) => {
    return (<Modale id={"modal"}  taille={"lg"} closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>Canon lisse ou rayé ?</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-around"}>
                <img
                    alt={"Canon lisse ou rayé ?"}
                    src={lisse_raye}
                    className={"modale-type-canon-lisse-raye"}
                />
            </span>
        </ModaleContent>
    </Modale>)
}

export default ModaleLisseRaye;
import React from "react";
import Titre from "../../composants/Titre";
import "./EcranConditionsGenerales.css";
import {fr} from "../../constantes/ClassesDSFR";
import LienExterneRGA from "../../composants/LienExterneRGA";

const EcranConditionsGenerales : React.FC = () => {

    function presentation(){
        return <>
            <h4 className={"uppercase"}>1. Présentation</h4>
            <p>
                Les présentes conditions générales d’utilisation (ci-après “CGU”) ont pour objet de définir les conditions d’accès et d’utilisation du portail RGA
                accessible en ligne par le lien suivant : <LienExterneRGA>https://rga.sca.minint.fr/</LienExterneRGA>.
            </p>
            <p>
                Est défini comme Utilisateur du site (ci-après l’”Utilisateur” ou les “Utilisateurs”) toute personne se connectant au portail RGA.
            </p>
            <p>
                L’utilisation du portail RGA de manière occasionnelle ou régulière par l’utilisateur est subordonnée à l’acceptation pleine et entière,
                et au respect intégral des clauses, droits et obligations découlant des présentes CGU.
            </p>
            <p>
                Cette utilisation est strictement réservée à l’Utilisateur tel que défini dans les présentes CGU. Le Service central des armes et explosifs
                se réserve le droit de modifier ou de mettre à jour à tout moment les présentes CGU et sans accord préalable de l’utilisateur.
            </p>
            <p>
                L’utilisateur est tenu, à chaque visite, de prendre connaissance des éventuelles évolutions des présentes CGU.
            </p>
            <p>
                Pour toutes questions relatives au site et aux présentes CGU, vous pouvez contacter le SCAE à l’aide du formulaire de contact du portail RGA.
            </p>
        </>
    }

    function identiteResponsable(){
        return <>
            <h4 className={"uppercase"}>2. Identité du responsable de traitement</h4>
            <p>
                Le chef du Service central des armes et explosifs pour le ministre de l’Intérieur et des Outre-Mer.
            </p>
        </>
    }


    function objectifTraitementDonnees(){
        return <>
            <h4 className={"uppercase"}>3. Objectif du traitement de données</h4>
            <p>
                L’objectif principal du Référentiel Général des Armes est la mise en place d’un portail sécurisé permettant d’effectuer des demandes de classement d’armes,
                la consultation et l’alimentation du référentiel.
            </p>
            <p>
                Ouvert aux professionnels du domaine des armes dans un premier temps, puis aux usagers du domaines des armes, l’accès est autorisé via un compte RGA.
            </p>
        </>
    }

    function disponibilitesDesServices(){
        return <>
            <h4 className={"uppercase"}>4. Disponibilité des services</h4>
            <p>
                Le site est accessible 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d’évènement hors du contrôle du SCAE.<br/>
                Toutefois, une interruption pour raison de maintenance technique ou de mise à jour nécessaire au bon fonctionnement du portail et des matériels afférents,
                ou pour toute autre raison, peut-être décidée par le SCAE ; cet accès en tout ou partie du portail pouvant être suspendu sans avis préalable de l’utilisateur.
            </p>
            <p>
                L’utilisateur est informé que le SCAE peut mettre fin ou modifier les caractéristiques des services offerts sur le portail, à tout moment,
                sans préavis et sans que l’utilisateur ndispose d’un recours à l’encontre du SCAE.
            </p>
        </>
    }

    function dureeDonnees(){
        return <>
            <h4 className={"uppercase"}>5. Durée de conservation des données</h4>
            <p>
                Les données personnelles collectées sur le site sont conservées le temps de l’existence du compte et jusqu’à migration vers le SIA.
                </p>
            <p>
                Le journal des opérations de collecte relatives aux opérations des utilisateurs est conservé pendant une durée de 5 ans.
            </p>
        </>
    }

    function securite(){
        return <>
            <h4 className={"uppercase"}>6. Sécurité</h4>
            <p>
                Les mesures de sécurité sont mises en oeuvre conformément à la politique de sécurité des systèmes d’information (PSSI)
                du ministère de l’Intérieur et des Outre-Mer, issue de la PSSI de l’État.
            </p>
            <p>
                Le téléservice a fait l’objet d’une décision d’homologation.<br/>
                Les données à caractère personnel et relatives du portail RGA sont stockés par un service protégé du ministère de l’Intérieur et des Outre-Mer.
            </p>
            <p>
                En cas de violation ou compromission des données personnelles détenues, une notification sera transmise dans les 72 heures aux usagers concernés.
            </p>
        </>
    }

    function noticeLegale(){
        return <>
            <h4 className={"uppercase"}>7. Notice légale</h4>
            <p>
                L’utilisateur s’interdit toute action susceptible d’entraver ou de perturber le bon fonctionnement ou l’accessibilité technique au portail.
            </p>
            <p>
                L’utilisateur s’engage à accéder au site en utilisant un matériel ne contenant pas de virus.
            </p>
            <p>
                Le SCAE ne garantit pas que ses serveurs soient exempts de virus ou autres composants dangereux.
                Il appartient donc à l’utilisation de prendre toutes les mesures appropriées pour protéger ses propres données et/ou logiciels de la
                contamination par d’éventuels virus circulant sur le réseau Internet.
            </p>
        </>
    }


    function avertissement(){
        return <>
            <h4 className={"uppercase"}>8. Avertissement</h4>
            <p>
                Malgré tout le soin apporté par nos équipes éditoriales et techniques à la rédaction et la mise en ligne des documents,
                des erreurs typographiques ou des inexactitudes techniques ne peuvent être exclues. Le SCAE se réserve le droit de les corriger à tout moment
                dès qu’elles sont portées à sa connaissance.
            </p>
        </>
    }


    function liensHypertexte(){
        return <>
            <h4 className={"uppercase"}>9. Lien hypertextes entrants</h4>
            <p>
                Tout site public ou privé est autorisé à établir, sans autorisation préalable, un lien vers les informations diffusées dans ce site.
                En revanche les pages du site www.sca-rga.interieur.gouv.fr ne doivent pas imbriquées à l’intérieur des pages d’un autre site :
                rien ne doit méprendre le visiteur sur l’origine d’une page vue.<br/>
                Le SCAE se réserve le droit de demander la suppression d’un lien qu’elle estime non-conforme à l’objet du site,
                à ses missions ou plus généralement à l’éthique d’un portail public.<br/>
                L’autorisation de mise en place d’un lien valable pour otu support, à l’exception de ceux diffusant des informations à caractère polémique,
                pornographique, xénophophe ou pouvant, dans une plus large mesure porter atteinte à la sensibilité du plus grand nombre.
            </p>
        </>
    }


    function cookies(){
        return <>
            <h4 className={"uppercase"}>10. Cookies</h4>
            <p>
                Par la présente, il s’agit de cookies strictement nécessaires. Ces cookies sont indispensables au bon fonctionnement du site web et ne peuvent
                pas être désactivés de nos systèmes, ils ne sont généralement qu’activés en réponse à des actions que vous effectuez et qui correspondent à une
                demande de services, comme la configuration de vos préférences de confidentialité, la connexion ou le remplissage de formulaires.
                Vous pouvez configurer votre navigateur pour bloquer ou être alerté de l’utilisation de ces cookies.
                Cependant, si cette catégorie de cookies - qui ne stocke aucune donnée personnelle - est bloquée, certaines parties du site ne pouront pas fonctionner.
            </p>
        </>
    }


    function stabiliteAdresse(){
        return <>
            <h4 className={"uppercase"}>11. Stabilité des adresses</h4>
            <p>
                Nous essayons autant que faire se peut, de maintenir des adresses URL de pages stables.
                Toutefois, ce site pouvant faire à tout moment l’objet de réorganisations, nous ne pouvons pas garantir la stabilité de l’adresse des pages internes du site.
            </p>
        </>
    }


    function proprieteIntelectuelle(){
        return <>
            <h4 className={"uppercase"}>12. Propriété intellectuelle</h4>
            <p>
                L’usager s’interdit sous peine de voir sa responsabilité civile et/ou pénale engagée notamment de modifier, copier, reproduire, télécharger,
                diffuser, transmettre, exploiter commercialement et/ou distribuer de quelque façon que ce soit les services, les pagesdu portail RGA,
                ou les codes informatiques des éléments composant les services du portail.
            </p>
            <div>
                <p>L’usager s’engage également à ne pas utiliser le sit pour :</p>
            </div>
            <ul>
                <li>
                    transmettre par quelque procédé que ce soit ( e-mail ou autre), tout contenu incluant des programmes, des codes, des virus, etc.
                    destinés à détruire ou limiter les fonctionnalités du site internet de l’éditeur.
                </li>
                <li>
                    transmettre par quelque procédé que ce soit dans les services interactifs proposés sur le site internet de l’éditeur,
                    tout contenu illicite ou nuisible, et notamment des photos ou messages à caractère injurieux, insultant, diffament, dénigrant,
                    dégradant, raciste, xénophobe, sexiste, pornographique, menaçant ou qui pourrait inciter à la haine ou au harcèlement et qui irait
                    à l’encontre du droit au respect de la vie privée ou  sans rapport avec les thèmes proposés.
                </li>
                <li>
                    transmettre par quelque procédé que ce soit, tout contenu et notamment des photos violant le droit de la propriété intellectuelle, industrielle,
                    les droits de la personne, etc.
                </li>
            </ul>
            <p>
                Les signes distinctifs du ministère de l’Intérieur et des Outre-mer, tels que les noms de domaine, marques, dénominations ainsi que les logos figurant su le portail RGA sont protéges par le Code de la propriété intellectuelle. Toute reproduction totale ou partielle de ces signes distinctifs effectuée à partir des éléments du portail RGA sans autorisation expresse du ministère de l’Intérieur et des Outre-mer est donc prohibé, au sens du Code de la propriété intellectuelle.
            </p>
        </>
    }


    function droitsDonnees(){
        return <>
            <h4 className={"uppercase"}>13. Vos droits sur les données vous concernant </h4>
            <p>
                Conformément au Règlement Général pour la Protection des Données (RGPD), les informations recueillies font l’objet d’un enregistrement
                au registre du correspondant délégué à la protection des données (CDPD).<br/>
                L’utilisateur peut accéder et obtenir copie de ses données et des traitements effectués sur celles-ci.<br/>
                L’utilisateur peut rectifier ou faire rectifier les données le concernant.
            </p>
        </>
    }


    function exercerDroits(){
        return <>
            <h4 className={"uppercase"}>13.1. exercer ses droits</h4>
            <div>
                <p>Le correspondant délégué à la protection des données (CDPD) du SCAE est votre interlocuteur pour toute demande d’exercice de vos droits sur ce traitement.</p>
                <ul>
                    <li>
                        <p> Contacter le correspondant délégué à la protection des données (CDPD) par voie électronique : <b>donnees-personnelles-sca@interieur.gouv.fr</b></p>
                    </li>
                    <li>
                        <p> Contacter le correspondant délégué à la protection des données (CDPD) par courrier postal :</p>
                    </li>
                </ul>
                <span className={"fr-pl-2w"}>Ministère de l’intérieur et des Outre-mer</span><br/>
                <span className={"fr-pl-2w"}>Secrétariat général</span><br/>
                <span className={"fr-pl-2w"}>Service Central des Armes et Explosifs</span><br/>
                <span className={"fr-pl-2w"}>À l’attention du correspondant délégué à la protection des données (CDPD)</span><br/>
                <span className={"fr-pl-2w"}>Place Beauvau</span><br/>
                <span className={"fr-pl-2w"}>75008 Paris</span><br/>
            </div>
            <p>
                Dans les deux cas, veuillez joindre à votre demande la copie de votre carte nationale d’identité en cas d’exercice de vos droits.
            </p>
        </>
    }


    function reclamation(){
        return <>
            <h4 className={"uppercase"}>13.2. Réclamation (plainte) auprès de la cnil</h4>
            <p>
                Si vous estimez, après nous avoir contactés, que vos droits sur vos données ne sont pas respectés,
                vous pouvez adresser une réclamation (plainte) à la CNIL à l’adresse : <LienExterneRGA>https://www.cnil.fr/fr/plaintes</LienExterneRGA>
            </p>
        </>
    }

    return (<section className={fr.my_4w + "fr-container"}>

        <Titre souligne>Condition générales d'utilisation</Titre>

        {presentation()}
        {identiteResponsable()}
        {objectifTraitementDonnees()}
        {disponibilitesDesServices()}
        {dureeDonnees()}
        {securite()}
        {noticeLegale()}
        {avertissement()}
        {liensHypertexte()}
        {cookies()}
        {stabiliteAdresse()}
        {proprieteIntelectuelle()}
        {droitsDonnees()}
        {exercerDroits()}
        {reclamation()}

    </section>);
}

export default EcranConditionsGenerales;
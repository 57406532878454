import React from "react";
import PropTypes from "prop-types";
import "../index.css"
import {useAlerteContext} from "./RGAContext";

AlerteRGATemporise.propTypes = {
    type: PropTypes.oneOf(['error', 'warning', 'success', 'info']).isRequired,
    titre: PropTypes.string,
    description: PropTypes.string.isRequired
}

export default function AlerteRGATemporise(props: any): JSX.Element {
    const {type, titre, description} = props;
    const {fermerAlerte} = useAlerteContext();

    return <div className="alerte" onClick={fermerAlerte}>
        <div className={"fr-alert fr-alert--" + type}>
            {titre && <h3 className="fr-alert__title">{titre}</h3>}
            <p>{description}</p>
        </div>
    </div>
}
import React, {useEffect} from "react";
import {BoutonRadio} from "@design-system-etat/dsfr-react/lib/cjs";
import "../EcranRechercheSimplifiee.css"
import {fr} from "../../../constantes/ClassesDSFR";
import {Controller, useForm} from "react-hook-form";
import {AutoCompleteRGA} from "../../../composants/SelectHorsDsfr/AutoCompleteRGA";
import {SelectOption} from "../../../entites/Referentiels/SelectOption";

interface RechSimplCarteReferentielProps {
    titre: string;
    estActif?: boolean;
    avecTexteSaisie?: boolean;
    onRadioClick: () => any;
    onReferentielChange: (marque: string) => void
    valeur: string
    options: SelectOption[]
}

const RechSimplCarteReferentiel: React.FC<RechSimplCarteReferentielProps> = ({
                                                                                 titre,
                                                                                 estActif,
                                                                                 onRadioClick,
                                                                                 avecTexteSaisie,
                                                                                 onReferentielChange,
                                                                                 valeur,
                                                                                 options
                                                                             }) => {

    const form = useForm<{ valeurReferentiel: string }>({mode: 'onChange', defaultValues: {valeurReferentiel: valeur}});

    useEffect(() => {
        if (estActif) {
            form.reset({valeurReferentiel: valeur})
            if (valeur && valeur !== '-') {
                onReferentielChange(valeur)
            }
        }
    }, [estActif]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={"rech-simpl-carte-marque"}>
        <div className={"rech-simpl-carte__presentation " + (estActif ? "rech-simpl-carte__presentation--active" : "")}>
            <div className={"d-flex flex-center rech-simpl-carte__radio"}>
                <BoutonRadio
                    label={titre}
                    name={"radio"}
                    value={titre}
                    defaultChecked={estActif}
                    onClick={onRadioClick}
                />
            </div>
            {estActif && (<>
                {avecTexteSaisie && <p className={fr.ml_2w}>Je saisis dans les champs ci-dessous :</p>}
                <div className={fr.px_2w}>
                    <Controller
                        name="valeurReferentiel"
                        control={form.control}
                        render={({field}) => (
                            <AutoCompleteRGA
                                onChange={(option: SelectOption) => {
                                    if (estActif) {
                                        field.onChange(option?.value ? option.value : "")
                                        onReferentielChange(form.getValues("valeurReferentiel"))
                                    }
                                }}
                                sansChargement
                                value={form.getValues('valeurReferentiel') ? new SelectOption(form.getValues("valeurReferentiel")) : null}
                                field={field}
                                options={options}
                            />
                        )}
                    />
                </div>
            </>)}
        </div>
    </div>
}

export default RechSimplCarteReferentiel;

export const TexteModaleParametres =
    {
        COMPTE_DUREE_EXPIRATION_MDP: {
            description: "Veuillez renseigner la nouvelle durée de validation des mots de passe",
            label: "Valeur de durée de validation des mots de passe"
        },

        COMPTE_NB_MAX_TENTATIVE_CONNEXION: {
            description: "Veuillez renseigner le nouveau nombre de tentatives de connexion erronées avant blocage du compte",
            label: "Valeur du nombre de tentatives de connexion erronées avant blocage du compte"
        },

        IMPORT_EN_MASSE_MAX_SIZE: {
            description: "Veuillez renseigner la nouvelle taille maximale du fichier d'import en masse",
            label: "Valeur de la taille maximale du fichier d'import en masse"
        },

        CAROUSEL_INTERVAL: {
            description: "Veuillez renseigner la nouvelle durée en millisecondes durant laquelle une image du carrousel est affichée.",
            label: "Valeur de la durée en millisecondes durant laquelle une image du carrousel est affichée."
        },

        MAIL_REPLY_TO: {
            description: "Veuillez renseigner le nouveau mail Reply-to",
            label: "Valeur du mail Reply-to"
        },

        MAIL_RETURN_PATH: {
            description: "Veuillez renseigner le nouveau mail Return-Path",
            label: "Valeur du mail Return-Path"
        },

        NB_MAX_TRACES_EXPORTEES: {
            description: "Veuillez renseigner le nouveau nombre maximum de lignes présentes dans l'export des traces",
            label: "Valeur du nombre maximum de lignes présentes dans l'export des traces"
        },

        COMPTE_DELAI_AVERTISSEMENT_MDP: {
            description: "Veuillez renseigner le nouveau délai d'avertissement avant expiration des mots de passe",
            label: "Valeur du délai d'avertissement avant expiration des mots de passe"
        },

        MAIL_CONTACT: {
            description: "Veuillez renseigner le nouveau mail de contact",
            label: "Valeur du mail de contact"
        },

        MAIL_FROM :  {
            description :"Veuillez renseigner le nouveau mail d'expédition",
            label : "Valeur du mail d'expédition"
        }
    }
import Arme from "./Arme";
import {
    DEMANDES_STATUTS,
    DEMANDES_STATUTS_LIBELLES_CREER_ARME,
    DEMANDES_STATUTS_LIBELLES_TRAITER_ARME
} from "../constantes/DemandeStatutEnum";
import {UtilisateurProfilCategorieEnum} from "../constantes/UtilisateurProfilCategorieEnum";
import {auFormat2Caracteres} from "../utils/DateUtils";

export interface DemandeArme {
    "@id": string,
    id: number,
    type: string,
    ficheArme: Arme,
    statut: DEMANDES_STATUTS,
    createur: UtilisateurDemandeArme,
    "validateur": UtilisateurDemandeArme,
    "dateCreation": Date,
    "dateMaj": Date,
    "dateDepot": Date,
    "dateValidation": Date,
    "dateSuppression": Date
}

interface UtilisateurDemandeArme {
    id: number,
    prenom: string,
    nom: string,
    societe: string,
    siren: string,
    siret: string,
    ssoId: string
}

export interface DemandeArmeLite {
    id: number,
    statut: string,
    dateCreation: Date,
    dateMaj: Date,
    dateDepot: Date,
    demandeur: string,
    marque: string,
    modele: string,
    calibre: string,

}

export interface DemandeArmeFiltre {
    id: number,
    statut: string[],
    dateCreation: Date,
    dateMaj: Date,
    dateDepot: Date,
    marque: string,
    modele: string,
    calibre: string,
    demandeur:string
}

export const demandeArmeColonneOrdre = {
    id: 1,
    statut: 2,
    dateCreation: 3,
    dateMaj: 4,
    dateDepot: 5,
    demandeur: 6,
    marque: 7,
    modele: 8,
    calibre: 9

}

export const demandeArmeToDemandeArmeLite = (demande: DemandeArme): DemandeArmeLite => {
    return {
        id: demande?.id,
        statut: demande?.statut,
        dateCreation: demande?.dateCreation,
        dateMaj: demande?.dateMaj,
        dateDepot: demande?.dateDepot,
        demandeur: demande?.createur.societe,
        marque: demande?.ficheArme?.marque?.libelle ?? demande?.ficheArme?.marqueSaisie,
        modele: demande?.ficheArme?.modele?.libelle ?? demande?.ficheArme?.modeleSaisie,
        calibre: demande?.ficheArme?.canons[0]?.calibre?.libelle,
    }
}


export const demandeToLigneCsv = (demande: DemandeArme,categorieProfil?:UtilisateurProfilCategorieEnum) => {

    let statut = ""
    if (categorieProfil=== UtilisateurProfilCategorieEnum.PRO) {
            statut = DEMANDES_STATUTS_LIBELLES_CREER_ARME[demande.statut] ?? ""

    } else if (categorieProfil === UtilisateurProfilCategorieEnum.SCAE) {
            statut =  DEMANDES_STATUTS_LIBELLES_TRAITER_ARME[demande.statut]??""
    } else {
        statut= DEMANDES_STATUTS_LIBELLES_TRAITER_ARME.depose_validation
    }

    return {
        "Num demande": demande.id,
        "Statut": statut,
        "Date de soumission": demande.dateDepot ? auFormat2Caracteres(new Date(demande.dateDepot).getDate()) + "/" + auFormat2Caracteres(new Date(demande.dateDepot).getMonth() + 1) + "/" + auFormat2Caracteres(new Date(demande.dateDepot).getFullYear()) :"",
        "Demandeur": demande.createur.societe,
        famille: demande.ficheArme.famille?.libelle,
        typeArme: demande.ficheArme.typeArme?.libelle,
        marque: demande.ficheArme.marque?.libelle ?? demande.ficheArme?.marqueSaisie,
        modele: demande.ficheArme.modele?.libelle ?? demande.ficheArme?.modeleSaisie,
        fabricant: demande.ficheArme.fabricant?.libelle ?? demande.ficheArme?.fabricantSaisie,
        paysFabricant: demande.ficheArme.pays?.libelle,
        modeFonctionnement: demande.ficheArme.modeFonctionnement?.libelle,
        systemeAlimentation: demande.ficheArme.modeAlimentation?.libelle,
        longueurArme: demande.ficheArme.longueur,
        capaciteHorsChambre: demande.ficheArme.capaciteHorsChambre,
        capaciteChambre: demande.ficheArme.capaciteChambre,
        calibreCanonUn: demande.ficheArme.canons.at(0)?.calibre?.libelle,
        modePercussionCanonUn: demande.ficheArme.canons.at(0)?.modePercussion?.libelle,
        typeCanonUn: demande.ficheArme.canons.at(0)?.typeCanon?.libelle,
        "longueurCanonUn(mm/po)": demande.ficheArme.canons.at(0)?.longueurCanon,
        calibreCanonDeux: demande.ficheArme.canons.at(1)?.calibre?.libelle,
        modePercussionCanonDeux: demande.ficheArme.canons.at(1)?.modePercussion?.libelle,
        typeCanonDeux: demande.ficheArme.canons.at(1)?.typeCanon?.libelle,
        "longueurCanonDeux(mm/po)": demande.ficheArme.canons.at(1)?.longueurCanon,
        calibreCanonTrois: demande.ficheArme.canons.at(2)?.calibre?.libelle,
        modePercussionCanonTrois: demande.ficheArme.canons.at(2)?.modePercussion?.libelle,
        typeCanonTrois: demande.ficheArme.canons.at(2)?.typeCanon?.libelle,
        "longueurCanonTrois(mm/po)": demande.ficheArme.canons.at(2)?.longueurCanon,
        calibreCanonQuatre: demande.ficheArme.canons.at(3)?.calibre?.libelle,
        modePercussionCanonQuatre: demande.ficheArme.canons.at(3)?.modePercussion?.libelle,
        typeCanonQuatre: demande.ficheArme.canons.at(3)?.typeCanon?.libelle,
        "longueurCanonQuatre(mm/po)": demande.ficheArme.canons.at(3)?.longueurCanon,
        apparenceArmeAutomatique: demande.ficheArme.apparenceArmeGuerre ? "Oui" : "Non",
        prototype: demande.ficheArme.prototype ? "Oui" : "Non",
        designationCommerciale: demande.ficheArme.designationCommerciales.map(desi => desi.libelle)
    }
}

import React, {useEffect, useState} from "react";
import "../../index.css"
import {CarteHeader, CarteImage, Icone} from "@design-system-etat/dsfr-react/lib/cjs";
import "./EcranRechercheAvancee.css"
import {
    Bouton,
    Carte,
    CarteBody,
    CarteContent,
    CarteDescription,
    CarteTitre,
    downloadLine
} from "@design-system-etat/dsfr-react";
import {arrowGoBackLine, arrowLeftLine} from "@design-system-etat/dsfr-react/lib/esm";
import Pictogram from "../../entites/Pictogramme";
import search from "@gouvfr/dsfr/dist/artwork/pictograms/digital/search.svg";
import warning from "@gouvfr/dsfr/dist/artwork/pictograms/system/warning.svg";
import {ReferentielAPIService} from "../../infrastructure/InfrastructureFactory";
import Arme from "../../entites/Arme";
import {fr} from "../../constantes/ClassesDSFR";
import {FiltrageArmeAvancee, FiltrageArmeAvanceeRef} from "./FiltrageArmeAvancee";
import {
    criteresAvanceesToCriteresArmes,
    CriteresRechercheAvancee,
    CriteresRechercheAvanceeInitial
} from "../../entites/CriteresRechercheArme";
import {ReferentielsOptions} from "../../entites/Referentiels/Referentiels";
import {SelectOption} from "../../entites/Referentiels/SelectOption";
import ModaleRecapArme from "../ecran-recherche-simplifiee/resultats/ModaleRecapArme";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {FicheArmeActionEnum} from "../../constantes/FicheArmeActionEnum";
import {exportRechercheArmeToCsv} from "../../infrastructure/arme/ExportService";
import {useAlerteContext} from "../../composants/RGAContext";
import {PageTableau} from "../../entites/PageTableau";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import TableauResultatRecherche from "../ecran-recherche-simplifiee/resultats/TableauResultatRecherche";
import PaginationRGA from "../../composants/PaginationRGA";

const EcranRechercheAvancee: React.FC = () => {

    const [armesPage, setArmesPage] = useState<PageTableau<Arme>>();
    const [pageCourante, setPageCourante] = useState<number>(1);
    const [criteresRecherche, setCriteresRecherche] = useState<CriteresRechercheAvancee>(CriteresRechercheAvanceeInitial);
    const [tableauEnChargement, setTableauEnChargement] = useState<boolean>(false);
    const [tableauAffiche, setTableauAffiche] = useState<boolean>(false);
    const [referentiels, setReferentiels] = useState<ReferentielsOptions>(null);
    const filtrageRef = React.createRef<FiltrageArmeAvanceeRef>();
    const [elementsParPage, setElementsParPage] = useState<number>(25);
    const [modaleArmeOuvert, setModaleArmeOuvert] = useState<boolean>(false);
    const [armeModale, setArmeModale] = useState<Arme>(null);
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    const [tri, setTri] = useState({})
    const {mettreAjourAlerte} = useAlerteContext()

    const pictoRechercheArme: Pictogram = {
        svg: search,
        alt: "pictogram recherche"
    };

    const pictoPrecautionAvantRecherche: Pictogram = {
        svg: warning,
        alt: "pictogram precaution avant la recherche"
    };

    useEffect(() => {
        if (Object.keys(tri).length > 0) {
            rechercher(criteresRecherche, pageCourante, elementsParPage, tri)
        }
    }, [tri]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleExport() {
        mettreAJourDateDerniereAction();
        if(armesPage?.nombreElementsTotal <5000) {
            ReferentielAPIService.rechercherArmes(criteresAvanceesToCriteresArmes(criteresRecherche), 1, armesPage.nombreElementsTotal, false)
                .then(armesTableau => {
                    const armesIds: number[] = armesTableau.elements.map(arme => {
                        return arme.id
                    })
                    if (armesIds.length > 300) {
                        getPageTableauArmePourGrandExport(armesIds).then((armes: PageTableau<Arme>) => {
                            exportRechercheArmeToCsv(armes.elements)
                        })
                    } else {
                        ReferentielAPIService.rechercherArmesPourExport(armesIds).then((armes: PageTableau<Arme>) => {
                            exportRechercheArmeToCsv(armes.elements)
                        })
                    }
                })
        }else{
            mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "Trop de résultats pour l'export, veuillez affiner votre recherche."," A noter : un export du catalogue complet est possible sur l'écran d'accueil."))
        }
    }


    function modifierTri(cle: string) {
        if (Object.keys(tri).includes(cle)) {
            if (tri[cle] === "ASC") {
                setTri({[cle]: "DESC"})
            } else {
                setTri({});
            }
        } else {
            setTri({[cle]: "ASC"})
        }

    }

    const getPageTableauArmePourGrandExport = async (
        armesIds: number[]
    ): Promise<PageTableau<Arme>> => {
        let armeIdPourExportSliced: Array<Array<number>> = [];
        for (let index = 0; index < armesIds.length; index += 300) {
            armeIdPourExportSliced.push(armesIds.slice(index, index + 300));
        }

        let promisesRechercheArmesPourExport: Array<Promise<PageTableau<Arme>>> =
            [];

        for (const armeIdPourExportSlice of armeIdPourExportSliced) {
            promisesRechercheArmesPourExport.push(
                ReferentielAPIService.rechercherArmesPourExport(armeIdPourExportSlice)
            );
        }

        const reponsesRechercheArmesPourExport = await Promise.all(
            promisesRechercheArmesPourExport
        );

        return reponsesRechercheArmesPourExport.reduce(
            (resultatRechercheArmesPourExport: PageTableau<Arme>, armeIdPourExportSlice: PageTableau<Arme>): PageTableau<Arme> => {
                return {
                    elements: resultatRechercheArmesPourExport.elements.concat(
                        armeIdPourExportSlice.elements
                    ),
                    nombrePages: 1,
                    nombreElementsTotal:
                        resultatRechercheArmesPourExport.nombreElementsTotal +
                        armeIdPourExportSlice.nombreElementsTotal,
                };
            },
            new PageTableau<Arme>([] as Array<Arme>, 0, 0)
        );
    };

    const visiterArme = (sia: string) => {
        mettreAJourDateDerniereAction();
        window.open(URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + sia, "_blank")
    }

    const rechercher = (criteres: CriteresRechercheAvancee, pageDemande: number, elementsParPageDemande: number, tri?: {}) => {
        mettreAJourDateDerniereAction();
        if (JSON.stringify(criteres) !== JSON.stringify(CriteresRechercheAvanceeInitial)) {
            ReferentielAPIService.recupererReferentielsFiltres(criteresAvanceesToCriteresArmes(criteres), false).then(referentielsRecus => {
                let nouveauxReferentiels = {...referentielsRecus}
                Object.keys(referentielsRecus).forEach(cle => nouveauxReferentiels[cle] = referentielsRecus[cle].map(referentiel => new SelectOption(referentiel)))
                // @ts-ignore
                setReferentiels(nouveauxReferentiels)
            })
            setTableauAffiche(true)
            setTableauEnChargement(true)
            ReferentielAPIService.rechercherArmes(criteresAvanceesToCriteresArmes(criteres), pageDemande, elementsParPageDemande, false, tri).then(pageTableau => {
                setPageCourante(pageDemande)
                setArmesPage(pageTableau)
                setCriteresRecherche(criteres)
                setTableauEnChargement(false)
            })
        } else {
            setReferentiels(null)
            setTableauAffiche(false)
        }
    }

    const cartePrecaution = () => {
        return <Carte isHorizontal ratioHorizontal="tier">
            <CarteBody>
                <CarteContent>
                    <CarteTitre>
                        Recherche avancée
                    </CarteTitre>
                    <CarteDescription>
                        <span className={"d-flex"}>
                            <span className={"texte-bleu"}>
                                <Icone icone={arrowLeftLine}/>
                            </span>
                            <span className={"fr-ml-1w"}>
                                Définissez vos critères de filtres dans le menu latéral gauche
                            </span>
                        </span>
                    </CarteDescription>
                </CarteContent>
            </CarteBody>
            <CarteHeader>
                <CarteImage>
                    <img
                        src={pictoRechercheArme.svg}
                        alt={pictoRechercheArme.alt}
                        className=" recherche-avancee__img fr-responsive-img"
                    />
                </CarteImage>
            </CarteHeader>
        </Carte>
    }

    const carteRechercheAvancee = () => {
        return <Carte isHorizontal ratioHorizontal="tier" taille={"md"}>
            <CarteBody>
                <CarteContent>
                    <CarteTitre>
                        Précautions avant la recherche
                    </CarteTitre>
                    <CarteDescription>
                        <><span>Une arme doit toujours être considérée comme chargée</span> <br/>
                            <span>Une arme ne doit jamais être dirigée vers quelqu'un</span><br/>
                            <span>Avant de manipuler une arme, toujours s'assurer qu'elle est mise en sécurité</span></>
                    </CarteDescription>
                </CarteContent>
            </CarteBody>
            <CarteHeader>
                <CarteImage>
                    <img
                        src={pictoPrecautionAvantRecherche.svg}
                        className="recherche-avancee__img fr-responsive-img"
                        alt={pictoPrecautionAvantRecherche.alt}
                    />
                </CarteImage>
            </CarteHeader>
        </Carte>
    }

    return (<>
        {modaleArmeOuvert && (<ModaleRecapArme
            arme={armeModale}
            onFermerClick={() => {
                mettreAJourDateDerniereAction();
                setModaleArmeOuvert(false)
            }}
            onAccederFicheClick={() => visiterArme(armeModale.sia)}
        />)}
        <span className={"surcharge-dsfr-recherche-avance"}>
        <section className={"fr-container fr-my-6w"}>
            <div className={"d-flex flex-space-between"}>
                <div className={fr.mb_2w + "d-flex"}>
                    <div>
                        <img src={pictoRechercheArme.svg} alt={pictoRechercheArme.alt}/>
                    </div>
                    <div>
                        <div style={{marginTop: "1rem"}}></div>
                        <span style={{fontWeight: "bold", fontSize: "1.5rem"}}>Rechercher une arme</span>
                        <br/>
                        <span style={{fontWeight: "bold", fontSize: "1rem"}}>Recherche avancée</span>
                    </div>
                </div>
                <div className={"fr-pt-2w"}>
                    <Bouton
                        icone={arrowGoBackLine}
                        label="Réinitialiser"
                        iconePosition="left"
                        onClick={() => {
                            mettreAJourDateDerniereAction();
                            filtrageRef.current.reset();
                            setReferentiels(null)
                            setTableauAffiche(false)
                            setTri({})

                        }}
                    />
                </div>
            </div>
            <div className={"d-flex "}>
                <div className={"recherche-avancee__filtrage"}>
                    <FiltrageArmeAvancee
                        ref={filtrageRef}
                        referentiels={referentiels}
                        rechercher={valeursFormulaire => {
                            setPageCourante(1)
                            rechercher(valeursFormulaire, 1, elementsParPage)
                        }}
                    />
                </div>
                <div className={fr.ml_2w + "recherche-avancee__precautions"}>
                    {!tableauAffiche && <>
                        <div className={"fr-m-4w"}>
                            {carteRechercheAvancee()}
                        </div>
                        <div className={"fr-m-4w"}>
                            {cartePrecaution()}
                        </div>
                    </>}
                    {tableauAffiche && (<>
                        {tableauEnChargement && (
                            <div className={fr.mt_2w + "texte-centre"}>Chargement en cours ...</div>
                        )}
                        {!tableauEnChargement && (<>
                            {armesPage?.elements?.length === 0 && (
                                <div className={fr.mt_2w + "texte-centre"}>Aucun résultat</div>
                            )}
                            {armesPage?.elements?.length > 0 && (<div id={"tableau-recherche-avancee"}>
                                <TableauResultatRecherche
                                    handleExport={handleExport}
                                    resultats={armesPage}
                                    onTri={modifierTri}
                                    tri={tri}
                                    onAfficherInformation={(arme) => {
                                        mettreAJourDateDerniereAction();
                                        setModaleArmeOuvert(true)
                                        setArmeModale(arme)
                                    }}
                                />
                                <PaginationRGA
                                    pageCourante={pageCourante}
                                    totalPages={armesPage.nombrePages}
                                    changePage={pageDemande => {
                                        rechercher(criteresRecherche, pageDemande, elementsParPage)
                                    }}
                                    elementsParPageParDefaut={elementsParPage}
                                    onElementsParPageChange={elementsParPageRecu => {
                                        setElementsParPage(elementsParPageRecu)
                                        setPageCourante(1)
                                        rechercher(criteresRecherche, 1, elementsParPageRecu)
                                    }}
                                    optionElementsParPage={[25, 50, 100]}
                                />
                                <Bouton
                                    icone={downloadLine}
                                    iconePosition="right"
                                    label={"Exporter"}
                                    niveau="primaire"
                                    onClick={handleExport}
                                />
                            </div>)}
                        </>)}
                    </>)}
                </div>
            </div>
        </section>
        </span>
    </>);
};

export default EcranRechercheAvancee;

import React, {useEffect, useState} from "react";
import {fr} from "../../../constantes/ClassesDSFR";
import RechSimplCarte from "../carte/RechSimplCarte";
import RechSimplCarteMarque from "../carte/RechSimplCarteReferentiel";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";
import {RechSimplMarqueEnum, RechSimplTypeArmeEnum} from "../../../constantes/RechSimplOptionsEnum";
import {useAlerteContext} from "../../../composants/RGAContext";

interface RechSimplMarqueProps {
    onSuivantClick: (marque: string, estMarqueArtisante: boolean) => any
    onPrecedentClick: () => any
    onAfficherResultatClick: () => any
}

const RechSimplMarque: React.FC<RechSimplMarqueProps> = ({
                                                             onSuivantClick,
                                                             onPrecedentClick,
                                                             onAfficherResultatClick
                                                         }) => {

    const {criteres, referentiels} = useRechercheSimplifieeContext();
    const [marque, setMarque] = useState<{ libelle: string, estMarqueArtisante: boolean }>({
        libelle: "",
        estMarqueArtisante: false
    });
    const [typeMarqueSelectionne, setTypeMarqueSelectionne] = useState<RechSimplMarqueEnum>(null);
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    useEffect(() => {
        let estArtisan: boolean
        if (criteres.marque) {
            if (criteres.marque === RechSimplMarqueEnum.INCONNU) {
                estArtisan = false
                setTypeMarqueSelectionne(RechSimplMarqueEnum.INCONNU)
            } else {
                estArtisan = !!criteres.estMarqueArtisante
                setTypeMarqueSelectionne(criteres.estMarqueArtisante ? RechSimplMarqueEnum.ARTISAN : RechSimplMarqueEnum.NON_ARTISAN)
            }
        } else {
            estArtisan = false
            setTypeMarqueSelectionne(null)
        }
        setMarque({libelle: `${criteres.marque}`, estMarqueArtisante: estArtisan})
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <div className={"w-100"}>
            <h4 className={"texte-centre"}>Marque</h4>
            <div className={fr.mt_2w + "d-flex flex-space-between"}>
                <RechSimplCarteMarque
                    valeur={marque.estMarqueArtisante ? "" : marque.libelle}
                    titre={"Je connais la marque"}
                    estActif={typeMarqueSelectionne === RechSimplMarqueEnum.NON_ARTISAN}
                    avecTexteSaisie
                    options={referentiels?.MARQUE}
                    onRadioClick={() => setTypeMarqueSelectionne(RechSimplMarqueEnum.NON_ARTISAN)}
                    onReferentielChange={marque => {
                        if (typeMarqueSelectionne === RechSimplMarqueEnum.NON_ARTISAN) {
                            setMarque({libelle: marque, estMarqueArtisante: false})
                            setTypeMarqueSelectionne(RechSimplMarqueEnum.NON_ARTISAN)
                        }
                    }}
                />
                {(criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU 
                    && referentiels.MARQUE_ARTISAN && referentiels.MARQUE_ARTISAN.length > 0 ) &&
                    <RechSimplCarteMarque
                        valeur={marque.estMarqueArtisante ? marque.libelle : ""}
                        titre={"Marque non identifiable (Artisan)"}
                        estActif={typeMarqueSelectionne === RechSimplMarqueEnum.ARTISAN}
                        options={referentiels?.MARQUE_ARTISAN}
                        onRadioClick={() => setTypeMarqueSelectionne(RechSimplMarqueEnum.ARTISAN)}
                        onReferentielChange={marque => {
                            if (typeMarqueSelectionne === RechSimplMarqueEnum.ARTISAN) {
                                setMarque({libelle: marque, estMarqueArtisante: true})
                                setTypeMarqueSelectionne(RechSimplMarqueEnum.ARTISAN)
                            }
                        }}
                    />
                }
                <RechSimplCarte
                    titre={"Je ne la connais pas"}
                    estActif={typeMarqueSelectionne === RechSimplMarqueEnum.INCONNU}
                    onClick={() => {
                        setTypeMarqueSelectionne(RechSimplMarqueEnum.INCONNU)
                    }}
                />
            </div>
        </div>
        <BoutonsEcranRecherche
            onAfficherResultatClick={onAfficherResultatClick}
            onPrecedentClick={onPrecedentClick}
            onSuivantClick={() => {
                mettreAJourDateDerniereAction();
                if (typeMarqueSelectionne !== RechSimplMarqueEnum.INCONNU) {
                    onSuivantClick(marque.libelle, typeMarqueSelectionne === RechSimplMarqueEnum.ARTISAN)
                } else {
                    onSuivantClick("-", false)
                }
            }}
            suivantInterdit={
                !marque.libelle &&
                (((typeMarqueSelectionne === RechSimplMarqueEnum.ARTISAN) === marque.estMarqueArtisante) || ((typeMarqueSelectionne === RechSimplMarqueEnum.NON_ARTISAN) === marque.estMarqueArtisante)) &&
                typeMarqueSelectionne !== RechSimplMarqueEnum.INCONNU}
        />
    </>
}

export default RechSimplMarque;

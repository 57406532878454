/**
 *
 * Module Javascript Captcha
 * v1.0
 *
 * No jquery, IE8+ compatible
 *
 */
var MiCaptcha = (function () {

    /*
     * Service Captcha
     */
    var serviceBaseUrl = null;

    /**
     * Internal variables
     */
    var captchaId = null; //Current CAPTCHA ID
    var rootElement = null;
    var application = null;
    var elements = {
        "container"       : "mi-captcha",
        "image"           : "mi-captcha-image",
        "audioPlayButton" : "mi-captcha-audio-play",
        "audio"           : "mi-captcha-audio",
        "refreshButton"   : "mi-captcha-refresh"
    };
    var inputHidden = {
        "application" : "mi-captcha-application",
        "captchaId"   : "mi-captcha-id"
    };

    var getImageElement = function() {
        return rootElement.getElementsByClassName(elements.image)[0];
    };

    var getAudioElement = function() {
        return rootElement.getElementsByClassName(elements.audio)[0];
    };

    /**
     * Initialisation module
     */
    var init = function(options) {

        let elts = document.getElementsByClassName(elements.container);
        rootElement = elts[0];
        application = options.application;
        serviceBaseUrl = options.serviceBaseUrl;

        //Events handlers
        var audioPlayButton = rootElement.getElementsByClassName(elements.audioPlayButton)[0];
        audioPlayButton.addEventListener('click', function(ev) {
            if (ev) {
                ev.preventDefault();
                ev.stopPropagation();
            }
            getAudioElement().play();
        });
        var refreshCaptchaButton = rootElement.getElementsByClassName(elements.refreshButton)[0];
        refreshCaptchaButton.addEventListener('click', refreshCaptcha);

        //Create hidden inputs
        var inputCaptcha = document.createElement('div');
        inputCaptcha.innerHTML = '<input type="hidden" name="' + inputHidden.application + '"/><input type="hidden" name="' + inputHidden.captchaId + '"/>';
        rootElement.appendChild(inputCaptcha);

        //Init Captcha
        refreshCaptcha();
    };

    /**
     * Récupération du flux audio Captcha
     */
    var requestAudio = function() {
        if (captchaId !== null) {

            var xhr = new XMLHttpRequest();
            var audioUrl = serviceBaseUrl + '/getaudio?application='+ application + '&id=' + captchaId + '&tt=' + (new Date()).getTime();
            xhr.open('GET', audioUrl, true);
            xhr.responseType = 'blob';

            //PRELOAD AUDIO : XHR Events
            xhr.onreadystatechange = function() {
                // Success : Request OK
                if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    getAudioElement().src = audioUrl;
                }
            };
            //SEND ASYNC
            xhr.send();
        }
    };

    /**
     * Génération nouveau captcha
     */
    var refreshCaptcha = function(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        var xhr = new XMLHttpRequest();
        var url = serviceBaseUrl + '/getimage?application='+ application + '&tt=' + (new Date()).getTime();
        xhr.open('GET', url, true);
        xhr.responseType = 'json';

        //XHR Events
        xhr.onreadystatechange = function() {

            var imageElement = getImageElement();

            // Success : Request OK
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                imageElement.src = this.response.image;
                imageElement.alt = 'captcha';

                //Set Captcha Id instance
                captchaId = this.response.id;
                //Load audio file
                requestAudio(ev);
                //Set hidden fields
                document.getElementsByName(inputHidden.application)[0].value = application;
                document.getElementsByName(inputHidden.captchaId)[0].value = captchaId;
            } // Fail
            else if (this.readyState === XMLHttpRequest.DONE) {
                captchaId = null;
                imageElement.src = '';
                imageElement.alt = 'Erreur captcha';
            }
        };

        //SEND ASYNC
        xhr.send();
    };

    return {
        load: init,
        refresh: refreshCaptcha,
    };

}());

export default MiCaptcha;
import React, {useEffect, useState} from "react";
import "./App.css";
import {Navigate, Route, Routes} from "react-router-dom";
import EcranDesFonctionnalites from "./pages/ecran-des-fonctionnalites/EcranDesFonctionnalites";
import EcranDeConnexion from "./pages/ecran-de-connexion/EcranDeConnexion";
import FooterRGA from "./pages/footer/FooterRGA";
import EcranAccessibilite from "./pages/ecran-accessibilite/EcranAccessibilite";
import EcranConditionsGenerales from "./pages/ecran-conditions-generales/EcranConditionsGenerales";
import EcranNousContacter from "./pages/ecran-nous-contacter/EcranNousContacter";
import {URLPageEnum} from "./constantes/URLPageEnum";
import EcranConsulterLesTraces from "./pages/ecran-consulter-les-traces/EcranConsulterLesTraces";
import FicheArme from "./pages/fiche-arme/FicheArme";
import {AdministrationDroit} from "./pages/administration/administration-des-droits/AdministrationDroit";
import {useAlerteContext} from "./composants/RGAContext";
import {AlertesRGA} from "./composants/AlertesRGA";
import EcranRechercheAvancee from "./pages/ecran-recherche-avancee/EcranRechercheAvancee";
import AdministrationDesReferentiels from "./pages/administration-referentiels/AdministrationDesReferentiels";
import {SessionService} from "./infrastructure/InfrastructureFactory";
import RouteProtege from "./utils/navigation/RouteProtege";
import HeaderRga from "./pages/header/HeaderRga";
import AdministrationDesParametres from "./pages/administration-parametres/AdministrationDesParametres";
import {
    EcranAdministrationUtilisateurs
} from "./pages/ecran-administration-utilisateurs/EcranAdministrationUtilisateurs";
import {UtilisateurDroitEnum} from "./constantes/UtilisateurDroitEnum";
import EcranCreationCompte from "./pages/ecran-de-connexion/composants/EcranCreationCompte";
import EcranMonCompte from "./pages/ecran-mon-compte/EcranMonCompte";
import EcranRechercheSimplifiee from "./pages/ecran-recherche-simplifiee/EcranRechercheSimplifiee";
import {RechercheSimplifieeContextProvider} from "./composants/RechercheSimplifieeContext";
import EcranRenouvelerMotDePasse from "./pages/ecran-renouveler-mot-de-passe/EcranRenouvelerMotDePasse";
import EcranDeVie from "./pages/ecran-de-vie/EcranDeVie";
import {FilArianeRGA} from "./composants/FilArianeRGA";
import {routesFilAriane} from "./constantes/RouteFilAriane";
import {UtilisateurProfilCategorieEnum} from "./constantes/UtilisateurProfilCategorieEnum";
import {AdministrationDesArmes} from "./pages/administration-demandes-arme/AdministrationDesArmes";
import EcranImportMasseDemande from "./pages/ecran-import-masse-demande/EcranImportMasseDemande";
import AdministrationPageDeConnexion from "./pages/administration-page-de-connexion/AdministrationPageDeConnexion";
import EcranImportFichesMasse from "./pages/ecran-import-fiches-masse/EcranImportFichesMasse";

export const App: React.FC = () => {

    const {estUtilisateurConnecte} = useAlerteContext();
    const [profilUtilisateur, setProfilUtilisateur] = useState<UtilisateurProfilCategorieEnum>(null)

    useEffect(() => {
        setProfilUtilisateur(SessionService.getProfilCategorie())
    }, [estUtilisateurConnecte])

    return (
        <>
            <HeaderRga/>
            <FilArianeRGA routes={routesFilAriane}/>
            <AlertesRGA/>
            <Routes>
                <Route index path="/"
                       element={estUtilisateurConnecte ? <EcranDesFonctionnalites/> : <EcranDeConnexion/>}/>
                <Route path={URLPageEnum.CONNEXION_LIVRE_DE_POLICE}
                       element={estUtilisateurConnecte ? <EcranDesFonctionnalites/> : <EcranDeConnexion/>}/>
                <Route path={URLPageEnum.CONNEXION_DETENTEUR}
                       element={estUtilisateurConnecte ? <EcranDesFonctionnalites/> : <EcranDeConnexion/>}/>
                <Route path={URLPageEnum.ACCESSIBILITE} element={<EcranAccessibilite/>}/>
                <Route path={URLPageEnum.NOUS_CONTACTER} element={<EcranNousContacter/>}/>
                <Route path={URLPageEnum.CONDITIONS_GENERALES} element={<EcranConditionsGenerales/>}/>
                <Route path={URLPageEnum.CREATION_COMPTE} element={<EcranCreationCompte/>}/>
                <Route path={URLPageEnum.RENOUVELER_MOT_DE_PASSE} element={<EcranRenouvelerMotDePasse/>}/>
                <Route element={<RouteProtege/>}>
                    <Route path={URLPageEnum.ECRAN_FONCTIONNALITE} element={
                        <EcranDesFonctionnalites/>}
                    />
                    <Route path={URLPageEnum.RECHERCHE_AVANCEE_ARMES} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.CONSULTER_FICHES]}>
                            <EcranRechercheAvancee/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.RECHERCHE_SIMPLIFIEE} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.CONSULTER_FICHES]}>
                            <RechercheSimplifieeContextProvider>
                                <EcranRechercheSimplifiee/>
                            </RechercheSimplifieeContextProvider>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.ECRAN_CONSULTER_LES_TRACES} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.CONSULTER_TRACES]}>
                            <EcranConsulterLesTraces/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.ADMINISTRATION_DROITS} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.GERER_DROITS]}>
                            <AdministrationDroit/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.FICHE_ARME + "/:action/:idFiche?"} element={
                        // La gestion des droits est géré dans le composant directement
                        <FicheArme afficherDemande={false}/>}
                    />
                    <Route path={URLPageEnum.DEMANDE_ARME + "/:action/:idFiche?"} element={
                        // La gestion des droits est géré dans le composant directement
                        <FicheArme afficherDemande={true}/>}
                    />
                    <Route path={URLPageEnum.ADMINISTRATION_REFERENTIELS + "/:referentiel?"} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS]}>
                            <AdministrationDesReferentiels/>
                        </RouteProtege>
                    }/>
                    <Route path={URLPageEnum.CONSULTER_DEMANDES_ARMES} element={
                        <RouteProtege
                            droitsRequis={profilUtilisateur === UtilisateurProfilCategorieEnum.PRO ?
                                [UtilisateurDroitEnum.CREER_DEMANDE_FICHE]
                                :
                                [UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS]
                            }
                        >
                            <AdministrationDesArmes/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.ADMINISTRATION_PARAMETRES} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.GERER_APPLICATION]}>
                            <AdministrationDesParametres/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.ADMINISTRATION_UTILISATEURS} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.GERER_DROITS]}>
                            <EcranAdministrationUtilisateurs/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.HEALTH_CHECK} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.VISIBILITE_HEALTHCHECK]}>
                            <EcranDeVie/>
                        </RouteProtege>
                    }/>
                    <Route path={URLPageEnum.ADMINISTRATION_PAGE_DE_CONNEXION} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.GERER_PAGE_CONNEXION]}>
                            <AdministrationPageDeConnexion/>
                        </RouteProtege>
                    }/>
                    <Route path={URLPageEnum.IMPORT_MASSE_DEMANDE} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.IMPORTER_DEMANDES_FICHES_MASSE]}>
                            <EcranImportMasseDemande/>
                        </RouteProtege>}
                    />
                    <Route path={URLPageEnum.IMPORT_MASSE_FICHES} element={
                        <RouteProtege droitsRequis={[UtilisateurDroitEnum.CREER_FICHE]}>
                            <EcranImportFichesMasse/>
                        </RouteProtege>}
                    />
                    {SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO &&
                        <Route path={URLPageEnum.CONSULATION_COMPTE} element={
                            <EcranMonCompte/>
                        }/>
                    }
                </Route>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
            <FooterRGA/>
        </>
    );
};


export enum UtilisateurDroitEnum {
    GERER_COMPTES = "GERER_COMPTES",
    CONSULTER_FICHES = "CONSULTER_FICHES",
    VISIBILITE_FICHES = "VISIBILITE_FICHES",
    CREER_FICHE = "CREER_FICHE",
    CREER_DEMANDE_FICHE = "CREER_DEMANDE_FICHE",
    IMPORTER_DEMANDES_FICHES_MASSE = "IMPORTER_DEMANDES_FICHES_MASSE",
    TRAITER_DEMANDES_FICHES_ET_REFERENTIELS = "TRAITER_DEMANDES_FICHES_ET_REFERENTIELS",
    GERER_APPLICATION = "GERER_APPLICATION",
    VISIBILITE_HEALTHCHECK = "VISIBILITE_HEALTHCHECK",
    GERER_DROITS = "GERER_DROITS",
    SUPPRESSION_DEMANDES = "SUPPRESSION_DEMANDES",
    CONSULTER_TRACES = "CONSULTER_TRACES",
    GERER_PAGE_CONNEXION = "GERER_PAGE_CONNEXION",
    GERER_PARAM_APPLI = "GERER_PARAM_APPLI"
}
import {
    Bouton,
    Col,
    GroupeBouton,
    Input,
    Modale,
    ModaleContent,
    ModaleFooter,
    ModaleTitre,
    Row
} from "@design-system-etat/dsfr-react"
import {useParams} from "react-router";
import {ReferentielConstanteType, ReferentielsConstantes} from "../../../constantes/ReferentielsConstantes";
import {Toogle} from "@design-system-etat/dsfr-react/lib/esm/components/interface/Form";
import React, {useEffect, useState} from "react";
import Titre from "../../../composants/Titre";
import {Loader} from "@design-system-etat/dsfr-react/lib/cjs";

interface ModaleReferentielProps {
    isOpen: boolean,
    onClose: () => any,
    modeEdition: boolean,
    valeurInitiale: any,
    onClick: (elementReferentiel: any, modeRequete: "AJOUTER" | "EDITER" | "ACTIVER_STATUS" | "ACTIVER_ARTISAN") => any,
    isLoading: boolean,
    titre: string
}

const ModaleReferentiel: React.FC<ModaleReferentielProps> = (props) => {
    const {isOpen, onClose, modeEdition, valeurInitiale, onClick, isLoading, titre} = props
    const params = useParams()
    const referentiel: ReferentielConstanteType = Object.values(ReferentielsConstantes).find(item => item.id === params.referentiel)
    const [elementReferentiel, setElementReferentiel] = useState(genererValeurInitiale())

    useEffect(() => {
        if (isOpen) {
            if (modeEdition)
            setElementReferentiel(valeurInitiale)
            else
                setElementReferentiel(genererValeurInitiale())
        } else {
            setElementReferentiel(genererValeurInitiale())
        }
    }, [isOpen, modeEdition])//eslint-disable-line react-hooks/exhaustive-deps

    function genererValeurInitiale(): object {
        let valeur = {actif: true}
        referentiel.modale['champs'].forEach((champ) => {
            if(champ.nom ==="artisan"){
                valeur = {...valeur, [champ.nom]: false}
            }else {
            valeur = {...valeur, [champ.nom]: ""}
            }
        })
        return valeur
    }

    function afficherTexteInput(champ): JSX.Element {
        return (
            <div className={"modale-referentiel-texte-input"} key={champ.nom}>
                <Input
                    label={champ.description}
                    name={champ.nom}
                    maxLength={50}
                    value={elementReferentiel[champ.nom]}
                    onChange={(e) => (setElementReferentiel({
                        ...elementReferentiel,
                        [champ.nom]: e.target.value
                    }))}
                />
            </div>
        );
    }

    function afficherToggleInput(champ): JSX.Element {
        return (
            <Row key={champ.nom}>
                <Col lg={3} md={3} sm={3}>
                    <div> {champ.description} :</div>
                </Col>
                <Col lg={6} className={"modale-referentiel-toggle-input"}>
                    {champ.option[0]}
                    <div>
                        <Toogle
                            label=""
                            name={champ.nom}
                            checked={elementReferentiel[champ.nom]}
                            onChange={(e) => (setElementReferentiel({...elementReferentiel, [champ.nom]: e.target.checked}))}
                        />
                    </div>
                    {champ.option[1]}
                </Col>
            </Row>
        )
    }

    function afficherActifToggle(): JSX.Element {

        return (
            <div className={"modale-referentiel-actif-input"}>
                Actif
                <Toogle
                    label=""
                    name={"actif"}
                    defaultChecked={true}
                    onChange={(e) => (setElementReferentiel({...elementReferentiel, actif: e.target.checked}))}
                />
            </div>
        );
    }

    function afficherChampsSelonType(referentiel) {
        return <>
            {referentiel.modale['champs'].map((champ) => {
                switch (champ.type) {
                    case 'text':
                        return afficherTexteInput(champ);
                    case 'toggle':
                        return afficherToggleInput(champ);
                    default :
                        return <></>
                }
            })}
        </>;
    }

    return (
        isOpen && <div className={"modaleRef"}>

            <Modale
                closeCallback={onClose}
                id="modale-referentiel"
            >

                <ModaleTitre idModale="icone">
                    <Titre>
                        {modeEdition ? "Modifier" : "Créer"}
                    </Titre>

                </ModaleTitre>

                {isLoading ?
                    <ModaleContent>
                        <Loader/>
                    </ModaleContent>
                    :
                    (<ModaleContent>
                        <div className={"modaleContent"}>
                            <p>{titre}</p>
                            {afficherChampsSelonType(referentiel)}
                            {!modeEdition && afficherActifToggle()}
                        </div>
                    </ModaleContent>)}

                <ModaleFooter>
                    <GroupeBouton
                        alignementHorizontal="right"
                        isHorizontal
                        reverse
                        iconePosition="left"
                    >
                        <Bouton
                            icone={modeEdition ? "" : "fr-icon-checkbox-circle-fill"}
                            iconePosition="left"
                            disabled={elementReferentiel["libelle"] === ""}
                            label={modeEdition ? "Valider" : "Ajouter"}
                            niveau="primaire"
                            type="submit"
                            onClick={() => onClick(elementReferentiel, modeEdition ? "EDITER" : "AJOUTER")}
                        />
                    </GroupeBouton>
                </ModaleFooter>
            </Modale>
        </div>

    )
}

export default ModaleReferentiel
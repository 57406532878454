import React from "react";
import Arme from "../../../entites/Arme";
import {fr} from "../../../constantes/ClassesDSFR";
import Canon from "../../../entites/Canon";
import {Bouton, GroupeBouton, ModaleFooter, Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react";

interface ModaleRecapArmeProps {
    arme?: Arme,
    onFermerClick?: () => void
    onAccederFicheClick: () => void
}

const ModaleRecapArme: React.FC<ModaleRecapArmeProps> = ({arme, onFermerClick, onAccederFicheClick}) => {

    const genererInfo = (titre: string, contenu: string | number) => {
        return <div className={"bloc-bordure-bleu"}>
            <div className={"info-titre"}><b>{titre}</b></div>
            <div className={"info-libelle"}>{contenu}</div>
        </div>
    }

    const genererCanon = (numeroCanon: number, canon: Canon) => {
        return <div className={"bloc-bordure-bleu bloc-canon"}>
            <div className={fr.mb_2w + " info-titre-canon"}><b>CANON {numeroCanon}</b></div>
            {canon ?
                <>
                    <div className={fr.mb_2w + " info-canon"}>
                        <div className={"texte-desactive-gris"}>Calibre</div>
                        <div className={"canon-libelle"}>{canon.calibre.libelle}</div>
                    </div>
                    <div className={fr.mb_2w + " info-canon"}>
                        <div className={"texte-desactive-gris"}>Type canon</div>
                        <div className={"canon-libelle"}>{canon.typeCanon.libelle}</div>
                    </div>
                    <div className={fr.mb_2w + " info-canon"}>
                        <div className={"texte-desactive-gris"}>Longueur du canon (mm/po)</div>
                        <div className={"canon-libelle"}>{canon.longueurCanon}</div>
                    </div>
                </>
                :
                <p>Aucun</p>
            }

        </div>
    }

    return (<span className={"modal-recap-arme-surcharge-DSFR"}>
        <Modale id={"modal"} taille={"lg"}
                closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>Aperçu fiche RGA</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-around"}>
                {genererInfo("Réf. RGA", arme.sia)}
                {genererInfo("Class Nat.", arme.classementValideFrance.libelle)}
                {genererInfo("Marque", arme.marque.libelle)}
                {genererInfo("Modèle", arme.modele.libelle)}
            </span>
            <span className={"d-flex flex-space-between"}>
                {genererInfo("Fabricant", arme.fabricant.libelle)}
                {genererInfo("Mode de fonctionnement", arme.modeFonctionnement.libelle)}
                {genererInfo("Système d'alimentation", arme.modeAlimentation.libelle)}
            </span>
            <span className={"d-flex flex-space-between"}>
                {genererCanon(1, arme.canons?.at(0))}
                {genererCanon(2, arme.canons?.at(1))}
                {genererCanon(3, arme.canons?.at(2))}
                {genererCanon(4, arme.canons?.at(3))}
            </span>
        </ModaleContent>
        <ModaleFooter>
            <GroupeBouton
                alignementHorizontal="right"
                isHorizontal
                reverse
            >
                <Bouton className={fr.mr_2w + fr.mt_2w} niveau={"primaire"} label={"Accéder à la fiche RGA"}
                        onClick={onAccederFicheClick}/>
            </GroupeBouton>
        </ModaleFooter>

    </Modale>
    </span>)
}

export default ModaleRecapArme;
export enum DEMANDES_STATUTS {
    BROUILLON = 'brouillon',
    DEPOSE = 'depose_validation', //soumettre hors prototype
    INCOMPLET = 'a_completer',
    VALIDE = 'valide',
    REFUSE = 'refuse',
    CLASSEMENT = 'depose_classement', //soumettre prototype
    CLASSEMENT_VALIDE = 'classement_valide',
    DESIGNATION_VALIDE = 'designations_a_valider',
    DESIGNATION_TRAITE = 'designations_traitees',
}

export enum DEMANDES_STATUTS_LIBELLES_CREER_ARME {
    brouillon = "Brouillon",
    a_completer = "À compléter",
    depose_validation = "Déposée",
    depose_classement = "Déposée (P)",
    refuse = "Refusée",
    valide = "Validée"
}

export enum DEMANDES_STATUTS_LIBELLES_TRAITER_ARME {
    depose_validation = "À valider",
    a_completer = "À compléter",
    depose_classement = "À classer (P)",
    refuse = "Refusée",
    valide = "Validée"
}
import React, {useEffect} from "react";
import InformationsConnexion from "./composants/InformationsConnexion";
import CompteRGA from "./composants/CompteRGA";
import {fr} from "../../constantes/ClassesDSFR";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";

const EcranDeConnexion: React.FC = () => {
    const {seDeconnecter,estUtilisateurConnecte,mettreAjourAlerte} = useAlerteContext();

    useEffect(() => {
        if (estUtilisateurConnecte) {
            seDeconnecter()
            mettreAjourAlerte(new  Alerte(typeAlerte.INFORMATION, "","Vous êtes déconnecté."))
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section id="ecran-connexion" className={fr.my_4w + "fr-container"}>
            <InformationsConnexion/>
            <CompteRGA/>
        </section>
    )
}

export default EcranDeConnexion;

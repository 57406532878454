export enum StatutDemandeEnum {
    STATUT_BROUILLON = 'brouillon',
    STATUT_DEPOSE = 'depose_validation',
    STATUT_INCOMPLETE = 'a_completer',
    STATUT_VALIDE = 'valide',
    STATUT_REFUSE = 'refuse',
    STATUT_DEMANDE_CLASSEMENT = 'depose_classement',
    STATUT_DEMANDE_CLASSEMENT_VALIDE = 'classement_valide',
    STATUT_DESIGNATIONS_A_VALIDER = 'designations_a_valider',
    STATUT_DESIGNATIONS_TRAITEES = 'designations_traitees',
    STATUT_SUPPRIME = 'supprime',
}
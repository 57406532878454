import React, {useEffect, useState} from "react";
import "../EcranDeConnexion.css"
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import {accountCircleFill} from "@design-system-etat/dsfr-react/lib/cjs/components/fondamentaux/Icone";
import {Input} from "@design-system-etat/dsfr-react/lib/cjs";
import {userFill} from "@design-system-etat/dsfr-react/lib/esm";
import Titre from "../../../composants/Titre";
import {fr} from "../../../constantes/ClassesDSFR";
import {lireParametreTechnique} from "../../../utils/parametre/ParametreTechniqueService";
import {ParametreTechniqueEnum} from "../../../constantes/ParametreTechniqueEnum";
import {useLocation, useNavigate} from "react-router-dom";
import {RgaAppliAPI, SessionService} from "../../../infrastructure/InfrastructureFactory";
import {URLPageEnum} from "../../../constantes/URLPageEnum";
import {useAlerteContext} from "../../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../../entites/Alerte";
import {ModalMdpReinit} from "./ModalMdpReinit/ModalMdpReinit";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";

const CompteRGA: React.FC = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const [connexionValide, setConnexionValide] = useState<boolean>(false);
    const [utilisateurConnecte, setUtilisateurConnecte] = useState<boolean>(false);
    const [login,setLogin] =useState<string>("");
    const [motDePasse,setMotDePasse] =useState<string>("");
    const [modalMdpReinit, setModalMdpReinit] = useState<boolean>(false);
    const [erreurIdentification,setErreurIdentification]=useState<boolean>(false);

    const {mettreAjourAlerte, seConnecter, mettreAJourDateDerniereAction} = useAlerteContext();

    useEffect(()=>{
       setErreurIdentification(false)
    },[motDePasse,login])

    useEffect(() => {
        lireParametreTechnique(ParametreTechniqueEnum.SSO_ACTIF).then(ssoActif => {
            mettreAJourDateDerniereAction();
            if (ssoActif) {
                RgaAppliAPI.connexionSSO()
                    .then(validerConnexion)
                    .catch(() => {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Erreur connexion SSO."))
                    })
            } else if (location.pathname.includes(URLPageEnum.CONNEXION_LIVRE_DE_POLICE)) {
                RgaAppliAPI.connexionLivreDePolice()
                    .then(validerConnexion)
                    .catch(() => {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", MessagesErreurEnum.ERREUR_CONNEXION))
                    })
            } else if (location.pathname.includes(URLPageEnum.CONNEXION_DETENTEUR)) {
                RgaAppliAPI.connexionDetenteur()
                    .then(validerConnexion)
                    .catch(() => {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", MessagesErreurEnum.ERREUR_CONNEXION))
                    })
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (utilisateurConnecte && connexionValide) {
            navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
        }
    }, [utilisateurConnecte, connexionValide, navigate])

   const connexionLoginMdp= ()=>{
       RgaAppliAPI.connexionLoginMdp(login, motDePasse)
           .then(reponse => {
               validerConnexion(reponse);
               if(SessionService.isExpireBientot()){
                   const message = 'Votre mot de passe expire dans ' + SessionService.getNbJoursAvantExpirationMdp() + ' jours, vous pouvez le renouveler dès maintenant.'
                   mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", message))
                   navigate(URLPageEnum.RENOUVELER_MOT_DE_PASSE, { state: message})
               }
           })
           .catch((erreur) => {
               setErreurIdentification(erreur.erreurIdentification)
               if(erreur.doitChangerMotDePasse){
                   navigate(URLPageEnum.RENOUVELER_MOT_DE_PASSE, { state: erreur.message})
               } else {
                   mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", erreur.message))
               }
           })
   }

   const validerConnexion = (token: string) => {
       seConnecter(token);
       setUtilisateurConnecte(true);
       setConnexionValide(true);
   }

    const creationdeCompte = () => {
        return <>
            <Titre className="texte-bleu">Création de compte</Titre>
            <p className="texte-bleu">Vous ne disposez pas encore de compte professionnel sur l'espace RGA ?</p>
            <div>
                <Bouton
                    icone={accountCircleFill}
                    iconePosition="left"
                    label="Créer mon compte"
                    niveau="primaire"
                    onClick={() => {
                        navigate(URLPageEnum.CREATION_COMPTE)
                    }}
                />
            </div>
        </>
    }

    return <>
        {modalMdpReinit && <ModalMdpReinit
            onAnnulerClick={() => setModalMdpReinit(false)}
            onFermerClick={() => setModalMdpReinit(false)}
            onValiderClick={() => setModalMdpReinit(false)}
        />}
        <div className="bloc-bleu">
            <Titre souligne>Compte RGA</Titre>
            <Row className="d-flex">
                <Col col={6} className="bloc-creation-de-compte texte-centre">
                    {creationdeCompte()}
                </Col>
                <Col col={6}>
                    <Titre className="texte-bleu texte-centre">Connexion</Titre>
                    <p className="texte-bleu texte-centre">Vous disposez déjà d'un compte ? Connectez-vous :</p>
                    <Row alignementHorizontal={"center"}>
                        <Col className="texte-centre" col={8}>
                            <Input
                                type="text"
                                name="identifiantDeConnexion"
                                label="Identifiant de connexion"
                                style={{marginLeft: "auto", marginRight: "auto"}}
                                required
                                onChange={(event)=>{setLogin(event.target.value)}}
                                error={erreurIdentification}
                            />
                        </Col>
                    </Row>

                    <Row alignementHorizontal={"center"}>
                        <Col col={8}>
                            <div className="texte-centre">
                                <Input
                                    type="password"
                                    name="motDePasse"
                                    label="Mot de passe"
                                    style={{marginLeft: "auto", marginRight: "auto"}}
                                    required
                                    onChange={(event)=>{setMotDePasse(event.target.value)}}
                                    error={erreurIdentification}
                                />
                            </div>
                            <p
                                className="texte-bleu texte-petit texte-mdp-oublie bouton-reinitialisation-mot-de-passe"
                                onClick={() => setModalMdpReinit(true)}
                            >
                                Mot de passe oublié ?
                            </p>
                        </Col>
                    </Row>

                    <div className="texte-centre">
                        <Bouton
                            icone={userFill}
                            iconePosition="left"
                            label="Se connecter"
                            niveau="primaire"
                            onClick={connexionLoginMdp}
                        />
                    </div>
                </Col>
            </Row>
            <p style={{textAlign: "right"}} className={fr.mb_2w + fr.pt_2w + "texte-bleu texte-petit"}>Les champs
                marqués d'un * sont obligatoires</p>
        </div>
    </>
}

export default CompteRGA;
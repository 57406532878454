import React, {useEffect, useState} from "react";
import {fr} from "../../../constantes/ClassesDSFR";
import RechSimplCarte from "../carte/RechSimplCarte";
import arme_air_comprime_epaule from "../../../resources/recherche-simplifiee/arme_air_comprime_epaule.svg";
import arme_air_comprime_de_poing from "../../../resources/recherche-simplifiee/arme_air_comprime_de_poing.svg";
import arme_a_feu_epaule from "../../../resources/recherche-simplifiee/arme_a_feu_epaule.svg";
import arme_a_feu_de_poing from "../../../resources/recherche-simplifiee/arme_a_feu_de_poing.svg";
import {RechSimplFamilleEnum, RechSimplTypeArmeEnum} from "../../../constantes/RechSimplOptionsEnum";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";

interface RechSimplFamilleProps {
    onSuivantClick: (famille: RechSimplFamilleEnum) => any
    onPrecedentClick: () => any
}

const RechSimplFamille : React.FC<RechSimplFamilleProps> = ({onSuivantClick, onPrecedentClick}) => {

    const {criteres} = useRechercheSimplifieeContext();
    const [familleSelectionnee, setFamilleSelectionnee] = useState<RechSimplFamilleEnum>(null);

    useEffect(() => {
        let famille : RechSimplFamilleEnum = null;
        if(criteres.famille){
            famille = criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? RechSimplFamilleEnum.ARME_EPAULE : RechSimplFamilleEnum.ARME_DE_POING
        }
        setFamilleSelectionnee(famille)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <div className={"w-100"}>
            <h4 className={fr.pb_2w + "texte-centre"}>Sélectionnez votre famille d'arme {criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU ? "à feu" : "à air comprimé" } :</h4>
            <div className={"d-flex flex-space-around"}>
                <RechSimplCarte
                    titre={"Arme de poing"}
                    description={"Arme qui se tient par une poignée à l'aide d'une seule main et qui n'est pas destinée à être épaulée."}
                    image={criteres.typeArme === "arme_a_feu" ? arme_a_feu_de_poing : arme_air_comprime_de_poing}
                    estActif={familleSelectionnee === RechSimplFamilleEnum.ARME_DE_POING}
                    onClick={() => setFamilleSelectionnee(RechSimplFamilleEnum.ARME_DE_POING)}
                />
                <RechSimplCarte
                    titre={"Arme d'épaule"}
                    description={"Arme que l'on épaule pour tirer."}
                    image={criteres.typeArme === "arme_a_feu" ? arme_a_feu_epaule : arme_air_comprime_epaule}
                    estActif={familleSelectionnee === RechSimplFamilleEnum.ARME_EPAULE}
                    onClick={() => setFamilleSelectionnee(RechSimplFamilleEnum.ARME_EPAULE)}
                />
            </div>
        </div>
        <BoutonsEcranRecherche
            sansResultats
            onPrecedentClick={onPrecedentClick}
            onSuivantClick={() => onSuivantClick(familleSelectionnee)}
            suivantInterdit={!familleSelectionnee}
        />
    </>
}

export default RechSimplFamille;

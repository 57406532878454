import API from "./APIConfiguration";
import {EcranFonctionnaliteApiServicePort} from "../../utils/port/EcranFonctionnaliteApiServicePort";
import IndicateurEcranFonctionnalite from "../../entites/indicateurEcranFonctionnalite";

const getIndicateurEcranFonctionnalite = (): Promise<IndicateurEcranFonctionnalite> => {
    return API.get(`/init_tableau_de_bord`).then(response => {
        return {
            creerDemandeFiche: response.data['CREER_DEMANDE_FICHE'],
            gererCompte:  response.data['GERER_COMPTES'],
            traiterDemandesFichesEtReferentiels: response.data['TRAITER_DEMANDES_FICHES_ET_REFERENTIELS']
        }
    });
}

export const EcranFonctionnaliteApiServiceImpl: EcranFonctionnaliteApiServicePort = {
    getIndicateurEcranFonctionnalite
}

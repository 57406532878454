import React, {useEffect, useState} from "react";
import {RgaAppliAPI} from "../../../infrastructure/InfrastructureFactory";
import {DroitParProfil} from "../../../entites/DroitParProfil";
import {
    Container,
    Tableau,
    TableauBody,
    TableauCell,
    TableauHeader,
    TableauHeaderCell,
    TableauRow,
    Toogle
} from "@design-system-etat/dsfr-react/lib/cjs";
import "./AdministrationDroit.css";
import {AdministrationDroits, Profil} from "../../../entites/Profil";
import {Bouton, Row} from "@design-system-etat/dsfr-react";
import {InfoBulle} from "../../../composants/InfoBulle";
import {useAlerteContext} from "../../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../../entites/Alerte";
import {MessageGenerique} from "../../../constantes/MessageGenerique";
import {DroitAModifier} from "../../../entites/DroitAModifier";

export const AdministrationDroit: React.FC = () => {

    const [matrice, setMatrice] = useState<DroitParProfil[]>([]);
    const [profils, setProfils] = useState<Profil[]>([]);
    const [droitASauvegarder, setDroitASauvegarder] = useState<DroitAModifier>({});
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext();
    useEffect(() => {
        RgaAppliAPI.droitParProfil().then(droitsParProfil => {
            initialiserMatrice(droitsParProfil);
            setProfils(droitsParProfil.profils);
        });
    }, []);

    function initialiserMatrice(administrationDroits: AdministrationDroits) {
        setDroitASauvegarder({});
        setMatrice(administrationDroits.matrice);

    }


    function enregistrerMatrice() {
        mettreAJourDateDerniereAction();
        RgaAppliAPI.enregistrerDroit(droitASauvegarder).then(
            droitsRechargees => {
                if (droitsRechargees) {
                    initialiserMatrice(droitsRechargees);
                    mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "",MessageGenerique.MISE_A_JOUR_VALIDE))
                } else {
                    mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "",MessageGenerique.REQUETE_EN_ERREUR))
                    console.error(MessageGenerique.REQUETE_EN_ERREUR)
                }
            }
        )
    }

    function chargerMatrice() {
        RgaAppliAPI.droitParProfil().then(droitsParProfil => {
            initialiserMatrice(droitsParProfil);
            setProfils(droitsParProfil.profils);
        });
        mettreAJourDateDerniereAction();
    }

    function modifierValeur(profil: Profil, droit: DroitParProfil) {

        mettreAJourDateDerniereAction();
        let matriceAJour = matrice.map(ligne => {
            if (ligne.id === droit.id) {
                ligne = {...ligne, [profil.code]: {...ligne[profil.code], valeur: !ligne[profil.code].valeur}}
                setDroitASauvegarder({
                    ...droitASauvegarder,
                    [droit.id + "-" + profil.id]: {
                        id_profil: profil.id,
                        id_droit: droit.id,
                        value: !!ligne[profil.code].valeur
                    }
                })
            }
            return ligne;
        })
        setMatrice(matriceAJour);
    }

    function afficherToggle(droit: DroitParProfil, profil: Profil) {
        const permission = droit[profil.code];
        const identifiant_toogle = profil.code + "_" + droit.id;
        const toogle =
            <div className={"droits_alignement_toogle"}><Toogle
            disabled={!permission.modifiable}
            label={""}
            name={"toogle_droit_" + identifiant_toogle}
            checked={permission.valeur}
            onChange={() => modifierValeur(profil, droit)}
        />
            </div>;


        return permission.infobulle ?
            <InfoBulle position="bottom" texte={permission.infobulle}
                       id={'infobulle_toogle_' + identifiant_toogle}>{toogle}</InfoBulle>
            : toogle;


    }

    function ligneBoutonAction() {
        return <Row alignementHorizontal="right">
            <Bouton
                label="Annuler"
                niveau="secondaire"
                taille="lg"
                onClick={() => chargerMatrice()}
                className="droits_tableau__button"
            />
            <Bouton
                label="Enregistrer"
                niveau="primaire"
                taille="lg"
                onClick={() => enregistrerMatrice()}
                className="droits_tableau__button"
            />
        </Row>;
    }

    function tableauDesDroits() {
        return <Tableau avecBordure caption={"Administration des droits"} tailleFixe={true}>
            <TableauHeader>
                <TableauRow>
                    <>
                        <TableauHeaderCell >
                            PROFILS / DROITS
                        </TableauHeaderCell>
                        {profils.sort((a, b) => a.id > b.id ? 1 : -1).map((profil) =>
                            <TableauHeaderCell key={"header_" + profil.id}>
                                <div id={"titre_" + profil.code}>
                                    {profil.libelle === "PROFESSIONNEL" ? "PRO" : profil.libelle}
                                </div>
                            </TableauHeaderCell>
                        )}
                    </>
                </TableauRow>
            </TableauHeader>

            <TableauBody>
                {matrice.map((ligne) =>
                    <TableauRow key={"ligne_" + ligne.id}>
                        <>
                            <TableauCell>
                                <div id={"libelle_droit_" + ligne.id}>
                                    {ligne.libelle}
                                </div>
                            </TableauCell>
                            {profils.sort((a, b) => a.id > b.id ? 1 : -1).map((profil) =>
                                <TableauCell key={"cellule_droit_" + ligne.id + "_" + profil.id} >
                                    <div className={"display-inline-block"}>  {afficherToggle(ligne, profil)}</div>
                                </TableauCell>
                            )}
                        </>
                    </TableauRow>
                )}
            </TableauBody>
        </Tableau>;
    }

    return (
        <Container className="droits_tableau" taille={"lg"} margeExterne={true}>
            {tableauDesDroits()}
            {ligneBoutonAction()}

        </Container>
    )

}

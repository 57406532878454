export const MailType = {
    DEMANDE_INFORMATION: "Bonjour, \n" +
        "\n" +
        "Votre demande de création de fiche RGA n° %idDemande% nécessite les informations complémentaires suivantes : \n" +
        "\n" +
        "Merci de compléter votre demande avec des photos\n" +
        "\n" +
        "Cordialement, \n" +
        "\n" +
        "Le Service des Armes et Explosifs",
    REFUS_DEMANDE: "Bonjour, \n" +
        "\n" +
        "Votre demande de création de fiche RGA n° %idDemande% a été refusée (à compléter)\n" +
        "\n" +
        "Si votre arme est un prototype, veuillez saisir une demande d’ajout de fiche d’arme en cochant « Prototype » pour avoir le classement de votre arme.\n" +
        "\n" +
        "\n" +
        "\n" +
        "Cordialement, \n" +
        "\n" +
        "Le Service des Armes et Explosifs ",
    VALIDATION_DEMANDE:""
}
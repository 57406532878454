import React, {useEffect, useState} from "react";
import Titre from "../../composants/Titre";
import {fr} from "../../constantes/ClassesDSFR";
import front from "../../resources/healthcheck/website.png"
import database from "../../resources/healthcheck/database.png"
import back from "../../resources/healthcheck/server.png"
import {RgaAppliAPIImpl} from "../../infrastructure/api/RgaAppliAPI";
import "./EcranDeVie.css"
const EcranNousContacter : React.FC = () => {

    const [healthCheck, setHealthCheck] = useState<any>();

    const healthList = [
        {code: 'rga_sca_intranet', name: 'Serveur front intranet', icon: front},
        {code: 'rga_sca_internet', name: 'Serveur front', icon: front},
        {code: 'back', name: 'Serveur back', icon: back},
        {code: 'bdd', name: 'Base de données', icon: database},
    ];

    const statusColors = {
        OK: 'green',
        KO: 'red',
        NC: 'orange',
    };

    const getHealthCheckByCode = (code: string) => {
        return healthList.find(e => e.code === code);
    };

    useEffect(() => {
        RgaAppliAPIImpl.recupererHealthCheck().then(setHealthCheck)
    }, [])

    return (<section className={fr.my_4w + "fr-container"}>
        <Titre>Etat des composants</Titre>
        {healthCheck && (<div>
            <div className={" d-flex flex-center"}>
                {Object.keys(healthCheck).map(key => {
                    const healthCheckItem = getHealthCheckByCode(key);
                    return (
                        <div key={healthCheckItem.code}>
                            <div className={"carte-vie"}>
                                <p style={{color: statusColors[healthCheck[key]]}}>
                                    <b>{healthCheck[key]}</b>
                                </p>
                                <img src={healthCheckItem.icon} alt={healthCheckItem.name} />
                                <div>{healthCheckItem.name}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>)}
    </section>)
}

export default EcranNousContacter;
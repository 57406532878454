export enum URLPageEnum {
    CONNEXION = "/",
    ECRAN_FONCTIONNALITE = '/fonctionnalites',
    ADMINISTRATION_DROITS = '/droits/administration',
    CONNEXION_LIVRE_DE_POLICE = "/livre-de-police/login",
    CONNEXION_DETENTEUR = "/detenteurs/login",
    ACCESSIBILITE = '/accessibilite',
    CONDITIONS_GENERALES = '/conditions-generales',
    ECRAN_CONSULTER_LES_TRACES = '/traces/consultation',
    NOUS_CONTACTER = '/nous-contacter',
    RECHERCHE_AVANCEE_ARMES = '/armes/recherche-avancee',
    ADMINISTRATION_REFERENTIELS = '/referentiels/administration',
    RECHERCHE_SIMPLIFIEE = '/recherche-simplifiee',
    FICHE_ARME = '/armes',
    ADMINISTRATION_PARAMETRES = "/parametres/administration",
	RENOUVELER_MOT_DE_PASSE="/renouveler-mot-de-passe",
    ADMINISTRATION_UTILISATEURS="/utilisateurs/administration",
    CREATION_COMPTE = '/compte/creation',
    HEALTH_CHECK="health-check",
    CONSULATION_COMPTE = '/mon-compte',
    CONSULTER_DEMANDES_ARMES = "/demandes-armes/consultation",
    DEMANDE_ARME = "/demandes-armes",
    IMPORT_MASSE_DEMANDE = '/importer-masse-demande',
    ADMINISTRATION_PAGE_DE_CONNEXION = "/page-de-connexion/administration",
    IMPORT_MASSE_FICHES = '/importer-masse-fiche'
}
import React, {FC, useState} from "react";
import {errorFill, questionFill} from "@design-system-etat/dsfr-react/lib/cjs";
import {Bouton, Icone} from "@design-system-etat/dsfr-react";
import "./InfoBulleCliquable.css"

interface InfoBulleProps {
    texte: string,
    id : string
}

export const InfoBulleCliquable: FC<InfoBulleProps> = (props) => {

    const [isOpen, setIsOpen] = useState(false)

    const {texte} = props;
    return <div className={"infoBulleCliquable"} >
        <div className={"icone"} id={props.id} onClick={() => setIsOpen(true)}>
            <Icone icone={questionFill}/>
        </div>
        {isOpen ? (<div className={"infoBulleCliquableContenu"}>
            <div>
                <p>
                    {texte}
                </p>
            </div>
            <div>
                <Bouton label={""} icone={errorFill} taille="sm" niveau="tertiaire-sans-bordure"
                        onClick={() => setIsOpen(false)}/>
            </div>
        </div>) : (<></>)}
    </div>

}


import React, {FC, useEffect, useState} from "react";
import {fr} from "../constantes/ClassesDSFR";
import {FilAriane, FilArianeItem} from "@design-system-etat/dsfr-react/lib/cjs";
import {useLocation} from "react-router";
import "./FilArianeRGA.css"
import {RouteFilAriane} from "../constantes/RouteFilAriane";

interface FilArianeRGAProps {
    routes: RouteFilAriane[]
}

export const FilArianeRGA: FC<FilArianeRGAProps> = ({routes}) => {

    const {pathname} = useLocation();
    const [routesFilArianne, setRoutesFilArianne] = useState<RouteFilAriane[]>([]);

    const recupererPageCourante = () => {
        let page = routes?.find(route => {
            return pathname.includes(route.urlPage)
        })

        if (!page) {
            const indexDernierSlash = pathname.lastIndexOf("/")
            const urlTronquee = pathname.substring(0, indexDernierSlash)
            page = routes?.find(route => route.urlPage === urlTronquee)
        }
        return page
    }

    useEffect(() => {
        let page = recupererPageCourante()
        let nouvelleRoutes : RouteFilAriane[] = []
        if (page) {
            nouvelleRoutes.push(page)
        }
        while (page?.parent != null) {
            const urlPageParente = page.parent
            page = routes.find(route => urlPageParente === route.urlPage)
            nouvelleRoutes.push(page)
        }
        setRoutesFilArianne([...nouvelleRoutes].reverse())
    }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

    return <section className={fr.ml_6w}>
        {routesFilArianne.length > 0 &&
            <div className={"d-flex flex-start surcharge-dsfr-fil-ariane"}>
                <div className={"texte-fil-ariane"}>
                    Vous êtes ici :
                </div>
                <FilAriane>
                    <>
                        {routesFilArianne?.map((route, index) => {
                            const estDernierItem = index === routesFilArianne.length - 1;
                            return <FilArianeItem {...estDernierItem ? {} : {href:route.urlPage}} currentPage={estDernierItem} key={index}>
                                {route?.nom}
                            </FilArianeItem>
                        })}
                    </>
                </FilAriane>
            </div>
        }
    </section>

}


import React, {useState} from "react";
import {BoutonRadio} from "@design-system-etat/dsfr-react/lib/cjs";
import "../EcranRechercheSimplifiee.css"
import playButton from "../../../resources/recherche-simplifiee/play_button.png"
import "./RechSimplCarte.css"
import {Modale, ModaleContent} from "@design-system-etat/dsfr-react";

interface RechSimplCarteProps {
    titre: string;
    description?: any;
    horizontal?: boolean;
    image?: string;
    gif?: string;
    estActif?: boolean;
    onClick: () => any;
}

const RechSimplCarte: React.FC<RechSimplCarteProps> = ({
                                                           titre,
                                                           description,
                                                           horizontal,
                                                           image,
                                                           gif,
                                                           estActif,
                                                           onClick
                                                       }) => {

    const [modaleGif, setModaleGif] = useState(false)

    return <>
        {
            modaleGif && <span className={"recherche-modale-gif"}>
                <Modale id={"gif"} taille={"lg"} closeCallback={() => setModaleGif(false)}>
                <ModaleContent>
                    <img src={gif} alt={""}/>
                </ModaleContent>
            </Modale>
            </span>
        }
        <div className={(horizontal ? "rech-simpl-carte--horizontal" : "rech-simpl-carte")}>
            <div
                className={"rech-simpl-carte__presentation"}>
                <div className={"rech-simpl-carte__gif"} onClick={() => {
                    if (gif) {
                        setModaleGif(true)
                    }
                }}>
                    {gif && <div className={"play-button "}>
                        <img
                            alt={titre}
                            src={playButton}
                        />
                    </div>}

                    {image && (<div className={"w-100 texte-centre"}>
                        <img
                            alt={titre}
                            className="fr-responsive-img rech-simpl-carte__image"
                            src={image}
                        />
                    </div>)}
                </div>
                <div className={"d-flex flex-center rech-simpl-carte__radio"}>
                    <BoutonRadio
                        label={titre}
                        name={"radio"}
                        value={titre}
                        checked={estActif}
                        onClick={onClick}
                    />
                </div>
            </div>
            {description && (
                <div className={"rech-simpl-carte__description"}>
                    <p className={"texte-justifie"}>{description}</p>
                </div>
            )}
        </div>

    </>
}

export default RechSimplCarte;

import {
    MODES_DE_FONCTIONNEMENT,
    RechSimplFamilleEnum,
    RechSimplModeDeFonctionnementEnum,
    RechSimplTypeArmeEnum,
    RechSimplTypeCanonOption
} from "../constantes/RechSimplOptionsEnum";

export interface CriteresRechercheArme {
    marque: string,
    modele: string,
    fabricant: string,
    canons1: {
        calibre: string,
        typeCanon: string
    },
    canons2: {
        calibre: string,
        typeCanon: string
    },
    canons3: {
        calibre: string,
        typeCanon: string
    },
    canons4: {
        calibre: string,
        typeCanon: string
    },
    modeFonctionnement: string[],
    modeAlimentation: string,
    typeArme: string,
    famille: string
}

export interface CriteresRechercheAvancee {
    marque: string,
    modele: string,
    fabricant: string,
    canons1: {
        calibre: string,
        typeCanon: string
    },
    canons2: {
        calibre: string,
        typeCanon: string
    },
    canons3: {
        calibre: string,
        typeCanon: string
    },
    canons4: {
        calibre: string,
        typeCanon: string
    },
    modeFonctionnement: string,
    modeAlimentation: string
}

export interface CriteresRechercheSimplifiee {
    typeArme: RechSimplTypeArmeEnum;
    famille: RechSimplFamilleEnum;
    nombreCanons: number;
    typeCanons: RechSimplTypeCanonOption;
    calibres: {
        calibre1: string,
        calibre2: string,
        calibre3: string,
        calibre4: string
    };
    modeDeFonctionnement: RechSimplModeDeFonctionnementEnum;
    marque: string;
    estMarqueArtisante: boolean;
    modele: string;
}

export const CriteresRechercheSimplifieeInitial : CriteresRechercheSimplifiee = {
    typeArme: null,
    famille: null,
    nombreCanons: 0,
    typeCanons: RechSimplTypeCanonOption.PAR_DEFAUT,
    calibres: {
        calibre1: "",
        calibre2: "",
        calibre3: "",
        calibre4: ""
    },
    modeDeFonctionnement: null,
    marque: "",
    estMarqueArtisante: false,
    modele: "",
}

export const CriteresRechercheAvanceeInitial: CriteresRechercheAvancee = {
    marque: "",
    modele: "",
    fabricant: "",
    canons1: {
        calibre: "",
        typeCanon: ""
    },
    canons2: {
        calibre: "",
        typeCanon: ""
    },
    canons3: {
        calibre: "",
        typeCanon: ""
    },
    canons4: {
        calibre: "",
        typeCanon: ""
    },
    modeFonctionnement: "",
    modeAlimentation: ""
}



export const criteresSimplifieesToCriteresArmes = (criteres: CriteresRechercheSimplifiee) : CriteresRechercheArme => {
    return {
        typeArme: criteres?.typeArme,
        famille: criteres?.famille,
        modele: criteres?.modele !== "-" ? criteres?.modele : "",
        fabricant: "",
        canons1: {
            calibre:  criteres?.calibres.calibre1,
            typeCanon:  criteres?.typeCanons?.getCanon1() ?? ""
        },
        canons2: {
            calibre:  criteres?.calibres.calibre2,
            typeCanon:  criteres?.typeCanons?.getCanon2() ?? ""
        },
        canons3: {
            calibre:  criteres?.calibres.calibre3,
            typeCanon:  criteres?.typeCanons?.getCanon3() ?? ""
        },
        canons4: {
            calibre:  criteres?.calibres.calibre4,
            typeCanon:  criteres?.typeCanons?.getCanon4() ?? ""
        },
        marque: criteres?.marque !== "-" ?  criteres?.marque : "",
        modeFonctionnement: MODES_DE_FONCTIONNEMENT[criteres?.modeDeFonctionnement]?.map(mode =>  mode),
        modeAlimentation: ""
    };
}

export const criteresAvanceesToCriteresArmes = (criteres: CriteresRechercheAvancee) : CriteresRechercheArme => {
    return {
        typeArme: null,
        famille: null,
        modele: criteres.modele,
        fabricant: criteres.fabricant,
        canons1: criteres.canons1,
        canons2: criteres.canons2,
        canons3: criteres.canons3,
        canons4: criteres.canons4,
        marque: criteres.marque,
        modeFonctionnement: criteres.modeFonctionnement?.length > 0 ? [criteres.modeFonctionnement] : null,
        modeAlimentation: criteres.modeAlimentation
    };
}
import {ReferentielAPIPort} from "../../utils/port/ReferentielAPIPort";
import ReferentielLite from "../../entites/Referentiels/ReferentielLite";
import {PageTableau} from "../../entites/PageTableau";
import Arme from "../../entites/Arme";
import API from "./APIConfiguration";
import {MessageGenerique} from "../../constantes/MessageGenerique";
import {CriteresRechercheArme} from "../../entites/CriteresRechercheArme";
import {
    RechSimplFamilleEnum,
    RechSimplTypeArmeEnum,
} from "../../constantes/RechSimplOptionsEnum";
import {Referentiels} from "../../entites/Referentiels/Referentiels";

const preparerCriteresArme = (criteres: CriteresRechercheArme, isRechercheSimplifie: boolean = false): string => {
    let nouveauxCriteres: string = ""

    let nombreCanon: number = 0;

    if (criteres.canons1.calibre !== '-' && criteres.canons1.calibre !== '') {
        nouveauxCriteres += "&canons1.calibre.libelle=" + encodeURIComponent(criteres.canons1?.calibre)
    }
    if (criteres.canons2.calibre !== '-' && criteres.canons2.calibre !== '') {
        nouveauxCriteres += "&canons2.calibre.libelle=" + encodeURIComponent(criteres.canons2?.calibre)
    }
    if (criteres.canons3.calibre !== '-' && criteres.canons3.calibre !== '') {
        nouveauxCriteres += "&canons3.calibre.libelle=" + encodeURIComponent(criteres.canons3?.calibre)
    }
    if (criteres.canons4.calibre !== '-' && criteres.canons4.calibre !== '') {
        nouveauxCriteres += "&canons4.calibre.libelle=" + encodeURIComponent(criteres.canons4?.calibre)
    }
    if (criteres.canons1.typeCanon || criteres.canons2.typeCanon || criteres.canons3.typeCanon || criteres.canons4.typeCanon) {
        if (criteres.canons1.typeCanon) {
            if (criteres.canons1.typeCanon !== "-") {
                nouveauxCriteres += "&canons1.typeCanon.libelle=" + encodeURIComponent(criteres.canons1.typeCanon)
            }
            nombreCanon++
        }
        if (criteres.canons2.typeCanon) {
            if (criteres.canons2.typeCanon !== "-") {
                nouveauxCriteres += "&canons2.typeCanon.libelle=" + encodeURIComponent(criteres.canons2.typeCanon)
            }
            nombreCanon++
        }
        if (criteres.canons3.typeCanon) {
            if (criteres.canons3.typeCanon !== "-") {
                nouveauxCriteres += "&canons3.typeCanon.libelle=" + encodeURIComponent(criteres.canons3.typeCanon)
            }
            nombreCanon++
        }
        if (criteres.canons4.typeCanon) {
            if (criteres.canons4.typeCanon !== "-") {
                nouveauxCriteres += "&canons4.typeCanon.libelle=" + encodeURIComponent(criteres.canons4.typeCanon)
            }
            nombreCanon++
        }
    }

    if (criteres.marque) nouveauxCriteres += "&marque.libelle=" + encodeURIComponent(criteres.marque)
    if (criteres.modele) nouveauxCriteres += "&modele.libelle=" + encodeURIComponent(criteres.modele)
    if (criteres.fabricant) nouveauxCriteres += "&fabricant.libelle=" + encodeURIComponent(criteres.fabricant)
    if (criteres.modeAlimentation) nouveauxCriteres += "&modeAlimentation.libelle=" + encodeURIComponent(criteres.modeAlimentation)
    if (criteres.modeFonctionnement) criteres.modeFonctionnement.forEach(mode => {
        nouveauxCriteres += "&modeFonctionnement.libelle[]=" + encodeURIComponent(mode)
    })
    if (criteres.famille) nouveauxCriteres += "&famille.libelle=" + (criteres.famille === RechSimplFamilleEnum.ARME_DE_POING ? "POING" : "EPAULE")
    if (criteres.typeArme) nouveauxCriteres += "&classementValideFrance.libelle" + (criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_AIR_COMPRIME ? "=" : "!=") + "C 4°"

    if (isRechercheSimplifie && nombreCanon !== 0) {
        nouveauxCriteres += "&nombreCanon=" + nombreCanon
    }
    return nouveauxCriteres
}

const recupererReferentielLite = (referentiel: string, valeurFiltre?: string): Promise<ReferentielLite[]> => {
    let url = referentiel + (referentiel.includes("/calibres") ? "&" : "?") + 'itemsPerPage=300&page=1&actif=true&sort[libelle]=asc';
    if (valeurFiltre.trim()) {
        url += "&libelle=" + encodeURIComponent(valeurFiltre.trim())
    }
    return API.get(url, {headers: {'Accept': 'application/ld+json'}}
    ).then(reponse => {
        return reponse.data["hydra:member"].map(referentiel => {
            return new ReferentielLite(
                referentiel.libelle,
                referentiel.actif
            )
        })
    }).catch(() => Promise.reject())
}

const recupererReferentielsFiltres = (criteres: CriteresRechercheArme, isRechercheSimplifie = false): Promise<Referentiels> => {
    return API.get("referentiels_recherche?" + preparerCriteresArme(criteres, isRechercheSimplifie).replaceAll('%25', '%'), {headers: {'Accept': 'application/ld+json'}})
        .then(reponse => {
            return reponse.data as Referentiels
        })
}

function preparerTri(tri: object) {
    let sousUrl = "";
    if (tri && Object.keys(tri).length >0) {
        const cle = Object.keys(tri)[0];

        sousUrl = "&order[";
        switch (cle) {
            case "type":
                sousUrl += "typeArme.libelle";
                break;
            case "marque":
                sousUrl += "marque.libelle";
                break;
            case "modele":
                sousUrl += "modele.libelle";
                break
            case "calibre1":
                sousUrl += "canon1.calibre.libelle";
                break;
            case "calibre2":
                sousUrl += "canon2.calibre.libelle";
                break;
            case "typeCanon1":
                sousUrl += "canons1.typeCanon.libelle";
                break;
            case "typeCanon2":
                sousUrl += "canons2.typeCanon.libelle";
                break;
            default:
                sousUrl += cle;
                break;
        }
        sousUrl += "]=" + tri[cle]?.toLowerCase();
    }
    return sousUrl
}

const rechercherArmes = (criteres: CriteresRechercheArme, page: number, elementsParPage: number, isRechercheSimplifie = false, tri?: object): Promise<PageTableau<Arme>> => {
    let url = "recherche_armes?" + preparerCriteresArme(criteres, isRechercheSimplifie) + "&page=" + page + "&itemsPerPage=" + elementsParPage

    url += preparerTri(tri);

    return API.get(encodeURI(url).replaceAll('%25', '%'), {headers: {'Accept': 'application/ld+json'}}).then(reponse => {
        return new PageTableau<Arme>(
            reponse.data["hydra:member"].map(arme => {
                return arme as Arme
            }),
            reponse.data["hydra:totalItems"],
            elementsParPage
        );
    })
}


function addRefElement(ref: string, element: object): Promise<string> {
    return API.post(ref, element)
        .then(
            () => {
                return Promise.resolve("L’élément de référentiel " + element['libelle'] + " a été créé avec succès")
            }
        ).catch((error) => {
            if (error.response.status === 409)
                return Promise.reject("L’élément de référentiel " + element['libelle'] + " n’a pas été créé car il existe déjà.")
            else
                return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR)
        })
}

function updateRefElement(ref: string, idName: number, data: object, modeUpdate: "AJOUTER" | "EDITER" | "ACTIVER_STATUS" | "ACTIVER_ARTISAN", nomInital?: string): Promise<string> {
    return API.put(ref + "/" + idName, data)
        .then(
            () => {
                return Promise.resolve(MessagesConstantes(data, nomInital).REUSSITE[modeUpdate])

            }
        ).catch((error) => {
            if (error.response.status === 409)
                return Promise.reject(MessagesConstantes(data, nomInital).ERREUR[modeUpdate])
            else
                return Promise.reject(MessageGenerique.REQUETE_EN_ERREUR)
        })
}

function importRAHC(file: File): Promise<string> {
    let formData = new FormData();
    formData.append("file", file);
    return API.post('/import_rahc', formData)
        .then(
            () => {
                return Promise.resolve('Votre fichier ' + file.name + ' a bien été déposé')
            }
        ).catch((error) => {
            if (error.response.status === 400) {
                return Promise.reject('Le format du fichier que vous avez déposé n\'est pas correct. Le fichier doit être au format CSV."')
            } else {
                return Promise.resolve(MessageGenerique.REQUETE_EN_ERREUR)
            }
        })
}

const MessagesConstantes = (element, ancienNom) => ({
    REUSSITE: {
        EDITER: "L’élément de référentiel " + element['libelle'] + " a été modifié avec succès.",
        ACTIVER_STATUS: "L’élément de référentiel " + element['libelle'] + " a été " + (element['actif'] ? "activé" : "désactivé"),
        ACTIVER_ARTISAN: "Le tag Artisan du référentiel " + element['libelle'] + " a été " + (element['artisan'] ? "activé" : "désactivé")
    },
    ERREUR: {
        EDITER: "L’élément de référentiel " + ancienNom + " n’a pas pu être remplacé par " + element['libelle'] + " car l’élément de référentiel " + element['libelle'] + " existe déjà.",
        ACTIVER_STATUS: "L’élément de référentiel " + element['libelle'] + " n'a été " + (element['actif'] ? "activé" : "désactivé"),
        ACTIVER_ARTISAN: "Le tag Artisan du référentiel " + element['libelle'] + " n'a pas été changé"
    }
})

const rechercherArmesPourExport = (armesIds: Array<number>): Promise<PageTableau<Arme>> => {

    const url: string = armesIds.reduce((urlString: string, currentArmeId: number) => {
        if (urlString === '/recherche_armes/export?') {
            return urlString + "id[]=" + currentArmeId.toString()
        }
        return urlString + "&id[]=" + currentArmeId.toString();
    }, '/recherche_armes/export?')

    return API.get(url).then(reponse => {
        return new PageTableau<Arme>(
            reponse.data.map(arme => {
                return arme as Arme
            }),
            armesIds.length,
            armesIds.length
        );
    })
}
export const ReferentielAPIServiceImpl: ReferentielAPIPort = {
    recupererReferentielLite,
    rechercherArmes,
    recupererReferentielsFiltres,
    addRefElement,
    updateRefElement,
    importRAHC,
    rechercherArmesPourExport
};

import {FooterAPIPort} from "../../utils/port/FooterAPIPort";
import {FormulaireContact} from "../../entites/FormulaireContact";
import API from "./APIConfiguration";

const envoyerMessage = (formulaireContact: FormulaireContact) : Promise<number> => {
    return API.post(`/contact`, formulaireContact);
}

export const FooterServiceAPIImpl: FooterAPIPort = {
    envoyerMessage
};
import React, {useState} from "react";
import Pictogram from "../../entites/Pictogramme";
import digitalArt from "@gouvfr/dsfr/dist/artwork/pictograms/leisure/digital-art.svg";
import Titre from "../../composants/Titre";
import {arrowRightSLastLine, Container, downloadLine} from "@design-system-etat/dsfr-react/lib/cjs";
import "./EcranImportMasseDemande.css"
import {Bouton, Icone, Row} from "@design-system-etat/dsfr-react";
import {Link, useNavigate} from "react-router-dom";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {URLPageEnum} from "../../constantes/URLPageEnum";

export default function EcranImportMasseDemande(): JSX.Element {
    const navigate = useNavigate();
    const pictoFichesRga: Pictogram = {
        svg: digitalArt,
        alt: "pictogram fiches RGA"
    };

    const [fichierMasse, setFichierMasse] = useState(null)
    const {mettreAjourAlerte} = useAlerteContext();

    function importFichierMasse(): void {
        RgaAppliAPI.importMasseDemandesFiches(fichierMasse)
            .then((res) => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", res))
            })
            .catch((res) => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", res))
            })
    }

    return (<>
        <Container className={"importMasse"}>
            <div className={"zoneTitre"}>
                <img src={pictoFichesRga.svg} alt={pictoFichesRga.alt} id="carte_menu_picto"/>
                <div>
                    <Titre>
                        Import des demandes de création de fiche RGA
                    </Titre>
                    <p>
                        Import des demandes de création en masse
                    </p>
                </div>
            </div>

            <div className={"content"}>
                <p>
                    Importez vos demandes de création en masse
                </p>
                <div>
                    <input className="fr-upload" type="file" id="file-upload" name="file-upload"
                           onChange={(e) => setFichierMasse(e.target.files[0])}/>
                </div>
                <Bouton label={"Déposer"} niveau={"primaire"} onClick={() => importFichierMasse()}/>
                <p>
                    Vous n’avez pas le modèle de fichier à utiliser ? Téléchargez-le ici
                </p>
                <div>
                    <Link to={'/demandes/RGA-modele_import_demandes_masse.csv'} target="_blank"> Télécharger le
                        modèle <Icone icone={downloadLine} taille="sm"/></Link>
                    <p className={"description"}>CSV - 0,5Ko </p>
                </div>
            </div>

            <Row alignementHorizontal={"right"}>

                    <Bouton className={'boutonRetour'} label={"Retour à l'accueil"} iconePosition={"right"}
                            icone={arrowRightSLastLine} niveau={"primaire"}
                            onClick={() => {
                                navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                            }}/>
            </Row>


        </Container>

    </>);
}
import React from "react";
import {fr} from "../../constantes/ClassesDSFR";
import Canon from "../../entites/Canon";
import {Bouton, GroupeBouton, ModaleFooter,Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react";
import {DemandeArme} from "../../entites/DemandeArme";
import {SessionService} from "../../infrastructure/InfrastructureFactory";
import {UtilisateurDroitEnum} from "../../constantes/UtilisateurDroitEnum";
import {
    DEMANDES_STATUTS_LIBELLES_CREER_ARME,
    DEMANDES_STATUTS_LIBELLES_TRAITER_ARME
} from "../../constantes/DemandeStatutEnum";
import {UtilisateurProfilCategorieEnum} from "../../constantes/UtilisateurProfilCategorieEnum";
import {auFormat2Caracteres} from "../../utils/DateUtils";
interface ModaleRecapArmeProps {
    demande?: DemandeArme,
    onFermerClick?: () => void
    onAccederFicheClick: () => void
}

const ModaleRecapArme: React.FC<ModaleRecapArmeProps> = ({demande, onFermerClick, onAccederFicheClick}) => {

    const genererInfo = (titre: string, contenu: string | number) => {
        return <div className={"bloc-bordure-bleu"}>
            <div className={"info-titre"}>{titre}</div>
            <div className={"info-libelle"}>{contenu}</div>
        </div>
    }

    const genererCanon = (numeroCanon: number, canon: Canon) => {
        return <div className={"bloc-bordure-bleu"}>
            <div className={fr.mb_2w + " info-titre-canon"}><b>CANON {numeroCanon}</b></div>
            {canon ?
                <>
                    <div className={fr.mb_2w}>
                        <div className={"texte-desactive-gris"}>Calibre</div>
                        <div>{canon?.calibre?.libelle}</div>
                    </div>
                    <div className={fr.mb_2w}>
                        <div className={"texte-desactive-gris"}>Type canon</div>
                        <div>{canon?.typeCanon?.libelle}</div>
                    </div>
                    <div className={fr.mb_2w}>
                        <div className={"texte-desactive-gris"}>Longueur du canon (mm/po)</div>
                        <div>{canon?.longueurCanon}</div>
                    </div>
                </>
                :
                <p>Aucun</p>
            }

        </div>
    }

    const recupererStatut = (statut: string): string => {
        if (SessionService.getUtilisateurDroits().includes(UtilisateurDroitEnum.CREER_DEMANDE_FICHE)) {
            return DEMANDES_STATUTS_LIBELLES_CREER_ARME[statut]
        } else if (SessionService.getUtilisateurDroits().includes(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS)) {
            return DEMANDES_STATUTS_LIBELLES_TRAITER_ARME[statut]
        }
    }

    const genererTitre = () => {
        if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE)
            return "Aperçu demande à traiter"
        else if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO)
            return "Aperçu de ma demande"
    }

    const genererTexteBouton = () => {
        if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE)
            return "Traiter la demande"
        else if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO)
            return "Accéder à ma demande"
    }

    const genererPremiereLigneModale = () => {
        if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE)
            return <>
                {genererInfo("N° Demande", demande?.id)}
                {genererInfo("Statut", recupererStatut(demande?.statut))}
                {genererInfo("Demandeur", demande?.createur?.societe)}
                {genererInfo("Date de soumission", genererDate(demande?.dateDepot))}
            </>
        else if (SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO)
            return <>
                {genererInfo("N° Demande", demande?.id)}
                {genererInfo("Statut", recupererStatut(demande?.statut))}
                {genererInfo("Date de soumission", genererDate(demande?.dateDepot))}
                {genererInfo("Date de modification", genererDate(demande?.dateMaj))}
            </>
    }

    const genererDate = (date: Date) => {
        if (date) {
            const dateGenere = new Date(date)
            return auFormat2Caracteres(dateGenere.getDate()) + "/" + auFormat2Caracteres(dateGenere.getMonth() + 1) + "/" + auFormat2Caracteres(dateGenere.getFullYear())
        } else {
            return ""
        }
    }

    return (<Modale id={"modal"} taille={"lg"} closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>{genererTitre()}</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-between"}>
                {genererPremiereLigneModale()}
            </span>
            <span className={"d-flex flex-space-between"}>
                {genererInfo("Date de validation", genererDate(demande.dateValidation))}
                {genererInfo("Marque", demande?.ficheArme?.marque?.libelle ?? demande?.ficheArme?.marqueSaisie ?? "")}
                {genererInfo("Modèle", demande?.ficheArme?.modele?.libelle ?? demande?.ficheArme?.modeleSaisie ?? "")}
                {genererInfo("Fabricant", demande?.ficheArme?.fabricant?.libelle ?? demande?.ficheArme?.fabricantSaisie ?? "")}
            </span>
            <span className={"d-flex flex-space-between"}>
                {genererInfo("Mode de fonct.", demande?.ficheArme?.modeFonctionnement?.libelle)}
                {genererInfo("Système d'alim.", demande?.ficheArme?.modeAlimentation?.libelle)}
                {genererInfo("Famille", demande?.ficheArme?.famille?.libelle)}
                {genererInfo("Type", demande?.ficheArme?.typeArme?.libelle)}
            </span>
            <span className={"d-flex flex-space-between"}>
                {genererCanon(1, demande?.ficheArme?.canons?.at(0))}
                {genererCanon(2, demande?.ficheArme?.canons?.at(1))}
                {genererCanon(3, demande?.ficheArme?.canons?.at(2))}
                {genererCanon(4, demande?.ficheArme?.canons?.at(3))}
            </span>


        </ModaleContent>
        <ModaleFooter>
            <GroupeBouton
                alignementHorizontal="right"
                isHorizontal
                reverse
            >
                <Bouton className={fr.mr_2w + fr.mt_2w} niveau={"primaire"} label={genererTexteBouton()}
                        onClick={onAccederFicheClick}/>
            </GroupeBouton>
        </ModaleFooter>
    </Modale>)
}

export default ModaleRecapArme;
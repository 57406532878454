import React from "react";
import Titre from "../../composants/Titre";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import Input from "@design-system-etat/dsfr-react/lib/esm/components/interface/Form/Input/Input";
import {useForm} from "react-hook-form";
import {MessagesErreurEnum} from "../../constantes/MessagesErreurEnum";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {fr} from "../../constantes/ClassesDSFR";
import {useLocation} from "react-router";
import {UtilisateurAPIServiceImpl} from "../../infrastructure/api/UtilisateurAPIService";
import {ServiceErrorsEnum} from "../../constantes/ServiceErrorsEnum";
import {useNavigate} from "react-router-dom";
import {URLPageEnum} from "../../constantes/URLPageEnum";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {InputPassword} from "@design-system-etat/dsfr-react/lib/cjs";
import {MessageGenerique} from "../../constantes/MessageGenerique";

interface FormulaireRenouvellementMdp {
    mail: string,
    ancienMdp: string,
    nouveauMdp1: string,
    nouveauMdp2: string
}

const EcranRenouvelerMotDePasse : React.FC = () => {

    const form = useForm<FormulaireRenouvellementMdp>({mode: 'onChange'});
    const {mettreAjourAlerte, seConnecter, mettreAJourDateDerniereAction} = useAlerteContext();

    const navigate = useNavigate();
    const location = useLocation();

    const genererMessage = (erreur : ServiceErrorsEnum) : string => {
        switch (erreur){
            case ServiceErrorsEnum.MAUVAIS_ANCIEN_MDP :
                return MessageGenerique.ANCIEN_MDP_INCORRECT
            case ServiceErrorsEnum.MDP_IDENTIQUE :
                return MessageGenerique.MDPS_IDENTIQUES
            case ServiceErrorsEnum.MDP_PAS_IDENTIQUE :
                return MessageGenerique.MDPS_PAS_IDENTIQUES
            case ServiceErrorsEnum.NOUVEAU_MDP_INVALIDE :
                return MessageGenerique.MDP_PAS_CORRECT
            case ServiceErrorsEnum.COMPTE_BLOQUE :
                return MessageGenerique.COMPTE_BLOQUE
            case ServiceErrorsEnum.NOUVEAU_COMPTE :
                return MessageGenerique.NOUVEAU_COMPTE
            case ServiceErrorsEnum.UTILISATEUR_NON_TROUVE :
                return MessageGenerique.UTILISATEUR_NON_TROUVE
        }
    }

    const genererErreursChamp = (erreur: ServiceErrorsEnum) => {
        switch (erreur){
            case ServiceErrorsEnum.MAUVAIS_ANCIEN_MDP :
                form.reset({mail: form.getValues("mail"), ancienMdp: "", nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('ancienMdp', { type: "custom", message: MessageGenerique.ANCIEN_MDP_INCORRECT })
                break;
            case ServiceErrorsEnum.MDP_IDENTIQUE :
                form.reset({mail: form.getValues("mail"), ancienMdp: form.getValues("ancienMdp"), nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('nouveauMdp1', { type: "custom", message: MessageGenerique.MDPS_IDENTIQUES })
                break;
            case ServiceErrorsEnum.MDP_PAS_IDENTIQUE :
                form.reset({mail: form.getValues("mail"), ancienMdp: form.getValues("ancienMdp"), nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('nouveauMdp1', { type: "custom", message: MessageGenerique.MDPS_PAS_IDENTIQUES })
                form.setError('nouveauMdp2', { type: "custom", message: MessageGenerique.MDPS_PAS_IDENTIQUES })
                break;
            case ServiceErrorsEnum.NOUVEAU_MDP_INVALIDE :
                form.reset({mail: form.getValues("mail"), ancienMdp: form.getValues("ancienMdp"), nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('nouveauMdp1', { type: "custom", message: 'Le nouveau mot de passe ne respecte pas les conditions.' })
                break;
            case ServiceErrorsEnum.UTILISATEUR_NON_TROUVE :
                form.reset({mail: "", ancienMdp: "", nouveauMdp1: "", nouveauMdp2: ""})
                form.setError('mail', { type: "custom", message: 'Le mail renseigné ne correspond à aucun compte.' })
        }
    }

    const modifierMdp = () => {
        mettreAJourDateDerniereAction();
        UtilisateurAPIServiceImpl.verifierMotDePasse(form.getValues("mail"), form.getValues("nouveauMdp1"), form.getValues("nouveauMdp2"), form.getValues("ancienMdp"))
            .then(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", 'Mot de passe modifié.'))
                RgaAppliAPI.connexionLoginMdp(form.getValues("mail"),form.getValues("nouveauMdp1"))
                    .then(token => {
                        seConnecter(token);
                        navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
                    })
                    .catch(() => {
                        mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Une erreur inattendue est survenue"))
                    })
            })
            .catch(reponse => {
                const typeErreur = reponse.response.data.error.type
                genererErreursChamp(typeErreur)
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", genererMessage(typeErreur)))
            })
    }

    return (<section className={fr.my_6w + "fr-container"}>
        <Titre souligne>Renouvellement du mot de passe</Titre>
        <text className={"texte-bleu"}>{location.state}</text>
        <div className="bloc-bleu">
            <Row className="d-flex">
                <Col col={6} className={"bloc-renouvellement-mot-de-passe"}>
                    <span className={fr.m_1w}>
                        <p><b>Le mot de passe doit contenir :</b></p>
                        <ul>
                            <li>au moins 1 majuscule</li>
                            <li>au moins 1 minuscule</li>
                            <li>au moins 1 chiffre</li>
                            <li>au moins 1 caractère spécial</li>
                            <li>10 caractères min et 20 caractères max.</li>
                        </ul>
                    </span>
                </Col>
                    <Col col={6}>
                        <form onSubmit={form.handleSubmit(modifierMdp)}>
                            <div className={fr.ml_2w + fr.my_4w}>
                                <Input
                                    label="E-mail *"
                                    name="identifiantDeConnexion"
                                    error={form.formState.errors.mail}
                                    {...form.register('mail', {
                                        required: MessagesErreurEnum.CHAMP_REQUIS,
                                        maxLength: {value: 320, message: MessagesErreurEnum.LONGUEUR_MAX_320},
                                        pattern: {value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: MessagesErreurEnum.MAIL}
                                    })}
                                    style={{marginLeft: "auto", marginRight: "auto"}}
                                />
                            </div>
                            <div className={fr.ml_2w + fr.my_4w}>
                                <InputPassword
                                    maskFonction={texte => texte}
                                    name="identifiantDeConnexion"
                                    label="Ancien mot de passe (ou mot de passe temporaire)*"
                                    error={form.formState.errors.ancienMdp}
                                    {...form.register('ancienMdp', {
                                        required: MessagesErreurEnum.CHAMP_REQUIS,
                                        maxLength: {value: 20, message: MessagesErreurEnum.LONGUEUR_MAX_20}
                                    })}
                                />
                            </div>
                            <div className={fr.ml_2w + fr.my_4w}>
                                <InputPassword
                                    maskFonction={texte => texte}
                                    name="identifiantDeConnexion"
                                    label="Nouveau mot de passe *"
                                    error={form.formState.errors.nouveauMdp1}
                                    {...form.register('nouveauMdp1', {
                                        required: MessagesErreurEnum.CHAMP_REQUIS,
                                        maxLength: {value: 20, message: MessagesErreurEnum.LONGUEUR_MAX_20}
                                    })}
                                />
                            </div>
                            <div className={fr.ml_2w + fr.my_4w}>
                                <InputPassword
                                    maskFonction={texte => texte}
                                    name="identifiantDeConnexion"
                                    label="Ressaisir le nouveau mot de passe *"
                                    error={form.formState.errors.nouveauMdp2}
                                    {...form.register('nouveauMdp2', {
                                        required: MessagesErreurEnum.CHAMP_REQUIS,
                                        maxLength: {value: 20, message: MessagesErreurEnum.LONGUEUR_MAX_20}
                                    })}                                />
                            </div>
                            <div className="texte-centre">
                                <Bouton
                                    label="Valider"
                                    type={"submit"}
                                    niveau="primaire"
                                />
                            </div>
                        </form>
                    </Col>
            </Row>
            <p style={{textAlign: "right"}} className={fr.mb_2w + fr.pt_2w + "texte-bleu texte-petit"}>Les champs
                marqués d'un * sont obligatoires</p>
        </div>
    </section>)
}

export default EcranRenouvelerMotDePasse;

import Arme from "./Arme";

export interface ReferentielLibre {
    fabricant: string,
    marque: string,
    modele: string
}

export const construireReferentielLibre = (arme: Arme): ReferentielLibre => {
    return {
        fabricant: arme.fabricantSaisie,
        marque: arme.marqueSaisie,
        modele: arme.modeleSaisie
    }
}


export interface ReferentielLibreInexistant {
    libelle: string,
    referentiel: string,
    type: string
}

export interface ReferentielLibreACreer{
    valeur: string,
    referentiel: string,
    type: string
}
import {Bouton} from "@design-system-etat/dsfr-react";
import {useState} from "react";

interface RAHCReferentielProps {
    onSubmit : (file : any) => void
}


const RAHCReferentiel: React.FC<RAHCReferentielProps> = ({ onSubmit }) => {

    const [fichierRAHC, setFichierRAHC] = useState(null)

    return (<>

        <div className={"rahcTitre"}>
            <p>
                Importation du référentiel des armes historiques et de collection
            </p>
        </div>

        <div className={"rahcContenu"}>

            <div>
                <p>
                    Importez votre référentiel des armes historiques et de collection
                </p>
                <input className="fr-upload" type="file" id="file-upload" name="file-upload"
                       onChange={(e) => setFichierRAHC(e.target.files[0])}/>
                <Bouton label={"Déposer"} niveau={"primaire"} disabled={!fichierRAHC} onClick={() =>onSubmit(fichierRAHC)}/>

            </div>
        </div>


    </>)
}

export default RAHCReferentiel
import React from "react";
import {Modale, ModaleContent, ModaleTitre} from "@design-system-etat/dsfr-react/lib/cjs";
import "./ModaleTypeCanon.css"
import canon4_2lisse_2raye_1 from "../../../resources/recherche-simplifiee/4canons/canon_2lissesH_2rayesV.svg";
import canon4_2lisse_2raye_2 from "../../../resources/recherche-simplifiee/4canons/canon_2lissesV_2rayesH.svg";
import canon4_3lisse_1raye_1 from "../../../resources/recherche-simplifiee/4canons/canon_3lisses_1rayeU.svg";
import canon4_3lisse_1raye_2 from "../../../resources/recherche-simplifiee/4canons/canon_3lisses_1rayeB.svg";
import canon4_4lisse_1 from "../../../resources/recherche-simplifiee/4canons/canon_4lisses_losange.svg";
import canon4_4lisse_2 from "../../../resources/recherche-simplifiee/4canons/canon_4lisses_carre.svg";
import canon4_4raye_1 from "../../../resources/recherche-simplifiee/4canons/canon_4rayes_losange.svg";
import canon4_4raye_2 from "../../../resources/recherche-simplifiee/4canons/canon_4rayes_carre.svg";
import canon4_3raye_1lisse_1 from "../../../resources/recherche-simplifiee/4canons/canon_1lisseU_3rayes.svg";
import canon4_3raye_1lisse_2 from "../../../resources/recherche-simplifiee/4canons/canon_1lisseB_3rayes.svg";


import ExempleTypeCanonModale from "./ExempleTypeCanonModale";

interface Modale1CanonProps {
    onFermerClick?: () => void
}

const Modale1Canon : React.FC<Modale1CanonProps> = ({onFermerClick}) => {
    return (<Modale id={"modal"}  taille={"lg"} closeCallback={onFermerClick}>
        <ModaleTitre idModale="modal">
            <span className={"d-flex flex-center"}>4 canons / exemples</span>
        </ModaleTitre>
        <ModaleContent>
            <span className={"d-flex flex-space-around"}>
                <ExempleTypeCanonModale titre={"2 lisses + 2 rayés"} image1={canon4_2lisse_2raye_1} image2={canon4_2lisse_2raye_2}/>
                <ExempleTypeCanonModale titre={"3 lisses + 1 rayé"} image1={canon4_3lisse_1raye_1} image2={canon4_3lisse_1raye_2}/>
            </span>
            <span className={"d-flex flex-space-around"}>
                <ExempleTypeCanonModale titre={"4 lisses"} image1={canon4_4lisse_1} image2={canon4_4lisse_2}/>
                <ExempleTypeCanonModale titre={"4 rayés"} image1={canon4_4raye_1} image2={canon4_4raye_2}/>
            </span>
            <span className={"d-flex flex-center"}>
                <ExempleTypeCanonModale titre={"3 rayés + 1 lisse"} image1={canon4_3raye_1lisse_1} image2={canon4_3raye_1lisse_2}/>
            </span>
        </ModaleContent>
    </Modale>)
}

export default Modale1Canon;
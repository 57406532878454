import React, {FC, ReactElement} from "react";
import {PlacesType, Tooltip} from "react-tooltip";

interface InfoBulleProps {
    children: ReactElement | ReactElement[] | string,
    texte: string,
    position: PlacesType,
    id: string
}

export const InfoBulle: FC<InfoBulleProps> = (props) => {
    const {id,texte,position,children}=props;
    return <>
        <div data-tooltip-id={id}
             data-tooltip-content={texte}
             data-tooltip-place={position}
        >
            {children}
        </div>
        <Tooltip style={{zIndex:1}} id={id} />
    </>

}


import React from "react";
import {Bouton, Col, Row} from "@design-system-etat/dsfr-react";
import Input from "@design-system-etat/dsfr-react/lib/esm/components/interface/Form/Input/Input";
import {useForm} from "react-hook-form";
import {FormulaireCompte, FormulaireCompteInitial} from "../../../entites/FormulaireCompte";
import Alerte, {typeAlerte} from "../../../entites/Alerte";
import {useAlerteContext} from "../../../composants/RGAContext";
import MiCaptcha from "../../../utils/captcha";
import {fr} from "../../../constantes/ClassesDSFR";
import Titre from "../../../composants/Titre";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import Captcha from "../../../composants/Captcha";
import {RgaAppliAPIImpl} from "../../../infrastructure/api/RgaAppliAPI";
import {ServiceErrorsEnum} from "../../../constantes/ServiceErrorsEnum";
import {useNavigate} from "react-router-dom";
import {URLPageEnum} from "../../../constantes/URLPageEnum";


const EcranCreationCompte : React.FC = () => {

    const navigate = useNavigate();

    const form = useForm<FormulaireCompte>({mode: 'onChange', defaultValues: FormulaireCompteInitial});

    const alerteUtilisateurCree = "Votre compte est en cours de validation. Vous recevrez prochainement, par courriel à l'adresse indiquée,  un mot de passe provisoire. Ce mot de passe devra être changé à votre première connexion. Le service central des armes et explosifs."
    const alerteUtilisateurExistant = "Ce mail est déjà lié à un compte utilisateur, si vous ne connaissez plus vos identifiants, veuillez cliquer sur le lien 'Mot de passe oublié'."
    const alerteCaptchaInvalide = "Une erreur est survenue lors de la saisie du captcha."
    const alerteCaptchaErreur = "Une erreur est survenue lors du contrôle du captcha."

    const {mettreAjourAlerte} = useAlerteContext();

    const genererMessageErrreur = (erreur: string) => {
        switch(erreur){
            case ServiceErrorsEnum.CAPTCHA_INVALIDE:
                return alerteCaptchaInvalide
            case ServiceErrorsEnum.CAPTCHA_ERREUR:
                return alerteCaptchaErreur
            case ServiceErrorsEnum.UTILISATEUR_EXISTANT:
                return alerteUtilisateurExistant
        }
    }

    const envoyerMessage = () => {
        form.setValue("captcha.id", (document.getElementsByName("mi-captcha-id")[0] as HTMLInputElement).value);
        form.setValue("captcha.application", (document.getElementsByName('mi-captcha-application')[0] as HTMLInputElement).value);
        RgaAppliAPIImpl.creationCompte(form.getValues())
            .then(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", alerteUtilisateurCree))
                navigate("/")
            })
            .catch(error => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", genererMessageErrreur(error.response.data.error.type)))
            })
        form.setValue("captcha.value", "");
        MiCaptcha.refresh();
    }

    return (<section className={fr.my_6w + "fr-container"}>
        <Titre souligne>Formulaire de création de compte</Titre>
        <form onSubmit={form.handleSubmit(envoyerMessage)}>
            <Row goutierre>
                <Col col={6} className={fr.pr_2w}>
                    <Input
                        label={"Nom *"}
                        name="nom"
                        longueurMax
                        error={form.formState.errors.nom}
                        {...form.register('nom', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 50, message: MessagesErreurEnum.LONGUEUR_MAX_50},
                            pattern: {value: /^[A-Za-zÀ-ÿ' -]+$/, message: MessagesErreurEnum.QUE_DES_LETTRES}
                        })}
                    />
                </Col>
                <Col col={6} className={fr.pl_2w}>
                    <Input
                        label={"Prénom *"}
                        name="prenom"
                        longueurMax
                        error={form.formState.errors.prenom}
                        {...form.register('prenom', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 50, message: MessagesErreurEnum.LONGUEUR_MAX_50},
                            pattern: {value: /^[A-Za-zÀ-ÿ' -]+$/, message: MessagesErreurEnum.QUE_DES_LETTRES}
                        })}
                    />
                </Col>
            </Row>
            <Row goutierre>
                <Col col={6} className={fr.pr_2w}>
                    <Input
                        label={"Société *"}
                        name="societe"
                        longueurMax
                        error={form.formState.errors.societe}
                        {...form.register('societe', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 150, message: MessagesErreurEnum.LONGUEUR_MAX_150},
                        })}
                    />
                </Col>
                <Col col={6} className={fr.pl_2w}>
                    <Input
                        type={"number"}
                        label={"SIREN *"}
                        name="siren"
                        longueurMax
                        error={form.formState.errors.siren}
                        {...form.register('siren', {
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 9, message: MessagesErreurEnum.NEUF_CHIFFRES},
                            minLength: {value: 9, message: MessagesErreurEnum.NEUF_CHIFFRES},
                        })}
                    />
                </Col>
            </Row>
            <Row goutierre>
                <Col col={6} className={fr.pr_2w}>
                    <Input
                        label={"E-mail *"}
                        name="email"
                        longueurMax
                        error={form.formState.errors.mail}
                        {...form.register('mail',{
                            required: MessagesErreurEnum.CHAMP_REQUIS,
                            maxLength: {value: 320, message: MessagesErreurEnum.LONGUEUR_MAX_320},
                            pattern: {value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: MessagesErreurEnum.MAIL}
                        })}
                    />
                </Col>
                <Col col={6} className={fr.pl_2w}>
                </Col>
            </Row>
            <Captcha
                onChange={valeur => form.setValue("captcha", valeur)}
                error={form.formState.errors.captcha}
                register={form.register('captcha.value',{
                    required: MessagesErreurEnum.CHAMP_REQUIS,
                })}
            />
            <p style={{textAlign: "left"}} className={fr.mb_2w + "texte-bleu texte-petit"}>Les champs marqués d'un * sont obligatoires</p>
            <div className={"d-flex flex-end"}>
                    <Bouton
                        taille="lg"
                        className={fr.m_2w}
                        label="Annuler"
                        niveau="secondaire"
                        onClick={() => {
                            navigate(URLPageEnum.CONNEXION)
                        }}
                    />
                    <Bouton
                        type={"submit"}
                        taille="lg"
                        className={fr.m_2w}
                        label="Soumettre"
                        niveau="primaire"
                    />
            </div>
        </form>
    </section>)
}

export default EcranCreationCompte;

import React, {useEffect, useState} from "react";
import {arrowLeftSFirstLine} from "@design-system-etat/dsfr-react/lib/cjs";
import {Bouton} from "@design-system-etat/dsfr-react";
import {fr} from "../../../constantes/ClassesDSFR";
import {downloadLine} from "@design-system-etat/dsfr-react/lib/esm";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";
import PaginationRGA from "../../../composants/PaginationRGA";
import {URLPageEnum} from "../../../constantes/URLPageEnum";
import {FicheArmeActionEnum} from "../../../constantes/FicheArmeActionEnum";
import ModaleRecapArme from "./ModaleRecapArme";
import Arme from "../../../entites/Arme";
import "./EcranResultatRecherche.css"
import {exportRechercheArmeToCsv} from "../../../infrastructure/arme/ExportService";
import {ReferentielAPIService} from "../../../infrastructure/InfrastructureFactory";
import {criteresSimplifieesToCriteresArmes} from "../../../entites/CriteresRechercheArme";
import {useAlerteContext} from "../../../composants/RGAContext";
import {PageTableau} from "../../../entites/PageTableau";
import Alerte, {typeAlerte} from "../../../entites/Alerte";
import TableauResultatRecherche from "./TableauResultatRecherche";

interface EcranResultatRechercheProps {
    onChangePage: (pageDemande: number, elementsParPage: number, tri?: any) => any,
    onPrecedentClick: () => any
}

const EcranResultatRecherche: React.FC<EcranResultatRechercheProps> = ({onChangePage, onPrecedentClick}) => {

    const [elementsParPage, setElementsParPage] = useState<number>(25);
    const [pageCourante, setPageCourante] = useState<number>(1);
    const [armeModale, setArmeModale] = useState<Arme>(null);
    const [modaleArmeOuvert, setModaleArmeOuvert] = useState<boolean>(false);
    const {resultats, criteres} = useRechercheSimplifieeContext();
    const {mettreAJourDateDerniereAction} = useAlerteContext();
    const [tri, setTri] = useState({});
    const {mettreAjourAlerte} = useAlerteContext()

    const visiterArme = (sia: string) => {
        mettreAJourDateDerniereAction();
        window.open(URLPageEnum.FICHE_ARME + "/" + FicheArmeActionEnum.CONSULTER + "/" + sia, "_blank")
    }

    useEffect(() => {
        onChangePage(pageCourante, elementsParPage, tri)
    }, [tri]); // eslint-disable-line react-hooks/exhaustive-deps

    function modifierTri(cle: string) {
        if (Object.keys(tri).includes(cle)) {
            if (tri[cle] === "ASC") {
                setTri({[cle]: "DESC"})
            } else {
                setTri({});
            }
        } else {
            setTri({[cle]: "ASC"})
        }

    }

    function handleExport() {
        mettreAJourDateDerniereAction();
        if(resultats.nombreElementsTotal < 5000){
            ReferentielAPIService.rechercherArmes(criteresSimplifieesToCriteresArmes(criteres), 1, resultats.nombreElementsTotal , true)
                .then(armesTableau => {
                    const armesIds :number[] = armesTableau.elements.map(arme => {
                        return arme.id
                    })
                    if (armesIds.length > 300){
                        getPageTableauArmePourGrandExport(armesIds).then((armes :PageTableau<Arme>) => {
                            exportRechercheArmeToCsv(armes.elements)
                        })
                    } else {
                        ReferentielAPIService.rechercherArmesPourExport(armesIds).then((armes :PageTableau<Arme>) => {
                            exportRechercheArmeToCsv(armes.elements)
                        })
                    }
                })
        }    else{
        mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "Trop de résultats pour l'export, veuillez affiner votre recherche."," A noter : un export du catalogue complet est possible sur l'écran d'accueil."))
    }

    }

    const getPageTableauArmePourGrandExport = async (
        armesIds: number[]
    ): Promise<PageTableau<Arme>> => {
        let armeIdPourExportSliced: Array<Array<number>> = [];
        for (let index = 0; index < armesIds.length; index += 300) {
            armeIdPourExportSliced.push(armesIds.slice(index, index + 300));
        }

        let promisesRechercheArmesPourExport: Array<Promise<PageTableau<Arme>>> =
            [];

        for (const armeIdPourExportSlice of armeIdPourExportSliced) {
            promisesRechercheArmesPourExport.push(
                ReferentielAPIService.rechercherArmesPourExport(armeIdPourExportSlice)
            );
        }

        const reponsesRechercheArmesPourExport = await Promise.all(
            promisesRechercheArmesPourExport
        );

        return reponsesRechercheArmesPourExport.reduce(
            (resultatRechercheArmesPourExport: PageTableau<Arme>, armeIdPourExportSlice: PageTableau<Arme>): PageTableau<Arme> => {
                return {
                    elements: resultatRechercheArmesPourExport.elements.concat(
                        armeIdPourExportSlice.elements
                    ),
                    nombrePages: 1,
                    nombreElementsTotal:
                        resultatRechercheArmesPourExport.nombreElementsTotal +
                        armeIdPourExportSlice.nombreElementsTotal,
                };
            },
            new PageTableau<Arme>([] as Array<Arme>, 0, 0)
        );
    };

    return <>
        {modaleArmeOuvert && (<ModaleRecapArme
            arme={armeModale}
            onFermerClick={() => {
                mettreAJourDateDerniereAction();
                setModaleArmeOuvert(false)
            }}
            onAccederFicheClick={() => visiterArme(armeModale.sia)}
        />)}
        <div className={"d-flex flex-space-between"}>
            <div>
                <TableauResultatRecherche
                    handleExport={handleExport}
                    resultats={resultats}
                    onTri={modifierTri}
                    tri={tri}
                    onAfficherInformation={(arme) => {
                        mettreAJourDateDerniereAction();
                        setModaleArmeOuvert(true)
                        setArmeModale(arme)
                    }}
                />
            </div>
        </div>
        <PaginationRGA
            pageCourante={pageCourante}
            totalPages={resultats.nombrePages}
            changePage={pageDemande => {
                mettreAJourDateDerniereAction();
                setPageCourante(pageDemande)
                onChangePage(pageDemande, elementsParPage)
            }}
            elementsParPageParDefaut={elementsParPage}
            onElementsParPageChange={elementsParPageRecu => {
                setElementsParPage(elementsParPageRecu)
                setPageCourante(1)
                onChangePage(1, elementsParPageRecu)
            }}
            optionElementsParPage={[25, 50, 100]}
        />
        <div className={fr.mt_5w + "d-flex flex-space-between"}>
            <Bouton
                icone={arrowLeftSFirstLine}
                iconePosition="left"
                label={"Retour"}
                niveau="secondaire"
                onClick={onPrecedentClick}
            />
            <Bouton
                icone={downloadLine}
                iconePosition="right"
                label={"Exporter"}
                niveau="primaire"
                onClick={handleExport}
            />
        </div>
    </>
}

export default EcranResultatRecherche;

import API from "../api/APIConfiguration";
import {ArmeAPIPort} from "../../utils/port/ArmeAPIPort";
import {PageTableau} from "../../entites/PageTableau";
import {DemandeArme, DemandeArmeFiltre} from "../../entites/DemandeArme";
import {
    DEMANDES_STATUTS_LIBELLES_CREER_ARME,
    DEMANDES_STATUTS_LIBELLES_TRAITER_ARME
} from "../../constantes/DemandeStatutEnum";
import {SessionService} from "../InfrastructureFactory";
import {UtilisateurDroitEnum} from "../../constantes/UtilisateurDroitEnum";

const genereRecupererDemandeArmesParams = (cle: string, arme: DemandeArmeFiltre) => {

    let params = ''

    if(arme[cle]){
        if(cle === "modele"){
            params += `&ficheArme.modeleSaisie=${arme[cle].toUpperCase()}`
        } else if (cle === "marque") {
            params += `&ficheArme.marqueSaisie=${arme[cle].toUpperCase()}`
        } else if (cle === "calibre") {
            params += `&ficheArme.canons.calibre.libelle=${arme[cle].toUpperCase()}`
        } else if(cle === "statut"){
           arme["statut"].forEach(statut=>{
               if(SessionService.getUtilisateurDroits().includes(UtilisateurDroitEnum.TRAITER_DEMANDES_FICHES_ET_REFERENTIELS)){
                   params += `&${cle}[]=${Object.keys(DEMANDES_STATUTS_LIBELLES_TRAITER_ARME).find(key => DEMANDES_STATUTS_LIBELLES_TRAITER_ARME[key].includes(statut))}`
               }
               else if(SessionService.getUtilisateurDroits().includes(UtilisateurDroitEnum.CREER_DEMANDE_FICHE)){
                   params += `&${cle}[]=${Object.keys(DEMANDES_STATUTS_LIBELLES_CREER_ARME).find(key => DEMANDES_STATUTS_LIBELLES_CREER_ARME[key].includes(statut))}`
               }
           })
        } else if(cle === "demandeur"){
            params += `&createur.societe=${arme[cle].toUpperCase()}`
        } else {
            params += `&${cle}=${arme[cle]}`
        }
        return params
    }
}

const genereRecupererDemandeArmesOrderParams = (order) => {
    let cle
    if(order.cle === "modele") cle = "ficheArme.modeleSaisie"
    else if(order.cle === "marque") cle = "ficheArme.marqueSaisie"
    else if(order.cle === "calibre") cle = "ficheArme.canons.calibre.libelle"
    else if(order.cle === "demandeur") cle = "createur.societe"
    else cle = order.cle
    return `&order[${cle}]=${order.valeur.toLowerCase()}`
}
const recupererDemandeArmes = (page: number, itemsParPage: number, order: {cle: string, valeur: string}, arme: DemandeArmeFiltre) : Promise<PageTableau<DemandeArme>> => {
    let params = ''
    params += '&page=' + page
    params += '&itemsPerPage=' + itemsParPage
    if(order.cle){
        params += genereRecupererDemandeArmesOrderParams(order)
    }
     Object.keys(arme).forEach(cle => params += genereRecupererDemandeArmesParams(cle, arme) ?? '')

    return API.get('demande_fiche_armes?' + params, {headers: {'Accept': 'application/ld+json'}})
        .then((reponse) => {
            return new PageTableau<DemandeArme>(
                reponse.data["hydra:member"].map(demande => {return demande as DemandeArme}),
                reponse.data["hydra:totalItems"],
                itemsParPage
            );
        })
}


const supprimerDemandeArme = (id: number) : Promise<number> => {
    return API.delete('demande_fiche_armes/' + id)
        .then((reponse) => {
            return reponse.status
        })
}

export const ArmeServiceAPIImpl: ArmeAPIPort = {
    recupererDemandeArmes,
    supprimerDemandeArme
}
import {Captcha as CaptchaEntite, CaptchaInitial} from "./Captcha";

export interface FormulaireReinitMdp {
    email: string,
    captcha: CaptchaEntite
}

export const FormulaireReinitMdpInitial : FormulaireReinitMdp = {
    email: "",
    captcha: CaptchaInitial
}
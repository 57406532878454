import React, {useEffect, useState} from "react";
import RechSimplCarte from "../carte/RechSimplCarte";
import arme_a_feu from "../../../resources/recherche-simplifiee/arme_a_feu.svg";
import arme_aire_comprimee from "../../../resources/recherche-simplifiee/arme_air_comprime.svg";
import {fr} from "../../../constantes/ClassesDSFR";
import {RechSimplTypeArmeEnum} from "../../../constantes/RechSimplOptionsEnum";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";

interface RechSimplTypeArmeProps {
    onSuivantClick: (typeArme: RechSimplTypeArmeEnum) => any
    onPrecedentClick: () => any
}

const RechSimplTypeArme : React.FC<RechSimplTypeArmeProps> = ({onSuivantClick, onPrecedentClick}) => {

    const {criteres} = useRechercheSimplifieeContext();
    const [typeArmeSelectionne, setTypeArmeSelectionne] = useState<RechSimplTypeArmeEnum>(null);

    useEffect(() => {
        let typeArme : RechSimplTypeArmeEnum = null;
        if(criteres.typeArme){
            typeArme = criteres.typeArme === RechSimplTypeArmeEnum.ARME_A_FEU ? RechSimplTypeArmeEnum.ARME_A_FEU : RechSimplTypeArmeEnum.ARME_A_AIR_COMPRIME
        }
        setTypeArmeSelectionne(typeArme)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <h4 className={fr.pb_2w + "texte-centre"}>Sélectionnez votre type d'arme :</h4>
        <div className={"d-flex flex-space-around"}>
            <RechSimplCarte
                titre={"Arme à feu"}
                description={"Arme qui tire un projectile par l'action de la combustion d'une charge propulsive."}
                image={arme_a_feu}
                estActif={typeArmeSelectionne === RechSimplTypeArmeEnum.ARME_A_FEU}
                onClick={() => setTypeArmeSelectionne(RechSimplTypeArmeEnum.ARME_A_FEU)}
            />
            <RechSimplCarte
                titre={"Arme à air comprimé"}
                description={<>Armes et lanceurs dont le projectile est propulsé de manière non pyrotechnique avec une énergie à la bouche <b>supérieure ou égale à 20 joules</b>.</>}
                image={arme_aire_comprimee}
                estActif={typeArmeSelectionne === RechSimplTypeArmeEnum.ARME_A_AIR_COMPRIME}
                onClick={() => setTypeArmeSelectionne(RechSimplTypeArmeEnum.ARME_A_AIR_COMPRIME)}
            />
        </div>
        <BoutonsEcranRecherche
            sansResultats
            onPrecedentClick={onPrecedentClick}
            onSuivantClick={() => onSuivantClick(typeArmeSelectionne)}
            suivantInterdit={!typeArmeSelectionne}
        />
    </>
}

export default RechSimplTypeArme;

export const ExportApiConstantes = {
    CATALOGUE: {
        url: "/get_catalogue_armes",
        baseName: "export_catalogue_",
        successMessage: "Export du catalogue des armes",
        errorMessage: "Une erreur est survenue lors de l'export du catalogue des armes"
    },
    RAHC: {
        url: "/get_rahc",
        baseName: "export_rahc_",
        successMessage: "Export du Référentiel des Armes Historiques et de Collection",
        errorMessage: "Une erreur est survenue lors de l'export du RAHC"
    },
    MARQUE: {
        url: "/get_marques_armes",
        baseName: "export_marques_",
        successMessage: "Export des marques ",
        errorMessage: "Une erreur est survenue lors de l'export des marques"
    },
    BASE: {
        url: "/get_catalogue_complet_armes",
        baseName: "export_base_",
        successMessage: "Export de la base",
        errorMessage: "Une erreur est survenue lors de l'export de la base"
    },
    CALIBRE: {
        url: "/get_calibres_armes",
        baseName: "export_calibres_",
        successMessage: "Export des calibres",
        errorMessage: "Une erreur est survenue lors de l'export des calibres"
    }
}

export type ExportApiType = {
    url: string,
    baseName: string,
    successMessage: string,
    errorMessage: string
}

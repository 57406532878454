import FormulaireArme, {FormulaireCanon} from "../../../entites/FormulaireArme";
import TuileArme from "./TuileArme";
import {Bouton, Col, Container, Row} from "@design-system-etat/dsfr-react";
import {
    Control,
    Controller,
    FieldErrors,
    UseFieldArrayReturn,
    UseFormClearErrors,
    UseFormGetValues
} from "react-hook-form";
import {MessagesErreurEnum} from "../../../constantes/MessagesErreurEnum";
import {AutoCompleteRGA} from "../../../composants/SelectHorsDsfr/AutoCompleteRGA";
import {ReferentielAPIEnum} from "../../../constantes/ReferentielAPIEnum";
import {Input} from "@design-system-etat/dsfr-react/lib/cjs";
import TuileCanon from "./TuileCanon";
import {FicheArmeActionEnum} from "../../../constantes/FicheArmeActionEnum";
import React, {useEffect, useState} from "react";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {useAlerteContext} from "../../../composants/RGAContext";
import Canon from "../../../entites/Canon";
import {SelectOption} from "../../../entites/Referentiels/SelectOption";
import {FiltreAPI} from "../../../entites/FiltreAPI";
import {chargerReferentiel} from "../FicheArmeUtils";

interface CanonFicheArmeProps {
    lectureSeul: boolean
    action: string
    canons: Canon[]
    control: Control<FormulaireArme>
    chargerReferentielDebounce: any
    canonsUseFieldArray: UseFieldArrayReturn<FormulaireArme, "canons", "id">
    clearErrors: UseFormClearErrors<FormulaireArme>
    errors: FieldErrors<FormulaireArme>
    getValues: UseFormGetValues<FormulaireArme>
}


const CanonFicheArme: React.FC<CanonFicheArmeProps> = (props: CanonFicheArmeProps) => {
    const {
        lectureSeul, action, canons, control, chargerReferentielDebounce, clearErrors,
        errors,
        getValues
    } = props;
    const {fields, append, remove, replace} = props.canonsUseFieldArray;
    const [calibreForm, setCalibreForm] = useState<SelectOption[]>([]);
    const [modePercussionForm, setModePercussionForm] = useState<SelectOption[]>([]);
    const [typeForm, setTypeForm] = useState<SelectOption[]>([]);
    const {mettreAJourDateDerniereAction, mettreAjourAlerte} = useAlerteContext()

    useEffect(() => {
        if (!lectureSeul) {
            const filtre: FiltreAPI[] = [{cle: "actif", valeur: "true"}] as FiltreAPI[];
            chargerReferentiel(ReferentielAPIEnum.CALIBRES, filtre, (ref) => setCalibreForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.MODES_PERCUSSION, filtre, (ref) => setModePercussionForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
            chargerReferentiel(ReferentielAPIEnum.TYPES_CANON, filtre, (ref) => setTypeForm(ref), (alerteErreur) => mettreAjourAlerte(alerteErreur))
        }
    }, [lectureSeul])// eslint-disable-line react-hooks/exhaustive-deps

    function calculerErreur(name: string, index: number, nomSecondaire: string) {
        let messageErreur: string = null;

        const erreur = errors[name]
        if (erreur?.length > index && erreur[index]?.[nomSecondaire]) {
            messageErreur = erreur[index]?.[nomSecondaire]?.message;
        }

        return messageErreur
    }

    function afficherTuileCanon() {
        const nombreCanon = canons?.length ?? 0;
        const tailleTuilleCanon = nombreCanon < 3 ? 4 : 3;
        const tailleTuilleCanonFormulaire = fields.length < 3 ? 4 : 3;

        function ajouterCanon() {
            mettreAJourDateDerniereAction();
            append({
                numCanon: fields.length + 1,
                typeCanon: null,
                longueurCanon: 0,
                "idGdr": null,
                modePercussion: null,
                calibre: null,
            })
        }

        function dupliquerCanon(numeroCanon) {
            mettreAJourDateDerniereAction();
            const canonADupliquer = getValues("canons").find(canon => canon.numCanon === numeroCanon)
            append({
                numCanon: fields.length + 1,
                typeCanon: canonADupliquer.typeCanon,
                longueurCanon: canonADupliquer.longueurCanon,
                "idGdr": null,
                modePercussion: canonADupliquer.modePercussion,
                calibre: canonADupliquer.calibre
            })
        }


        function recalculerOrdreCanon(resultatDND: DropResult): void {
            if (resultatDND.destination) {
                const canonATrier: FormulaireCanon[] = Array.from(getValues("canons"));
                const [canonDecaler] = canonATrier.splice(resultatDND.source.index, 1);
                canonATrier.splice(resultatDND.destination.index, 0, canonDecaler);
                replace(canonATrier.map((canon, index) => {
                        return {...canon, numCanon: index + 1}
                    }
                ))
                clearErrors("canons")
            }

        }

        function afficherTuileCanonModifiable(canon, index: number) {
            function afficherChampCalibre() {
                return <>
                    <Row>
                        <p className="texte-desactive-gris">
                            Calibre
                        </p>
                    </Row>
                    <Row className={"canon--ligne-valeur"}>
                        <Col col={12}>
                            <Controller
                                name={`canons.${index}.calibre`}
                                control={control}
                                rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                                render={({field}) => (
                                    <AutoCompleteRGA
                                        id={"fa-select-canon-calibre-" + index}
                                        erreur={calculerErreur("canons", index, "calibre")}
                                        referentiel={ReferentielAPIEnum.CALIBRES}
                                        options={calibreForm}
                                        setOptions={setCalibreForm}
                                        chargerOptions={chargerReferentielDebounce}
                                        field={field}
                                        filtreCalibre={true}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                </>;
            }

            function afficherChampPercussion() {
                return <>
                    <Row>
                        <p className="texte-desactive-gris">
                            Mode de percussion
                        </p>
                    </Row>
                    <Row className={"canon--ligne-valeur"}>
                        <Col col={12}>
                            <Controller
                                control={control}
                                rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                                name={`canons.${index}.modePercussion`}
                                render={({field}) => (
                                    <AutoCompleteRGA
                                        id={"fa-select-canon-percussion-" + index}
                                        erreur={calculerErreur("canons", index, "modePercussion")}
                                        referentiel={ReferentielAPIEnum.MODES_PERCUSSION}
                                        options={modePercussionForm}
                                        setOptions={setModePercussionForm}
                                        chargerOptions={chargerReferentielDebounce}
                                        field={field}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                </>;
            }

            function afficherChampType() {
                return <>
                    <Row>
                        <p className="texte-desactive-gris">
                            Type
                        </p>
                    </Row>
                    <Row className={"canon--ligne-valeur"}>
                        <Col col={12}>
                            <Controller
                                control={control}
                                rules={{required: MessagesErreurEnum.CHAMP_REQUIS}}
                                name={`canons.${index}.typeCanon`}
                                render={({field}) => (
                                    <AutoCompleteRGA
                                        id={"fa-select-canon-type-" + index}
                                        erreur={calculerErreur("canons", index, "typeCanon")}
                                        referentiel={ReferentielAPIEnum.TYPES_CANON}
                                        options={typeForm}
                                        setOptions={setTypeForm}
                                        chargerOptions={chargerReferentielDebounce}
                                        field={field}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                </>;
            }

            function afficherChampTailleCanon() {
                return <>
                    <Row>
                        <p className="texte-desactive-gris">
                            Longueur du canon (mm)
                        </p>
                    </Row>
                    <Row className={"canon--ligne-valeur"}
                         alignementVertical={"bottom"}>
                        <Col col={12}>
                            <Controller
                                name={`canons.${index}.longueurCanon`}
                                control={control}
                                rules={{
                                    required: MessagesErreurEnum.CHAMP_REQUIS,
                                    maxLength: {
                                        value: 4,
                                        message: MessagesErreurEnum.LONGUEUR_MAX
                                    },
                                    pattern: {
                                        value: /^\d{1,4}$/,
                                        message: MessagesErreurEnum.MAUVAIS_FORMAT
                                    }
                                }}
                                render={({field}) => <Input {...field}
                                                            boutonAction={<div
                                                                className={"align-self-bas"}>mm</div>}
                                                            maxLength={4}
                                                            error={calculerErreur("canons", index, "longueurCanon") ?
                                                                {message: calculerErreur("canons", index, "longueurCanon")} : null
                                                            }
                                />}


                            />
                        </Col>

                    </Row>
                </>;
            }

            return <TuileArme idTuile={"tuile-canon-" + canon.numCanon}
                              titre={"CANON " + (canon.numCanon)}
                              bouton={<>
                                          {(getValues("canons").length < 4) && <Bouton taille={"sm"} label={"Dupliquer"}
                                                                                     onClick={() => dupliquerCanon(canon.numCanon)}/>}
                                          {getValues("canons").length > 1 && <span
                                              className={"fr-icon-close-circle-fill fr-icon--lg texte-bleu"}
                                              aria-hidden
                                              onClick={() => {
                                                  mettreAJourDateDerniereAction();
                                                  remove(index)
                                                  const canonATrier: FormulaireCanon[] = Array.from(getValues("canons"));
                                                  replace(canonATrier.map((canon, index) => {
                                                          return {...canon, numCanon: index + 1}
                                                      }
                                                  ))
                                              }}/>
                                          }
                                      </>}>
                                  {afficherChampCalibre()}
                                  {afficherChampPercussion()}
                                  {afficherChampType()}
                                  {afficherChampTailleCanon()}
                                      </TuileArme>
                                      ;
                                  }

                                  function afficherTuileAjoutCanon() {
                                  return <Draggable key={"nonDND"} draggableId={"nonDND"} index={fields.length}
                                  isDragDisabled={true}>
                              {(provided) => (
                                  <div
                                  ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                                  style={{
                                  ...provided.draggableProps.style
                              }}
                                  className={"fr-col-" + tailleTuilleCanonFormulaire}
                                  >
                                  <TuileArme idTuile={"fa-ajouter-canon"} titre={""}>
                                  <div className={"width-100 texte-centre"}>
                                  <Bouton label={"Ajouter un canon"}
                                  icone={"fr-icon-add-line"}
                                  iconePosition={"left"}
                                  onClick={() => ajouterCanon()}/>
                                  </div>
                                  </TuileArme>
                                  </div>)}
                                  </Draggable>;
                              }

                                  return <>
                                  {lectureSeul ?
                                      canons?.map((canon, index) => <Col key={"fa_canon_" + index}
                                                                         col={tailleTuilleCanon}>
                                              <TuileCanon canon={canon}/>
                                          </Col>
                                      )
                                      :
                                      <Col col={12}>
                                          <DragDropContext onDragEnd={recalculerOrdreCanon}>
                                              <Droppable droppableId="droppable" direction="horizontal">
                                                  {(paramDNDConteneur, etatDNDConteneur) => (
                                                      <div
                                                          ref={paramDNDConteneur.innerRef}
                                                          className={etatDNDConteneur.isDraggingOver ? "fa-DND-conteneur-mouvement" : "fa-DND-conteneur "}
                                                          {...paramDNDConteneur.droppableProps}
                                                      >
                                                          {fields.map((canon, index) => (
                                                              <Draggable key={canon.id} draggableId={canon.id}
                                                                         index={index}>
                                                                  {(paramDNDDraggable) => (
                                                                      <div
                                                                          ref={paramDNDDraggable.innerRef}
                                                                          {...paramDNDDraggable.draggableProps}
                                                                          {...paramDNDDraggable.dragHandleProps}
                                                                          style={{
                                                                              ...paramDNDDraggable.draggableProps.style
                                                                          }}
                                                                          className={"fr-col-" + tailleTuilleCanonFormulaire}
                                                                      >
                                                                          {afficherTuileCanonModifiable(canon, index)}

                                                                      </div>
                                                                  )}
                                                              </Draggable>
                                                          ))}
                                                          {(!lectureSeul && fields.length < 4) && afficherTuileAjoutCanon()}
                                                          {paramDNDConteneur.placeholder}
                                                      </div>
                                                  )}
                                              </Droppable>
                                          </DragDropContext>
                                      </Col>
                                  }
                              </>
                                  }

                                  return <Container className={"fa-surcharge-container-DSFR"}>
                                  <Row alignementHorizontal={"left"}>
                                      <div className="fiche-arme--sous-titre">
                                          {lectureSeul ? "Canons" : "Canons *"}
                                      </div>
                                      <div className={"fiche-arme--supplement-sous-titre"}>
                                          (Hors élément d'arme supplémentaire)
                                      </div>
                                      <br/>

                                  </Row>
                                  {action !== FicheArmeActionEnum.CONSULTER && <i>
                                      Renseigner les calibres des canons lisses suivis des canons rayés le cas échéant
                                      <br/>
                                      Vous pouvez modifier l’ordre des canons en les faisant glisser vers la gauche ou
                                      vers la droite
                                  </i>}
                                  <Row goutierre>
                                      {afficherTuileCanon()}
                                  </Row>


                              </Container>;
                                  }

                                  export default CanonFicheArme;
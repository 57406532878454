import React, {useEffect, useState} from "react";
import "../EcranDeConnexion.css"
import {Carte, CarteBody, CarteContent, CarteDescription, CarteTitre, Col} from "@design-system-etat/dsfr-react";
import {CarteHeader, CarteImage} from "@design-system-etat/dsfr-react/lib/cjs";
import {fr} from "../../../constantes/ClassesDSFR";
import {RgaAppliAPI} from "../../../infrastructure/InfrastructureFactory";
import {ConfigurationPageDeConnexion} from "../../../entites/ConfigurationPageDeConnexion";

const InformationsConnexion : React.FC = () => {

    const [configurationsPageDeConnexion, setConfigurationsPageDeConnexion] = useState([] as ConfigurationPageDeConnexion[])

    useEffect(() => {
        recupererConfigurationPageDeConnexion()
    }, [])

    const recupererConfigurationPageDeConnexion = () => {
        RgaAppliAPI.recupererConfigurationPageDeConnexion()
            .then((reponse) => {
                setConfigurationsPageDeConnexion(reponse)
            })
    }

    return (<>
        <h2>Informations</h2>
         <div className="d-flex">
             {configurationsPageDeConnexion[0] && <Col className={fr.mr_2w}>
                <Carte isHorizontal>
                    <CarteBody>
                        <CarteContent>
                            <CarteTitre>
                                {configurationsPageDeConnexion[0].titre}
                            </CarteTitre>
                            <CarteDescription>
                                {configurationsPageDeConnexion[0].description}
                            </CarteDescription>
                        </CarteContent>
                    </CarteBody>
                    <CarteHeader>
                        <CarteImage>
                            <img
                                alt={configurationsPageDeConnexion[0].nom}
                                className=" fr-responsive-img "
                                // @ts-ignore
                                src={(window.PREFIX_URL_IMAGE ??"") + configurationsPageDeConnexion[0].url}
                            />
                        </CarteImage>
                    </CarteHeader>
                </Carte>
            </Col>}
            {configurationsPageDeConnexion[1] && <Col className={fr.ml_2w}>
                <Carte>
                    <CarteBody>
                        <CarteContent>
                            <CarteTitre>
                                {configurationsPageDeConnexion[1].titre}
                            </CarteTitre>
                            <CarteDescription>
                                {configurationsPageDeConnexion[1].description}
                            </CarteDescription>
                        </CarteContent>
                    </CarteBody>
                    <CarteHeader>
                        <CarteImage>
                            <img
                                alt={configurationsPageDeConnexion[1].nom}
                                className=" fr-responsive-img "
                                // @ts-ignore
                                src={(window.PREFIX_URL_IMAGE ??"") +  configurationsPageDeConnexion[1].url}
                            />
                        </CarteImage>
                    </CarteHeader>
                </Carte>
            </Col>}
        </div>
    </>)
}

export default InformationsConnexion;

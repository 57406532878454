export enum ServiceErrorsEnum{
    COMPTE_BLOQUE = "BlockedAccount",
    NOUVEAU_COMPTE = "NewAccount",
    UTILISATEUR_NON_TROUVE = "UserNotFound",
    CAPTCHA_INVALIDE = "invalidCaptcha",
    CAPTCHA_ERREUR = "errorCaptcha",
    UTILISATEUR_EXISTANT = "ExistingUser",
    MAUVAIS_ANCIEN_MDP = "BadOldPassword",
    MDP_IDENTIQUE = "PasswordEquals",
    MDP_PAS_IDENTIQUE = "DoublePasswordMismatch",
    NOUVEAU_MDP_INVALIDE = "InvalidNewPassword"
}
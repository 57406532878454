import React, {useEffect, useState} from "react";
import {
    Checkbox,
    Input,
    Tableau,
    TableauBody,
    TableauCell,
    TableauHeader,
    TableauHeaderCell,
    TableauRow
} from "@design-system-etat/dsfr-react/lib/cjs";
import {useParams} from "react-router";
import {ReferentielConstanteType, ReferentielsConstantes} from "../../../constantes/ReferentielsConstantes";
import Toggle from "@design-system-etat/dsfr-react/lib/cjs/components/interface/Form/Toogle/Toogle";

interface TableauReferentielProps {
    contenu: any,
    isLoading: boolean,
    updateFiltre: (filtres: object) => any,
    onClick: (isOpen?: boolean, modeEdition?: boolean, modaleContenu?) => any,
    onToggleChange: (data: object, modeRequete: "AJOUTER" | "EDITER" | "ACTIVER_STATUS" | "ACTIVER_ARTISAN") => any,

}

const TableauReferentiel: React.FC<TableauReferentielProps> = ({
                                                                   contenu,
                                                                   isLoading,
                                                                   updateFiltre,
                                                                   onClick,
                                                                   onToggleChange
                                                               }) => {

    const params = useParams()
    const referentiel: ReferentielConstanteType = Object.values(ReferentielsConstantes).find(item => item.id === params.referentiel)
    const [actifCheckbox, setActifCheckbox] = useState(true)
    const [inactifCheckbox, setInactifCheckbox] = useState(true)
    const [filtres, setFiltres] = useState(genererValeurInitiale())
    const [hauteurTableau, setHauteurTableau] = useState("20vh")

    useEffect(() => {
        setFiltres({...filtres, actif: actifCheckbox !== inactifCheckbox ? actifCheckbox.toString() : ""})
    }, [actifCheckbox, inactifCheckbox])//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateFiltre(filtres)
    }, [filtres]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetFiltres();
    }, [referentiel])//eslint-disable-line react-hooks/exhaustive-deps

    function resetFiltres() {
        setFiltres(genererValeurInitiale())
        setActifCheckbox(true)
        setInactifCheckbox(true)
    }

    function genererValeurInitiale(): object {
        let valeur = {}
        referentiel.modale['champs'].forEach((champ) => {
            valeur = {...valeur, [champ.nom]: ""}
            if (champ.type === "text") {
                valeur = {...valeur, ['sort[' + champ.nom + ']']: ""}
            }
        })
        return valeur
    }

    function genererEntete() {
        return referentiel.modale['champs'].map((champ) => {

            let ordreTriValeur = filtres['sort[' + champ.nom + ']']
            let ordreTriProps = ordreTriValeur === "" ? {} : {ordreTri: filtres['sort[' + champ.nom + ']']};
            let valeurRetour

            if (champ.nom === "libelle") {
                valeurRetour =
                    <TableauHeaderCell
                        key ={champ.nom}
                        {...ordreTriProps}
                        onClick={() => {
                            ajouterFiltreTri(champ.nom)
                        }} scope="col">
                        {referentiel.nom}
                    </TableauHeaderCell>

            } else if (champ.type === "text") {
                valeurRetour =
                    <TableauHeaderCell
                        key={champ.nom}
                        scope="col"
                        {...ordreTriProps}
                        onClick={() => {
                            ajouterFiltreTri(champ.nom)
                        }}>
                        {champ.nom}
                    </TableauHeaderCell>

            } else if (champ.type === "toggle") {
                valeurRetour =
                    <TableauHeaderCell key ={champ.nom} scope="col">
                        <div >
                            {champ.nom}
                        </div>
                    </TableauHeaderCell>
            }
            return valeurRetour
        })
    }

    function genererFiltres() {
        return referentiel.modale['champs'].map((champ) => {
            if (champ.type === "text") {
                return (
                    <TableauCell key={champ.nom}>
                        <div className={"tableauFiltreText"}>
                            <Input
                                label=""
                                name="filtre"
                                placeholder="Rechercher"
                                value={filtres[champ.nom]}
                                onChange={(e) => (setFiltres({...filtres, [champ.nom]: e.target.value}))}
                            />
                        </div>
                    </TableauCell>
                )
            } else if (champ.type === "toggle") {
                return (
                    <TableauCell key={champ.nom} >
                        <div >
                            Activer/Désactiver
                        </div>
                    </TableauCell>
                )
            } else return <></>
        })
    }

    function genererContenu() {
        return contenu.map((element, index) => {
            return (
                <tr className={"referentiel-contenu-tableau"} key={element["@id"]}>
                    <>
                        <td>
                            <Toggle name={"toggle_referentiel_"+element["@id"]}
                                    label={""}
                                    defaultChecked={element.actif}
                                    onClick={(e) => {
                                        setHauteurTableau( document.getElementById("tableauReferentiel").clientHeight + "px")
                                        onToggleChange({...element, actif: e.target.checked}, "ACTIVER_STATUS")
                                    }}
                            />
                        </td>
                        {referentiel.modale['champs'].map((champ) => {
                            if(champ.type === "text")
                                return (
                                    <td  className={"texteCellule"} onClick={() => onClick(true, true, element)} key={champ.nom + element.id}>
                                        {element[champ.nom]}
                                    </td>
                                )
                            else if (champ.type === "toggle")
                                return(
                                    <td key={champ.nom + element["@id"]} >
                                        <Toggle
                                         label={""}
                                         name={"toggle_referentiel_artisan_"+element["@id"]}
                                         defaultChecked = {element[champ.nom]}
                                         onClick={(e) => {
                                             setHauteurTableau( document.getElementById("tableauReferentiel").clientHeight + "px")
                                             onToggleChange({...element, artisan: !!e.target.checked}, "ACTIVER_ARTISAN")
                                         }}
                                        />
                                    </td>
                                )
                            else return (<></>)
                        })}
                    </>
                </tr>
            )
        })
    }

    function ajouterFiltreTri(nomChampTri: string): void {
        if (filtres['sort[' + nomChampTri + ']']) {
            const ordreTriValeur = filtres['sort[' + nomChampTri + ']']
            setFiltres({...filtres, ['sort[' + nomChampTri + ']']: ordreTriValeur === "ASC" ? "DESC" : "ASC"})
        } else {
            let nouveauFiltres = filtres
            referentiel.modale['champs'].forEach((champ) => {
                if (champ.type === "text" && champ.nom !== nomChampTri) {
                    nouveauFiltres = {...nouveauFiltres, ['sort[' + champ.nom + ']']: ""}
                }
            })
            setFiltres({...nouveauFiltres, ['sort[' + nomChampTri + ']']: "ASC"})
        }
    }


    return (
        <div className={"tableauReferentiel"} id={"tableauReferentiel"}>

            <Tableau caption={""} avecBordure tailleFixe>
                <TableauHeader>
                    <TableauRow>
                        <>
                            <TableauHeaderCell>
                                Actif
                            </TableauHeaderCell>

                            {genererEntete()}
                        </>
                    </TableauRow>
                </TableauHeader>

                <TableauBody>
                    <TableauRow className={"filtreRow"}>
                        <>
                            <TableauCell>
                                <>
                                    <Checkbox
                                        label="Actif"
                                        name="actif"
                                        value="1"
                                        taille="sm"
                                        checked={actifCheckbox}
                                        onClick={(e) => setActifCheckbox(e.target.checked)}
                                        onChange={() => {}}
                                        disabled={!inactifCheckbox}
                                    />
                                    <Checkbox
                                        label="Inactif"
                                        name="inactif"
                                        value="2"
                                        taille="sm"
                                        checked={inactifCheckbox}
                                        onClick={(e) => setInactifCheckbox(e.target.checked)}
                                        onChange={() => {}}
                                        disabled={!actifCheckbox}
                                    />
                                </>
                            </TableauCell>

                            {genererFiltres()}

                        </>
                    </TableauRow>
                    {isLoading ? <tr key={"loading"}>
                            <td colSpan={referentiel.modale['champs'].length + 1} style={{padding: 0, height: hauteurTableau}}>
                                <div className={"loader-container"}>
                                    <div className={"lds-dual-ring"}></div>
                                </div>
                            </td>
                        </tr> :
                        <>
                            {genererContenu()}
                        </>}
                </TableauBody>
            </Tableau>
        </div>
    )
}
export default TableauReferentiel
import React, {useEffect, useState} from "react";
import {fr} from "../../../constantes/ClassesDSFR";
import RechSimplCarte from "../carte/RechSimplCarte";
import poing_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_de_poing_automatique.png";
import poing_rep_manuelle
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_de_poing_a_rep_manuelle_v2.jpg";
import poing_un_coup
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_de_poing_a_1_coup.png";
import poing_semi_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_de_poing_semi_automatique.png";
import epaule_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_epaule_automatique.png";
import epaule_rep_manuelle
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_epaule_rep_manuelle.png";
import epaule_un_coup
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_epaule_a_1_coup.png";
import epaule_semi_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/image/arme_epaule_semi_automatique.png";
import gif_epaule_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_epaule_automatique.gif";
import gif_epaule_rep_manuelle
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_epaule_a_rep_manuelle.gif";
import gif_epaule_un_coup
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_epaule_1_coup.gif";
import gif_epaule_semi_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_epaule_semi_auto.gif";
import gif_poing_auto from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_poing_auto.gif";
import gif_poing_rep_manuelle
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_poing_a_rep_manelle_v2.gif";
import gif_poing_un_coup from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_de_poing_2.gif";
import gif_poing_semi_auto
    from "../../../resources/recherche-simplifiee/mode-de-fonctionnement/gif/arme_de_poing_semi_auto.gif";
import {
    MODES_DE_FONCTIONNEMENT,
    RechSimplFamilleEnum,
    RechSimplModeDeFonctionnementEnum
} from "../../../constantes/RechSimplOptionsEnum";
import BoutonsEcranRecherche from "../BoutonsEcranRecherche";
import {useRechercheSimplifieeContext} from "../../../composants/RechercheSimplifieeContext";
import {Col, Row} from "@design-system-etat/dsfr-react";

interface RechSimplModeDeFonctionnementProps {
    onSuivantClick: (modeDeFonctionnement: RechSimplModeDeFonctionnementEnum) => any,
    onPrecedentClick: () => any
    onAfficherResultatClick: () => any
}

const RechSimplModeDeFonctionnement: React.FC<RechSimplModeDeFonctionnementProps> = ({onSuivantClick, onPrecedentClick, onAfficherResultatClick}) => {

    const {criteres, referentiels} = useRechercheSimplifieeContext();

    const [modeDeFonctionnementSelectionne, setModeDeFonctionnementSelectionne] = useState<RechSimplModeDeFonctionnementEnum>(null);

    useEffect(() => {
        // @ts-ignore
        setModeDeFonctionnementSelectionne(criteres.modeDeFonctionnement ? `${criteres.modeDeFonctionnement}` : null)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <div className={"w-100"}>
            <h4 className={fr.pb_2w + "texte-centre"}>Quel est son mode de fonctionnement ?</h4>
            <Row goutierre={true} >
                <Col col={8}>
                    <Row  goutierre={true}>
                        {referentiels?.MODE_FONCTIONNEMENT.some(item => MODES_DE_FONCTIONNEMENT[RechSimplModeDeFonctionnementEnum.A_UN_COUP].includes(item.label)) && (
                            <Col col={6}><RechSimplCarte
                                titre={"À un coup"}
                                horizontal
                                image={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? epaule_un_coup : poing_un_coup}
                                gif={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? gif_epaule_un_coup : gif_poing_un_coup}
                                estActif={modeDeFonctionnementSelectionne === RechSimplModeDeFonctionnementEnum.A_UN_COUP}
                                onClick={() => setModeDeFonctionnementSelectionne(RechSimplModeDeFonctionnementEnum.A_UN_COUP)}
                            />
                            </Col>
                        )}

                        {referentiels?.MODE_FONCTIONNEMENT.some(item => MODES_DE_FONCTIONNEMENT[RechSimplModeDeFonctionnementEnum.SEMI_AUTOMATIQUE].includes(item.label)) && (
                            <Col col={6}><RechSimplCarte
                                titre={"Semi-automatique"}
                                horizontal
                                image={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? epaule_semi_auto : poing_semi_auto}
                                gif={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? gif_epaule_semi_auto : gif_poing_semi_auto}
                                estActif={modeDeFonctionnementSelectionne === RechSimplModeDeFonctionnementEnum.SEMI_AUTOMATIQUE}
                                onClick={() => setModeDeFonctionnementSelectionne(RechSimplModeDeFonctionnementEnum.SEMI_AUTOMATIQUE)}
                            />
                            </Col>
                        )}

                        {referentiels?.MODE_FONCTIONNEMENT.some(item => MODES_DE_FONCTIONNEMENT[RechSimplModeDeFonctionnementEnum.A_REPETITION_MANUELLE].includes(item.label)) && (
                            <Col col={6}><RechSimplCarte
                                titre={"À répétition manuelle"}
                                horizontal
                                image={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? epaule_rep_manuelle : poing_rep_manuelle}
                                gif={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? gif_epaule_rep_manuelle : gif_poing_rep_manuelle}
                                estActif={modeDeFonctionnementSelectionne === RechSimplModeDeFonctionnementEnum.A_REPETITION_MANUELLE}
                                onClick={() => setModeDeFonctionnementSelectionne(RechSimplModeDeFonctionnementEnum.A_REPETITION_MANUELLE)}
                            />
                            </Col>
                        )}
                        {referentiels?.MODE_FONCTIONNEMENT.some(item => MODES_DE_FONCTIONNEMENT[RechSimplModeDeFonctionnementEnum.AUTOMATIQUE].includes(item.label)) && (
                            <Col col={6}><RechSimplCarte
                                titre={"Automatique"}
                                horizontal
                                image={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? epaule_auto : poing_auto}
                                gif={criteres.famille === RechSimplFamilleEnum.ARME_EPAULE ? gif_epaule_auto : gif_poing_auto}
                                estActif={modeDeFonctionnementSelectionne === RechSimplModeDeFonctionnementEnum.AUTOMATIQUE}
                                onClick={() => setModeDeFonctionnementSelectionne(RechSimplModeDeFonctionnementEnum.AUTOMATIQUE)}
                            />
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col col={4}>
                    <Row>
                        <Col>
                            <RechSimplCarte
                                titre={"Je ne le connais pas"}
                                horizontal={true}
                                estActif={modeDeFonctionnementSelectionne === RechSimplModeDeFonctionnementEnum.INCONNU}
                                onClick={() => setModeDeFonctionnementSelectionne(RechSimplModeDeFonctionnementEnum.INCONNU)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        <BoutonsEcranRecherche
            onPrecedentClick={onPrecedentClick}
            onAfficherResultatClick={onAfficherResultatClick}
            onSuivantClick={() => onSuivantClick(modeDeFonctionnementSelectionne)}
            suivantInterdit={modeDeFonctionnementSelectionne === null}
        />
    </>
}

export default RechSimplModeDeFonctionnement;

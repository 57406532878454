import React, {useState} from "react";
import {Bouton, Col, Container, ModaleTitre, Row} from "@design-system-etat/dsfr-react";
import Alerte, {typeAlerte} from "../../../entites/Alerte";
import {Modale, ModaleContent, ModaleFooter} from "@design-system-etat/dsfr-react/lib/cjs";
import {URLPageEnum} from "../../../constantes/URLPageEnum";
import {useLocation, useNavigate} from "react-router-dom";
import {MessageGenerique} from "../../../constantes/MessageGenerique";
import {useAlerteContext} from "../../../composants/RGAContext";
import {TypeTraitementSCAEEnum} from "../../../constantes/TypeTraitementSCAEEnum";
import Titre from "../../../composants/Titre";
import {ConversionPDF, RgaAppliAPI, SessionService} from "../../../infrastructure/InfrastructureFactory";
import {UtilisateurProfilCategorieEnum} from "../../../constantes/UtilisateurProfilCategorieEnum";
import {StatutDemandeEnum} from "../../../constantes/StatutDemandeEnum";
import {MailType} from "../../../constantes/MailType";
import {FicheArmeActionEnum} from "../../../constantes/FicheArmeActionEnum";

interface BoutonBasDePageProps {
    lectureSeul: boolean
    chargementEnCours: boolean
    estDemande: boolean
    idDemande?: string
    siaArme?: string
    reinitialiserFormulaire: Function
    typeDemande: string
    actionFiche: string
    verifierFormulaire: any
    setRemarqueValidationSCAE: Function,
    setEstDemande,
    soumettreFormulaireArme?
}


const BoutonBasDePage: React.FC<BoutonBasDePageProps> = (props: BoutonBasDePageProps) => {
    let navigate = useNavigate();
    let location = useLocation();

    const {
        lectureSeul,
        chargementEnCours,
        estDemande,
        idDemande,
        typeDemande,
        siaArme,
        reinitialiserFormulaire, actionFiche, verifierFormulaire,
        setRemarqueValidationSCAE,
        setEstDemande,
        soumettreFormulaireArme

    } = props;
    const [modaleAnnulation, setModaleAnnulation] = useState<boolean>(false);
    const [modaleSupprimer, setModaleSupprimer] = useState<boolean>(false);
    const [typeTraitementSCAE, setTypeTraitementSCAE] = useState<TypeTraitementSCAEEnum>(null);
    const {mettreAjourAlerte} = useAlerteContext();
    const [contenuMail, setContenuMail] = useState("");

    function modaleAnnuler() {
        return <span className={"correction_modal_dsfr_non_aligne"}> <Modale id={"fa-modale-annulation"} taille={"md"}>
            <ModaleContent>
                <p>Êtes-vous sûr de vouloir annuler ?</p>
                <p>Tous les éléments saisis seront perdus.</p>
            </ModaleContent>
            <ModaleFooter>
                <div className={"fa-groupe-bouton width-100 texte-droite"}>
                    <Bouton id={"fa-modal-annuler"} label={"Annuler"} niveau={"secondaire"}
                            onClick={() => setModaleAnnulation(false)}/>
                    <Bouton label={"Valider"} niveau={"primaire"}
                            onClick={() => {
                                setModaleAnnulation(false);
                                setEstDemande(location?.state?.afficherDemandePagePrecedente ?? estDemande)
                                navigate(-1)
                            }}/>
                </div>
            </ModaleFooter>
        </Modale></span>;
    }

    function modaleTraiterDemande() {

        let titreModale;
        let texteIndicatif;

        switch (typeTraitementSCAE) {
            case TypeTraitementSCAEEnum.DEMANDE_INFOS:
                titreModale = "Demande d'informations complémentaires";
                break
            case TypeTraitementSCAEEnum.REFUS:
                titreModale = "Refus de la demande";
                break
            case TypeTraitementSCAEEnum.VALIDATION:
                titreModale = "Confirmez-vous l'ajout de cette fiche au RGA ?";
                texteIndicatif =
                    <span>Vous pouvez ajouter une remarque qui sera envoyé à l'armurier</span>;
                break
            default:
                setTypeTraitementSCAE(null)
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", "Type de traitement inconnu."))
        }

        const traiterDemande = (event) => {
            event.currentTarget.disabled = true;

            if (typeTraitementSCAE === TypeTraitementSCAEEnum.VALIDATION) {
                soumettreFormulaireArme(StatutDemandeEnum.STATUT_VALIDE)
            } else {
                RgaAppliAPI.traiterDemande(idDemande, typeTraitementSCAE, contenuMail.trim()).then(() => {
                    setTypeTraitementSCAE(null);
                    mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "La demande n° " + idDemande + " a été traitée. Un courriel a été envoyé au créateur de la demande"));
                    navigate(URLPageEnum.CONSULTER_DEMANDES_ARMES);
                }).catch((raison) => mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "", raison)))

            }

        }


        return <span className={"correction_modal_dsfr_non_aligne"}>
            <Modale id={"fa-modale-traiter-demande"} taille={"md"}
                    closeCallback={() => setTypeTraitementSCAE(null)}>

            <ModaleContent>
                <Titre>  {titreModale}</Titre>
                <p>{texteIndicatif}</p>
                <div className={"modale-traitement-demande-textearea"}>
                    <textarea className="fr-input " value={contenuMail} maxLength={1000}
                              onChange={event => sauvegardeMailConfirmation(event.target.value)} id={"contenu-mail"}/>
                    <div> {(contenuMail.length < 1 && typeTraitementSCAE !== TypeTraitementSCAEEnum.VALIDATION) ? <span
                        className={"fr-error-text"}>Champ obligatoire</span> : <> {contenuMail.trim().length} /1000 </>}</div>
                </div>


            </ModaleContent>
            <ModaleFooter>
                <div className={" width-100 texte-droite"}>
                    <Bouton label={"Envoyer"} niveau={"primaire"}
                            disabled={(typeTraitementSCAE !== TypeTraitementSCAEEnum.VALIDATION) && (contenuMail.trim().length > 1000 || contenuMail.trim().length < 1)}
                            className={"fa-bouton-sauvegarder"}
                            icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                            onClick={traiterDemande}/>
                </div>
            </ModaleFooter>
        </Modale>
        </span>;
    }

    function modaleSuppression() {
        const validerSuppressionDemande = () => RgaAppliAPI.supprimerDemande(idDemande).then(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", "La demande n° " + idDemande + " a bien été supprimée."))
                navigate(URLPageEnum.ECRAN_FONCTIONNALITE)
            }
        ).catch(() => mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "", MessageGenerique.REQUETE_EN_ERREUR)))

        return <Modale id={"fa-modale-suppression"} taille={"sm"} closeCallback={() => setModaleSupprimer(false)}>
            <ModaleTitre idModale={"fa-modale-suppression"}>
                Supprimer une demande
            </ModaleTitre>
            <ModaleContent>
                <p>Êtes-vous sûr de vouloir supprimer votre demande ?</p>
            </ModaleContent>
            <ModaleFooter>
                <div id={"modale-bouton-suppression"} className={"fa-groupe-bouton width-100 texte-droite"}>
                    <Bouton label={"Supprimer"} niveau={"primaire"} className={"fa-bouton-suppression"}
                            onClick={() => validerSuppressionDemande()}/>
                </div>
            </ModaleFooter>
        </Modale>;
    }

    const sauvegardeMailConfirmation = (texte: string) => {
        setContenuMail(texte)
        setRemarqueValidationSCAE(texte)
    }

    const afficherBoutonSuppression = estDemande && lectureSeul && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO && typeDemande !== StatutDemandeEnum.STATUT_REFUSE;
    const afficherBoutonEnregister = estDemande && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO && actionFiche !== FicheArmeActionEnum.CONSULTER;
    const afficherBoutonSoumettre = estDemande && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.PRO &&
        (typeDemande === StatutDemandeEnum.STATUT_BROUILLON || typeDemande === StatutDemandeEnum.STATUT_INCOMPLETE || typeDemande === "");
    const afficherBoutonValider = !estDemande && !lectureSeul;
    const afficherBoutonExportPDF = lectureSeul && !estDemande;
    const afficherBoutonTraitementSCAE = estDemande && FicheArmeActionEnum.MODIFIER === actionFiche && SessionService.getProfilCategorie() === UtilisateurProfilCategorieEnum.SCAE;

    return <>
        <Container className={"fa-surcharge-container-DSFR"}>
            <Row alignementHorizontal={"right"} alignementVertical={"middle"}>
                {actionFiche !== FicheArmeActionEnum.CONSULTER &&
                    <Col col={5}> <Container className={"fa-surcharge-container-DSFR"}>
                        <p className="fr-badge fr-badge--warning">Les champs marqués d'une astérisque (*) sont
                            obligatoires pour valider votre fiche</p></Container>
                    </Col>}
                <Col className={"texte-droite"}>
                    <div className={"fa-groupe-bouton"} id={"fa-bouton-bas-page"}>


                        {lectureSeul ?
                            estDemande && <Bouton id={"fa-retour"} label={"Retour"} isLoading={chargementEnCours}
                                                  niveau={"secondaire"}
                                                  iconePosition="right" icone="fr-icon-arrow-left-s-first-line"
                                                  onClick={() => {
                                                      navigate(URLPageEnum.CONSULTER_DEMANDES_ARMES)
                                                  }}
                            /> : <>
                                <Bouton id={"fa-annuler"} label={"Annuler"} isLoading={chargementEnCours}
                                        niveau={"secondaire"}
                                        iconePosition="right" icone="fr-icon-arrow-left-s-first-line"
                                        onClick={() => setModaleAnnulation(true)}
                                />
                                <Bouton label={"Réinitialiser"} isLoading={chargementEnCours} niveau={"secondaire"}
                                        iconePosition="right" icone="fr-icon-arrow-go-back-fill"
                                        onClick={reinitialiserFormulaire} id={"fa-reinit"}
                                />
                            </>

                        }

                        {afficherBoutonSuppression && <Bouton
                            onClick={() => setModaleSupprimer(true)}
                            isLoading={chargementEnCours} className={"fa-bouton-suppression"}
                            niveau="primaire"
                            icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                            label={"Supprimer"}
                            id={"fa-supprimer"}
                        />
                        }
                        {afficherBoutonEnregister &&
                            <Bouton
                                onClick={() => {
                                    soumettreFormulaireArme(StatutDemandeEnum.STATUT_BROUILLON)
                                }}
                                isLoading={chargementEnCours}
                                niveau="primaire"
                                icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                                label={"Enregistrer"}
                                id={"fa-enregistrer"}
                            />
                        }
                        {afficherBoutonSoumettre && <Bouton
                            onClick={() => {
                                verifierFormulaire().then(formulaireValide => {
                                    if (formulaireValide) {
                                        soumettreFormulaireArme(StatutDemandeEnum.STATUT_DEPOSE)
                                    } else {
                                        mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Cette action est impossible car certains champs obligatoires ne sont pas remplis"))
                                    }
                                })
                            }}
                            isLoading={chargementEnCours} className={"fa-bouton-sauvegarder"}
                            niveau="primaire"
                            icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                            label={"Soumettre"}
                            id={"fa-soumettre"}
                        />
                        }
                        {afficherBoutonValider &&
                            <Bouton onClick={() => verifierFormulaire().then(formulaireValide => {
                                if (!formulaireValide) {
                                    mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Cette action est impossible car certains champs obligatoires ne sont pas remplis"))
                                }
                            })}
                                    isLoading={chargementEnCours} className={"fa-bouton-sauvegarder"}
                                    niveau="primaire" type={"submit"}
                                    icone="fr-icon-arrow-right-s-last-line" iconePosition="right" label={"Valider"}
                                    id={"fa-valider"}
                            />
                        }
                        {afficherBoutonTraitementSCAE && <>
                            <Bouton
                                isLoading={chargementEnCours}
                                onClick={() => {
                                    setContenuMail(MailType.DEMANDE_INFORMATION.replace("%idDemande%", idDemande))
                                    setTypeTraitementSCAE(TypeTraitementSCAEEnum.DEMANDE_INFOS)
                                }}
                                niveau="primaire"
                                icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                                label={"Demander infos"}
                                id={"fa-demander-infos"}
                            />
                            <Bouton
                                isLoading={chargementEnCours} className={"fa-bouton-suppression"}
                                onClick={() => {
                                    setContenuMail(MailType.REFUS_DEMANDE.replace("%idDemande%", idDemande))
                                    setTypeTraitementSCAE(TypeTraitementSCAEEnum.REFUS)
                                }}
                                niveau="primaire"
                                icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                                label={"Refuser"}
                                id={"fa-refuser"}
                            />
                            <Bouton
                                isLoading={chargementEnCours} className={"fa-bouton-sauvegarder"}
                                onClick={() => {
                                    verifierFormulaire().then(formulaireValide => {
                                        if (formulaireValide) {
                                            sauvegardeMailConfirmation(MailType.VALIDATION_DEMANDE)
                                            setTypeTraitementSCAE(TypeTraitementSCAEEnum.VALIDATION)
                                        } else {
                                            mettreAjourAlerte(new Alerte(typeAlerte.ATTENTION, "", "Formulaire incomplet"))
                                        }
                                    })

                                }}
                                niveau="primaire"
                                icone="fr-icon-arrow-right-s-last-line" iconePosition="right"
                                label={"Confirmer"}
                                id={"fa-confirmer"}/>
                        </>
                        }
                        {afficherBoutonExportPDF &&
                            <Bouton label={"Exporter la fiche en pdf"} isLoading={chargementEnCours}
                                    niveau="primaire" id={"fa-export-pdf"}
                                    icone="fr-icon-download-line" iconePosition="right"
                                    onClick={() => ConversionPDF.genererPdf(siaArme).then((retour) =>
                                        mettreAjourAlerte(retour ? new Alerte(typeAlerte.REUSSITE, "", "Export de la fiche RGA en cours.") :
                                            new Alerte(typeAlerte.ERREUR, "", "Erreur lors de l'export pdf de la fiche RGA.")
                                        ))
                                    }/>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
        {
            modaleAnnulation && modaleAnnuler()
        }
        {
            modaleSupprimer && modaleSuppression()
        }
        {
            typeTraitementSCAE && modaleTraiterDemande()
        }
    </>


}

export default BoutonBasDePage;
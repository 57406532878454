import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import Titre from "../../composants/Titre";
import {Bouton, Container} from "@design-system-etat/dsfr-react";
import "./AdministrationDesParametres.css"
import {
    pencilFill,
    Tableau,
    TableauBody,
    TableauHeader,
    TableauHeaderCell,
    TableauRow
} from "@design-system-etat/dsfr-react/lib/cjs";
import {InfoBulleCliquable} from "../../composants/InfoBulleCliquable";
import Parametre from "../../entites/Parametre";
import {RgaAppliAPI} from "../../infrastructure/InfrastructureFactory";
import {TABLEAU_ORDRE_TRI_ASC, TABLEAU_ORDRE_TRI_DESC} from "@design-system-etat/dsfr-react/lib/esm";
import {useForm} from "react-hook-form";
import ModaleParametres from "./composants/ModaleParametres";
import {useAlerteContext} from "../../composants/RGAContext";
import Alerte, {typeAlerte} from "../../entites/Alerte";
import {MessageGenerique} from "../../constantes/MessageGenerique";

const AdministrationDesParametres: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingModale, setIsLoadingModale] = useState(false)
    const [tableauParams, setTableauParams] = useState([] as Parametre[])
    const [triOrdre, setTriOrdre] = useState(TABLEAU_ORDRE_TRI_ASC as typeof TABLEAU_ORDRE_TRI_ASC | typeof TABLEAU_ORDRE_TRI_DESC)
    const [modale, setModale] = useState({isOpen: false, maxLength: 0, pattern: "", idTechnique: ""})
    const {mettreAjourAlerte, mettreAJourDateDerniereAction} = useAlerteContext()
    const form = useForm({
        mode: "onChange",
    });

    useEffect(() => {
        recupererParametre()
    }, [])

    function recupererParametre(): void {
        setIsLoading(true)
        RgaAppliAPI.recupererParametres()
            .then((reponse) => {
                setTableauParams(reponse.sort((firstItem, secondItem) => firstItem.libelle.localeCompare(secondItem.libelle)))
                setTriOrdre(TABLEAU_ORDRE_TRI_ASC)
                setIsLoading(false)
            })
    }

    function triTableau(ordre: typeof TABLEAU_ORDRE_TRI_ASC | typeof TABLEAU_ORDRE_TRI_DESC): void {
        mettreAJourDateDerniereAction();
        const coefficient = ordre === TABLEAU_ORDRE_TRI_ASC ? 1 : -1
        setTriOrdre(ordre)
        tableauParams.sort((firstItem, secondItem) => coefficient * firstItem.libelle.localeCompare(secondItem.libelle))
    }

    function obtenirValeur(id: number): string {
        return tableauParams.find(parametre => parametre.id === id).valeur
    }

    function enregistrerParametre(form: BaseSyntheticEvent) {
        mettreAJourDateDerniereAction();
        setIsLoadingModale(true)
        const id = form['id']
        const valeur = form['valeur']
        RgaAppliAPI.enregistrerParametre(id, valeur)
            .then(() => {
                const tmp = tableauParams
                const index = tmp.findIndex((parametre) => parametre.id === id)
                tmp[index] = {...tmp[index], valeur: valeur}
                setTableauParams(tmp)
                mettreAjourAlerte(new Alerte(typeAlerte.REUSSITE, "Succès", MessageGenerique.MISE_A_JOUR_VALIDE))
                setModale({...modale, isOpen: false})
                setIsLoadingModale(false)
            })
            .catch(() => {
                mettreAjourAlerte(new Alerte(typeAlerte.ERREUR, "Erreur", MessageGenerique.REQUETE_EN_ERREUR))
                setModale({...modale, isOpen: false})
                setIsLoadingModale(false)
            })
    }

    function getModale() {
        return modale.isOpen ?
            <ModaleParametres
                form={form}
                onClose={() => {
                    setModale({...modale, isOpen: false})
                }}
                onSubmit={enregistrerParametre}
                isLoading={isLoadingModale}
                {...modale}
            /> : <></>;
    }

    return (
        <>
            <Container>
                <Titre>
                    Paramètres applicatifs
                </Titre>
                    {getModale()}
                    <div className={"mainContainerParametres"}>
                        <Tableau tailleFixe caption={""} avecBordure couleur={"transparent"}>
                            <TableauHeader>
                                <TableauRow>
                                    <TableauHeaderCell
                                        ordreTri={triOrdre}
                                        onClick={() => triTableau(triOrdre === TABLEAU_ORDRE_TRI_ASC ? TABLEAU_ORDRE_TRI_DESC : TABLEAU_ORDRE_TRI_ASC)}>
                                        Propriété
                                    </TableauHeaderCell>
                                    <th className={"centerText headerValeur"}>
                                        Valeur
                                    </th>
                                </TableauRow>
                            </TableauHeader>
                            <TableauBody>
                                {isLoading ? <tr key={"loading"}>
                                        <td colSpan={2} className={"loadingTd"}>
                                            <div className={"loader-container"}>
                                                <div className={"lds-dual-ring"}></div>
                                            </div>
                                        </td>
                                    </tr> :
                                    <>
                                        {
                                            tableauParams.map((parametre) => {
                                                return (
                                                    <TableauRow key={parametre.id}>
                                                        <td className={"libelleCell"}>
                                                            {parametre.libelle}
                                                            <InfoBulleCliquable
                                                                texte={parametre.description}
                                                                id={"tooltip_"+parametre.idTechnique}/>
                                                        </td>
                                                        <td className={"rightSide valeurCell"}>
                                                            <>
                                                                {obtenirValeur(parametre.id)}
                                                                <Bouton
                                                                    label={""}
                                                                    id={"bouton_"+parametre.idTechnique}
                                                                    niveau="tertiaire-sans-bordure"
                                                                    icone={pencilFill}
                                                                    taille={"sm"}
                                                                    onClick={() => {
                                                                        mettreAJourDateDerniereAction();
                                                                        form.setValue("valeur", obtenirValeur(parametre.id))
                                                                        form.setValue("id", parametre.id)
                                                                        setModale({
                                                                            ...modale,
                                                                            isOpen: true,
                                                                            maxLength: parametre.maxLength,
                                                                            pattern: parametre.regexp,
                                                                            idTechnique: parametre.idTechnique
                                                                        })
                                                                    }}/>
                                                            </>
                                                        </td>
                                                    </TableauRow>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </TableauBody>
                        </Tableau>
                    </div>
                </Container>
            </>
    )
}
export default AdministrationDesParametres
import {SessionServiceImpl} from "./session/SessionService";
import {RgaAppliAPIImpl} from "./api/RgaAppliAPI";
import {SessionPort} from "../utils/port/SessionPort";
import RgaAppliAPIPort from "../utils/port/RgaAppliAPIPort";
import {ExportServiceImpl} from "./arme/ExportService";
import {ExportPort} from "../utils/port/ExportPort";
import {FooterAPIPort} from "../utils/port/FooterAPIPort";
import {FooterServiceAPIImpl} from "./api/FooterService";
import {ReferentielAPIPort} from "../utils/port/ReferentielAPIPort";
import {ReferentielAPIServiceImpl} from "./api/ReferentielAPIService";
import {ConversionPDFPort} from "../utils/port/ConversionPDFPort";
import {ConversionPDFImpl} from "../utils/pdf/ConversionPDF";
import {ArmeServiceAPIImpl} from "./arme/ArmeApiService";
import {ArmeAPIPort} from "../utils/port/ArmeAPIPort";
import {EcranFonctionnaliteApiServiceImpl} from "./api/EcranFonctionnaliteApiService";
import {EcranFonctionnaliteApiServicePort} from "../utils/port/EcranFonctionnaliteApiServicePort";


export const SessionService: SessionPort = SessionServiceImpl;
export const RgaAppliAPI: RgaAppliAPIPort = RgaAppliAPIImpl;
export const FooterServiceApi: FooterAPIPort = FooterServiceAPIImpl;
export const ExportService: ExportPort = ExportServiceImpl;
export const ReferentielAPIService: ReferentielAPIPort = ReferentielAPIServiceImpl;
export const ConversionPDF:ConversionPDFPort=ConversionPDFImpl;
export const ArmeServiceAPI:ArmeAPIPort=ArmeServiceAPIImpl;
export const EcranFonctionnaliteApiService:EcranFonctionnaliteApiServicePort=EcranFonctionnaliteApiServiceImpl;

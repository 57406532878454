export enum MessagesErreurEnum {
    CHAMP_REQUIS = "Veuillez compléter ce champ",
    LONGUEUR_MAX = "Ce champ dépasse la limite autorisée",
    LONGUEUR_MAX_15 = "Ce champ est limité à 15 caractères",
    LONGUEUR_MAX_20 = "Ce champ est limité à 20 caractères",
    LONGUEUR_MAX_50 = "Ce champ est limité à 50 caractères",
    LONGUEUR_MAX_150 = "Ce champ est limité à 150 caractères",
    LONGUEUR_MAX_200 = "Ce champ est limité à 200 caractères",
    LONGUEUR_MAX_255 = "Ce champ est limité à 255 caractères",
    LONGUEUR_MAX_320 = "Ce champ est limité à 320 caractères",
    LONGUEUR_MAX_1000 = "Ce champ est limité à 1000 caractères",
    QUE_DES_LETTRES = "Ce champ comporte des caractères non autorisés",
    NEUF_CHIFFRES = "Ce champ doit comporter 9 chiffres",
    NON_AUTORISE = "Ce champ comporte des caractères non autorisés",
    MAUVAIS_FORMAT = "Ce champ ne respecte pas le format attendu",
    MAIL = "Adresse mail non valide",
    CHAMP_ERREUR="Ce champ est en erreur",
    ERREUR_CONNEXION = "Erreur de connexion",
}
export const fr = {
    m_1w : "fr-m-1w ",
    m_2w : "fr-m-2w ",
    mr_2w : "fr-mr-2w ",
    ml_2w : "fr-ml-2w ",
    ml_6w : "fr-ml-6w ",
    mb_2w : "fr-mb-2w ",
    mt_6w : "fr-mt-6w ",
    my_6w : "fr-my-6w ",
    mb_5w : "fr-mb-5w ",
    mb_1w : "fr-mb-1w ",
    pt_1w : "fr-pt-1w ",
    pt_3w : "fr-pt-3w ",
    p_2w : "fr-p-2w ",
    my_4w : "fr-my-4w ",
    my_2w : "fr-my-2w ",
    mx_4w : "fr-mx-4w ",
    pt_2w : "fr-pt-2w ",
    pb_2w : "fr-pb-2w ",
    pl_2w : "fr-pl-2w ",
    pr_2w : "fr-pr-2w ",
    mt_5w : "fr-mt-5w ",
    mt_2w : "fr-mt-2w ",
    mt_3w : "fr-mt-3w ",
    mb_4w : "fr-mb-4w ",
    px_2w : "fr-px-2w ",
    p_1w : "fr-p-1w "
}